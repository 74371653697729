/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

/**
 * Utilities package. Please keep this codebase at minumum.
 * Only add really really really important functions that
 * will make our lives better.
 */

import Immutable from 'immutable';
import Moment from 'moment';

/**
 * Take an immutable list and break it into groups of N members.
 */
let BreakIntoGroups = function(arr, n) {
    let groups = Immutable.List();
    for (let i = 0; i < arr.size; i += n) {
        groups = groups.push(arr.slice(i, i + n));
    }

    return groups;
};

/**
 * Take two immutable objects and compare two subsets defined
 * by the props array.
 *
 * @param Immutable newObj
 * @param Immutable oldObj
 * @param Array props
 *
 * @return Boolean true if both subsets are equal.
 */
let CompareImmutable = function(newObj, oldObj, props) {
    if (!props || props.length === 0) {
        return newObj.equals(oldObj);
    }

    const filterProps = function(v, k) {
        return props.indexOf(k) !== -1;
    };

    const a = newObj.filter(filterProps);

    const b = oldObj.filter(filterProps);

    return a.equals(b);
};

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
let Debounce = function(func, wait, immediate) {
    let timeout;
    return function() {
        // FIXME: remove linter disabling comment
        // eslint-disable-next-line
        const context = this
        const args = arguments;
        let later = function() {
            timeout = null;
            if (!immediate) {func.apply(context, args);}
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) {func.apply(context, args);}
    };
};


/**
 * Take two objects and compare two subsets defined
 * by the props array.
 *
 * @param a
 * @param b
 * @param Array props
 *
 * @return Boolean true if both subsets are equal.
 */
let Compare = function(a, b, props) {
    if (!props || props.length === 0) {
        let keys = Object.keys(a);
        return keys.length === Object.keys(b).length &&
            keys.every( (k) => {
                return a[k] === b[k];
            });
    }
    return props.every( (k) => {
        return a[k] === b[k];
    });
};


let IsMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
        return (IsMobile.Android() || IsMobile.BlackBerry() || IsMobile.iOS() || IsMobile.Opera() || IsMobile.Windows());
    }
};

let FilterCountry = function(option, filter) {
    //filtered is initializing with option to cover empty filter
    let filtered = option;
    if (filter && filter.length > 0) {
        let trimmedFilter = filter.trim();
        filtered = option.label.toLowerCase().indexOf(trimmedFilter.toLowerCase()) !== -1;
    }
    let usCountry = ['us', 'usa', 'united states'];
    if (option.label.toLowerCase() === 'u.s.' && filter.toLowerCase() !== '') {
        filtered = usCountry.some((element) => {
            return element.includes(filter.toLowerCase());
        });
    }
    return filtered;
};

const FindInConstant = (constants, objectId) => {
    return Immutable.fromJS(
        Object.keys(constants)
            .map(k => constants[k])
            .filter(obj => objectId === obj.id)[0]
    );
};

let FormatDate = function(releaseDate, format, invalidValue) {
    let d = Moment(releaseDate);
    if (d.isValid() && releaseDate !== undefined) {
        return d.format(format);
    }
    if (invalidValue) {
        return invalidValue;
    }
    return '-';
};

let FormatReleaseDate = function(releaseDate, type, invalidValue) {
    let d = Moment(releaseDate);
    //Default
    let rVal = d.format('MMMM DD, YYYY');

    switch (type) {
    //FULL
    case 0:
        rVal = d.format('MMMM DD, YYYY');
        break;
    //MonthYear
    case 1:
        rVal = d.format('MMMM YYYY');
        break;
    //SeasonYear
    case 2:
        let month = d.format('MM');
        let season;
        switch (month) {
        case '01':
            season = 'Winter';
            break;
        case '04':
            season = 'Spring';
            break;
        case '07':
            season = 'Summer';
            break;
        case '09':
        case '10':
            season = 'Fall';
            break;
        default:
            season = 'Midseason';
            break;
        }

        rVal = season + d.format(' YYYY');
        break;
    //Year
    case 3:
        rVal = d.format('YYYY');
        break;
    //TBA
    case 4:
        rVal = 'TBA';
        break;

    case 5:
        return releaseDate;
    }

    if (d.isValid() && releaseDate !== undefined) {
        return rVal;
    }
    if (invalidValue) {
        return invalidValue;
    }
    return '-';
};

// Return a thumbnail close to a provided size.
// eslint-disable-next-line default-param-last
let GetThumbnail = (thumbnails = Immutable.List(), width) => {
    if (!thumbnails.size) {
        return;
    }

    if (!width) {
        return thumbnails.get(0);
    }

    return thumbnails.reduce((r, t) => {
        let rValue = Math.abs(r.get('width') - width);
        let tValue = Math.abs(t.get('width') - width);

        if (rValue < tValue) {
            // r is closest to width than t.
            return r;
        }

        return t;
    });
};

/**
 * Take a trigger event and returns true if it was valid key
 * @param event
 * @returns {boolean}
 */
const ENTER_KEY_CODE = 13;
const SPACE_KEY_CODE = 32;
const TRIGGER_KEY_CODES = [ENTER_KEY_CODE, SPACE_KEY_CODE];

const IsNotTriggerKey = (event) => {
    return (event.type === 'keyup' && (TRIGGER_KEY_CODES.indexOf(event.keyCode) === -1));
};

/**
 * Return a function that will skip calling the handler
 * if the event is any key other than one of TRIGGER_KEY_CODES.
 */
const HandleWithTriggerKeys = (handler) => {
    return (event) => {

        if (
            event.type === 'keyup' &&
            (TRIGGER_KEY_CODES.indexOf(event.keyCode) === -1)
        ) {
            // Ignore and exit without calling the handler.
            event.preventDefault();
            return;
        }

        handler(event);
    };
};

/**
 * [SortRows] sort rows by type of order in state.sortDir
 *
 * @param {Object} list      [Immutable List]
 * @param {String} sortBy    [Immutable List]
 * @param {String} sortDir   [Immutable List]
 */
const Sort = function(list, sortBy, sortDir) {
    sortBy = sortBy || 'name';
    sortDir = sortDir || 'desc';

    function sortFunc(value1, value2) {
        // FIXME: find a better way to do this!
        if (typeof value1 === 'number' && typeof value2 === 'number') {
            return value2 - value1;
        }

        if (value1 === null || value1 === undefined) {value1 = '';}
        if (value2 === null || value2 === undefined) {value2 = '';}

        return value2.localeCompare(value1);
    }

    return list.sort((item1, item2) => {
        let value1 = item1.get(sortBy);
        let value2 = item2.get(sortBy);

        if (sortDir === 'desc') {
            return sortFunc(value1, value2);
        }
        return sortFunc(value2, value1);
    });
};

const StartDownload = (href) => {
    // Create a link element and click it to start the download.
    const linkElement = document.createElement('a');
    linkElement.setAttribute(
        'href',
        href
    );
    linkElement.setAttribute('style', 'display: none');
    const evt = document.createEvent('MouseEvents');
    evt.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, linkElement);
    linkElement.dispatchEvent(evt);
    linkElement.remove();
};

const DownloadAsTextFile = (filename, text) => {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
};

export {
    BreakIntoGroups,
    Compare,
    CompareImmutable,
    Debounce,
    DownloadAsTextFile,
    HandleWithTriggerKeys,
    IsMobile,
    IsNotTriggerKey,
    FilterCountry,
    FindInConstant,
    FormatDate,
    FormatReleaseDate,
    GetThumbnail,
    Sort,
    StartDownload
};
