/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';

import {NotificationActions} from '../notification/notification-actions';
import Request from '../request';
import SessionStore from '../session/session-store';

class MFARejectedError extends Error {
    constructor() {
        super();
        return;
    }
}

class MFARejectedCodeError extends Error {
    constructor() {
        super();
        return;
    }
}

class MFARequestError extends Error {
    constructor() {
        super();
        return;
    }
}

class MFAUnknownStatusError extends Error {
    constructor(status, transactionId) {
        super(
            `Unkown MFA transaction status: ${status}. Transaction ID: ${transactionId}`
        );
        this.status = status;
        this.transactionId = transactionId;
        return;
    }
}

class MFAUnregisteredError extends Error {
    constructor(userId) {
        super(
            `User ${userId} is not enrolled in MFA Authentication.`
        );
        this.userId = userId;
        return;
    }
}

// Cache the promise resolver so that it can be called when validating
// the token.
let resolveMFA;
// Ask the server for the current status of a MFA transaction ID.
// Returns a promise that will be resolved only when user approves
// the push notification.
const checkValidMFA = (transactionId, userId, authorization) => {
    return new Promise((resolve, reject) => {
        let getStatus = (t, u) => {
            if (verifyTokenPromise) {
                resolveMFA = resolve;
                return;
            }

            Request.get(`user/${u}/mfa-validate/${t}`).set('Authorization', authorization).then(mfaValidateRes => {
                switch (mfaValidateRes.body.result) {
                case 'REJECTED':
                    NotificationActions.hideModal('mfaPushNotificationModal');
                    mfaInProgress = null;
                    return reject(new MFARejectedError());
                case 'SUCCESS':
                    NotificationActions.hideModal('mfaPushNotificationModal');
                    mfaInProgress = null;
                    return resolve();
                case 'WAITING':
                    // Wait 1 second and try again.
                    return setTimeout(
                        () => getStatus(t, u),
                        1000
                    );
                default:
                    NotificationActions.hideModal('mfaPushNotificationModal');
                    mfaInProgress = null;
                    return reject(
                        new MFAUnknownStatusError(mfaValidateRes.body.result, t)
                    );
                }
            }).catch(reject);
        };

        getStatus(transactionId, userId);

        return;
    });
};

let mfaInProgress;
const RequestMFA = (userId, fromLogin, authorization = SessionStore.getAuthorization()) => {

    if (mfaInProgress) {
        return mfaInProgress;
    }

    let mfaValidCheck = Promise.reject({status: 404});

    if (!fromLogin) {
        mfaValidCheck = Request.get(`user/${userId}/mfa-valid`).set('Authorization', authorization);
    }

    mfaInProgress = mfaValidCheck.then(res => res).catch(err => {
        // Throw any other error so that it gets handled by the caller.
        if (err.status !== 404) {
            mfaInProgress = null;
            throw err;
        }

        return Request.get(`user/${userId}/mfa-request`).set('Authorization', authorization).then(res => res).catch(mfaRequestErr => {
            if (mfaRequestErr.status === 401 && mfaRequestErr.response.body.error === 'MFA_NOT_REGISTERED') {
                return Request.get(`user/${userId}/mfa-activation`).set('Authorization', authorization).then(
                    () => {
                        NotificationActions.showModal('mfaEnrollmentEmailModal');
                        mfaInProgress = null;
                        throw new MFAUnregisteredError(userId);
                    }
                );
            }
            mfaInProgress = null;
            throw new MFARequestError();
        }).then(mfaRequestRes => {
            NotificationActions.showModal('mfaPushNotificationModal');
            return checkValidMFA(mfaRequestRes.body.transactionId, userId, authorization);
        });
    });

    return mfaInProgress;
};

// Cache the promise so that it can be used in
// the checkValidMFA method.
let verifyTokenPromise = null;
const VerifyToken = (
    // eslint-disable-next-line default-param-last
    userId = SessionStore.getState().getIn(['authUser', 'id']),
    verificationCode,
    authorization = SessionStore.getAuthorization()
) => {
    verifyTokenPromise = Request.get(`user/${userId}/mfa-validate/${verificationCode}`).set('Authorization', authorization).then(response => {
        if (response.body.result === 'REJECTED') {
            throw new MFARejectedCodeError();
        }
        // Clear the cached promise.
        verifyTokenPromise = null;
        NotificationActions.hideModal('mfaPushNotificationModal');
        resolveMFA();
        resolveMFA = null;
        mfaInProgress = null;
        return;
    }).catch(err => {
        throw err;
    });

    return verifyTokenPromise;
};

const mfa = {
    checkValidMFA,
    MFARejectedError,
    MFARejectedCodeError,
    MFARequestError,
    MFAUnknownStatusError,
    MFAUnregisteredError,
    RequestMFA,
    VerifyToken
};

export default mfa;
