/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

const VIDEO_CATEGORIES = {
    BEHIND_THE_SCENES: 'Behind the Scenes',
    ELEMENT: 'Element',
    FULL_EPISODE_FEATURE: 'Full Episode/Feature',
    PROMO: 'Promo',
    SALES: 'Sales',
    SAMPLE: 'Sample'
};

const CONSTANTS = {
    ASSET_TYPE: {
        IMAGE: 1,
        VIDEO: 2,
        AUDIO: 3,
        PRESS_RELEASE: 4,
        PRESS_KIT: 5,
        FACT_SHEET: 6,
        SPEC_SHEET: 7,
        SALES_SHEET: 8,
        PROGRAMMING_GRID: 9,
        BULLETIN: 10,
        SYNOPSIS: 11,
        MISCELLANEOUS: 12,
        MERCHANDISE: 13,
        RESEARCH_LINK: 14,
        RESEARCH_FILE: 15,
        BIZ_FILE: 16,
        BIZ_DOC: 17,
        MARKETING: 18,
        SERIES_MARKETING_TOOL_KITS: 19,
        MARS_VIDEO: 20,
        DOCUMENT: 21,
        DOCUMENTS: 21,
        SCRIPT: 22,
        VIDEO_TIMELINE: 23,
        VIDEO_USER_CLIP: 24,
    },
    ASSET_TYPE_NAMES: {
        1: 'image',
        2: 'video',
        3: 'audio',
        4: 'document',
        5: 'document',
        6: 'document',
        7: 'document',
        8: 'document',
        9: 'document',
        10: 'document',
        11: 'document',
        12: 'document',
        13: 'merchandise',
        14: 'document',
        15: 'document',
        16: 'document',
        17: 'document',
        18: 'document',
        19: 'document',
        20: 'document',
        21: 'document',
        22: 'script',
        23: 'video-timeline',
        24: 'video-user-clip',
    },
    ASSET_TYPE_SHOW_NAMES: {
        1: 'Image',
        2: 'Video',
        3: 'Audio',
        4: 'Press Release',
        5: 'Press Kit',
        6: 'Fact Sheet',
        7: 'Spec Sheet',
        8: 'Sales Sheet',
        9: 'Programming Grid',
        10: 'Bulletin',
        11: 'Synopsis',
        12: 'Miscellaneous',
        13: 'Merchandise',
        14: 'Research Link',
        15: 'Research File',
        16: 'Biz File',
        17: 'Biz Doc',
        18: 'Marketing',
        19: 'Series - Marketing Tool Kits',
        20: 'MARS Video',
        21: 'Document',
        22: 'Script',
        23: 'Video Timeline',
        24: 'Video user clip',
    },
    AUDIO: {
        CONTENT_TYPES: {
            EPISODIC_RADIO_SPOT: 1,
            GENERIC_RADIO_SPOT: 2,
            SPOT_REEL: 3,
            THEME_SONG: 4,
            SONG: 5,
            INTERVIEW: 6,
            CLIP: 7,
            AUDIO_STEM: 8
        }
    },
    DELIVERY_TYPES: {
        ON_DEMAND: 0,
        NEEDS_APPROVAL: 1,
        NON_SERVICEABLE: 2,
    },
    IMAGE_CONTENT_TYPE:{
        1: 'Key Art',
        2: 'Outdoor Art',
        3: 'Online',
        4: 'Logo',
        5: 'Photography/Stills',
        6: 'Gallery',
        7: 'SVOD',
        8: 'Client Samples/ Created',
        9: 'Animation Production Material',
        10: 'BTS',
        11: 'Other',
        12: 'Social Media',
        13: 'Events',
        14: 'HBO Max'
    },
    MERCHANDISE_CONTENT_TYPE: {
        1: 'General'
    },
    SCRIPT: {
        1: '890',
        2: 'Anti Piracy',
        3: 'CCSL',
        4: 'Dialogue List',
        5: 'Dubbing Card',
        6: 'Edit Decision List',
        7: 'Forced 890',
        8: 'Forced PAC',
        9: 'Forced SMI',
        10: 'Forced SRT',
        11: 'Forced Text',
        12: 'Format Sheets',
        13: 'Music Cues',
        14: 'PAC',
        15: 'Promo',
        16: 'SMI',
        17: 'Spotting/Subtitle List',
        18: 'SRT',
        19: 'STL',
        20: 'Text',
        21: 'Trailers',
        22: 'Translation',
        23: 'Visa Card',
        24: 'As Broadcast',
        25: 'Shooting'
    },
    VIDEO_CATEGORIES,
    VIDEO_CONTENT_TYPE: {
        101: {description: 'Full Episode', category: VIDEO_CATEGORIES.FULL_EPISODE_FEATURE, playVideoCaption: 'title.synopsis.play-button.watch-episode'},
        102: {description: 'Full Program', category: VIDEO_CATEGORIES.FULL_EPISODE_FEATURE, playVideoCaption: 'title.synopsis.play-button.watch-program'},
        103: {description: 'Feature', category: VIDEO_CATEGORIES.FULL_EPISODE_FEATURE, playVideoCaption: 'title.synopsis.play-button.watch-feature'},
        121: {description: 'Series Launch Promo', category: VIDEO_CATEGORIES.PROMO, playVideoCaption: 'title.synopsis.play-button.watch-promo'},
        122: {description: 'Season Launch Promo', category: VIDEO_CATEGORIES.PROMO, playVideoCaption: 'title.synopsis.play-button.watch-promo'},
        123: {description: 'Mid-Season Promo', category: VIDEO_CATEGORIES.PROMO, playVideoCaption: 'title.synopsis.play-button.watch-promo'},
        124: {description: 'Episodic Promo', category: VIDEO_CATEGORIES.PROMO, playVideoCaption: 'title.synopsis.play-button.watch-promo'},
        125: {description: 'Generic Promo', category: VIDEO_CATEGORIES.PROMO, playVideoCaption: 'title.synopsis.play-button.watch-promo'},
        126: {description: 'Teaser', category: VIDEO_CATEGORIES.PROMO, playVideoCaption: 'title.synopsis.play-button.watch-teaser'},
        127: {description: 'Trailer', category: VIDEO_CATEGORIES.PROMO, playVideoCaption: 'title.synopsis.play-button.watch-trailer'},
        128: {description: 'Next On', category: VIDEO_CATEGORIES.PROMO, playVideoCaption: 'title.synopsis.play-button.watch-promo'},
        129: {description: 'Recap', category: VIDEO_CATEGORIES.PROMO, playVideoCaption: 'title.synopsis.play-button.watch-promo'},
        130: {description: 'TV Spot', category: VIDEO_CATEGORIES.PROMO, playVideoCaption: 'title.synopsis.play-button.watch-promo'},
        131: {description: 'Sneak Peek', category: VIDEO_CATEGORIES.PROMO, playVideoCaption: 'title.synopsis.play-button.watch-promo'},
        141: {description: 'Green Screen', category: VIDEO_CATEGORIES.ELEMENT, playVideoCaption: 'title.synopsis.play-button.watch-video'},
        142: {description: 'Clip', category: VIDEO_CATEGORIES.ELEMENT, playVideoCaption: 'title.synopsis.play-button.watch-video'},
        143: {description: 'Tags', category: VIDEO_CATEGORIES.ELEMENT, playVideoCaption: 'title.synopsis.play-button.watch-video'},
        144: {description: 'B-Roll', category: VIDEO_CATEGORIES.ELEMENT, playVideoCaption: 'title.synopsis.play-button.watch-video'},
        145: {description: 'Graphic', category: VIDEO_CATEGORIES.ELEMENT, playVideoCaption: 'title.synopsis.play-button.watch-video'},
        146: {description: 'Element', category: VIDEO_CATEGORIES.ELEMENT, playVideoCaption: 'title.synopsis.play-button.watch-video'},
        147: {description: 'Source Materials', category: VIDEO_CATEGORIES.ELEMENT, playVideoCaption: 'title.synopsis.play-button.watch-video'},
        148: {description: 'Interstitial', category: VIDEO_CATEGORIES.ELEMENT, playVideoCaption: 'title.synopsis.play-button.watch-video'},
        149: {description: 'Clip Reel', category: VIDEO_CATEGORIES.ELEMENT, playVideoCaption: 'title.synopsis.play-button.watch-video'},
        150: {description: 'VAM', category: VIDEO_CATEGORIES.ELEMENT, playVideoCaption: 'title.synopsis.play-button.watch-video'},
        151: {description: 'Textless Materials', category: VIDEO_CATEGORIES.ELEMENT, playVideoCaption: 'title.synopsis.play-button.watch-video'},
        152: {description: 'Music', category: VIDEO_CATEGORIES.ELEMENT, playVideoCaption: 'title.synopsis.play-button.watch-video'},
        161: {description: 'EPK', category: VIDEO_CATEGORIES.BEHIND_THE_SCENES, playVideoCaption: 'title.synopsis.play-button.watch-epk'},
        162: {description: 'Featurette', category: VIDEO_CATEGORIES.BEHIND_THE_SCENES, playVideoCaption: 'title.synopsis.play-button.watch-featurette'},
        163: {description: 'Interview', category: VIDEO_CATEGORIES.BEHIND_THE_SCENES, playVideoCaption: 'title.synopsis.play-button.watch-video'},
        164: {description: 'Promotional Special', category: VIDEO_CATEGORIES.BEHIND_THE_SCENES, playVideoCaption: 'title.synopsis.play-button.watch-video'},
        165: {description: 'BTS - Finished', category: VIDEO_CATEGORIES.BEHIND_THE_SCENES, playVideoCaption: 'title.synopsis.play-button.watch-video'},
        167: {description: 'Panel', category: VIDEO_CATEGORIES.BEHIND_THE_SCENES, playVideoCaption: 'title.synopsis.play-button.watch-video'},
        168: {description: 'Event Footage', category: VIDEO_CATEGORIES.BEHIND_THE_SCENES, playVideoCaption: 'title.synopsis.play-button.watch-video'},
        169: {description: 'Open Ends', category: VIDEO_CATEGORIES.BEHIND_THE_SCENES, playVideoCaption: 'title.synopsis.play-button.watch-video'},
        170: {description: 'Webisode', category: VIDEO_CATEGORIES.BEHIND_THE_SCENES, playVideoCaption: 'title.synopsis.play-button.watch-video'},
        181: {description: 'Sales Presentation', category: VIDEO_CATEGORIES.SALES, playVideoCaption: 'title.synopsis.play-button.watch-presentation'},
        182: {description: 'Sizzle Reel', category: VIDEO_CATEGORIES.SALES, playVideoCaption: 'title.synopsis.play-button.watch-sizzle'},
        183: {description: 'Brand Extension', category: VIDEO_CATEGORIES.SALES, playVideoCaption: 'title.synopsis.play-button.watch-video'},
        184: {description: 'Integration Clip', category: VIDEO_CATEGORIES.SALES, playVideoCaption: 'title.synopsis.play-button.watch-video'},
        185: {description: 'Sales Kit', category: VIDEO_CATEGORIES.SALES, playVideoCaption: 'title.synopsis.play-button.watch-video'},
        186: {description: 'Marketing Kit', category: VIDEO_CATEGORIES.SALES, playVideoCaption: 'title.synopsis.play-button.watch-video'},
        201: {description: 'Client Produced Samples', category: VIDEO_CATEGORIES.SAMPLE, playVideoCaption: 'title.synopsis.play-button.watch-video'},
        202: {description: 'US Network Promos', category: VIDEO_CATEGORIES.SAMPLE, playVideoCaption: 'title.synopsis.play-button.watch-video'}
    },
};

export {
    CONSTANTS as AssetTypeConstants,
};
