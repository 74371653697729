/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {AlertTypes} from './alert';
import {NotificationActions, NotificationConstants} from './notification-actions';
import {Dispatcher} from '../flux-helpers';


class NotificationStore extends ReduceStore {
    getInitialState() {
        return Immutable.fromJS({
            alertTimeout: null,
            mfaEnrollmentEmailModal: false,
            mfaPushNotificationModal: false,
            notifications: []
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case NotificationConstants.ALERT.SHOW:
            if (!action.noDuplicates || (action.noDuplicates && state.get('notifications').findIndex(n => n.get('message') === action.message) === -1)) {
                // Don't allow more than 5 notifications.
                if (state.get('notifications').size > 4) {
                    state = state.update('notifications', notifications => notifications.shift());
                }

                // Add the new notification in last position.
                state = state.update('notifications', notifications => {
                    return notifications.push(Immutable.Map({
                        arguments: action.arguments,
                        isAlert: true,
                        message: action.message,
                        type: action.type
                    })).map(
                        // And renumerate all notifications.
                        (n, i) => n.set('index', i)
                    );
                });

                // Update the first notification to add the dismiss timeout
                // if needed.
                state = state.updateIn(['notifications', 0], n => this.setDismissTimeout(n));
            }
            break;

        case NotificationConstants.CLEAR:
            state = this.getInitialState();
            break;

        case NotificationConstants.MODAL.HIDE:
            if (action.modalId) {
                state = state.set(action.modalId, false);
            }
            break;

        case NotificationConstants.HIDE:
            const index = action.index;

            const notification = state.getIn(['notifications', index], Immutable.Map());
            // Invoke callbacks and clear timeouts.
            notification.get('onDismiss', () => void {})();
            clearTimeout(notification.get('hideTimeout'));

            state = state.update(
                'notifications', ns => ns.splice(
                    // Remove notification from array.
                    index, 1
                ).map(
                    // And renumerate all others.
                    (n, i) => n.set('index', i)
                )
            );

            // Check first notification, if it has dismissAfter but no
            // timeout, then create it.
            state = state.updateIn(['notifications', 0], n => this.setDismissTimeout(n));
            break;

        case NotificationConstants.MODAL.SHOW:
            let item = state.get('notifications').first();

            if (item && item.get('type') === AlertTypes.ALERT_SUCCESS.name) {
                state = state.update('notifications', notifications => notifications.shift());
            }

            state = state.update('notifications', notifications => notifications.push(Immutable.Map({
                type: action.type,
                title: action.title,
                message: action.message,
                confirmText: action.confirmText,
                show: true,
                onConfirm: action.onConfirm,
                onCancel: action.onCancel
            })));
            break;

        case NotificationConstants.MODAL.SHOW_ID:
            state = state.set(action.modalId, true);
            break;

        case NotificationConstants.MODAL.SHOW_ON_TOP:
            state = state.update('notifications', notifications => notifications.unshift(Immutable.Map({
                type:action.type,
                title:action.title,
                message: action.message,
                confirmText: action.confirmText,
                show: true,
                onConfirm: action.onConfirm,
                onCancel: action.onCancel})));
            break;

        default:
            break;
        }
        return state;
    }

    setDismissTimeout(n) {
        if (n === undefined) {return;}

        const dismissAfter = n.getIn(['type', 'dismissAfter']);
        if (dismissAfter && !n.get('hideTimeout')) {
            n = n.set('hideTimeout', setTimeout(NotificationActions.hide, dismissAfter));
        }

        return n;
    }
}

export default new NotificationStore(Dispatcher);
