/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {createBrowserHistory} from 'history';
import {Subject} from 'rxjs';

const BrowserHistory = createBrowserHistory();

let o = new Subject();

// This is an special actions object, it forces a Flux workflow
// into the react-router.
class RouterActions {
    back() {
        BrowserHistory.goBack();
        return;
    }

    forbidden() {
        BrowserHistory.replace('/access-denied');
    }

    // Redirect the user inside the app. Here since we are skipping the
    // router lifecycle
    notFound() {
        // Redirect the user.
        BrowserHistory.replace('/errors/not-found');
        return;
    }

    redirect(to, replace) {
        if (to[0] !== '/') {
            to = `/${to}`;
        }
        // Redirect the user.
        if (replace) {
            BrowserHistory.replace(to);
        } else {
            BrowserHistory.push(to);
        }
        return;
    }

}

const ra = new RouterActions();

export {
    BrowserHistory,
    ra as RouterActions,
    o as ObservableRoute
};
