/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import SuperAgent from 'superagent';
import url from 'url';

import Config from '../config/config';
import {SessionActions} from '../session/session-actions';
import SessionStore from '../session/session-store';


const ACCEPT_HEADER = 'application/vnd.wbtv-v3+json,application/vnd.wbtv-v2+json;q=0.2,application/vnd.wbtv-v1+json;q=0.1';
const ACCEPT_V1_HEADER = 'application/vnd.wbtv-v1+json';
const ACCEPT_V2_HEADER = 'application/vnd.wbtv-v2+json';
const ACCEPT_V3_HEADER = 'application/vnd.wbtv-v3+json';

/**
 * Enable promises in SuperAgent.
 */
SuperAgent.Request.prototype.canceler = function(canceler) {
    this.cancelerSubscription = canceler.subscribe(() => {
        this.abort();
    });

    return this;
};

// its hard to test this with SuperAgent_mock
// so will ignore next.
/* istanbul ignore next */
let getAgent = function() {
    // don't patch end if was patched already
    if (SuperAgent._withSession) {
        return SuperAgent;
    }

    SuperAgent._withSession = true;

    // Tell SuperAgent how to parse the API response body.
    SuperAgent.parse[ACCEPT_V1_HEADER] = JSON.parse;
    SuperAgent.parse[ACCEPT_V2_HEADER] = JSON.parse;
    SuperAgent.parse[ACCEPT_V3_HEADER] = JSON.parse;

    // Patch Request.end()
    let oldThen = SuperAgent.Request.prototype.then;
    SuperAgent.Request.prototype.then = function(cb, errCb) {
        // set logged in request headers
        if (localStorage.getItem('__token') && !this.get('Authorization')) {
            this.set('Authorization', localStorage.getItem('__token'));
        }

        if (this.get('Accept') === undefined) {
            this.set('Accept', ACCEPT_HEADER);
        }

        if (this.method !== 'GET' && this.get('Content-Type') === undefined) {
            this.set('Content-Type', 'application/json');
        }

        // Set language accept header based on user's selection on login screen (or profile dropdown)
        const selectedLanguage = localStorage.getItem('__locale');
        if (selectedLanguage) {
            this.set('Accept-Language', selectedLanguage);
        }

        return new Promise((resolve, reject) => {
            oldThen.call(
                this,
                res => {
                    if (this.cancelerSubscription) {
                        this.cancelerSubscription.unsubscribe();
                    }

                    if (res.ok) {
                        // Reset the timeout timer, but do it later, please.
                        setTimeout(SessionActions.clearTimeout);
                    }

                    if (cb) {
                        return resolve(cb(res));
                    }

                    return resolve();
                },
                err => {
                    if (err.status === 404 && SessionStore.isSessionValid()) {
                        //Session is active, but received a 404, check heartbeat to see if it has active session
                        SessionActions.keepAlive();
                    }

                    if (errCb) {
                        return resolve(errCb(err));
                    }

                    return reject(err);
                }
            );
        });
    };

    return SuperAgent;
};

let resolveURL = function(uri) {
    if (!url.parse(uri).protocol) {
        // If Config.ApiBaseUrl does not end with "/" we will get http errors
        if (!Config.ApiBaseUrl[Config.ApiBaseUrl.length -1 ] === '/') {
            throw new Error(`Config.ApiBaseUrl must end with slash: "${Config.ApiBaseUrl}"`);
        }

        // If URI begins with "/" we will get http errors
        if (uri[0] === '/') {
            throw new Error(`Request URI must not begin with slash: "${uri}"`);
        }

        return url.resolve(Config.ApiBaseUrl, uri);
    }

    return uri;
};

// its hard to test this with SuperAgent_mock
// so will ignore next.
/* istanbul ignore next */
let agent = {
    del: function(uri) {
        return getAgent().del(resolveURL(uri));
    },

    get: function(uri) {
        return getAgent().get(resolveURL(uri));
    },

    post: function(uri) {
        return getAgent().post(resolveURL(uri));
    },

    put: function(uri) {
        return getAgent().put(resolveURL(uri));
    }
};

export default agent;
export {
    ACCEPT_HEADER,
    ACCEPT_V1_HEADER,
    ACCEPT_V2_HEADER,
    ACCEPT_V3_HEADER,
    getAgent,
    resolveURL
};
