/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import {v4 as UUID} from 'uuid';

import SerieNavigationStore from '../../titles/serie-navigation/serie-navigation-store';
import querySelectorAll from '../../utils/query-selector-all';
import {GetThumbnail} from '../../utils/utils';
import {timecodeFactory} from '../timecode/time-code';


/**
 * @param {number} hitTime
 * @returns {number}
 */
export const getClipIn = (hitTime) => {
    // Clip should start 5 seconds before search hit
    return Math.max(hitTime - 5, 0);
};

/**
 * @param {number} hitTime
 * @param {number} clipIn
 * @param {number} videoDuration
 * @returns {number}
 */
export const getClipOut = (hitTime, clipIn, videoDuration) => {
    // Clip should end 25 seconds after the hit and The out point should be 30 seconds after the In point
    return Math.min(Math.max(hitTime + 25, clipIn + 30), videoDuration);
};

/**
 * @param timecode
 * @param {number} hitInFrame
 * @returns {number}
 */
const getTcInFrame = (timecode, hitInFrame) => {
    const hitTime = timecode.framesToSeconds(hitInFrame);
    const clipIn = getClipIn(hitTime);
    return timecode.secondsToFrames(clipIn);
};

/**
 * @param timecode
 * @param {number} hitInFrame
 * @param {number} videoDuration
 * @returns {number}
 */
const getTcOutFrame = (timecode, hitInFrame, videoDuration) => {
    const hitTime = timecode.framesToSeconds(hitInFrame);
    const clipIn = getClipIn(hitTime);
    const clipOut = getClipOut(hitTime, clipIn, videoDuration);
    return timecode.secondsToFrames(clipOut);
};

export function convertVideoFragmentToClip(videoFragment, thumbnails = {}) {
    const {
        browseTitleList,
        contentType,
        episodeNumber,
        id,
        rightsNotes,
        rootTitleId,
        rootTitleDisplayName,
        tcInFrameRounded: hitInFrame,
        seasonNumber,
        text,
        videoDurationFrames,
        videoFrameRate: frameRate,
        videoId,
        videoRightsMediaType,
        videoRightsTermType,
        videoRightsTerritoryType,
        videoRightsType,

        dialogue = '',
        tags = [],
        settings = [],
        characters = [],
        thumbnailUrl = '',
        type = 'MOMENT',
        name: videoName = '',
    } = videoFragment;

    const thumbnailsUrl = [];

    const timecode = timecodeFactory({frameRate});
    const videoDuration = timecode.framesToSeconds(videoDurationFrames);

    const tcInFrameRounded = getTcInFrame(timecode, hitInFrame);
    const tcOutFrameRounded = getTcOutFrame(timecode, hitInFrame, videoDuration);

    const clipDurationInSec = Math.round(timecode.framesToSeconds(tcOutFrameRounded - tcInFrameRounded));
    const duration = timecode.secondsToTime(clipDurationInSec, false);

    const displayName = detectDisplayName(seasonNumber, episodeNumber, timecode, hitInFrame);

    const thumbnailList = (thumbnails[`${videoId}`] || {}).thumbnailList || [];
    const thumbnail = GetThumbnail(Immutable.fromJS(thumbnailList), 400);

    if (thumbnailUrl) {
        thumbnailsUrl.push(thumbnailUrl);
    }
    if (thumbnail && thumbnail.get('thumbnailUrl')) {
        thumbnailsUrl.push(thumbnail.get('thumbnailUrl'));
    }

    return {
        browseTitleList,
        clipDurationInSec,
        contentType,
        displayName,
        duration,
        episodeNumber,
        frameRate,
        hitInFrame,
        id,
        rightsNotes,
        rootTitleId,
        rootTitleDisplayName,
        seasonNumber,
        tcInFrameRounded,
        tcOutFrameRounded,
        thumbnailsUrl,
        text,
        videoId,
        videoRightsMediaType,
        videoRightsTermType,
        videoRightsTerritoryType,
        videoRightsType,
        type,
        name: videoName,

        matches: createListOfMatches(extractHighlights(dialogue), tags, settings, characters),
        tags: {
            dialogue,
            tags,
            settings,
            characters,
        }
    };
}

export function enrichClipsViaSerieNavigation(clips, seasons) {
    return clips.map((clip) => {
        const titleId = clip.getIn(['browseTitleList', 0, 'id']);
        if (titleId) {
            const frameRate = clip.get('frameRate');
            const timecode = timecodeFactory({frameRate});
            let {seasonNumber, episodeNumber} = SerieNavigationStore.detectSeasonAndEpisodeNumbers(seasons, titleId);
            if (!seasonNumber && !episodeNumber) {
                seasonNumber = clip.get('seasonNumber');
                episodeNumber = clip.get('episodeNumber');
            }
            const displayName = detectDisplayName(seasonNumber, episodeNumber, timecode, clip.get('hitInFrame'));
            return clip.merge({
                displayName,
                seasonNumber,
                episodeNumber,
            });
        }
        return clip;
    });
}

function detectDisplayName(seasonNumber, episodeNumber, timecode, hitInFrame) {
    let se = '';
    if (seasonNumber) {
        se = `S${seasonNumber}`;
    }
    if (episodeNumber) {
        se += `E${episodeNumber.toString().padStart(2, '0')}`;
    }
    se = se ? `${se} ` : '';
    return `${se}@ ${timecode.framesToTime(hitInFrame, false)}`;
}

function createListOfMatches(dialogues, tags, settings, characters) {
    return [
        ...matchesEntityCreator('dialogue', dialogues),
        ...matchesEntityCreator('character', characters),
        ...matchesEntityCreator('setting', settings),
        ...matchesEntityCreator('tag', tags),
    ];
}

function matchesEntityCreator(type, array) {
    return array.map((text) => ({text, type, uid: UUID()}));
}

function extractHighlights(html) {
    const tags = [];
    const cache = {};
    Array.from(querySelectorAll(html)).forEach(el => {
        const text = el.textContent.trim();
        if (!text || cache[text.toLowerCase()]) {
            return;
        }
        cache[text.toLowerCase()] = true;
        tags.push(text);
    });
    return tags;
}
