/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */
import {STATION_SUBSCRIPTION_CT_TYPES_MAP} from '@wbdt-sie/brainiac-web-common';

const CONSTANTS = {
    CANCEL_REMOVE: 'order-actions.cancel_remove',
    CART: {
        ADD_OK: 'order-actions.cart.add_ok',
        SAVE_OK: 'order-actions.cart.save_ok',
        GET_ITEMS_AMOUNT: 'order-actions.cart.get_items_amount',
    },
    CLEAR_DOWNLOADS: 'order-actions.clear_downloads',
    CLIENT: {
        SELECT:'client.select',
        CLEAR:'client.clear',

    },
    DELIVERY_INFO: {
        GET: {
            SUCCESS: 'deliveryInfo.get.success'
        }
    },
    DELIVERY_SPEED_TYPE: {
        GET: {
            SUCCESS: 'deliverySpeedType.get.success'
        },
        INSTANT: {
            iconClass: 'glyphicon glyphicon-circle-arrow-up',
            toggleText: 'cart.view.delivery-speed.toggle.instant'
        },
        NEAR_INSTANT: {
            iconClass: 'glyphicon glyphicon-circle-arrow-right',
            toggleText: 'cart.view.delivery-speed.toggle.near-instant'
        },
        SLOW: {
            iconClass: 'glyphicon glyphicon-circle-arrow-down',
            toggleText: 'cart.view.delivery-speed.toggle.slow'
        },
    },
    DOWNLOAD_COMPLETE: 'order-actions.download_complete',
    DOWNLOAD_COMPLETE_ALL: 'order-actions.download_complete_all',
    DOWNLOAD_RENDITION_TYPE: {
        HiRes: {id: 'HiRes', name: 'High-Res', type: 'HiRes'},
        LoRes: {id: 'LoRes', name: 'Low-Res', type: 'LoRes'},
        Source: {id: 'Source', name: 'Source', type: 'Source'}
    },
    EMAIL_DELIVERY_ITEM_TYPES: {
        IMAGES: {itemType: 'image', message: 'cart.view.please-note.images'},
        MOMENTS: {itemType: 'video-user-clip', message: 'cart.view.please-note.moments'},
        QUICK_DELIVERY_VIDEOS: {itemType: 'video', message: 'cart.view.please-note.quick-delivery-videos'},
        TRADITIONAL_DELIVERY_VIDEOS: {itemType: 'video', message: 'cart.view.please-note.traditional-delivery-videos'},
    },
    ORDER: {
        BY_CLIENT_ID: {
            GET: {
                SUCCESS: 'order.by_client_id.get.success'
            }
        },
        CHECK_STATUS_FULFILLED: {
            SUCCESS: 'order-actions.order.status.fulfilled.success'
        },
        CLEAR: 'order.clear',
        FILTERS: {
            CLIENT: {
                APPEND: 'order.filters.client.append',
                SUCCESS: 'order.filters.client.success'
            },
            REQUESTER: {
                APPEND: 'order.filters.requester.append',
                SUCCESS: 'order.filters.requester.success'
            }
        },
        GET: {
            ERROR: 'order-actions.order.get.error',
            START: 'order-actions.order.get.start',
            SUCCESS: 'order-actions.order.get.success'
        },
        HISTORY: {
            SUCCESS: 'order-actions.order.history.get.success'
        },
        ITEM: {
            MODIFY: 'order.item.modify',
        },
        ITEMS: {
            GET: {
                ERROR: 'order-actions.get-items.error',
                START: 'order-actions.get-items.start',
                SUCCESS: 'order-actions.get-items.success'
            }
        },
        METADATA: {
            GET: {
                SUCCESS: 'order-actions.order.metadata.get.success'
            }
        },
        METADATA_TITLE: {
            GET: {
                START:'order-actions.order.metadata-title.get.start',
                SUCCESS: 'order-actions.order.metadata-title.get.success',
                ERROR: 'order-actions.order.metadata-title.get.error'
            }
        },
        RELOAD_TO_CART: {
            CLEAR: 'order.reload_to_cart.clear',
            SET: 'order.reload_to_cart.set'
        },
        SORT: {
            SET: 'sort.set'
        },
        SORT_TYPE: {
            CLIENT_NAME: {id: 'CLIENT_NAME', name: 'client_name'},
            COMPLETE_DATE: {id: 'COMPLETE_DATE', name: 'datecompleted'},
            DUE_DATE: {id: 'DUE_DATE', name: 'duedate'},
            IMAGE_COUNT: {id: 'IMAGE_COUNT', name: 'image_count'},
            ORDER_DATE: {id: 'ORDER_DATE', name: 'order_date'},
            ORDER_NAME: {id: 'ORDER_NAME', name: 'name'},
            ORDER_NUMBER: {id: 'ORDER_NUMBER', name: 'order_number'},
            ORDER_STATUS: {id: 'ORDER_STATUS', name: 'status'},
            PACKAGE_NUMBER: {id: 'PACKAGE_NUMBER', name: 'id'},
            REQUEST_DATE: {id: 'REQUEST_DATE', name: 'request_date'},
            REQUESTER: {id: 'REQUESTER', name: 'requester'},
            SUBSCRIPTION_DATE: {id: 'SUBSCRIPTION_DATE', name: 'subscription_date'},
            SUBSCRIPTION_TYPE: {id: 'SUBSCRIPTION_TYPE', name: 'subscription_type'},
            TITLE: {id: 'TITLE', name: 'title_name'},
            VIDEO_COUNT: {id: 'VIDEO_COUNT', name: 'video_count'},
            VIDEO_USER_CLIP_COUNT: {id: 'VIDEO_USER_CLIP_COUNT', name: 'video_user_clip_count'},
        },
        STATUS: {
            0: 'Cart mode',
            1: 'In process',
            2: 'In process but modified',
            3: 'Approved',
            4: 'Approved with modification',
            5: 'Fulfilled',
            6: 'Denied',
            10: 'Saved',
            11: 'By client in process',
            12: 'Standing order in process',
            21: 'In process but modified by field officer',
            50: 'Mam false',
            51: 'Fulfilment',
            60: 'Requested',
            61: 'Partially Fulfilment',
            62: 'Error'
        },
        STATUS_BY_NAME: {
            CART_MODE: 0,
            IN_PROCESS: 1,
            IN_PROCESS_BUT_MODIFIED: 2,
            APPROVED: 3,
            APPROVED_WITH_MODIFICATIONS: 4,
            FULFILLED: 5,
            DENIED: 6,
            SAVED: 10,
            BY_CLIENT_IN_PROCESS: 11,
            STANDING_ORDER_IN_PROCESS: 12,
            IN_ORDER_BUT_MODIFIED_BR_FIELD_OFFICER: 21,
            MAM_FALSE: 50,
            FULFILMENT: 51,
            REQUESTED: 60,
            PARTIALLY_FULFILMENT: 61,
            ERROR: 62
        },
        STATUS_CHANGE_HIDE: 'order.status.change.hide',
        STATUS_CHANGE_SHOW: 'order.status.change.show',
        TAB: {
            ORDERS_IN_PROGRESS: {id: 1, url: 'in-progress'},
            MY_ORDERS: {id: 2, url: 'my-orders'},
            REQUESTS_SENT_ME: {id: 3, url: 'sent-to-me'},
            MY_SAVED_CARTS: {id: 4, url: 'saved-carts'},
            ALL_ORDERS: {id: 5, url: 'all-orders'},
            SUBSCRIPTIONS: {id: 6, url: 'user-subscription'},
            STANDING_ORDERS: {id: 7, url: 'standing-orders'},
            METADATA: {id: 8, url: 'metadata'}
        },
        TOGGLE_SELECT: 'order.select',
        TOGGLE_SELECT_ALL: 'order.select.all',
        UNSUBSCRIBE_CLIENT_HIDE: 'order.unsubscribe.client.hide',
        UNSUBSCRIBE_CLIENT_SHOW: 'order.unsubscribe.client.show',
        UPDATE: 'order.update',
    },
    ORDERS: {
        GET: {
            ERROR: 'order-actions.orders.get.error',
            START: 'order-actions.orders.get.start',
            SUCCESS: 'order-actions.orders.get.success'
        },
    },
    ORDER_FILTERS: {
        '/orders': {
            client: true,
            dateAdded: true,
            dueDate: true,
            orderName: true,
            orderNumber: true,
            requester: true,
            status: [true, false, false, true, true],
        },
        '/orders/my-orders': {
            client: true,
            dateAdded: true,
            dueDate: true,
            orderName: true,
            orderNumber: true,
            status: [true, true, true, true, true],
        },
        '/orders/sent-to-me': {
            client: true,
            dateAdded: true,
            dueDate: true,
            orderName: true,
            orderNumber: true,
            requester: true,
        },
        '/orders/my-saved-carts': {
            client: true,
            dateAdded: true,
            dueDate: true,
            orderName: true,
            orderNumber: true,
        },
        '/orders/all-orders': {
            client: true,
            dateAdded: true,
            dueDate: true,
            orderName: true,
            orderNumber: true,
            requester: true,
            status: [true, true, true, true, true],
        },
        '/orders/subscriptions': {},
        '/orders/standing-orders': {},
        '/orders/metadata': {
            status: [],
        },
    },
    REMOVE: 'order-actions.remove',
    REMOVE_OK: 'order-actions.remove_ok',
    REQUESTER: {
        GET: {
            SUCCESS: 'requester.get.success'
        }
    },
    SUBSCRIPTION_CONTENT_TYPES: STATION_SUBSCRIPTION_CT_TYPES_MAP,
    TITLE: {
        GET: {
            SUCCESS: 'title.get.success'
        },
        UNSUBSCRIBE_CLIENT: {
            CLEAR: 'order.unsubscribe_client.clear',
            SET: 'order.unsubscribe_client.set'
        },
    },
    UPDATE_VIDEO_FILE_FORMAT_ITEMS: {
        SUCCESS: 'order-actions.update-video-file-format-items.success'
    },
    USER: {
        GET: {
            SUCCESS: 'user.get.success'
        }
    },
    USERS_LIST: {
        GET: {
            SUCCESS: 'usersList.get.success'
        }
    },
    VIDEO_FILE_FORMAT_OPTIONS: {
        XDCAM: {id: 'XDCAM', idForAPI: 'XDCAM', name: 'cart.view.table.video-format.xdcam'},
        H_264: {id: 'H.264', idForAPI: 'H.264', name: 'cart.view.table.video-format.mp4'},
        PRO_RES: {id: 'ProRes', idForAPI: 'PRO_RES', name: 'cart.view.table.video-format.prores'}
    },
    toArray: function(constant) {
        return Object.keys(this[constant])
            .map(k => this[constant][k])
            .sort((a, b) => a.name.localeCompare(b.name));
    }
};

export {
    CONSTANTS as OrderConstants,
};
