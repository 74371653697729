/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {GetVideoDisplayName} from '@wbdt-sie/brainiac-web-common';
import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal} from 'react-bootstrap';

import {ClipActions} from './clip-actions';
import ClipStore, {CreateClipValidations} from './clip-store';
import {FormItem, FormRow} from '../../form/form';
import {MessagesContext} from '../../messages/messages-context';
import {AlertsWarnings} from '../../notification/alert-warning';
import {PlayerActions} from '../../player/player-actions';
import PlayerStore from '../../player/player-store';
import {AssetTitleConstants} from '../../titles/asset-title/asset-title-actions';
import SerieNavigationStore from '../serie-navigation/serie-navigation-store';

class CreateClipModal extends React.Component {
    static get propTypes() {
        return {
            clip: PropTypes.instanceOf(Immutable.Map),
            searchTerm: PropTypes.string,
            show: PropTypes.bool.isRequired,
        };
    }

    static get defaultProps() {
        return {
            clip: null,
            searchTerm: '',
        };
    }

    static calculateState() {
        return {
            video: PlayerStore.getState().get('video'),
            selectedVideo: PlayerStore.getState().get('selectedVideo'),
        };
    }

    static getStores() {
        return [PlayerStore];
    }

    constructor(props) {
        super(props);
        this.handleSubmitEvent = this.handleSubmitEvent.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onCreate = this.onCreate.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            (this.state.video.get('id') && !prevState.video.get('id')) ||
            (this.props.clip?.get('name') === '' && prevProps.clip?.get('name') === '')

        ) {
            setTimeout(() => {
                ClipActions.updateClip(Immutable.fromJS({name: this.generateSuggestedClipName()}));
            }, 10);
        }
    }

    static contextType = MessagesContext;

    handleSubmitEvent(event) {
        event.preventDefault();
        this.onCreate();
    }

    onClose() {
        ClipActions.closeClipModal();
    }

    onCreate() {
        const validations = ClipStore.getValidations();
        const {clip} = this.props;
        if (validations.length) {
            // touch the model, for showing error messages
            ClipActions.updateClip(Immutable.fromJS({isTouched: true}));
            return;
        }
        ClipActions.saveClip(clip);
        this.onClose();
        if (clip.get('id')) {
            PlayerActions.hidePlayer();
        }
    }

    updateClip(attr, value) {
        ClipActions.updateClip(Immutable.fromJS({[attr]: value}));
    }

    generateSuggestedClipName() {
        const showName = ClipStore.getState().get('showName');
        const {clip, searchTerm} = this.props;
        let description, video;
        if (searchTerm) {
            video = ClipStore.getState().get('video');
            description = searchTerm;
        } else {
            const currentVideo = this.state.video;
            const selectedVideo = this.state.selectedVideo;

            if (selectedVideo.size) {
                video = selectedVideo;
            } else {
                video = currentVideo;
            }

            description = GetVideoDisplayName(video, AssetTitleConstants);
        }

        let episodeNumber = 0;
        let seasonNumber = 0;
        if (video && isFullEpisode(video)) {
            const seasons = SerieNavigationStore.getState().getIn(['serieNavigation', 'seasons']);
            const sanData = SerieNavigationStore.detectSeasonAndEpisodeNumbers(seasons, clip.get('titleId'));
            episodeNumber = sanData.episodeNumber;
            seasonNumber = sanData.seasonNumber;
        }
        return ClipStore.generateClipName(showName, seasonNumber, episodeNumber, description);
    }

    render() {
        const validations = ClipStore.getValidations();
        const {clip, searchTerm, show} = this.props;
        const {messages} = this.context.intl;
        const isCreateClip = clip && !clip.get('id');

        let warnings;
        if (clip && clip.get('isTouched')) {
            warnings = (
                <AlertsWarnings
                    title={messages['signup.form.error.message']}
                    validations={validations}
                />
            );
        }

        let title = '', submitTittle = '';
        if (searchTerm) {
            title = 'title.modal.moment.create.title';
            submitTittle = 'title.modal.moment.create.add-to-cart';
        } else if (isCreateClip) {
            title = 'title.modal.moment.create.title';
            submitTittle = 'title.modal.moment.create.add-to-cart';
        } else {
            title = 'title.modal.moment.edit.title';
            submitTittle = 'title.modal.moment.edit.title';
        }

        let createButtonClass = 'glyphicon-pencil';
        if (isCreateClip) {
            createButtonClass = 'glyphicon-shopping-cart';
        }

        return (
            <Modal show={clip && show} onHide={this.onClose}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="modal-title text-center text-uppercase">{messages[title]}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={this.handleSubmitEvent}>
                        <FormRow>
                            <FormItem attr="name"
                                label={messages['title.modal.moment.create.name']}
                                placeholder={messages['title.modal.moment.create.name.placeholder']}
                                model={this.props.clip}
                                setter={this.updateClip}
                                type="text"
                                autoFocus={true}
                                validations={CreateClipValidations.name.validations}
                            />
                        </FormRow>
                    </form>
                    {warnings}
                    <div className="form-group">
                        <label>{messages['title.modal.moment.create.note']}</label><br/>
                        <em>{messages['title.modal.moment.create.note.description']}</em>
                        <br/>
                        <code className="text-primary">{generateFileName(clip.get('name'))}</code>
                    </div>
                </Modal.Body>
                <Modal.Footer className="padding-all-20">
                    <Button bsStyle="default" className="pull-left btn-secondary" onClick={this.onClose}>{messages['common.close']}</Button>
                    <Button bsStyle="primary" className="pull-right" disabled={!!validations.length} onClick={this.onCreate}>
                        <span className={ClassNames('glyphicon', createButtonClass)}/>&nbsp;
                        {messages[submitTittle]}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default Container.create(CreateClipModal);

export {CreateClipModal};

// FIXME: INCLUDE THESE IN CLASS
function isFullEpisode(video) {
    return video.get('contentType', '')?.toString() === AssetTitleConstants.CONTENT_TYPES.VIDEO.FULL_EPISODE_OR_FEATURE.TYPES.FULL_EPISODE;
}

function generateFileName(clipName = '') {
    // from AzureBlobUtil#getSanitizedFileName
    clipName = clipName.replace(/[^a-zA-Z0-9\-.]+/g, '') || 'moment';
    return clipName.substr(0, 25) + '.mxf';
}
