/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {Dispatcher} from '../flux-helpers';
import {NotificationActions} from '../notification/notification-actions';
import {PlayerActions} from '../player/player-actions';
import Request from '../request';

const CONSTANTS = {
    BAFTA: {
        VIDEO: {
            GET: {
                SUCCESS: 'bafta.video.get.success'
            }
        },
        CLEAR: 'bafta.clear',
    }
};

class BaftaActions {
    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.BAFTA.CLEAR
        });
    }

    getUserVideo() {
        return Promise.all([
            Request.get('title/web/title'),
            Request.get('asset/web/video'),
        ]).then(responses => {
            const titleResults = Immutable.fromJS(responses[0].body);
            const videoResults = Immutable.fromJS(responses[1].body);

            const title = titleResults.get('results', []).first();
            const video = videoResults.get('results', []).first();

            return Promise.props({
                title,
                video,
                asset: Request.get(`asset/video/${video.get('id')}`) // To properly set the forensicUrlRequired field
            });
        }).then(data => {
            let asset = Immutable.fromJS(data.asset.body);
            data.video = data.video.set('forensicUrlRequired', asset.get('forensicUrlRequired'));

            return this.prepareVideo(data.video, data.title);
        });
    }

    // Get video streams and, if needed, the DRM token.
    /* istanbul ignore next */
    prepareVideo(video, title) {
        const videoId = video.get('id');

        let streamReq;
        if (video.get('forensicUrlRequired')) {
            streamReq = Request.get(`asset/video/${videoId}/forensic-url`);
        } else {
            streamReq = Request.get('asset/video/streamURL').query({'video-id': videoId}).then(r => ({body: r.body[0]}));
        }

        return streamReq.then(streamRes => {
            let streams = Immutable.fromJS(streamRes.body);

            if (!streams) {
                // No streams for this video.
                NotificationActions.showAlertDanger('player.errors.no-sources');
                let err = new Error(`Video ${videoId} does not have any playable sources.`);
                err.defaultPrevented = true;
                throw err;
            }

            let {hasDRM, err} = PlayerActions._hasDRM(streams, true);
            if (err) {
                // Throw error (instead of return) to reject the promise.
                throw err;
            }

            let tokenResponse;
            if (hasDRM) {
                streams = streams.set('assetId', videoId);
                tokenResponse = PlayerActions._requestToken(streams);
            }

            return Promise.props({
                streams,
                tokenResponse,
                // The following action gets the watchlist (creating it if needed)
                // and dispatches new actions to the PlayerStore. It resolves to an
                // empty promise if everything was ok.
                watchlist: PlayerActions.getWatchlistDetail(videoId)
            });
        }).then(data => {
            let token;
            if (data.tokenResponse) {
                token = Immutable.fromJS(data.tokenResponse.body);
            }

            video = video.set('assetId', video.get('id'));
            video = video.set('streams', Immutable.List([data.streams]));
            video = video.set('drmToken', token);

            Dispatcher.dispatch({
                actionType: CONSTANTS.BAFTA.VIDEO.GET.SUCCESS,
                video: video,
                title: title
            });

            return;
        }).catch(err => {
            if (!err.defaultPrevented) {
                NotificationActions.showAlertDanger('player.errors.streams.400');
            }
            throw err;
        });
    }

}

let actions = new BaftaActions();

export {
    actions as BaftaActions,
    CONSTANTS as BaftaConstants,
};
