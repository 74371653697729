/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Dispatcher} from 'flux';

let d = new Dispatcher();

let dispatch = d.dispatch;
d.dispatch = function(action) {
    if (!action.actionType) {
        throw new Error('Dispatcher violation: can not dispatch an action with an empty actionType.');
    }

    dispatch.call(d, action);
    return;
};

export default d;
