/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDatePicker from 'react-datepicker';

import {MessagesContext} from '../messages/messages-context';
import {IsMobile} from '../utils/utils';

export default class DatePicker extends React.Component {
    static get propTypes() {
        return {
            className: PropTypes.string.isRequired,
            dateFormat: PropTypes.string.isRequired,
            disabled: PropTypes.bool.isRequired,
            maxDate: PropTypes.string.isRequired,
            minDate: PropTypes.string.isRequired,
            onChange: PropTypes.func.isRequired,
            placeholder: PropTypes.string.isRequired,
            selected: PropTypes.string.isRequired
        };
    }

    static contextType = MessagesContext;

    fromMoment(value) {
        if (!value || !value.isValid()) {
            return '';
        }

        return value.format('yyyy-MM-DD');
    }

    handleChange(event) {
        let momentValue;
        if (event !== null) {
            momentValue = Moment(event);
        }
        return this.props.onChange(momentValue);
    }

    onChangeMobile(event) {
        this.props.onChange(Moment(event.target.value));
    }

    toDate(value) {
        if (value) {
            value = Moment(value);

            if (value.isValid()) {
                return Date.parse(value.toISOString());
            }
        }

        return null;
    }

    render() {
        let {maxDate, minDate, selected} = this.props;

        maxDate = this.toDate(maxDate);
        minDate = this.toDate(minDate);
        selected = this.toDate(selected);

        if (!IsMobile.any()) {
            return (
                <ReactDatePicker
                    dateFormat={'dd-MM-yyyy'}
                    isClearable={!this.props.disabled}
                    showYearDropdown={true}
                    {...this.props}
                    onChange={/* istanbul ignore next */(value) => /* istanbul ignore next */this.handleChange(value)}
                    maxDate={maxDate}
                    minDate={minDate}
                    selected={selected}
                    dropdownMode={'select'}
                    scrollableYearDropdown={true}
                />
            );
        }

        return <input
            className={this.props.className}
            max={this.fromMoment(maxDate)}
            min={this.fromMoment(minDate)}
            disabled={this.props.disabled}
            onChange={(this.onChangeMobile.bind(this))}
            value={this.fromMoment(selected)}
            type="date"
        />;
    }
}
