/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {GetSubtitleLanguageByCode} from '@wbdt-sie/brainiac-web-common';

// Based on audiotrackutils.ts from github:bitmovin/bitmovin-player-ui
export class BitmovinAudioTrackSwitchHandler {
    constructor(player, element, uimanager) {
        this.addAudioTrack = this.addAudioTrack.bind(this);
        this.removeAudioTrack = this.removeAudioTrack.bind(this);
        this.selectCurrentAudioTrack = this.selectCurrentAudioTrack.bind(this);
        this.refreshAudioTracks = this.refreshAudioTracks.bind(this);

        this.player = player;
        this.listElement = element;
        this.uimanager = uimanager;
        this.bindSelectionEvent();
        this.bindPlayerEvents();
        this.refreshAudioTracks();
    }
    addAudioTrack(event) {
        const audioTrack = event.track;
        if (!this.listElement.hasItem(audioTrack.id)) {
            this.listElement.addItem(audioTrack.id, this.getAudioTrackLabel(audioTrack), true);
        }
    }
    bindSelectionEvent() {
        this.listElement.onItemSelected.subscribe((_, value) => {
            this.player.setAudio(value);
        });
    }
    bindPlayerEvents() {
        // Update selection when selected track has changed
        this.player.on(this.player.exports.PlayerEvent.AudioChanged, this.selectCurrentAudioTrack);
        // Update tracks when source goes away
        this.player.on(this.player.exports.PlayerEvent.SourceUnloaded, this.refreshAudioTracks);
        // Update tracks when the period within a source changes
        this.player.on(this.player.exports.PlayerEvent.PeriodSwitched, this.refreshAudioTracks);
        // Update tracks when a track is added or removed
        this.player.on(this.player.exports.PlayerEvent.AudioAdded, this.addAudioTrack);
        this.player.on(this.player.exports.PlayerEvent.AudioRemoved, this.removeAudioTrack);
        this.uimanager.getConfig().events.onUpdated.subscribe(this.refreshAudioTracks);
    }
    getAudioTrackLabel(audioTrack) {
        if (audioTrack.label === audioTrack.lang) {
            let qualities = audioTrack.getQualities();
            let audioLabel = GetSubtitleLanguageByCode(audioTrack.label);
            if (audioTrack.role && audioTrack.role[0].value === 'alternate'
                || qualities && qualities[0].id.toLowerCase().indexOf('description') !== -1) {
                audioLabel += ' (Audio Description)';
            }
            return audioLabel;
        } else if (audioTrack.label.indexOf('_') !== -1) {
            return audioTrack.label
                .replace(/_/g, ' ')
                .replace(/(Audio Description|Stereo|Surround)/g, '($1)');
        }
        return audioTrack.label;
    }
    refreshAudioTracks() {
        const audioTracks = this.player.getAvailableAudio();
        const audioTrackToListItem = (audioTrack) => {
            return {
                key: audioTrack.id,
                label: this.getAudioTrackLabel(audioTrack)
            };
        };
        this.listElement.synchronizeItems(audioTracks.map(audioTrackToListItem));
        this.selectCurrentAudioTrack();
    }
    removeAudioTrack(event) {
        const audioTrack = event.track;
        if (this.listElement.hasItem(audioTrack.id)) {
            this.listElement.removeItem(audioTrack.id);
        }
    }
    selectCurrentAudioTrack() {
        let currentAudioTrack = this.player.getAudio();
        // HLS streams don't always provide this, so we have to check
        if (currentAudioTrack) {
            this.listElement.selectItem(currentAudioTrack.id);
        }
    }
}
