/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React from 'react';

import PlaceholderHorizontal from './placeholder-horizontal.svg';
import PlaceholderSquare from './placeholder-square.svg';
import PlaceholderVertical from './placeholder-vertical.svg';

const skipFormat = /^(data:|\/|http:|https:)/;

const FormatImgSrc = (src) => {
    if (skipFormat.test(src)) {
        return src;
    }

    return `/${src}`;
};

const PLACEHOLDERS = {
    HORIZONTAL: FormatImgSrc(PlaceholderHorizontal),
    SQUARE: FormatImgSrc(PlaceholderSquare),
    VERTICAL: FormatImgSrc(PlaceholderVertical)
};

const ImageLoader = ({alt, src, placeholder, ...other}) => {
    let element;

    const changeToPlaceholder = () => {
        if (element.src === placeholder) {return;}

        element.src = placeholder;
    };

    placeholder = placeholder || PLACEHOLDERS.SQUARE;

    if (!src) {
        src = placeholder;
    }

    src = FormatImgSrc(src);

    return (
        <img
            alt={alt}
            onContextMenu={e => e.preventDefault()}
            onError={changeToPlaceholder}
            ref={el => element = el}
            src={src}
            {...other}
        />
    );
};

ImageLoader.propTypes = {
    alt: PropTypes.string,
    placeholder: PropTypes.string,
    src: PropTypes.string.isRequired
};

ImageLoader.defaultProps = {
    alt: '',
    placeholder: '',
};

export default ImageLoader;

export {
    FormatImgSrc,
    PLACEHOLDERS as ImageLoaderPlaceholders
};
