/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {Alert} from 'react-bootstrap';
import {TransitionGroup} from 'react-transition-group';

import {NotificationActions} from './notification-actions';
import NotificationStore from './notification-store';
import {MessagesContext} from '../messages/messages-context';
import './alert.less';

const AlertTypes = {
    ALERT_DANGER: {
        bsClass: 'alert-danger',
        icon: 'fa-ban',
        name: 'ALERT_DANGER'
    },
    ALERT_INFO: {
        bsClass: 'alert-info bottom-right-alert',
        icon: 'fa-info-circle',
        name: 'ALERT_INFO',
    },
    ALERT_SUCCESS: {
        bsClass: 'alert-info',
        dismissAfter: 10000,
        icon: 'fa-check',
        name: 'ALERT_SUCCESS'
    },
    ALERT_WARNING: {
        bsClass: 'alert-warning',
        icon: 'fa-exclamation-triangle',
        name: 'ALERT_WARNING'
    }
};

class AlertWrapper extends React.Component {
    static get propTypes() {
        return {
            className: PropTypes.string,
            fixed: PropTypes.bool,
            transitionName: PropTypes.string,
            transitionTimeout: PropTypes.number
        };
    }

    static get defaultProps() {
        return {
            className: '',
            fixed: false,
            transitionName: 'alert',
            transitionTimeout: 300,
        };
    }

    static calculateState() {
        return {
            notifications: NotificationStore.getState().get('notifications').filter(n => n.get('isAlert'))
        };
    }

    static getStores() {
        return [NotificationStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !this.state.notifications.equals(nextState.notifications);
    }

    static contextType = MessagesContext;

    handleDismiss(index = 0) {
        NotificationActions.hide(index);
        return;
    }

    render() {
        if (this.state.notifications.size === 0) {
            // Return an empty transition group instead of null
            // to not break the UI.
            return (
                <TransitionGroup
                    transitionName={this.props.transitionName}
                    transitionEnterTimeout={this.props.transitionTimeout}
                    transitionLeaveTimeout={this.props.transitionTimeout}
                />
            );
        }

        let elem = this.state.notifications.map((notification, i) => {
            let message = this.context.intl.messages[notification.get('message')];
            if (notification.get('arguments')) {
                let args = notification.get('arguments').toJS();
                message = message.replace(/\{\}/g, () => args.shift());
            }

            return (
                <TransitionGroup
                    key={i}
                    transitionName={this.props.transitionName}
                    transitionEnterTimeout={this.props.transitionTimeout}
                    transitionLeaveTimeout={this.props.transitionTimeout}>
                    <Alert
                        bsClass={ClassNames('alert', notification.getIn(['type', 'bsClass']), this.props.className)}
                        onDismiss={this.handleDismiss.bind(this, notification.get('index'))}
                    >
                        <i className={ClassNames('icon fa', notification.getIn(['type', 'icon']))}></i>&nbsp;<span dangerouslySetInnerHTML={{__html: message}}/>
                    </Alert>
                </TransitionGroup>

            );
        });

        return (
            <div className={ClassNames({'alert-container': this.props.fixed})}>
                {elem}
            </div>
        );
    }
}

const AlertWrapperContainer = Container.create(AlertWrapper);

export {
    AlertTypes,
    // Export the non-decorated component so that we can test
    // React's lifecycle methods.
    AlertWrapper,
    AlertWrapperContainer as Alert
};
