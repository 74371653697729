/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {
    AudioQualitySelectBox,
    BufferingOverlay,
    CastStatusOverlay,
    CastToggleButton,
    Container,
    ControlBar,
    ErrorMessageOverlay,
    FullscreenToggleButton,
    PlaybackSpeedSelectBox,
    PlaybackTimeLabel,
    PlaybackTimeLabelMode,
    PlaybackToggleButton,
    PlaybackToggleOverlay,
    SeekBar,
    SeekBarLabel,
    SettingsPanel,
    SettingsPanelItem,
    SettingsPanelPage,
    SettingsPanelPageOpenButton,
    SettingsToggleButton,
    Spacer,
    SubtitleOverlay,
    SubtitleSettingsLabel,
    SubtitleSettingsPanelPage,
    TitleBar,
    UIContainer,
    UIManager,
    VideoQualitySelectBox,
    VolumeSlider,
    VolumeToggleButton,
    VRToggleButton,
    Watermark
} from 'bitmovin-player-ui';

import {BitmovinAudioTrackSelectBox} from './bitmovin-ui/audio-track-select-box';
import {BitmovinSubtitleSelectBox} from './bitmovin-ui/subtitle-select-box';

export function PlayerUIFactory() {
    let player = null;

    return Object.freeze({

        buildDefaultUI(p) {
            player = p;
            return new UIManager(player, this.createUIContainer());
        },

        createUIContainer() {
            let subtitleOverlay = new SubtitleOverlay();

            let mainSettingsPanelPage = new SettingsPanelPage({
                components: [
                    new SettingsPanelItem('Video Quality', new VideoQualitySelectBox()),
                    new SettingsPanelItem('Speed', new PlaybackSpeedSelectBox()),
                    new SettingsPanelItem('Audio Track', new BitmovinAudioTrackSelectBox()),
                    new SettingsPanelItem('Audio Quality', new AudioQualitySelectBox()),
                ],
            });

            let settingsPanel = new SettingsPanel({
                components: [
                    mainSettingsPanelPage,
                ],
                hidden: true,
            });

            let subtitleSettingsPanelPage = new SubtitleSettingsPanelPage({
                settingsPanel: settingsPanel,
                overlay: subtitleOverlay,
            });

            const subtitleSelectBox = new BitmovinSubtitleSelectBox();

            let subtitleSettingsOpenButton = new SettingsPanelPageOpenButton({
                targetPage: subtitleSettingsPanelPage,
                container: settingsPanel,
                ariaLabel: 'Subtitles',
                text: 'open',
            });

            mainSettingsPanelPage.addComponent(
                new SettingsPanelItem(
                    new SubtitleSettingsLabel({
                        text: 'Subtitles',
                        opener: subtitleSettingsOpenButton,
                    }),
                    subtitleSelectBox,
                    {
                        role: 'menubar',
                    },
                ));

            settingsPanel.addComponent(subtitleSettingsPanelPage);

            let controlBar = new ControlBar({
                components: [
                    settingsPanel,
                    new Container({
                        components: [
                            new PlaybackTimeLabel({
                                timeLabelMode: PlaybackTimeLabelMode.CurrentTime,
                                hideInLivePlayback: true
                            }),
                            new SeekBar({label: new SeekBarLabel()}),
                            new PlaybackTimeLabel({
                                timeLabelMode: PlaybackTimeLabelMode.TotalTime,
                                cssClasses: ['text-right']
                            }),
                        ],
                        cssClasses: ['controlbar-top'],
                    }),
                    new Container({
                        components: [
                            new PlaybackToggleButton(),
                            new VolumeToggleButton(),
                            new VolumeSlider(),
                            new Spacer(),
                            new CastToggleButton(),
                            new VRToggleButton(),
                            new SettingsToggleButton({settingsPanel: settingsPanel}),
                            new FullscreenToggleButton()
                        ],
                        cssClasses: ['controlbar-bottom'],
                    }),
                ],
            });

            return new UIContainer({
                components: [
                    subtitleOverlay,
                    new BufferingOverlay(),
                    new PlaybackToggleOverlay(),
                    new CastStatusOverlay(),
                    controlBar,
                    new TitleBar(),
                    new Watermark(),
                    new ErrorMessageOverlay(),
                ]
            });
        },
    });
}
