{
    "account.first.time.login": "Welcome to the designated onboarding page, where you can configure your preferences for video delivery, image choices, and various other settings. This notification will only appear once for your convenance.",
    "account.support.request.error": "There was an error submitting your request. Please try again or contact your Client Representative or Account Administrator.",
    "account.support.request.success": "Thank you for submitting this request. An Account Administrator will get in touch with you soon.",
    "account.tab.delivery-tab-title": "Delivery",
    "account.tab.delivery.delivery.title": "Delivery Preferences",
    "account.tab.delivery.editing": "Editing System",
    "account.tab.delivery.image-format": "Image Delivery Format",
    "account.tab.delivery.it.email": "IT Email",
    "account.tab.delivery.it.name": "IT Name",
    "account.tab.delivery.it.phone": "IT Phone",
    "account.tab.delivery.notifications.add": "Add Recipient for Orders",
    "account.tab.delivery.notifications.description": "Adding recipients to notifications will copy them on orders pertaining to images & videos, these recipients must already be part of the system to receive the notifications",
    "account.tab.delivery.notifications.email": "Email",
    "account.tab.delivery.notifications.name": "Name",
    "account.tab.delivery.notifications.title": "Notifications",
    "account.tab.delivery.save.error": "There was an error updating your profile, please try again later",
    "account.tab.delivery.save.success": "Profile updated successfully",
    "account.tab.delivery.save": "Update Delivery Preferences",
    "account.tab.delivery.video-format": "Video Delivery Format",
    "account.tab.download-history.list.asset-name": "Asset Name",
    "account.tab.download-history.list.content-type": "Content Type",
    "account.tab.download-history.list.request-date-time": "Request Date - Time",
    "account.tab.download-history.load-history-error": "There was an error loading your download history, please try again later",
    "account.tab.download-history.renditions.hi-res-rgb": "High-Res RGB",
    "account.tab.download-history.renditions.lo-res-rgb": "Lo-Res RGB",
    "account.tab.download-history.renditions.source": "Source",
    "account.tab.download-history.title": "Download History",
    "account.tab.info.account-access": "Account Access",
    "account.tab.info.address": "Address",
    "account.tab.info.changepassword.msg": "Before you change your password, make sure your email address is up-to-date. We'll use your email address to complete your password change.",
    "account.tab.info.changepassword.msg2": "Your recent email:",
    "account.tab.info.changepassword.title": "Change Password",
    "account.tab.info.city": "City",
    "account.tab.info.company": "Company Name",
    "account.tab.info.country": "Country",
    "account.tab.info.email": "Email",
    "account.tab.info.update.error.msg": "Please check the required fields.",
    "account.tab.info.firstname": "First Name",
    "account.tab.info.language.placeholder": "Select a Language",
    "account.tab.info.language.update.error": "There was an error saving your preferred language, please try again later",
    "account.tab.info.language.update.success": "Preferred Language Saved",
    "account.tab.info.language": "Preferred Language",
    "account.tab.info.last-login": "Last Login",
    "account.tab.info.lastname": "Last Name",
    "account.tab.info.phone": "Phone Number",
    "account.tab.info.request-additional-access.description": "Please set the category type to Accounts and we’ll get back to you as soon as we can!",
    "account.tab.info.request-additional-access": "Request Additional Access",
    "account.tab.info.request.access-type-question": "What type of access do you require?",
    "account.tab.info.request.select-access-type": "Select as many options from the dropdown as needed:",
    "account.tab.info.request.update.error": "There was an error submitting the change request. Please try again or contact you Client Representative or Account Administrator.",
    "account.tab.info.request.update.success": "Thank you for submitting this change request. An Account Administrator will get in touch with you soon.",
    "account.tab.info.state": "State/Province",
    "account.tab.info.title": "Account Info",
    "account.tab.info.update.msg": "Please enter correct info and request update.",
    "account.tab.info.update.save_request": "Request",
    "account.tab.info.update.title": "Update Account Info",
    "account.tab.info.update": "Update Account Info",
    "account.tab.info.zip": "Zip/Postal Code",
    "account.tab.metadata.save": "Update Metadata Export Spec",
    "account.tab.my-metadata.title": "Title Packages",
    "account.tab.my-orders.title": "My Orders",
    "account.tab.my-saved-carts.title": "My Saved Carts",
    "account.tab.my-saved-searches.confirm-delete-saved-search.dialog.description": "Would you like to delete this search?",
    "account.tab.my-saved-searches.date-saved": "Date Saved",
    "account.tab.my-saved-searches.delete-saved-search.error": "There was an error deleting saved search, please try again later",
    "account.tab.my-saved-searches.delete-saved-search.success": "Saved search was deleted successfully",
    "account.tab.my-saved-searches.load-error": "There was an error loading your saved searches, please try again later",
    "account.tab.my-saved-searches.title": "My Saved Searches",
    "account.tab.onboarding.title": "Onboarding",
    "account.tab.subscribed-content.title": "Subscribed Content",
    "account.tab.subscriptions-tab-title": "Subscriptions",
    "account.tab.subscriptions.add-subscriber.add": "Add",
    "account.tab.subscriptions.add-subscriber.title": "Add User Subscription",
    "account.tab.subscriptions.add-subscriber": "Add Subscriber",
    "account.tab.subscriptions.all-videos": "Video Subscriptions",
    "account.tab.subscriptions.episodic-delivery": "Episodics",
    "account.tab.subscriptions.full-episodes": "Screeners",
    "account.tab.subscriptions.images": "Image Subscriptions",
    "account.tab.subscriptions.list.subscribed-date": "Subscribed Date",
    "account.tab.subscriptions.list.suggested-by": "Suggested By",
    "account.tab.subscriptions.list.title": "Title Name",
    "account.tab.subscriptions.list.unsubscribe": "Unsubscribe",
    "account.tab.subscriptions.list.user": "User",
    "account.tab.subscriptions.station-air-date-schedules": "Station Air Date Schedules",
    "account.tab.subscriptions.station-air-schedules": "Station Air Schedules",
    "account.tab.subscriptions.station-contact-sheets": "Station Contact Sheets",
    "account.tab.subscriptions.station-format-sheets": "Station Format Sheets",
    "account.tab.subscriptions.station-general-show": "Station General Show Information",
    "account.tab.subscriptions.station-refeeds": "Station Refeeds",
    "account.tab.subscriptions.station-satellite-feeds": "Station Satellite Feeds",
    "account.tab.subscriptions.station-season-packets": "Station Season Packets",
    "account.tab.subscriptions.station-tape-orders": "Station Tape Orders",
    "account.tab.subscriptions.station-title-lists": "Station Title Lists",
    "account.tab.subscriptions.title": "Subscriptions",
    "account.tab.subscriptions.vam-delivery": "VAM Orders",
    "account.view.title": "My Account",

    "accounts.edit.delivery.metadata.language": "Preferred Metadata Language",
    "accounts.tab.delivery.metadata.exportSpec.title": "Metadata Export Spec",

    "asset.audio.content-types.1": "Episodic Radio Spot",
    "asset.audio.content-types.2": "Generic Radio Spot",
    "asset.audio.content-types.3": "Spot Reel",
    "asset.audio.content-types.4": "Theme Song",
    "asset.audio.content-types.5": "Song",
    "asset.audio.content-types.6": "Interview",
    "asset.audio.content-types.7": "Clip",
    "asset.audio.content-types.8": "Audio Stem",
    "asset.audio": "Audio",
    "asset.documents": "Documents",
    "asset.download.error": "There was an error generating the download URL, please try again or contact your client representative if the problem persists ",
    "asset.image.content-types.1": "Art - Promo",
    "asset.image.content-types.10": "Video Box Art",
    "asset.image.content-types.11": "Kiosk Art",
    "asset.image.content-types.12": "Billboard",
    "asset.image.content-types.13": "Banner",
    "asset.image.content-types.14": "Social Media",
    "asset.image.content-types.15": "Web Site - Other",
    "asset.image.content-types.16": "Web Site - WBITV",
    "asset.image.content-types.17": "Web Site - WBITV B2B",
    "asset.image.content-types.18": "Logo - Animation",
    "asset.image.content-types.19": "Logo - Movie Of The Week",
    "asset.image.content-types.2": "Box Art",
    "asset.image.content-types.20": "Logo - Network/Station",
    "asset.image.content-types.21": "Logo - Production Company",
    "asset.image.content-types.22": "Logo - Series",
    "asset.image.content-types.23": "Logo - Theatrical",
    "asset.image.content-types.24": "Logo - WB Division",
    "asset.image.content-types.25": "Title Treatment - On-air",
    "asset.image.content-types.26": "Title Treatment - Print",
    "asset.image.content-types.27": "Photography",
    "asset.image.content-types.28": "Unit",
    "asset.image.content-types.29": "Episodic",
    "asset.image.content-types.3": "Key Art",
    "asset.image.content-types.30": "Animated Stills",
    "asset.image.content-types.31": "Gallery",
    "asset.image.content-types.32": "Gallery - Cast",
    "asset.image.content-types.33": "Gallery - Multiple",
    "asset.image.content-types.34": "Gallery - Single",
    "asset.image.content-types.35": "Headshots",
    "asset.image.content-types.36": "Set",
    "asset.image.content-types.37": "SVOD vertical display art",
    "asset.image.content-types.38": "SVOD horizontal display art",
    "asset.image.content-types.39": "Story Art",
    "asset.image.content-types.4": "Key Art - International Version",
    "asset.image.content-types.40": "Client-created",
    "asset.image.content-types.41": "Animation Composite",
    "asset.image.content-types.42": "Backgrounds",
    "asset.image.content-types.43": "Character - Stock",
    "asset.image.content-types.44": "Character Art",
    "asset.image.content-types.45": "Character Art - Animation",
    "asset.image.content-types.46": "Character Art- General",
    "asset.image.content-types.47": "Behind the Scenes",
    "asset.image.content-types.48": "Events",
    "asset.image.content-types.49": "Events - IPJ",
    "asset.image.content-types.5": "Key Art - Television Series",
    "asset.image.content-types.50": "Events - LA Screenings",
    "asset.image.content-types.51": "Events - Misc.",
    "asset.image.content-types.52": "Other",
    "asset.image.content-types.53": "Ad - Contest/Promo",
    "asset.image.content-types.54": "Ad - Cover",
    "asset.image.content-types.55": "Ad - Trade",
    "asset.image.content-types.56": "Advertising",
    "asset.image.content-types.57": "Art - Merchandise",
    "asset.image.content-types.58": "Merchandise Art",
    "asset.image.content-types.59": "Merchandise",
    "asset.image.content-types.6": "Key Art - Texted",
    "asset.image.content-types.60": "Bags",
    "asset.image.content-types.61": "Content Asset Library",
    "asset.image.content-types.62": "Custom DVD/CD/VHS Packaging Clamshells",
    "asset.image.content-types.63": "Illustrator - Template",
    "asset.image.content-types.64": "Insert - DVD",
    "asset.image.content-types.65": "Marketing Sales Kit",
    "asset.image.content-types.66": "Photoshop - Action",
    "asset.image.content-types.67": "Product",
    "asset.image.content-types.68": "Promotional Packaging",
    "asset.image.content-types.69": "Stock",
    "asset.image.content-types.7": "Key Art - Textless",
    "asset.image.content-types.70": "Stock - WB Copyright",
    "asset.image.content-types.71": "Teaser Campaign",
    "asset.image.content-types.72": "Gif",
    "asset.image.content-types.73": "Countdown Card",
    "asset.image.content-types.74": "Social Media Clip",
    "asset.image.content-types.75": "Character Card",
    "asset.image.content-types.76": "Tile",
    "asset.image.content-types.77": "Tile - Burned In",
    "asset.image.content-types.78": "Background",
    "asset.image.content-types.79": "Logo - HBO Max",
    "asset.image.content-types.8": "Key Art - Titled",
    "asset.image.content-types.80": "Universal Search",
    "asset.image.content-types.81": "Episodic Tile",
    "asset.image.content-types.9": "Key Art - With Title Treatment",
    "asset.image.gallery.add-to-cart": "Add To Cart",
    "asset.image.gallery.arrow.previous": "Previous (arrow left)",
    "asset.image.gallery.arrow.right": "Previous (arrow right)",
    "asset.image.gallery.close": "Close (Esc)",
    "asset.image.gallery.download": "Download",
    "asset.image.gallery.edit-brainiac": "Edit in Brainiac",
    "asset.image.gallery.fullscreen": "Fullscreen",
    "asset.image.gallery.needs-approval": "Needs Approval",
    "asset.image.gallery.remove": "Remove",
    "asset.image.gallery.renditions.full-res": "High-Res",
    "asset.image.gallery.renditions.low-res": "Low-Res",
    "asset.image.gallery.renditions.source": "Source",
    "asset.image.gallery.restricted": "RESTRICTED",
    "asset.image.gallery.share": "Share",
    "asset.image.gallery.view-image": "View Image",
    "asset.image.gallery.zoom": "Zoom in/out",
    "asset.images": "Images",
    "asset.merchandise": "Merchandise",
    "asset.moments": "Moments",
    "asset.scripts": "Scripts",
    "asset.stations": "Station Documents",
    "asset.videos": "Videos",

    "billboard.item.button.other.text": "Get Info",
    "billboard.item.button.video.text": "Watch Video",

    "cart.add.all-images.error.already-in-cart": "These assets are already in your cart",
    "cart.add.all-images.ok": "All Images added to cart",
    "cart.add.all-images.warning": "You will be adding {} images to your cart. Would you like to proceed?",
    "cart.add.error.already-in-cart": "This asset is already in your cart",
    "cart.add.error": "There was an error adding the item to your cart, please try again later",
    "cart.add.image.multi": "Add All to Cart",
    "cart.add.image.ok": "Image added to cart",
    "cart.add.image.single": "Add To Cart",
    "cart.add.video-user-moment.ok": "Moment added to cart",
    "cart.add.video.multi": "Add All to Cart",
    "cart.add.video.ok": "Video added to cart",
    "cart.add.video.single": "Add To Cart",
    "cart.clear.error": "Error clearing the cart, please try again or contact your client representative if the problem persists.",
    "cart.clear.ok": "Cart successfully cleared.",
    "cart.download.all.error": "Error downloading order items, please try again or contact your client representative if the problem persists.",
    "cart.download.video.mp4": "Download MP4",
    "cart.image.delete.error": "There was an error deleting the item form your cart, please try again later",
    "cart.image.delete.success": "Item deleted successfully",
    "cart.instant.order.video.error.children-is-non-serviceable": "Stacked video '{}' is non-serviceable for instant download. It will not be included in the order.",
    "cart.instant.order.video.error.failed-to-get-children": "Failed to retrieve stacked videos for '{}'. These videos will not be included in the order.",
    "cart.instant.order.video.multi": "Instant Order All",
    "cart.instant.order.video.single.error": "Failed to create instant order for '{}'. Please try again or contact support.",
    "cart.instant.order.video.single.success": "Video '{}' Ordered - Download link will be emailed soon.",
    "cart.instant.order.video.single": "Instant Order",
    "cart.player.close-button": "Close Player",
    "cart.player.edit-brainiac": "Edit in Brainiac",
    "cart.player.moments-tool": "Moments Tool",
    "cart.player.share": "Share Title",
    "cart.submit.error": "There was an error submiting your cart please try again later",
    "cart.submit.ok": "Order successfully placed",
    "cart.update-file-format-items.error": "There was an error updating video file format for video items in your cart, please try again",
    "cart.view.approved": "Approved",
    "cart.view.clear-cart.dialog.description": "Are you sure you want to clear your cart?",
    "cart.view.clear-cart.dialog.title": "Are You Sure?",
    "cart.view.clear-cart": "Clear Cart",
    "cart.view.confirm-remove.dialog.description": "Are you sure you want to delete this item from your cart?",
    "cart.view.confirm-remove.dialog.title": "Are You Sure?",
    "cart.view.delivery-speed.toggle.instant": "Typically delivered within the next few minutes",
    "cart.view.delivery-speed.toggle.near-instant": "Typically delivered within the next few hours",
    "cart.view.delivery-speed.toggle.slow": "Typically delivered within a day",
    "cart.view.description": "Please review and place your request.",
    "cart.view.display": "Display: ",
    "cart.view.download-complete.description": "Would you like to remove all downloaded images from your cart?",
    "cart.view.download-complete.keep": "Keep",
    "cart.view.download-complete.remove": "Remove",
    "cart.view.download-complete.title": "Download Complete",
    "cart.view.download.download-ale": "Download ALE",
    "cart.view.download.download-all-high-res-images": "Download All High Res",
    "cart.view.download.download-all-images": "Download All Approved Images",
    "cart.view.download.download-all-low-res-images": "Download All Low Res",
    "cart.view.download.download-all-source-images": "Download All Source",
    "cart.view.downloaded": "Downloaded",
    "cart.view.error": "Error",
    "cart.view.inCart": "In Cart",
    "cart.view.load-ale.error": "There was an error loading ALE file please try again later",
    "cart.view.load.error": "There was an error loading your cart please try again later",
    "cart.view.notProcessed": "Not Processed",
    "cart.view.please-note.email.description": "Your order will be delivered in separate emails:",
    "cart.view.please-note.images": "Images",
    "cart.view.please-note.moments": "Moments",
    "cart.view.please-note.quick-delivery-videos": "Quick Delivery Videos",
    "cart.view.please-note.sidecar.description": "There are some videos here that do not have sidecar files but we will send you the sidecar files of the available ones.",
    "cart.view.please-note.traditional-delivery-videos": "Traditional Delivery Videos",
    "cart.view.please-note": "Please Note",
    "cart.view.processFail": "Process Fail",
    "cart.view.processing": "Processing",
    "cart.view.processSuccess": "Process Success",
    "cart.view.readyToDownload": "Ready to Download",
    "cart.view.requested": "Requested",
    "cart.view.save-for-later.dialog.description": "This will empty your cart. You can find the saved cart under \"My Account\"",
    "cart.view.save-for-later.dialog.name": "Request Name",
    "cart.view.save-for-later.dialog.title": "Are You Sure?",
    "cart.view.save-for-later": "Save for Later",
    "cart.view.save.error": "There was an error saving your cart",
    "cart.view.save.ok": "Cart saved",
    "cart.view.submit": "Submit Request",
    "cart.view.table.asset-name": "Asset Name",
    "cart.view.table.content-type": "Content Type",
    "cart.view.table.direct-download": "Direct Download",
    "cart.view.table.feature": "FEATURE",
    "cart.view.table.format": "Format",
    "cart.view.table.full-running-order": "RO",
    "cart.view.table.mini-series": "MINI SERIES",
    "cart.view.table.moment-length": "Length",
    "cart.view.table.moment-name": "Name",
    "cart.view.table.rights.cleared": "Cleared",
    "cart.view.table.rights.contact-servicing": "Contact Servicing",
    "cart.view.table.rights.see-notes": "See Notes",
    "cart.view.table.rights": "Rights",
    "cart.view.table.runtime": "Run Time",
    "cart.view.table.series": "SERIES",
    "cart.view.table.status": "Status",
    "cart.view.table.title": "Title",
    "cart.view.table.video-format.mp4": "MP4/H.264: Playable File For Web",
    "cart.view.table.video-format.prores": "MOV: ProRes File",
    "cart.view.table.video-format.xdcam": "XDCAM: Editing Master",
    "cart.view.title": "View Cart",

    "common.add": "Add",
    "common.add-to-cart-all": "Add All To Cart",
    "common.aka": "AKA",
    "common.alerts": "Alerts",
    "common.alerts-warnings.title": "Oops! Something went wrong...",
    "common.are-you-sure": "Are You Sure?",
    "common.button.back": "Back",
    "common.cancel": "Cancel",
    "common.change-language": "Change Language",
    "common.select-language": "Select Language",
    "common.close": "Close",
    "common.comment": "Comment",
    "common.continue": "Continue",
    "common.delete": "Delete",
    "common.done": "Done",
    "common.download": "Download",
    "common.download-all": "Download All",
    "common.form.select.placeholder": "Please select an option",
    "common.grid-view": "Gallery view",
    "common.list-view": "List view",
    "common.more": "More",
    "common.mpm": "MPM #",
    "common.no": "No",
    "common.not-available": "N/A",
    "common.ok": "OK",
    "common.page-number": "Page number",
    "common.per-page": "Per Page",
    "common.play-video": "Play Video",
    "common.save": "Save",
    "common.share": "Share",
    "common.show": "Show",
    "common.submit": "Submit",
    "common.unknown.asset": "Unknown asset",
    "common.unknown.title": "Unknown title",
    "common.unsaved-changes": "You have unsaved changes. Are you sure you want to leave?",
    "common.unsubscribe": "Unsubscribe",
    "common.video-delivery-format.mp4.tooltip": "Video is deliverable as a streamable H.264 file.",
    "common.video-delivery-format.mp4": "MP4",
    "common.video-delivery-format.mxf-mp4.tooltip": "Video is deliverable as an XDCAM editing master or streamable H.264 file.",
    "common.video-delivery-format.mxf-mp4": "MXF/MP4",
    "common.video-delivery-format.mxf.tooltip": "Video is deliverable as an XDCAM editing master.",
    "common.video-delivery-format.mxf": "MXF",
    "common.video-delivery-format.na.tooltip": "Video is not available for delivery.",
    "common.video-delivery-format.na": "N/A",
    "common.view-all": "View All",
    "common.yes": "Yes",

    "date-format": "DD-MMM-YYYY",
    "datetime-format": "DD MMM YYYY hh:mm:ss A z",
    "datetime-middleDash-short-format": "DD-MMM-YYYY hh:mm A z",
    "datetime-short-format": "DD MMM YY @ hh:mm A z",
    "datetime-full-ampm-tz": "M/D/YYYY @ h:mm A (z)",

    "digital-sales-decks.tab.info.title": "Info",
    "digital-sales-decks.tab.talent.title": "Talent",
    "digital-sales-decks.tab.talent.actor": "Starring",
    "digital-sales-decks.tab.talent.director": "Director",
    "digital-sales-decks.tab.talent.producer": "Producer",
    "digital-sales-decks.tab.talent.voice-talent": "Voice Talent",
    "digital-sales-decks.tab.talent.host": "Host",
    "digital-sales-decks.tab.talent.writer": "Writer",
    "digital-sales-decks.tab.talent.musician": "Musician",
    "digital-sales-decks.tab.talent.choreographer": "Choreographer",
    "digital-sales-decks.tab.talent.executive-producer": "Executive Producer",
    "digital-sales-decks.tab.talent.guest-star": "Guest Star",
    "digital-sales-decks.tab.talent.author": "Author",
    "digital-sales-decks.tab.talent.animated-character": "Animated Character",
    "digital-sales-decks.tab.talent.misc-crew": "Miscellaneous Crew",
    "digital-sales-decks.tab.talent.creator": "Created By",

    "document-titles.browse-orders.all-orders": "All Orders",
    "document-titles.browse-orders.metadata": "Title Packages",
    "document-titles.browse-orders.my-orders": "My Orders",
    "document-titles.browse-orders.my-saved-carts": "My Saved Carts",
    "document-titles.browse-orders.orders-in-progress": "Orders In Progress",
    "document-titles.browse-orders.requests-sent-to-me": "Requests Sent to Me",
    "document-titles.browse-orders.standing-orders.unsbuscribe-clients": "Unsubscribe Selected Clients",
    "document-titles.browse-orders.standing-orders": "Standing Orders",
    "document-titles.browse-orders.subscriptions": "Subscriptions",
    "document-titles.cart-title": "Cart",
    "document-titles.common-download": "Download",
    "document-titles.dsd.info": "Digital Sales Deck - Info",
    "document-titles.dsd.talent": "Digital Sales Deck - Talent",
    "document-titles.dsd.title": "Digital Sales Deck",
    "document-titles.footer.adultswim-press-releases": "Adult Swim Press Releases",
    "document-titles.footer.cartoon-network-press-releases": "Cartoon Network Press Releases",
    "document-titles.footer.local-acquisitions": "Local Acquisitions and Co-Productions",
    "document-titles.footer.press-releases": "Press Releases",
    "document-titles.footer.press-site-releases": "Press Releases",
    "document-titles.footer.product-reports": "Product Reports",
    "document-titles.footer.theatrical-grids": "Theatrical Grids",
    "document-titles.footer.us-air-dates": "US Air Dates Schedule",
    "document-titles.forgot-password-confirm": "Reset Password Confirm",
    "document-titles.forgot-password": "Reset Password",
    "document-titles.home.no-content.sub-title": "There is no content available to view at this time. Please check again later.",
    "document-titles.home.no-content.title": "No Available Content",
    "document-titles.home": "Home",
    "document-titles.images": "Images",
    "document-titles.login": "Login",
    "document-titles.my-account.download-history": "Download History",
    "document-titles.my-account.info": "Account Info",
    "document-titles.my-account.my-orders": "My Account - My Orders",
    "document-titles.my-account.my-saved-carts": "My Account - My Saved Cars",
    "document-titles.my-account.my-saved-searches": "My Saved Searches",
    "document-titles.my-account.subscribed-content": "Subscribed Content",
    "document-titles.my-account.subscriptions": "Subscriptions",
    "document-titles.one-time-login": "One Time Login",
    "document-titles.order-title": "Order",
    "document-titles.order": "View Order",
    "document-titles.page-title-suffix-ADULTSWIM_PRESS": "Adult Swim Press",
    "document-titles.page-title-suffix-CARTOON_NETWORK_PRESS": "Cartoon Network Press",
    "document-titles.page-title-suffix-CARTOONITO_PRESS": "Cartoonito Press",
    "document-titles.page-title-suffix-FORMATS_PORTAL": "Formats Portal",
    "document-titles.page-title-suffix-HBO_MAX_PORTAL": "HBO Max Portal",
    "document-titles.page-title-suffix-HBO": "HBO",
    "document-titles.page-title-suffix-WBTVD_PRESS": "WBTVD.com",
    "document-titles.page-title-suffix-WBTVD": "WBTVD.com",
    "document-titles.press-site.shows-title": "Shows",
    "document-titles.reset-password": "Reset Password",
    "document-titles.search.hubs.animation": "Animation Hub",
    "document-titles.search.hubs.doc-films": "Doc Films Hub",
    "document-titles.search.hubs.documentary": "Documentary Hub",
    "document-titles.search.hubs.entertainment": "Entertainment Hub",
    "document-titles.search.hubs.factual": "Factual Hub",
    "document-titles.search.hubs.film": "Film  Hub",
    "document-titles.search.hubs.kids": "Kids Hub",
    "document-titles.search.hubs.limited-series": "Limited Series Hub",
    "document-titles.search.hubs.movies": "Movies Hub",
    "document-titles.search.hubs.reality": "Reality Hub",
    "document-titles.search.hubs.scripted": "Scripted Hub",
    "document-titles.search.hubs.tv": "TV Hub",
    "document-titles.search": "Search",
    "document-titles.talent.images": "Images",
    "document-titles.talent": "View Talent",
    "document-titles.title.images": "Images",
    "document-titles.title.moments": "Moments",
    "document-titles.title.title-asset.audio": "Audio",
    "document-titles.title.title-asset.documents": "Documents",
    "document-titles.title.title-asset.merchandise": "Merchandise",
    "document-titles.title.title-asset.pdf-modal.copy-link": "Copy link to this document:",
    "document-titles.title.title-asset.scripts": "Scripts",
    "document-titles.title.title-asset.stations": "Station Documents",
    "document-titles.title.videos": "Videos",
    "document-titles.title": "View Title",
    "document-titles.tou": "Terms of Use",
    "document-titles.work-order": "Package",

    "download.alternative-download.click": "click here",
    "download.alternative-download.text": "If your download does not begin automatically, ",
    "download.in-progress": "There’s a download in progress. It will be canceled if you leave the page, are you sure?",
    "download.preparing-files.text": "The files you requested are currently being processed and will be packaged together as a single download. This process may take a few minutes. The download will begin immediately when the process is complete, but please do not close this browser tab in the meantime.",
    "download.preparing-files.title": "Preparing Files For Download...",
    "download.progress.preparing-zip": "All files packaged. Getting zip file ready for download, this may take a while",
    "download.progress.text": "Packaging Files:",
    "download.return.home.page.phrase.1": "When your download is complete,",
    "download.return.home.page.phrase.2": "click here",
    "download.return.home.page.phrase.3": "to return to the home page.",
    "download.title.asset-package": "Downloading Assets",
    "download.title.cart": "Cart",
    "download.title.order": "Order #",
    "download.title.package": "Package #",
    "download.title.title-factsheet": "Downloading All Factsheets",

    "errors.access-denied.body": "In order to access this page please contact your WB representative.",
    "errors.access-denied.homepage": "Homepage",
    "errors.access-denied.return-to": "Return to",
    "errors.access-denied.title": "Approval Required",
    "errors.asset-not-found.body": "The asset you're looking for was not found.",
    "errors.asset-not-found.title": "Asset Not Found!",
    "errors.coming-soon.body": "This content will be available soon...",
    "errors.coming-soon.title": "Coming Soon",
    "errors.not-found.body": "The page you're looking for was not found.",
    "errors.not-found.homepage": "Homepage",
    "errors.not-found.return-to": "Return to",
    "errors.not-found.title": "Page Not Found!",
    "errors.playback-not-supported.content-security": "<b>WARNING:</b> Video playback will NOT be available because your device or browser does not meet our playback requirements. Please <a href=\"https://support.wbtvd.com/kb/article/8-playback-issues-video-wont-play-or-quality-is-poor/\">update your system</a>, or use one of our <a href=\"https://www.wbdscreeners.com/app/\">standalone apps</a>.",

    "footer.navmenu.dropdown.about": "About",
    "footer.navmenu.dropdown.about.item.contact-us": "Contact Us",
    "footer.navmenu.dropdown.about.item.contact-us-domestic": "Contact Us (Domestic)",
    "footer.navmenu.dropdown.about.item.terms-of-use": "Terms of Use",
    "footer.navmenu.dropdown.catalogs": "Catalogs",
    "footer.navmenu.dropdown.catalogs.item.content-hd": "Content in HD",
    "footer.navmenu.dropdown.catalogs.item.digital-series": "Digital Series",
    "footer.navmenu.dropdown.catalogs.item.formats": "Formats Catalog",
    "footer.navmenu.dropdown.catalogs.item.network-45": "Network 45",
    "footer.navmenu.dropdown.catalogs.item.reality": "Reality and Factual Catalog",
    "footer.navmenu.dropdown.catalogs.item.the-slate": "The Slate",
    "footer.navmenu.dropdown.internal": "Internal",
    "footer.navmenu.dropdown.internal.item.local-acquisitions": "Local Acquisitions and Co-Productions",
    "footer.navmenu.dropdown.internal.item.marketing": "Marketing",
    "footer.navmenu.dropdown.internal.item.product-report": "Product Reports",
    "footer.navmenu.dropdown.internal.item.requests": "Requests",
    "footer.navmenu.dropdown.internal.item.rights-catalog": "Rights Catalog",
    "footer.navmenu.dropdown.internal.item.servicing-highlights": "Servicing Highlights",
    "footer.navmenu.dropdown.internal.item.theatrical-grids": "Theatrical Grids",
    "footer.navmenu.dropdown.internal.item.us-air-dates": "U.S. Air Dates Schedule (ADDR)",
    "footer.navmenu.dropdown.internal.item.vam": "VAM List",
    "footer.navmenu.dropdown.internal.item.vam.air-date": "AIR DATE",
    "footer.navmenu.dropdown.internal.item.vam.due-date": "DUE DATE",
    "footer.navmenu.dropdown.internal.item.vam.episode-title": "EPISODE TITLE",
    "footer.navmenu.dropdown.internal.item.vam.load-error": "There was an error loading your vam publishing list, please try again later",
    "footer.navmenu.dropdown.internal.item.vam.order": "ORDER",
    "footer.navmenu.dropdown.internal.item.vam.vam-out-date": "VAM OUT DATE",
    "footer.navmenu.dropdown.internal.item.wbtv-directory": "WBTV Directory",
    "footer.navmenu.dropdown.tools": "Tools",
    "footer.navmenu.dropdown.tools.item.affiliate-info": "Affiliate Information",
    "footer.navmenu.dropdown.tools.item.digital-rights": "Digital Rights",
    "footer.navmenu.dropdown.tools.item.help": "Help",
    "footer.navmenu.dropdown.tools.item.nextspot": "NextSpot",
    "footer.navmenu.dropdown.tools.item.ordering": "Ordering",
    "footer.navmenu.dropdown.tools.item.pitchblue": "PitchBlue",
    "footer.navmenu.dropdown.tools.item.screeners": "Screeners",

    "forgot-password.confirm": "An e-mail has been sent to the address on your account with a link to reset your password. To ensure that you receive this message, please make sure the e-mail address on your account is up to date and that the message did not go to your junk/spam folder.",
    "forgot-password.contact": "If you do not receive this e-mail, please contact wbtvd@warnerbros.com.",
    "forgot-password.error.domain": "Please log in with your email and your Domain password. If you continue to have trouble getting access, please contact wbtvd@warnerbros.com.",
    "forgot-password.form.button": "Send",
    "forgot-password.form.email.error.empty": "Plase, fill the field below",
    "forgot-password.form.email.error.validation": "That's not a valid email",
    "forgot-password.form.email.placeholder": "Email...",
    "forgot-password.message": "Just type in your email address, and a link to reset your password will be e-mailed to you shortly.",
    "forgot-password.return.login": "Return to login",
    "forgot-password.title": "Reset Password",

    "header.brands.dropdown.select": "Select Brand:",
    "header.brands.toggle.nav": "Toggle Brands Navigation Menu",
    "header.logo": "Go to Homepage",
    "header.navbar.dropdown.item.account": "My Account",
    "header.navbar.dropdown.item.cart": "Cart",
    "header.navbar.dropdown.item.home": "Home",
    "header.navbar.dropdown.item.lightbox": "Lightbox",
    "header.navbar.dropdown.item.login": "Log In",
    "header.navbar.dropdown.item.logout": "Log Out",
    "header.navbar.dropdown.item.orders": "Orders",
    "header.navbar.dropdown.item.subscriptions": "Subscriptions",
    "header.navbar.dropdown.item.videos": "Videos",
    "header.navbar.error": "There was an error loading the page navigation items. Please contact support",
    "header.navbar.item.requests.happyfox.title": "WBTVD REQUESTS",
    "header.navbar.item.requests": "REQUESTS",
    "header.navbar.no-items-configured": "There are no navigation items configured, please contact support",
    "header.searchbar.dropdown.item.all": "All",
    "header.searchbar.dropdown.item.documents": "Documents",
    "header.searchbar.dropdown.item.episodes": "Episodes",
    "header.searchbar.dropdown.item.images": "Images",
    "header.searchbar.dropdown.item.moments": "Moments",
    "header.searchbar.dropdown.item.talent": "Talent",
    "header.searchbar.dropdown.item.titles": "Titles",
    "header.searchbar.dropdown.item.videos": "Video",
    "header.searchbar.results.talent": "TALENT",
    "header.searchbar.results.title": "TITLE",

    "help.errors.loading": "Error loading Help page data, please try again.",
    "help.errors.video-not-found": "Could not load video \"{}\"",

    "home.section.list.view-all": "View All",
    "home.section.new-this-week.photos": "Images",
    "home.section.new-this-week.photos.label": "images label",
    "home.section.new-this-week.screeners": "Screeners",
    "home.section.new-this-week.screeners.label": "screeners label",
    "home.section.new-this-week.titles": "Titles",
    "home.section.new-this-week.titles.label": "titles label",
    "home.section.new-this-week.total-count": "Total:",
    "home.section.new-this-week.total-count.label": "total label",
    "home.section.new-this-week.videos": "Videos",
    "home.section.new-this-week.videos.label": "videos label",

    "homepage.error.page-not-found": "No home page has been found for the current user. Please contact support.",
    "homepage.error.page-not-available": "No available content was found. Please contact your Client Rep.",
    "homepage.first.time.login": "Hi There, We're very excited to have you onboard WBTVD, please go to the \"My Account\" section under your user profile or simply click <a href='/account'>here</a> to get started, it'll only take a minute!",

    "homepage-titles.last-login": "Last Login",
    "homepage-titles.sort-by": "Sort by",
    "homepage-titles.titles": "Titles",
    "homepage-titles.titles.get.error": "There was an error retrieving titles list, please try again later",
    "homepage-titles.titles-to-explore": "Titles To Explore",
    "homepage-titles.title.premiere": "Premiere",
    "homepage-titles.welcome": "Welcome",

    "hub.animation.title": "Animation",
    "hub.doc-films.title": "Doc Films",
    "hub.film.title": "Film",
    "hub.kids.title": "Kids",
    "hub.limited-series.title": "Limited Series",
    "hub.search.display.title": "Display",
    "hub.search.documents.count.label": "{totalCount, plural, =0 {No Documents} one {# Document} other {# Documents}}",
    "hub.search.documents.prefix": "Document ",
    "hub.search.episodes.count.label": "{totalCount, plural, =0 {No Episodes} one {# Episode} other {# Episodes}}",
    "hub.search.episodes.prefix": "Episode ",
    "hub.search.images.count.label": "{totalCount, plural, =0 {No Images} one {# Image} other {# Images}}",
    "hub.search.images.prefix": "Image ",
    "hub.search.moments.count.label": "{totalCount, plural, =0 {No Moments} one {# Moment} other {# Moments}}",
    "hub.search.moments.prefix": "Moment ",
    "hub.search.talent.count.label": "Talent",
    "hub.search.talent.prefix": "Talent ",
    "hub.search.title": "Search Results for",
    "hub.search.titles.count.label": "{totalCount, plural, =0 {No Titles} one {# Title} other {# Titles}}",
    "hub.search.titles.prefix": "Title ",
    "hub.search.videos.count.label": "{totalCount, plural, =0 {No Videos} one {# Video} other {# Videos}}",
    "hub.search.videos.prefix": "Video ",
    "hub.search.view-all.title": "Results for",
    "hub.tv.title": "TV",

    "list-assets.column.value.new": "New",
    "list-assets.contentType.title": "Content Type",
    "list-assets.description.title": "Description",
    "list-assets.documentName.title": "Document Name",
    "list-assets.documentType.title": "Document Type",
    "list-assets.episode.title": "Episode",
    "list-assets.episodes.title": "Episodes",
    "list-assets.format-sheet-run.title": "Run",
    "list-assets.format-sheet-week.title": "Week",
    "list-assets.isNew.title": "New",
    "list-assets.moments.episode": "Episode {episode}",
    "list-assets.moments.season": "Season {season}",
    "list-assets.moments.seasons": "{count, plural, one {# season} other {# seasons}}",
    "list-assets.name.title": "Name",
    "list-assets.order.title": "#",
    "list-assets.pdf-preview-modal.close": "Close",
    "list-assets.runtime.title": "Runtime",
    "list-assets.run-date.title": "Run Date",
    "list-assets.scriptName.title": "Script Name",
    "list-assets.season.title": "Season",
    "list-assets.seasons.title": "Season",
    "list-assets.series.title": "Series",

    "list-documents.actions.get.error": "There was an error retrieving the document list, please try again later",
    "list-documents.table.date-created": "Date Created",
    "list-documents.table.document-types.29": "Alternative Content Sales Updates",
    "list-documents.table.name": "Name",

    "login.brand.name": "Warner Bros. Television Distribution.",
    "login.browser.not.supported": "Sorry, your browser is not fully supported. You may experience unexpected behavior.",
    "login.delete-user-session-brand.error": "There was an error deleting user session brand. Please try again.",
    "login.domain-zero-without-account.message.first-profile": "Let's take a moment to set up your profile.",
    "login.domain-zero-without-account.message.first-sign-in": "It looks like this is your first time signing in.",
    "login.domain-zero-without-account.title": "Connect Your Account",
    "login.email-or-login-code": "Enter email or login code...",
    "login.error.401": "Please check your username and/or password and try again.",
    "login.error.clear-session-token-mismatch": "Your account is locked. Please contact wbtvd@warnerbros.com.",
    "login.error.complete-form": "Please enter your email and password, or login code.",
    "login.error.expired": "Your password has expired, please contact wbtvd@warnerbros.com.",
    "login.error.inactive": "Your account is inactive, please contact wbtvd@warnerbros.com.",
    "login.error.locked_out": "Your account has been locked. An email has been sent to the email address on file for this account.",
    "login.error.mfa.400": "Error trying to send the MFA push notification. Please try again or contact your Client Representative.",
    "login.error.mfa.code.rejected": "Please enter a correct OKTA's verification code in order to access the content.",
    "login.error.mfa.rejected": "Please approve Okta's MFA Notification in order to access the content.",
    "login.error.warner-media": "We are working on an issue that may affect some employees access. We apologize for the inconvenience and thank you for your patience.",
    "login.error": "There has been an error. Please try again in a few minutes.",
    "login.forgot-password": "Reset Password",
    "login.help": "Help",
    "login.login": "Login",
    "login.login.in-progress": "Logging In...",
    "login.logout.invalid": "You have been logged out due to an invalid session.",
    "login.logout.success": "You have logged out successfully.",
    "login.logout.timeout": "You have been logged out due to inactivity.",
    "login.mfa.verifying-token": "Verifying code...",
    "login.navigate-to-partner.get-token.error": "There was an error navigating to \"{}\". Please try again.",
    "login.one-time-login": "One Time Login",
    "login.password": "Password...",
    "login.rate-limit-exceed": "There was an error processing your request. Please try again in 10 minutes.",
    "login.redirect.press-site.modal.body": "The new web address for the new WBTVD Press Site is <a href=\"%url\">https://press.wbtvd.com</a><br/>...You will be transferred to the site in a moment...<br/>If you still see this message, please click the link above. Thank you!",
    "login.redirect.press-site.modal.title": "We Have Moved!",
    "login.sign-up": "Sign Up",
    "login.terms-of-use": "Terms of Use",
    "login.update-user-session-brand.error": "There was an error updating user session brand. Please try again.",

    "new-this-week.title": "New this week",

    "one-time-login.confirm": "An e-mail has been sent to the address on your account with a one time login link. To ensure that you receive this message, please make sure the e-mail address on your account is up to date and that the message did not go to your junk/spam folder.",
    "one-time-login.contact": "If you do not receive this e-mail, please contact wbtvd@warnerbros.com.",
    "one-time-login.error.domain": "Please enter your email. If you continue to have trouble getting access, please contact wbtvd@warnerbros.com.",
    "one-time-login.error": "There was an error sending the one time login link. Please contact wbtvd@warnerbros.com",
    "one-time-login.form.button": "Send",
    "one-time-login.form.email.error.empty": "Plase, fill the field below",
    "one-time-login.form.email.error.validation": "That's not a valid email",
    "one-time-login.form.email.placeholder": "Email...",
    "one-time-login.form.progress": "Sending...",
    "one-time-login.message": "Just type in your email address, and a link to login will be e-mailed to you shortly.",
    "one-time-login.please-wait": "Please wait, we're processing your login...",
    "one-time-login.return.login": "Return to login",
    "one-time-login.success": "A one time login link has been sent to your email address.",
    "one-time-login.title": "One Time Login Link",

    "orders.alert.format": "Some of the videos in this order are not available in the requested delivery format, and will arrive in the format highlighted in red above.",
    "orders.create.error": "Please check the required fields and try again.",
    "orders.delivery-formats": "Delivery Formats",
    "orders.delivery-formats.video-file-format": "Video Delivery Format",
    "orders.delivery-formats.save-as-default": "Save As Default",
    "orders.delivery-formats.save-as-default.description": "Save these delivery options as the default on future orders",
    "orders.delivery-formats.sidecar-file": "Caption File",
    "orders.delivery-formats.sidecar-file.description": "Include Caption File",
    "orders.download-video.error": "There was an error downloading video with id {}",
    "orders.filters.navbar.filter-by": "Filter by: ",
    "orders.filters.navbar.titles.client": "Client",
    "orders.filters.navbar.titles.date.from": "From",
    "orders.filters.navbar.titles.date.to": "To",
    "orders.filters.navbar.titles.date_added": "Date Added Range",
    "orders.filters.navbar.titles.due_date": "Due Date Range",
    "orders.filters.navbar.titles.order-name": "Order Name",
    "orders.filters.navbar.titles.order-number": "Order Number",
    "orders.filters.navbar.titles.order-status": "Order Status",
    "orders.filters.navbar.titles.order-status.active": "Active",
    "orders.filters.navbar.titles.order-status.approved": "Approved",
    "orders.filters.navbar.titles.order-status.complete": "Complete",
    "orders.filters.navbar.titles.order-status.denied": "Denied",
    "orders.filters.navbar.titles.order-status.fulfilled": "Fullfilled",
    "orders.filters.navbar.titles.order-status.fulfillment": "Fulfillment",
    "orders.filters.navbar.titles.order-status.on-hold": "On hold",
    "orders.filters.navbar.titles.order-status.requested": "Requested",
    "orders.filters.navbar.titles.requester": "Requester",
    "orders.get.audio-config-types.error": "We could not load audio details for some videos, please check back later or reload the page",
    "orders.metadata.get.error": "There was an error getting your metadata. Please try again.",
    "orders.pagination.page": "Page",
    "orders.pagination.page-of": "of",
    "orders.reprocess-order": "Reprocess Order",
    "orders.reprocess-order.success": "Your Order has been reprocessed, please check your emails for notifications pertaining to the order.",
    "orders.reprocess-order.error": "There was an error while attempting to reprocess your Order. Please contact support.",
    "orders.sent-to-me.approve": "Approve",
    "orders.sent-to-me.approve-deny": "Please review and approve or deny.",
    "orders.sent-to-me.approve.error": "There was an error Approving the Order.",
    "orders.sent-to-me.approve.success": "Order approved.",
    "orders.sent-to-me.client-rep.title": "Review Request",
    "orders.sent-to-me.client.Number": "Phone Number",
    "orders.sent-to-me.client.company": "Company Name",
    "orders.sent-to-me.client.country": "Country",
    "orders.sent-to-me.client.delivery": "Delivery Format",
    "orders.sent-to-me.client.delivery.hd.50": "HD - 1080I 50",
    "orders.sent-to-me.client.delivery.hd.59": "HD - 1080I 59.94",
    "orders.sent-to-me.client.delivery.image": "Image Delivery Format",
    "orders.sent-to-me.client.delivery.sd.ntsc": "SD - NTSC",
    "orders.sent-to-me.client.delivery.sd.pal": "SD - PAL",
    "orders.sent-to-me.client.lookupByEmail": "Lookup Client by Email",
    "orders.sent-to-me.client.lookupByName": "Lookup Client by Name First/Last",
    "orders.sent-to-me.client.notes": "Notes",
    "orders.sent-to-me.client.sameAsRequester": " Same as Requester",
    "orders.sent-to-me.client.title": "Client",
    "orders.sent-to-me.deliver-to.emails": "Emails",
    "orders.sent-to-me.deliver-to.title": "Deliver to",
    "orders.sent-to-me.deny": "Deny",
    "orders.sent-to-me.deny.error": "There was an error Denying the Order.",
    "orders.sent-to-me.deny.success": "Order denied.",
    "orders.sent-to-me.fulfill": "Fulfill",
    "orders.sent-to-me.fulfill.error": "There was an error Fulfilling the Order.",
    "orders.sent-to-me.fulfill.success": "Order fulfilled.",
    "orders.sent-to-me.order-info.due-date": "Due Date",
    "orders.sent-to-me.order-info.last-action": "Last Action",
    "orders.sent-to-me.order-info.last-action-date": "Last Action Date",
    "orders.sent-to-me.order-info.order-name": "Request Name",
    "orders.sent-to-me.order-info.request-number": "Request Number",
    "orders.sent-to-me.order-info.status": "Status",
    "orders.sent-to-me.order-info.title": "Order Info",
    "orders.sent-to-me.order.comment-title": "Comment",
    "orders.sent-to-me.order.required-message": "Please provide intended use",
    "orders.sent-to-me.requester.company": "Company Name",
    "orders.sent-to-me.requester.country": "Country",
    "orders.sent-to-me.requester.email": "Email",
    "orders.sent-to-me.requester.name": "Requester Name",
    "orders.sent-to-me.requester.phone": "Phone Number",
    "orders.sent-to-me.requester.title": "Requester",
    "orders.sent-to-me.service-admin.title": "View Order",
    "orders.status.change.button": "Change Status",
    "orders.status.change.modal.description": "Change order status to",
    "orders.status.change.modal.error": "Status change failed",
    "orders.status.change.modal.success": "Order successfully updated",
    "orders.status.change.modal.title": "Change Order Status",
    "orders.tab.all-orders.title": "All Orders",
    "orders.tab.confirm-remove-content.dialog.description": "This item will be removed from your list.",
    "orders.tab.confirm-remove-content.dialog.title": "Are You Sure?",
    "orders.tab.my-metadata.title": "Title Packages",
    "orders.tab.my-orders.title": "My Orders",
    "orders.tab.my-saved-carts.confirm-reload-to-cart.dialog.description": "Current contents of your cart will be saved and replaced with this order. You can find the saved cart under 'My Account'.",
    "orders.tab.my-saved-carts.confirm-reload-to-cart.dialog.title": "Reload to Cart",
    "orders.tab.my-saved-carts.reload-to-cart.error": "Reload to Cart error.",
    "orders.tab.my-saved-carts.reload-to-cart.success": "Reloaded to Cart Ok.",
    "orders.tab.my-saved-carts.title": "My Saved Carts",
    "orders.tab.orders-in-progress.title": "Orders In Progress",
    "orders.tab.requests-sent-me.subtitle": "Please review and approve or deny.",
    "orders.tab.requests-sent-me.title": "Requests Sent to Me",
    "orders.tab.standing-orders.title": "Standing Orders",
    "orders.tab.subscribed-content.delete.error": "Something went wrong unable to remove asset",
    "orders.tab.subscribed-content.delete.success": "Asset successfully removed",
    "orders.tab.subscribed-content.table.title.asset-name": "Asset Name",
    "orders.tab.subscribed-content.table.title.content-type": "Content Type",
    "orders.tab.subscribed-content.table.title.subscribed-date": "Subscription Date",
    "orders.tab.subscribed-content.table.title.subscription-type": "Subscription Type",
    "orders.tab.subscribed-content.title.subscribed-content": "Subscribed Content",
    "orders.tab.subscriptions.confirm-unsubscribe-client.dialog.description": "Would you like to Unsubscribe client?",
    "orders.tab.subscriptions.confirm-unsubscribe-client.dialog.title": "Are You Sure?",
    "orders.tab.subscriptions.confirm-unsubscribe-selected-clients.dialog.description": "Would you like to Unsubscribe Selected clients?",
    "orders.tab.subscriptions.title": "Subscriptions",
    "orders.tab.subscriptions.unsubscribe-client.error": "Unsubscribe client error.",
    "orders.tab.subscriptions.unsubscribe-client.success": "Client successfully unsubscribed.",
    "orders.tab.subscriptions.unsubscribe-clients.client.error": "Error unsuscribing client: {}.",
    "orders.tab.subscriptions.unsubscribe-clients.error": "Unsubscribe clients error.",
    "orders.tab.subscriptions.unsubscribe-clients.success": "Clients successfully unsubscribed.",
    "orders.title": "Orders",
    "orders.view-order.title": "View Order",

    "player.container": "Video Player",
    "player.errors.browser.not-supported": "Watch this video on the <a href=\"https://www.wbdscreeners.com/page/app/index.html\">WBD Screeners App</a> available on iOS, Android, Apple TV, Android TV, Roku, Chromecast, and macOS. Also playable on your computer with the current versions of Safari (macOS), IE Edge (Windows 8.1+), Google Chrome and Mozilla Firefox",
    "player.errors.children": "There was an error while loading the playlist videos.",
    "player.errors.drm.has-license-but-no-source": "The video you want to play has a DRM license but no valid streams.",
    "player.errors.drm.incomplete-license": "The video you want to play has incomplete DRM information. Please try again or contact your Client Representative.",
    "player.errors.drm.token.404": "Error requesting DRM playback authorization. Please try again, if the problem persists, contact your Client Representative.",
    "player.errors.mfa.400": "Error trying to send the MFA push notification. Please try again or contact your Client Representative.",
    "player.errors.mfa.rejected": "Please approve Okta's MFA Notification in order to play the video.",
    "player.errors.mfa.status": "There was a problem reaching Okta for MFA status. Please try again.",
    "player.errors.no-sources": "There are no playable sources for this video.",
    "player.errors.streams.400": "There was an error while getting the streams to play the video. Please try again or contact your Client Representative.",
    "player.mfa.enrollment.modal.body": "An email has been sent to <b>%s</b><br/>Please open the email on your phone and follow the instructions on how to activate Okta Verify App for your account.",
    "player.mfa.enrollment.modal.title": "Secure Content",
    "player.mfa.push-notification.modal.body": "A notification has been sent to the Okta Verify App on your phone. Please approve to continue. OR you may",
    "player.mfa.push-notification.modal.body.code": "enter your verification code",
    "player.mfa.push-notification.modal.title": "Secure Content",
    "player.mfa.verification-code.modal.body": "A 6-digit code will display in the Okta app. Please enter the code below",
    "player.mfa.verification-code.modal.body.enter-code": "Enter code",

    "preauthenticated.invalid": "Invalid or expired token",
    "preauthenticated.please.login": "Please login to view shared content",

    "reset-password.additional-requirements.note": "Please note, password cannot contain the following:",
    "reset-password.additional-requirements.text": "Your first name, last name, user name/email address, or any of the last five passwords used",
    "reset-password.button.change": "Change",
    "reset-password.button.set": "Set",
    "reset-password.button.change.in-progress": "Changing...",
    "reset-password.button.set.in-progress": "Setting...",
    "reset-password.form.confirmPassword.placeholder": "Confirm New Password...",
    "reset-password.form.confirmPassword": "Confirm Password",
    "reset-password.form.password.error.empty": "Please, fill the field(s) below",
    "reset-password.form.password.error.match": "Passwords do not match",
    "reset-password.form.password.placeholder": "New Password...",
    "reset-password.form.password": "New Password",
    "reset-password.message.set": "To set your password please enter and confirm your password below and press \"SET.\"",
    "reset-password.message": "To reset your password please enter and confirm your new password below and press \"CHANGE.\"",
    "reset-password.set-new.error": "Unable to reset password. Please try again or try a different password.",
    "reset-password.set-new.expired-link":"The password reset link has expired, a new link has been sent to you by email",
    "reset-password.set-new.password.token.failed": "Password Reset link is not valid. Redirecting to login page...",
    "reset-password.set-new.password.validation-check.title": "Your new password must:",
    "reset-password.set-new.success": "Password updated, please login",

    "search-list.columns.assetDateCreated.title": "Date Created",
    "search-list.columns.assetName.title": "Asset",
    "search-list.columns.assetTitle.title": "Title",
    "search-list.columns.clipDisplayName.title": "Name/Description",
    "search-list.columns.genres.title": "Genres",
    "search-list.columns.language.title": "Language",
    "search-list.columns.num-episodes.title": "#Episodes",
    "search-list.columns.num-seasons.title": "#Seasons",
    "search-list.columns.release-date.title": "Release Date",
    "search-list.columns.runtime.minutes": "Minutes",
    "search-list.columns.runtime.title": "Runtime",
    "search-list.columns.talentCreditedAs.title": "Credited As",
    "search-list.columns.talentName.title": "Talent Name",
    "search-list.columns.title-type.title": "Title Type",
    "search-list.columns.title.title": "Title",
    "search-list.columns.type.title": "Type",
    "search-list.download.spreadsheet": "Download As Spreadsheet",
    "search.filters.category.1": "Theatrical Features",
    "search.filters.category.2": "Animated Features",
    "search.filters.category.3": "Made For Video Features",
    "search.filters.category.4": "Season Half Hour",
    "search.filters.category.5": "Season One Hour",
    "search.filters.category.6": "Talk Show Season",
    "search.filters.category.7": "Game Show Season",
    "search.filters.category.8": "Animated Series Season",
    "search.filters.category.9": "Cartoons",
    "search.filters.category.10": "Mini Series",
    "search.filters.category.11": "Network",
    "search.filters.category.12": "Cable",
    "search.filters.category.13": "Pay TV",
    "search.filters.category.14": "Specials",
    "search.filters.category.15": "Animated Specials",
    "search.filters.category.16": "Comedy Specials",
    "search.filters.category.17": "Music Specials",
    "search.filters.category.18": "Sports",
    "search.filters.category.19": "Documentaries",
    "search.filters.category.20": "Short Programs",
    "search.filters.category.21": "Making Of",
    "search.filters.category.23": "Episode",
    "search.filters.category.24": "Series Half Hour",
    "search.filters.category.25": "Series One Hour",
    "search.filters.category.26": "Talk Show",
    "search.filters.category.27": "Game Show",
    "search.filters.category.28": "Animated Series",
    "search.filters.category.29": "Format Rights",
    "search.filters.category.30": "Reality",
    "search.filters.category.31": "Reality Season",
    "search.filters.category.32": "Direct to Video",
    "search.filters.category.33": "Animation",
    "search.filters.category.34": "Cartoons Season",
    "search.filters.category.35": "Short Programs Season",
    "search.filters.category.36": "Event",
    "search.filters.category.37": "Other Products",
    "search.filters.category.38": "Segment",
    "search.filters.category.39": "Programming Package",
    "search.filters.category.40": "HBO Films",
    "search.filters.category.41": "Documentary Films",
    "search.filters.category.42": "Limited Series",
    "search.filters.category.43": "Docuseries",
    "search.filters.category.44": "Limited Series Season",
    "search.filters.category.45": "Docuseries Season",
    "search.filters.category.46": "Warner Bros: HBO Max Release",
    "search.filters.navbar.titles.and": "AND",
    "search.filters.navbar.titles.animated": "Category",
    "search.filters.navbar.titles.animated-checkbox": "Category",
    "search.filters.navbar.titles.animated.value": "Animated",
    "search.filters.navbar.titles.animation-checkbox": "Category",
    "search.filters.navbar.titles.animation.value": "Animation",
    "search.filters.navbar.titles.barcode": "Barcode",
    "search.filters.navbar.titles.box-office": "Box Office",
    "search.filters.navbar.titles.category": "Title Type",
    "search.filters.navbar.titles.category-dropdown": "Category",
    "search.filters.navbar.titles.country": "Country",
    "search.filters.navbar.titles.date-created": "Date Added",
    "search.filters.navbar.titles.date-from": "From Date",
    "search.filters.navbar.titles.date-to": "To Date",
    "search.filters.navbar.titles.definition": "Definition",
    "search.filters.navbar.titles.documentary-checkbox": "Category",
    "search.filters.navbar.titles.documentary.value": "Documentary",
    "search.filters.navbar.titles.film-checkbox": "Category",
    "search.filters.navbar.titles.film.value": "Film",
    "search.filters.navbar.titles.from": "From",
    "search.filters.navbar.titles.from_date": "From Date",
    "search.filters.navbar.titles.genre": "Genre",
    "search.filters.navbar.titles.genre_theme": "Genre & Theme",
    "search.filters.navbar.titles.hd-checkbox": "Definition",
    "search.filters.navbar.titles.hd.value": "HD",
    "search.filters.navbar.titles.setting": "Setting",
    "search.filters.navbar.titles.language": "Language",
    "search.filters.navbar.titles.tag": "Tag",
    "search.filters.navbar.titles.dialogue": "Dialogue",
    "search.filters.navbar.titles.talent-character": "Talent | Character",
    "search.filters.navbar.titles.library": "Library",
    "search.filters.navbar.titles.library-checkbox": "Category",
    "search.filters.navbar.titles.library.value": "Library",
    "search.filters.navbar.titles.limited-series-checkbox": "Category",
    "search.filters.navbar.titles.limited-series.value": "Limited Series",
    "search.filters.navbar.titles.live-action-checkbox": "Category",
    "search.filters.navbar.titles.live-action.value": "Live Action",
    "search.filters.navbar.titles.made-for-checkbox": "Category",
    "search.filters.navbar.titles.movies-checkbox": "Category",
    "search.filters.navbar.titles.movies.value": "Movies",
    "search.filters.navbar.titles.new-checkbox": "Category",
    "search.filters.navbar.titles.new.value": "New",
    "search.filters.navbar.titles.or": "OR",
    "search.filters.navbar.titles.release": "Release",
    "search.filters.navbar.titles.release-date": "Release Date",
    "search.filters.navbar.titles.returning-checkbox": "Category",
    "search.filters.navbar.titles.returning.value": "Returning",
    "search.filters.navbar.titles.run-time": "Runtime",
    "search.filters.navbar.titles.run-time.format": "0h 0m",
    "search.filters.navbar.titles.run-time.legend": "Use hours and/or minutes.",
    "search.filters.navbar.titles.scripted-checkbox": "Category",
    "search.filters.navbar.titles.scripted.value": "Scripted",
    "search.filters.navbar.titles.sd-checkbox": "Definition",
    "search.filters.navbar.titles.sd.value": "SD",
    "search.filters.navbar.titles.search_criteria": "Search Criteria",
    "search.filters.navbar.titles.sort": "Sort",
    "search.filters.navbar.titles.sort.alphabetical": "Alphabetical",
    "search.filters.navbar.titles.sort.ascending": "Ascending",
    "search.filters.navbar.titles.sort.date-added": "Date Added",
    "search.filters.navbar.titles.sort.date-release": "Date Released",
    "search.filters.navbar.titles.sort.descending": "Descending",
    "search.filters.navbar.titles.sort.relevance": "Relevance",
    "search.filters.navbar.titles.talent": "Talent",
    "search.filters.navbar.titles.theme": "Theme",
    "search.filters.navbar.titles.time-frame": "Date Added",
    "search.filters.navbar.titles.title-catalog": "Library",
    "search.filters.navbar.titles.to": "To",
    "search.filters.navbar.titles.to_date": "To Date",
    "search.filters.navbar.titles.tv": "Category",
    "search.filters.navbar.titles.tv-checkbox": "Category",
    "search.filters.navbar.titles.tv.value": "TV",
    "search.filters.navbar.titles.unscripted-checkbox": "Category",
    "search.filters.navbar.titles.unscripted.value": "Unscripted",
    "search.filters.time-frame.PAST_MONTH": "Past Month",
    "search.filters.time-frame.PAST_WEEK": "Past Week",
    "search.filters.time-frame.PAST_YEAR": "Past Year",
    "search.results.documents": "Documents",
    "search.results.episodes": "Episodes",
    "search.results.images": "Images",
    "search.results.moments": "Moments",
    "search.results.none-found": "None Found",
    "search.results.talent": "Talent",
    "search.results.titles": "Titles",
    "search.results.videos": "Videos",
    "search.results.view-all": "View All",
    "search.save-search.error": "Failed to save search URL, please try again or contact your client representative if the problem persists.",
    "search.save-search.saved-search-name": "Saved Search Name",
    "search.save-search.success": "Search URL successfully saved",
    "search.save-search.title": "Save search",
    "search.share-search.error": "Failed to share search URL, please try again or contact your client representative if the problem persists.",
    "search.share-search.success": "Search URL successfully shared",

    "session.timeoutWarning.keep-alive-button": "Keep Alive",
    "session.timeoutWarning.message-post": " Click the 'Keep Alive' button to continue",
    "session.timeoutWarning.message-pre": "Your session will expire in ",
    "session.timeoutWarning.title": "Your session is about to expire",

    "signup.additional-access-content.business-justification": "Business Justification",
    "signup.additional-access-content.business-justification.placeholder": "For what business purpose do you need this additional access?",
    "signup.additional-access-content.station-documents": "Enter All Station Callsign(s)",
    "signup.additional-access-content.station-documents.placeholder": "Example: KTXS, WRGB, WCWNM KVII, KVIH",
    "signup.error": "There was an error while trying to register a new user, please try again",
    "signup.error.400": "The server cannot process the request due to a client error",
    "signup.error.403": "There user couldn't be created. Please try again in a moment",
    "signup.error.422": "There's already a user registered with that email",
    "signup.form.error.message": "Please check the required fields and try again.",
    "signup.form.footer.text.block1": "By clicking on the submit button below, you thereby agree to the terms stated in the terms of use policy.",
    "signup.form.footer.text.block2": "to review the terms of use policy.",
    "signup.form.footer.text.button": "Click here",
    "signup.form.label.button.apply": "Apply",
    "signup.form.label.company-outlet.placeholder": "Please type your company name / outlet name",
    "signup.form.label.company-outlet": "Company / Outlet",
    "signup.form.label.company.placeholder": "Please type your company name",
    "signup.form.label.company": "Company",
    "signup.form.label.contact.adultswim_press": "Adult Swim Contact",
    "signup.form.label.contact.cartoon_network_press": "Cartoon Network Contact",
    "signup.form.label.contact.cartoonito_press": "Cartoonito Contact",
    "signup.form.label.contact.formats_portal": "Formats Contact",
    "signup.form.label.contact.hbo_max_portal": "HBO Max Contact",
    "signup.form.label.contact.hbo": "HBO Contact",
    "signup.form.label.contact.wbtvd_press": "WBD Contact",
    "signup.form.label.contact.wbtvd": "WB Contact",
    "signup.form.label.country.placeholder": "Please Select a Country",
    "signup.form.label.country": "Country",
    "signup.form.label.dayPhone": "Day Phone",
    "signup.form.label.email": "Email",
    "signup.form.label.eveningPhone": "Evening Phone",
    "signup.form.label.jobTitle": "Job Title",
    "signup.form.label.lastName": "Last Name",
    "signup.form.label.legal": "Legal",
    "signup.form.label.middleName": "Middle Name",
    "signup.form.label.name": "First Name",
    "signup.form.label.outlet.placeholder": "Please Select an Outlet",
    "signup.form.label.outlet": "Outlet",
    "signup.form.label.password": "Password",
    "signup.form.label.privacy-policy-fyc.text": " and acknowledge the ",
    "signup.form.label.privacy-policy": "Privacy Policy",
    "signup.form.label.referrers-email": "Referrer's Email",
    "signup.form.label.request-additional-security": "Request Additional Security",
    "signup.form.label.submit.fyc": "Submit",
    "signup.form.label.submit": "Submit For Approval",
    "signup.form.label.term-of-use-fyc.text": "I accept the ",
    "signup.form.label.term-of-use.text": "I agree to the ",
    "signup.form.label.term-of-use": "Terms of Use",
    "signup.form.label.wbtvd.placeholder": "Please Select One",
    "signup.form.responsibility.option.label.other": "Other",
    "signup.form.title.adultswim": "Adult Swim Sign-Up",
    "signup.form.title.adultswim_press": "Adult Swim Sign-Up",
    "signup.form.title.cartoon_network_press": "Cartoon Network Sign-Up",
    "signup.form.title.fyc": "Sign Up For FYC",
    "signup.form.title.hbo": "HBO Distribution Sign-Up",
    "signup.form.title.press_site": "Warner Bros. Press Sign-Up",
    "signup.form.title.wbtvd": "Warner Bros. Television Distribution Sign-Up",
    "signup.thank-you.text": "Your application will be reviewed and you will receive a reply via E- Mail within 48 hours.",
    "signup.thank-you.title": "Thanks for applying!",

    "static.page.title.2015-slate": "2015 Slate",
    "static.page.title.adultswim-contacts": "Adult Swim Contact Us",
    "static.page.title.animation-package": "Animation Package",
    "static.page.title.app-notes": "App Notes",
    "static.page.title.cartoon-network-press-contacts": "Cartoon Network Contact Us",
    "static.page.title.cartoonito-press-contacts": "Cartoonito Contact Us",
    "static.page.title.classic-movies": "Classic Movies",
    "static.page.title.contact-us": "Contact Us",
    "static.page.title.contact-us-domestic": "Contact Us - Domestic",
    "static.page.title.crime-package": "Crime Package",
    "static.page.title.digital-series": "Digital Series",
    "static.page.title.family-holiday": "Family Holiday",
    "static.page.title.formats-catalog": "Formats Catalog",
    "static.page.title.formats-showcase": "Formats Showcase",
    "static.page.title.full-episodes": "Full Episodes",
    "static.page.title.hbo-contacts": "HBO Contact Us",
    "static.page.title.hbo-max-contacts": "HBO Max Contact Us",
    "static.page.title.formats-portal-contacts": "Formats Contact Us",
    "static.page.title.hd-titles": "HD Titles",
    "static.page.title.horror-package": "Horror Package",
    "static.page.title.network45": "Network 45",
    "static.page.title.pitch-blue": "Pitch Blue",
    "static.page.title.press-site-contacts": "Press Site Contact Us",
    "static.page.title.products": "Products",
    "static.page.title.romance-package": "Romance Package",
    "static.page.title.summer-of-animation": "Summer of Animation",
    "static.page.title.terms-of-use": "Terms of Use",
    "static.page.title.tv-event": "TV Event",
    "static.page.title.harry-potter": "Harry Potter",
    "static.page.title.harry-potter/core-elements": "Core Elements",
    "static.page.title.harry-potter/editorial-packages": "Editorial Packages",
    "static.page.title.harry-potter/on-air-navigation": "On Air Navigation",
    "static.page.title.harry-potter/promo-packages": "Promo Packages",

    "station-summit.redirect.header": "Redirecting, Please Wait...",
    "station-summit.redirect.text": "Please click here if you are not redirected.",

    "talent.credits.info": "Info",
    "talent.credits.role": "Role",
    "talent.credits.role.actor": "Actor",
    "talent.credits.role.animated-character": "Animated Character",
    "talent.credits.role.author": "Author",
    "talent.credits.role.character-name": "Character Name",
    "talent.credits.role.character-name-comment": "Character Name/Comment",
    "talent.credits.role.choreographer": "Choreographer",
    "talent.credits.role.creator":"Created By",
    "talent.credits.role.director": "Director",
    "talent.credits.role.executive-producer": "Executive Producer",
    "talent.credits.role.guest-star": "Guest Star",
    "talent.credits.role.host": "Host",
    "talent.credits.role.misc-crew":"Miscellaneous Crew",
    "talent.credits.role.musician": "Musician",
    "talent.credits.role.producer": "Producer",
    "talent.credits.role.release-date": "Release Date",
    "talent.credits.role.title": "Title",
    "talent.credits.role.voice-talent": "Voice Talent",
    "talent.credits.role.writer": "Writer",
    "talent.credits.title": "Credits",
    "talent.info.biography": "Biography",

    "terms-of-use.button.agree": "Agree",
    "terms-of-use.button.close": "Close",
    "terms-of-use.button.deny": "Cancel",

    "tital.modal.generate-link.warning": "Please fill in all required fields.",

    "title-language-availability.availability-date": "Availability Date",
    "title-language-availability.dub": "Dub",
    "title-language-availability.export": "Export",
    "title-language-availability.export-button": "Generate Excel of Results",
    "title-language-availability.get.error": "Error loading Alternative Content Sales, please reload the page or contact your client representative if the problem persists",
    "title-language-availability.language": "Language",
    "title-language-availability.search": "Search",
    "title-language-availability.search-by-title": "Search Alternative Content Sales by Title",
    "title-language-availability.subtitle": "Subtitle",
    "title-language-availability.title": "Alternative Content Sales",
    "title-language-availability.title-name": "Title Name",
    "title-language-availability.version-code": "Version Code",
    "title.additional-info.common.data-not-available": "-",
    "title.additional-info.languages.availability-date": "Avail Date",
    "title.additional-info.languages.dub": "Dub",
    "title.additional-info.languages.subtitle": "Subtitle",
    "title.additional-info.languages.version-code": "Version Code",
    "title.additional-info.languages": "Alternative Content Sales",
    "title.additional-info.mastering-info.aspect-ratio": "Aspect Ratio",
    "title.additional-info.mastering-info.audios": "Audio",
    "title.additional-info.mastering-info.notes": "Notes",
    "title.additional-info.mastering-info.original-source-media": "Original Source Media",
    "title.additional-info.mastering-info.standard": "Standard",
    "title.additional-info.mastering-info": "Mastering Info",
    "title.additional-info.title-info.action-type": "Action Type",
    "title.additional-info.title-info.copyright-year": "Copyright Year",
    "title.additional-info.title-info.definition": "Definition",
    "title.additional-info.title-info.language": "Language",
    "title.additional-info.title-info.library": "Library",
    "title.additional-info.title-info.made-for": "Made For",
    "title.additional-info.title-info.movie-color": "Movie Color",
    "title.additional-info.title-info.mpaa-ratings": "MPAA rating",
    "title.additional-info.title-info.mpaa-ratings.no-rating": "No MPAA rating",
    "title.additional-info.title-info.network": "{networksCount, plural, one {Network: } other {Networks: }}",
    "title.additional-info.title-info.parental-rating": "Parental rating",
    "title.additional-info.title-info.presentation-credits": "Presentation Credits",
    "title.additional-info.title-info.production-company": "Production Company",
    "title.additional-info.title-info.program-legal": "Program Legal",
    "title.additional-info.title-info.promotional-legal": "Promotional Materials Legal",
    "title.additional-info.title-info.rating-reason": "Rating Reason",
    "title.additional-info.title-info.seasons": "Seasons",
    "title.additional-info.title-info": "Title Info",
    "title.additional-info.title": "Additional Info",
    "title.asset-list.filter.date-added-asc": "Date Added Ascending",
    "title.asset-list.filter.date-added-desc": "Date Added Descending",
    "title.asset-list.filter.relevance": "Relevance",
    "title.cast.actors": "Actors",
    "title.cast.animated-characters":"Animated Characters",
    "title.cast.authors":"Authors",
    "title.cast.choreographers":"Choreographers",
    "title.cast.creator": "Created By",
    "title.cast.directors": "Directors",
    "title.cast.executive-producers": "Executive Producers",
    "title.cast.guest-star":"Guest Stars",
    "title.cast.hosts":"Hosts",
    "title.cast.misc-crew": "Miscellaneous Crew",
    "title.cast.musicians":"Musicians",
    "title.cast.producers":"Producers",
    "title.cast.title": "Cast & Credits",
    "title.cast.voice-talent":"Voice Talent",
    "title.cast.writers": "Writers",
    "title.clipping.no-frame-rate": "Framerate not specified. Contact your servicing representative",
    "title.cta.edit": "Edit Title in Brainiac",
    "title.error.no-available-asset.audio": "There are not any audio assets available for this title",
    "title.error.no-available-asset.documents": "There are not any document assets available for this title",
    "title.error.no-available-asset.image": "There are not any image assets available for this title",
    "title.error.no-available-asset.merchandise": "There are not any merchandise assets available for this title",
    "title.error.no-available-asset.scripts": "There are not any script assets available for this title",
    "title.error.no-available-asset.video": "There are not any video assets available for this title",
    "title.episodes-list.button.download": "Download As Spreadsheet",
    "title.episodes-list.button.download-synopsis": "Download All Synopsis",
    "title.episodes-list.table.air-date": "Air Date",
    "title.episodes-list.table.logline": "60-Character Logline",
    "title.episodes-list.table.ro": "RO",
    "title.episodes-list.table.title": "Episode Title",
    "title.episodes-list.title": "Full Episode List",
    "title.header.action-type": "Action Type",
    "title.header.broadcast-premiere": "Broadcast Premiere",
    "title.header.copyright-year": "Copyright Year",
    "title.header.date": "Date",
    "title.header.episode": "Episode",
    "title.header.format-rights": "Format",
    "title.header.genres": "Genre",
    "title.header.network": "Network",
    "title.header.production-company": "Production Company",
    "title.header.program-length": "Program Length",
    "title.header.release-date": "Release Date",
    "title.header.run-time" : "Runtime",
    "title.header.run-time.minutes" : "Min.",
    "title.header.season": "Season",
    "title.header.series": "Series",
    "title.header.single-release": "Made-For-TV Movie",
    "title.header.themes": "Themes",
    "title.header.total-episodes": "Total Episodes",
    "title.header.total-seasons": "Total Seasons",
    "title.image-list.title": "Images",
    "title.image-list.title.animation-production-materials": "Animation Production Materials",
    "title.image-list.title.bts": "Behind the Scenes",
    "title.image-list.title.client-samples": "Client Samples",
    "title.image-list.title.episodic": "Episodic Images",
    "title.image-list.title.events": "Events",
    "title.image-list.title.gallery": "Gallery",
    "title.image-list.title.hbo-max": "HBO Max",
    "title.image-list.title.key-art": "Key Art",
    "title.image-list.title.logo": "Logo",
    "title.image-list.title.online": "Online",
    "title.image-list.title.other": "Other",
    "title.image-list.title.outdoor-art": "Outdoor Art",
    "title.image-list.title.photography-stills": "Photography/Stills",
    "title.image-list.title.social-media": "Social Media",
    "title.image-list.title.svod": "SVOD",
    "title.images.content-types.1": "Art Promo",
    "title.images.content-types.2": "Box Art",
    "title.images.content-types.3": "Key Art",
    "title.images.content-types.4": "Key Art International Version",
    "title.images.content-types.5": "Key Art Television Series",
    "title.images.content-types.6": "Key Art Texted",
    "title.images.content-types.7": "Key Art Textless",
    "title.images.content-types.8": "Key Art Titled",
    "title.images.content-types.9": "Key Art with Title",
    "title.images.content-types.10": "Video Box Art",
    "title.images.content-types.11": "Kisok Art",
    "title.images.content-types.12": "Billboard",
    "title.images.content-types.13": "Banner",
    "title.images.content-types.14": "Social Media",
    "title.images.content-types.15": "Website Other",
    "title.images.content-types.16": "Website Wbitv",
    "title.images.content-types.17": "Website wbitv B2B",
    "title.images.content-types.18": "Logo Animation",
    "title.images.content-types.19": "Logo Movie of Week",
    "title.images.content-types.20": "Logo Network Station",
    "title.images.content-types.21": "Logo Production Company",
    "title.images.content-types.22": "Logo Series",
    "title.images.content-types.23": "Logo Theatrical",
    "title.images.content-types.24": "Logo WB Division",
    "title.images.content-types.25": "Title Treatment On Air",
    "title.images.content-types.26": "Title Treatment Print",
    "title.images.content-types.27": "Photography",
    "title.images.content-types.28": "Unit",
    "title.images.content-types.29": "Episodic",
    "title.images.content-types.30": "Animated Stills",
    "title.images.content-types.31": "Gallery",
    "title.images.content-types.32": "Gallery Cast",
    "title.images.content-types.33": "Gallery Multiple",
    "title.images.content-types.34": "Gallery Single",
    "title.images.content-types.35": "Headshots",
    "title.images.content-types.36": "Set",
    "title.images.content-types.37": "Svod Vertical Display Art",
    "title.images.content-types.38": "Svod Horizontal Display Art",
    "title.images.content-types.39": "Story Art",
    "title.images.content-types.40": "Client Created",
    "title.images.content-types.41": "Animation Composite",
    "title.images.content-types.42": "Backgrounds",
    "title.images.content-types.43": "Character Stock",
    "title.images.content-types.44": "Character Art",
    "title.images.content-types.45": "Character Art Animation",
    "title.images.content-types.46": "Character Art General",
    "title.images.content-types.47": "Behind the Scenes",
    "title.images.content-types.48": "Events",
    "title.images.content-types.49": "Events IJP",
    "title.images.content-types.50": "Events LA Screenings",
    "title.images.content-types.51": "Events MISC",
    "title.images.content-types.52": "Other",
    "title.images.content-types.53": "Ad Contest",
    "title.images.content-types.54": "Ad Cover",
    "title.images.content-types.55": "Ad Trade",
    "title.images.content-types.56": "Advertising",
    "title.images.content-types.57": "Art Merchandising",
    "title.images.content-types.58": "Merchandise Art",
    "title.images.content-types.59": "Merchandise",
    "title.images.content-types.60": "Bags",
    "title.images.content-types.61": "Content Asset Library",
    "title.images.content-types.62": "Custom DVD Packaging",
    "title.images.content-types.63": "Illustrator Template",
    "title.images.content-types.64": "Insert DVD",
    "title.images.content-types.65": "Marketing Sales Kit",
    "title.images.content-types.66": "Photoshop Action",
    "title.images.content-types.67": "Product",
    "title.images.content-types.68": "Promotional Packaging",
    "title.images.content-types.69": "Stock",
    "title.images.content-types.70": "Stock WB Copyright",
    "title.images.content-types.71": "Teaser Campaign",
    "title.images.content-types.72": "GIF",
    "title.images.content-types.73": "Countdown Card",
    "title.images.content-types.74": "Social Media Clip",
    "title.images.content-types.75": "Character Card",
    "title.images.content-types.76": "Tile",
    "title.images.content-types.77": "Tile - Burned In",
    "title.images.content-types.78": "Background",
    "title.images.content-types.79": "Logo - HBO Max",
    "title.images.content-types.80": "Universal Search",
    "title.images.content-types.81": "Episodic Tile",
    "title.images.content-types.animationProductionMaterials": "Animation Production Materials",
    "title.images.content-types.bts": "Behind the Scenes",
    "title.images.content-types.clientSamples": "Client Samples",
    "title.images.content-types.events": "Events",
    "title.images.content-types.gallery": "Gallery",
    "title.images.content-types.hboMax": "HBO Max",
    "title.images.content-types.keyArt": "Key Art",
    "title.images.content-types.logo": "Logo",
    "title.images.content-types.online": "Online",
    "title.images.content-types.other": "Other",
    "title.images.content-types.outdoorArt": "Outdoor Art",
    "title.images.content-types.photographyStills": "Photography Stills",
    "title.images.content-types.socialMedia": "Social Media",
    "title.images.content-types.svod": "SVOD",
    "title.language-availability.get.error": "Error loading Title Language Availability, please reload the page",
    "title.links.title": "Links",
    "title.merchandise.episode.title": "Episode",
    "title.merchandise.season.title": "Season",
    "title.merchandise.series.title": "Series",
    "title.modal.audio.preview.download": "Download",
    "title.modal.audio.preview.title": "Audio Preview",
    "title.modal.frecuency": "Daily",
    "title.modal.moment.create.add-to-cart": "Add to cart",
    "title.modal.moment.create.error": "There was an error creating a new moment, please try again later",
    "title.modal.moment.create.error.no-permission": "You don't have access to create moments, please contact your client representative",
    "title.modal.moment.create.name": "Moment / File Name:",
    "title.modal.moment.create.name.placeholder": "Name / Describe Moment",
    "title.modal.moment.create.note": "Please Note:",
    "title.modal.moment.create.note.description": "The exported file name will be similar to the following:",
    "title.modal.moment.create.title": "Add Moment to cart",
    "title.modal.moment.edit.error": "There was an error updating moment. Please try again late",
    "title.modal.moment.edit.error.no-permission": "You don't have access to edit moments, please contact your client representative",
    "title.modal.moment.edit.success": "Moment updated in cart",
    "title.modal.moment.edit.title": "Update Moment",
    "title.modal.moment.save.title": "Save Moment",
    "title.modal.moment.validations.name": "Moment / File Name",
    "title.modal.share.allowed-views": "Allowed Views (Max 500)",
    "title.modal.share.copy-url": "OR copy the following URL",
    "title.modal.share.days-active": "Days Active (Max 14)",
    "title.modal.share.do-not-require-login": "Do not require login",
    "title.modal.share.error": "There was an error sharing title, please try again",
    "title.modal.share.generate-link": "Generate Link",
    "title.modal.share.include-api-url": "Include Title API URL",
    "title.modal.share.pre-authenticated-link": "Pre-Authenticated Link",
    "title.modal.share.send-email": "Send to email address",
    "title.modal.share.share": "Share",
    "title.modal.share.success": "Email sent.",
    "title.modal.share.title": "Share",
    "title.modal.share.validations.days-active": "Days Active",
    "title.modal.share.validations.email": "Send to email address",
    "title.modal.share.validations.maximum-views": "Allowed Views",
    "title.modal.subscribe-client.all-video": "All Videos",
    "title.modal.subscribe-client.documents": "Documents",
    "title.modal.subscribe-client.documents.placeholder": "Select documents to subscribe to",
    "title.modal.subscribe-client.episodic-promos": "Episodic Promos",
    "title.modal.subscribe-client.full-episodes": "Full Episodes",
    "title.modal.subscribe-client.images": "Images",
    "title.modal.subscribe-client.metadata": "Metadata Deliveries",
    "title.modal.subscribe-client.no-user.error": "Could not find an user with that email",
    "title.modal.subscribe-client.subtitle": "Send client daily emails when the following become available for this title:",
    "title.modal.subscribe-client.success": "Client Subscribed Successfully.",
    "title.modal.subscribe-client.title": "Subscribe Client",
    "title.modal.subscribe-client.to-subscribe": "Client to subscribe",
    "title.modal.subscribe-client.to-subscribe.label": "Client(s) to subscribe:",
    "title.modal.subscribe-client.to-subscribe.placeholder": "Enter Email Address",
    "title.modal.subscribe-client.validation.email": "This should be a valid email",
    "title.modal.subscribe-client.vam-delivery": "VAMs",
    "title.modal.subscribe.all-video": "All video",
    "title.modal.subscribe.error": "There was an error, please try again",
    "title.modal.subscribe.error.duplicated.other": "The user is already subscribed to this title.",
    "title.modal.subscribe.error.duplicated.own": "You are already subscribed to this title.",
    "title.modal.subscribe.full-episodes": "Full Episodes",
    "title.modal.subscribe.images": "Images",
    "title.modal.subscribe.my-account": "Go to \"My Account\" to edit your delivery frequency preferences »",
    "title.modal.subscribe.subtitle": "Receive daily emails when the following become available for this title:",
    "title.modal.subscribe.success": "Subscription Preferences Updated.",
    "title.modal.subscribe.title": "Notify",
    "title.modal.synopses.subtitle": "Additional Synopses Available",
    "title.modal.synopses.text": "These options are available for you depending on your needs.",
    "title.modal.synopses.title": "Alternate Synopses",
    "title.modal.synopses.log60": "60",
    "title.modal.synopses.log180": "180",
    "title.modal.synopses.short": "Short Synopsis",
    "title.modal.work-order.create": "Create A New Package",
    "title.modal.work-order.or": "OR",
    "title.modal.work-order.subtitle": "Add To Existing Package:",
    "title.modal.work-order.suggest-line.one": "You have added:",
    "title.modal.work-order.suggest-line.two": "Would you like to also add title metadata for:",
    "title.modal.work-order.title": "Add To Package",
    "title.modal.work-order.to-add.placeholder": "Select Package",
    "title.nav.sections.audio.title": "AUDIO",
    "title.nav.sections.documents.title": "DOCUMENTS",
    "title.nav.sections.episode.next": "Next",
    "title.nav.sections.episode.prev": "Prev",
    "title.nav.sections.episode.title": "EPISODE",
    "title.nav.sections.episodes.title": "EPISODES",
    "title.nav.sections.images.title": "IMAGES",
    "title.nav.sections.info.title": "INFO",
    "title.nav.sections.merchandise.title": "MERCHANDISE",
    "title.nav.sections.moments.title": "MOMENTS",
    "title.nav.sections.scripts.title": "SCRIPTS",
    "title.nav.sections.season.title": "SEASON",
    "title.nav.sections.series.title": "SERIES",
    "title.nav.sections.stations.title": "STATION DOCS",
    "title.nav.sections.title": "SECTIONS",
    "title.nav.sections.videos.title": "VIDEOS",
    "title.player.add-new-moment": " Add Moment To Cart",
    "title.player.update-moment": " Update Moment",
    "title.player.watch-moment": "Watch moment",
    "title.related-titles.title": "Related Titles",
    "title.release-dates.caption": "Release Dates",
    "title.release-dates.company": "Company / Network",
    "title.release-dates.content-type": "Content Type",
    "title.release-dates.date": "Release Date",
    "title.release-dates.export-multiple-releases": "Export Release Dates",
    "title.release-dates.note": "The following dates reflect when and where this title has been released.",
    "title.release-dates.territory": "Territory",
    "title.release-dates.view.less": "View Less",
    "title.release-dates.view.more": "View More",
    "title.seasons.panel.title": "Season %%1 Episodes",
    "title.synopsis.airline-availability-date": "Airline Date",
    "title.synopsis.delivery": "Delivery",
    "title.synopsis.delivery.subtitle": "Deliver Aspera packages of the following when they become available for this title:",
    "title.synopsis.download.fact-sheet": "Download Fact Sheet",
    "title.synopsis.download.fact-sheet-all": "Download All Fact Sheets",
    "title.synopsis.metadata": "Metadata",
    "title.synopsis.metadata.subtitle": "Create metadata packages whenever a new episode is available",
    "title.synopsis.non-theatrical-rights": "Non-Theatrical Rights",
    "title.synopsis.not-available": "Synopsis not yet available.",
    "title.synopsis.notifications": "Notifications",
    "title.synopsis.play-button.watch-episode": "Watch Episode",
    "title.synopsis.play-button.watch-epk": "Watch EPK",
    "title.synopsis.play-button.watch-feature": "Watch Feature",
    "title.synopsis.play-button.watch-featurette": "Watch Featurette",
    "title.synopsis.play-button.watch-presentation": "Watch Presentation",
    "title.synopsis.play-button.watch-program": "Watch Program",
    "title.synopsis.play-button.watch-promo": "Watch Promo",
    "title.synopsis.play-button.watch-sizzle": "Watch Sizzle",
    "title.synopsis.play-button.watch-teaser": "Watch Teaser",
    "title.synopsis.play-button.watch-trailer": "Watch Trailer",
    "title.synopsis.play-button.watch-video": "Watch Video",
    "title.synopsis.share": "Share",
    "title.synopsis.subscribe-client": "Subscribe Client",
    "title.synopsis.view-additional-synopses": "View Additional Synopses",
    "title.synopsis.work-order.add": "Add To Package",
    "title.video-list.air-date": "Air Date",
    "title.video-list.associated-videos": "Associated Videos",
    "title.video-list.filter.air-date-asc": "Air Date Ascending",
    "title.video-list.filter.air-date-desc": "Air Date Descending",
    "title.video-list.ip-address-restricted": "IP Address Restricted",
    "title.video-list.length": "Length",
    "title.video-list.play-limit-exceeded": "Play Limit Exceeded",
    "title.video-list.plays-remaining": "Plays Remaining",
    "title.video-list.title": "Videos",
    "title.video-list.type": "Type",
    "title.video-list.unavailable-on-this-platform": "Unavailable on this platform",
    "title.video-list.video-name": "Video Name",
    "title.videos.content-types.101": "Full Episode",
    "title.videos.content-types.102": "Full Program",
    "title.videos.content-types.103": "Feature",
    "title.videos.content-types.121": "Series Launch Promo",
    "title.videos.content-types.122": "Season Launch Promo",
    "title.videos.content-types.123": "Mid Season Promo",
    "title.videos.content-types.124": "Episodic Promo",
    "title.videos.content-types.125": "Generic Promo",
    "title.videos.content-types.126": "Teaser",
    "title.videos.content-types.127": "Trailer",
    "title.videos.content-types.128": "Next On",
    "title.videos.content-types.129": "Recap",
    "title.videos.content-types.130": "TV Spot",
    "title.videos.content-types.131": "Sneak Peek",
    "title.videos.content-types.141": "Green Screen",
    "title.videos.content-types.142": "Clip",
    "title.videos.content-types.143": "Tags",
    "title.videos.content-types.144": "B-Roll",
    "title.videos.content-types.145": "Graphic",
    "title.videos.content-types.146": "Element",
    "title.videos.content-types.147": "Source Materials",
    "title.videos.content-types.148": "Interstitial",
    "title.videos.content-types.149": "Clip Reel",
    "title.videos.content-types.150": "VAM",
    "title.videos.content-types.151": "Textless Materials",
    "title.videos.content-types.152": "Music",
    "title.videos.content-types.161": "EPK",
    "title.videos.content-types.162": "Featurette",
    "title.videos.content-types.163": "Interview",
    "title.videos.content-types.164": "Promotional Specials",
    "title.videos.content-types.165": "BTS Finished",
    "title.videos.content-types.167": "Panel",
    "title.videos.content-types.168": "Event footage",
    "title.videos.content-types.169": "Open Ends",
    "title.videos.content-types.170": "Webisode",
    "title.videos.content-types.181": "Sales Presentation",
    "title.videos.content-types.182": "Sizzle Reel",
    "title.videos.content-types.183": "Brand Extension",
    "title.videos.content-types.184": "Integreation Clip",
    "title.videos.content-types.185": "Sales Kit",
    "title.videos.content-types.186": "Marketing Kit",
    "title.videos.content-types.201": "Client Produced Samples",
    "title.videos.content-types.202": "US Network Promos",
    "title.videos.content-types.behindTheScenes": "Behind the Scenes",
    "title.videos.content-types.element": "Element",
    "title.videos.content-types.fullEpisodeOrFeature": "Full Episode/Feature",
    "title.videos.content-types.promo": "Promo",
    "title.videos.content-types.sales": "Sales",
    "title.videos.content-types.sample": "Sample",
    "title.stations.content-types.32": "Promotion",
    "title.stations.content-types.33": "Season Packet",
    "title.stations.content-types.34": "Air Schedule",
    "title.stations.content-types.35": "Contact Sheets",
    "title.stations.content-types.36": "Redelivery",
    "title.stations.content-types.38": "Cash Format",
    "title.stations.content-types.39": "Title List",
    "title.stations.content-types.40": "Show Info",
    "title.stations.content-types.41": "Production Timing Sheet",
    "title.stations.content-types.42": "Formats - Primary Run",
    "title.stations.content-types.43": "Formats - Secondary Run",
    "title.stations.content-types.44": "Formats - Tertiary Run",
    "title.stations.content-types.45": "Formats - Day Delay",
    "title.stations.content-types.46": "Formats - Weekend Primary Run",
    "title.stations.content-types.47": "Formats - Weekend Secondary Run",
    "title.stations.content-types.document-type": "Document Type",
    "title.stations.filter.run-week": "Run Week",
    "title.stations.filter.run-week.placeholder": "Select a week",

    "titles.moments.column.characters": "Characters",
    "titles.moments.column.moment": "Moment",
    "titles.moments.column.dialogue": "Dialogue",
    "titles.moments.column.locations": "Locations",
    "titles.moments.column.name-desc": "Name/Description",
    "titles.moments.column.parent-title": "Parent Title",
    "titles.moments.column.rights": "Rights",
    "titles.moments.column.settings": "Setting",
    "titles.moments.column.tags": "Tags",
    "titles.moments.column.title": "Title",
    "titles.moments.column.trt": "TRT",
    "titles.moments.column.type": "Type",
    "titles.moments.column.reel": "Reel",
    "titles.moments.column.auto": "Auto",
    "titles.moments.column.highlight_reel": "Highlight reel",
    "titles.moments.column.automatically_suggested_moment": "Automatically suggested moment",
    "titles.moments.column.thumbnail": "Thumbnail",
    "titles.moments.empty-query.title": "Specify Search",
    "titles.moments.empty-query-1": "Please specify your search terms to see related moments or select the [",
    "titles.moments.empty-query-2": "] icon when viewing any video to create your own moment.",
    "titles.moments.empty-query.link": "You can find additional moments for this title in the",
    "titles.moments.facets.talent-character.title": "Talent | Character",
    "titles.moments.facets.title": "Filters",
    "titles.moments.facets.type.moment-type": "Moment type",
    "titles.moments.facets.type.all": "All",
    "titles.moments.facets.type.auto-moment": "Auto Moment",
    "titles.moments.facets.type.highlight-reel": "Highlight Reel",
    "titles.moments.no-moments.title": "No Moments Available",
    "titles.moments.no-moments-1": "There are currently no moments available. Please select the [",
    "titles.moments.no-moments-2": "] icon when viewing any video to create your own moment.",
    "titles.moments.no-results-1": "There are no moments in WBTVD matching your search. Please refine your search or select the [",
    "titles.moments.no-results-2": "] icon when playing a video to create new moments in the WBTVD Moment Creator.",
    "titles.moments.no-results.link": "You can still find moments for this title in the",
    "titles.moments.search.placeholder": "Search moments...",
    "titles.moments.type.auto.label": "AUTO",
    "titles.moments.type.auto.title": "Automatically Suggested Moment",
    "titles.moments.title": "Moments",
    "titles.moments.trt.min": "TRT: {minutes} min {seconds} sec",
    "titles.moments.trt.sec": "TRT: {seconds} sec",

    "titles.create.talent.author":"Author",
    "titles.create.talent.choreographer":"Choreographer",
    "titles.create.talent.director": "Director",
    "titles.create.talent.executive-producer":"Executive Producer",
    "titles.create.talent.guest-star":"Guest Star",
    "titles.create.talent.host":"Host",
    "titles.create.talent.list.actions": "Actions",
    "titles.create.talent.list.character-name": "Character Name/Comment",
    "titles.create.talent.list.credited-as": "Credited As",
    "titles.create.talent.list.name": "Name",
    "titles.create.talent.list.thumbnail-tooltip": "Thumbnail",
    "titles.create.talent.musician":"Musician",
    "titles.create.talent.producer":"Producer",
    "titles.create.talent.voice-talent":"Voice Talent",
    "titles.create.talent.writer":"Writer",
    "titles.images.title": "Images",
    "titles.share-modal.error": "There was an error sharing link, please try again",
    "titles.share-modal.expire-link": "Expire Link In",
    "titles.share-modal.maximum-views": "Maximum Views",
    "titles.share-modal.success": "Link shared successfully",
    "titles.videos.display": "Display",
    "titles.videos.error": "There was an error loading the video, please try again",
    "titles.videos.player.watermark": "This copy authorized for",
    "titles.videos.title": "Videos",
    "titles.videos.video-countdown": "Video Continues In: {seconds} Seconds",

    "video.player.about-link": "https://www.wbtvd.com",
    "video.player.about-text": "© Warner Bros. Discovery, Inc.",

    "work-orders.add-titles-by-mpm.title": "Add Titles By MPM Number",
    "work-orders.add-titles.error": "Error adding titles, please try again.",
    "work-orders.artwork": "Artwork Needed?",
    "work-orders.artwork.text": "Order artwork for titles in package",
    "work-orders.batch-mpm.error": "Error Adding MPM Numbers [{}] To Package, please try again.",
    "work-orders.batch-mpm.validate.error": "MPM Numbers [{}] are not valid, please try different values.",
    "work-orders.batch-mpm.success": "MPM Numbers [{}] added successfully.",
    "work-orders.btn.add-to-work-order": "Add To Package",
    "work-orders.btn.download": "Download Package",
    "work-orders.btn.download.no-export-spec": "Export Spec is required for this download option.",
    "work-orders.btn.download-json": "Download as JSON",
    "work-orders.btn.download-sales-deck": "Download Sales Deck",
    "work-orders.btn.save": "Save",
    "work-orders.btn.save-and-download-sales-deck": "Save & Download Sales Deck",
    "work-orders.btn.save-and-submit": "Save & Submit",
    "work-orders.btn.view-digital-sales-deck": "View Digital Sales Deck",
    "work-orders.client": "Client",
    "work-orders.create.error": "Error Creating Package, please try again.",
    "work-orders.create.title": "New Package",
    "work-orders.download.error": "Error Downloading Sales Deck, please try again.",
    "work-orders.due-date": "Due Date",
    "work-orders.edit.title": "Edit Package",
    "work-orders.export-spec": "Export Spec",
    "work-orders.export-spec.error": "Export Spec is required on Submit.",
    "work-orders.btn.download.no-package-name": "Package Name is required.",
    "work-orders.language": "Language",
    "work-orders.language.text": "(If Available)",
    "work-orders.fallback-english": "If Requested Language Unavailable",
    "work-orders.fallback-english.off": "Keep missing fields blank",
    "work-orders.fallback-english.on": "Substitute English in missing fields",
    "work-orders.mpm-numbers.text": "(Enter numbers separated by spaces)",
    "work-orders.notes": "Notes",
    "work-orders.order-name": "Package Name",
    "work-orders.save.error": "Error Saving Package, please try again.",
    "work-orders.save.success": "Package was saved successfully.",
    "work-orders.save_and_download.success": "Package was saved successfully. Sales Deck Download will start shortly.",
    "work-orders.show-archived": "Show Archived Packages",
    "work-orders.status": "Status",
    "work-orders.status.change.modal.confirm.text": "Are you sure you want to update Package status?",
    "work-orders.status.change.modal.description": "Change status to",
    "work-orders.status.change.modal.error": "Status change failed",
    "work-orders.status.change.modal.success": "Package status successfully updated",
    "work-orders.status.change.modal.title": "Change Package Status",
    "work-orders.titles-in-order.title": "Titles in Package",
    "work-orders.titles-table.parent": "Parent",
    "work-orders.titles-table.ro": "RO",
    "work-orders.titles-table.title": "Title",
    "work-orders.validate-batch-mpm.error": "Error Validating MPM Numbers, plase try again."
}
