/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {OrderConstants} from './order-constants';
import {AssetTypeConstants} from '../asset-types/asset-type-constants';
import Validations from '../common/validations/validations';
import {Dispatcher} from '../flux-helpers';
import {SessionConstants} from '../session/session-actions';
import SessionStore from '../session/session-store';
import {FindInConstant} from '../utils/utils';

const OrderValidations = {
    clientUserId: {
        validations: [Validations.required]
    },
    dueDate: {
        validations: [Validations.required]
    },
    orderCommment: {
        validations: [Validations.requiredIf(
            () => SessionStore.canUser(SessionStore.PERMISSIONS.ORDERS.COMMENT_REQUIRED)
        )]
    }
};

class OrderStore extends ReduceStore {

    getVideoDeliveryFormatType(formatId) {
        if (formatId === OrderConstants.VIDEO_FILE_FORMAT_OPTIONS.PRO_RES.idForAPI) {
            formatId = OrderConstants.VIDEO_FILE_FORMAT_OPTIONS.PRO_RES.id;
        }
        return FindInConstant(OrderConstants.VIDEO_FILE_FORMAT_OPTIONS, formatId) || Immutable.fromJS({});
    }

    isReadyToDownload(imageStatus) {
        return (['downloaded', 'readyToDownload'].indexOf(imageStatus) !== -1);
    }

    canDownloadImage(image, imageStatus, orderStatus) {
        const canDownloadApproval = SessionStore.canUser(SessionStore.PERMISSIONS.DOWNLOAD.IMAGES.NEEDS_APPROVAL);
        const canDownloadNonServiceable = SessionStore.canUser(SessionStore.PERMISSIONS.DOWNLOAD.IMAGES.NON_SERVICEABLE);
        const canDownloadSource = SessionStore.canUser(SessionStore.PERMISSIONS.DOWNLOAD.IMAGES.SOURCE);
        const canDownloadFull = SessionStore.canUser(SessionStore.PERMISSIONS.DOWNLOAD.IMAGES.FULL) || canDownloadSource;
        const canDownloadLoRes = SessionStore.canUser(SessionStore.PERMISSIONS.DOWNLOAD.IMAGES.LO_RES) || canDownloadFull;

        const deliveryType = image.get('deliveryType');

        let canDownload = {
            canDownloadFull: false,
            canDownloadLoRes: false,
            canDownloadSource: false
        };

        const {CART_MODE, SAVED, REQUESTED, APPROVED, FULFILLED, FULFILMENT} = OrderConstants.ORDER.STATUS_BY_NAME;

        if (orderStatus !== undefined) {
            switch (deliveryType) {
            case AssetTypeConstants.DELIVERY_TYPES.ON_DEMAND:
                if ([CART_MODE, SAVED, REQUESTED, APPROVED, FULFILLED, FULFILMENT].includes(orderStatus)) {
                    canDownload = {...canDownload, canDownloadFull, canDownloadLoRes, canDownloadSource};
                }
                break;
            case AssetTypeConstants.DELIVERY_TYPES.NEEDS_APPROVAL:
                if (
                    ([CART_MODE, SAVED, REQUESTED].includes(orderStatus) && canDownloadApproval) ||
                    ([APPROVED, FULFILLED, FULFILMENT].includes(orderStatus))
                ) {
                    canDownload = {...canDownload, canDownloadFull, canDownloadLoRes, canDownloadSource};
                }
                break;
            case AssetTypeConstants.DELIVERY_TYPES.NON_SERVICEABLE:
                if (
                    ([CART_MODE, SAVED, REQUESTED].includes(orderStatus) && canDownloadNonServiceable) ||
                    ([APPROVED, FULFILLED, FULFILMENT].includes(orderStatus))
                ) {
                    canDownload = {...canDownload, canDownloadFull, canDownloadLoRes, canDownloadSource};
                }
                break;
            }

        } else {
            switch (deliveryType) {
            case AssetTypeConstants.DELIVERY_TYPES.ON_DEMAND:
                canDownload = {...canDownload, canDownloadFull, canDownloadLoRes, canDownloadSource};
                break;
            case AssetTypeConstants.DELIVERY_TYPES.NEEDS_APPROVAL:
                if (canDownloadApproval) {
                    canDownload = {...canDownload, canDownloadFull, canDownloadLoRes, canDownloadSource};
                }
                break;
            case AssetTypeConstants.DELIVERY_TYPES.NON_SERVICEABLE:
                if (canDownloadNonServiceable) {
                    canDownload = {...canDownload, canDownloadFull, canDownloadLoRes, canDownloadSource};
                }
                break;
            }
        }

        // Do not allow download if
        // 1. user has no specific permissions to download AND
        // 2. image is restricted and imageStatus is undefined (when not coming from cart) or
        // when is not downloaded nor ready to download
        if (!canDownloadApproval) {
            if (
                (deliveryType === AssetTypeConstants.DELIVERY_TYPES.NEEDS_APPROVAL && imageStatus === undefined) ||
                (!this.isReadyToDownload(imageStatus) && imageStatus !== undefined)
            ) {
                canDownload = {canDownloadFull: false, canDownloadLoRes: false, canDownloadSource: false};
            }
        }

        if (!canDownloadNonServiceable) {
            if (
                (deliveryType === AssetTypeConstants.DELIVERY_TYPES.NON_SERVICEABLE && imageStatus === undefined) ||
                (!this.isReadyToDownload(imageStatus) && imageStatus !== undefined)
            ) {
                canDownload = {canDownloadFull: false, canDownloadLoRes: false, canDownloadSource: false};
            }
        }

        return canDownload;
    }

    /**
     * Verifies order status and permissions only
     * TODO missing update in future to have same controls we have on cart/order downloadAll
     */
    canDownloadOrder(order) {
        let selectedDownloadRenditionType;
        if (order.get('downloadRenditionType')) {
            selectedDownloadRenditionType = OrderConstants.DOWNLOAD_RENDITION_TYPE[order.get('downloadRenditionType')];
        } else {
            selectedDownloadRenditionType = OrderConstants.DOWNLOAD_RENDITION_TYPE.HiRes;
        }
        const status = order.get('status');
        const isOrderApproved = status === OrderConstants.ORDER.STATUS_BY_NAME.APPROVED ||
            status === OrderConstants.ORDER.STATUS_BY_NAME.FULFILLED || status === OrderConstants.ORDER.STATUS_BY_NAME.FULFILMENT;
        const hasDownloadFullPermission = SessionStore.canUser(SessionStore.PERMISSIONS.DOWNLOAD.IMAGES.FULL);
        const hasDownloadLoResPermission = SessionStore.canUser(SessionStore.PERMISSIONS.DOWNLOAD.IMAGES.LO_RES);
        const hasDownloadSourcePermission = SessionStore.canUser(SessionStore.PERMISSIONS.DOWNLOAD.IMAGES.SOURCE);
        const canDownloadSource = hasDownloadSourcePermission || (isOrderApproved && selectedDownloadRenditionType.id === 'Source');
        const canDownloadHiRes = hasDownloadFullPermission || canDownloadSource || (isOrderApproved && selectedDownloadRenditionType.id === 'HiRes');
        const canDownloadLoRes = hasDownloadLoResPermission || canDownloadHiRes || isOrderApproved;
        return (
            order.get('status') === OrderConstants.ORDER.STATUS_BY_NAME.APPROVED ||
            order.get('status') === OrderConstants.ORDER.STATUS_BY_NAME.SAVED
        ) && (
            canDownloadSource || canDownloadHiRes || canDownloadLoRes
        );
    }

    canDownloadVideo(video) {
        return SessionStore.canUser(SessionStore.PERMISSIONS.DOWNLOAD.VIDEOS) &&
            video.get('deliveryType') === AssetTypeConstants.DELIVERY_TYPES.ON_DEMAND;
    }

    getInitialState() {
        return Immutable.Map({
            deliveryFormat: Immutable.Map(),
            downloaded: Immutable.List(),
            downloadedRenditionType: Immutable.fromJS(OrderConstants.DOWNLOAD_RENDITION_TYPE.LoRes),
            //from cart store
            downloadExecution: Immutable.Map(),
            downloadRenditionTypes: Immutable.fromJS(OrderConstants.toArray('DOWNLOAD_RENDITION_TYPE')),
            filters: Immutable.Map({
                client: Immutable.List(),
                dateAdded: Immutable.Map(),
                dueDate: Immutable.Map(),
                requester: Immutable.List()
            }),
            history: Immutable.List(),
            isWorkOrderLoading: false,
            itemsInCart: undefined,
            loading: true,
            loadingItems: false,
            myMetadata: Immutable.List(),
            myMetadataTitle: Immutable.List(),
            order: Immutable.Map({
                downloadRenditionType: OrderConstants.DOWNLOAD_RENDITION_TYPE.HiRes
            }),
            orders: Immutable.List(),
            orderTitles: Immutable.Map({
                image: Immutable.List(),
                video: Immutable.List(),
                'video-user-clip': Immutable.List()
            }),
            requester: Immutable.Map(),
            selectAll: false,
            selectedClient: Immutable.Map({
                notificationList: Immutable.List()
            }),
            selectedClients: Immutable.List(),
            statusChange: Immutable.Map({
                orderId: undefined,
                show: false
            }),
            totalCount: 1,
            unsubscribeClients: Immutable.Map({
                show: false
            }),
            //Map for browse page
            users: Immutable.Map(),
            //List for clients in sent-to-me page
            usersList: Immutable.List(),
            videoFileFormatOptions: Immutable.fromJS(OrderConstants.toArray('VIDEO_FILE_FORMAT_OPTIONS'))
        });
    }

    getValidations() {
        return Validations.validate(this.getState().get('order'), OrderValidations);
    }

    isInstantOrderVideo(video, canInstantOrderVideos) {
        return (
            video.get('deliveryType') !== AssetTypeConstants.DELIVERY_TYPES.NON_SERVICEABLE &&
            canInstantOrderVideos
        );
    }

    reduce(state, action) {
        switch (action.actionType) {

        case OrderConstants.CANCEL_REMOVE:
            state = state.delete('toRemove');
            break;

        case OrderConstants.CART.ADD_OK:
            let itemsAdded = action.items.map ( item => {
                return item.toJS();
            });
            let cartAssetType = AssetTypeConstants.ASSET_TYPE_NAMES[itemsAdded[0].assetType];
            state = state.updateIn(['orderTitles', cartAssetType], titles => {
                let updated = [];
                let found = false;
                titles.toJS().forEach(title => {
                    if (title.id === itemsAdded[0].titleId) {
                        found = true;
                        title.items = title.items.concat(itemsAdded);
                    }
                    updated.push(title);
                });
                if (!found) {
                    let title = {id: itemsAdded[0].titleId, name: itemsAdded[0].displayTitleName};
                    title.items=itemsAdded;
                    updated.push(title);
                }
                return Immutable.fromJS(updated);
            });
            state = state.set('itemsInCart', state.get('itemsInCart') + action.items.length);
            break;
        case OrderConstants.CART.SAVE_OK:
            state = state.merge({
                itemsInCart: 0,
                order: action.cart,
            });
            break;

        case OrderConstants.CLEAR_DOWNLOADS:
            state = state.set('downloaded', Immutable.List());
            break;

        case OrderConstants.CLIENT.CLEAR:
            state = state.set('selectedClient', Immutable.Map({
                notificationList: Immutable.List()
            }));
            break;

        case OrderConstants.CLIENT.SELECT:
            let user = action.user.set('notificationList', Immutable.List());
            state = state.set('selectedClient', user);
            state = state.setIn(['order', 'clientUserId'], user.get('id'));
            break;

        case OrderConstants.DELIVERY_INFO.GET.SUCCESS:
            state = state.setIn(['selectedClient', 'deliveryFormat'], action.deliveryFormat);
            state = state.setIn(['selectedClient', 'notificationList'], action.notificationList);
            break;

        case OrderConstants.DOWNLOAD_COMPLETE:
            state = state.set('downloaded', Immutable.fromJS([{itemId : action.itemId, titleIndex: action.titleIndex}]));
            break;

        case OrderConstants.DOWNLOAD_COMPLETE_ALL:
            let images = [];
            state.getIn(['orderTitles', 'image']).forEach((title, k) => {
                title.get('items').forEach( item => {
                    if (item.getIn(['asset', 'deliveryType']) === AssetTypeConstants.DELIVERY_TYPES.ON_DEMAND ||
                        item.get('status') === 'readyToDownload' || item.get('status') === 'downloaded') {
                        images.push({itemId: item.get('id'), titleIndex: k});
                    }
                });
            });
            state = state.set('downloaded', Immutable.fromJS(images));
            state = state.set('downloadedRenditionType', Immutable.fromJS(OrderConstants.DOWNLOAD_RENDITION_TYPE[action.renditionType]));
            break;

        case OrderConstants.ORDER.CLEAR:
            const downloadExecution = state.get('downloadExecution');
            const itemsInCart = state.get('itemsInCart');
            state = this.getInitialState();
            state = state.set('downloadExecution', downloadExecution);
            state = state.set('itemsInCart', itemsInCart);
            break;

        case OrderConstants.ORDER.FILTERS.CLIENT.APPEND:
            state = state.updateIn(['filters', 'client'], client => client.push(Immutable.Map({
                clientId: action.user.get('id'),
                email: action.user.get('email'),
                fullName: `${action.user.get('name')} ${action.user.get('lastName')}`
            })));
            break;

        case OrderConstants.ORDER.FILTERS.CLIENT.SUCCESS:
            let clients = action.users.map( u => {
                return Immutable.Map({
                    clientId: u.get('id'),
                    email: u.get('email'),
                    fullName: `${u.get('name')} ${u.get('lastName')}`
                });
            });
            state = state.setIn(['filters', 'client'], clients);
            break;

        case OrderConstants.ORDER.FILTERS.REQUESTER.APPEND:
            state = state.updateIn(['filters', 'requester'], requester => requester.push(Immutable.Map({
                requesterId: action.user.get('id'),
                email: action.user.get('email'),
                fullName: action.user.get('fullName'),
            })));
            break;

        case OrderConstants.ORDER.FILTERS.REQUESTER.SUCCESS:
            let requesters = action.users.map( u => {
                return Immutable.Map({
                    email: u.get('email'),
                    fullName: u.get('fullName'),
                    requesterId: u.get('id')
                });
            });
            state = state.setIn(['filters', 'requester'], requesters);
            break;

        case OrderConstants.ORDERS.GET.SUCCESS:
            state = state.merge({
                loading: false,
                orders: action.orders,
                totalCount: action.totalCount
            });
            break;

        case OrderConstants.ORDERS.GET.START:
            state = state.set('loading', true);
            break;

        case OrderConstants.ORDERS.GET.ERROR:
            state = state.set('loading', false);
            break;

        case OrderConstants.ORDER.GET.START:
            state = state.set('loading', true);
            break;

        case OrderConstants.ORDER.GET.SUCCESS:
            state = state.merge({
                loading: false
            });
            /* istanbul ignore else */
            if (action.order) {
                state = state.set('order', action.order);
            }
            if (action.itemsInCart >= 0) {
                state = state.set('itemsInCart', action.itemsInCart);
            }
            break;

        case OrderConstants.CART.GET_ITEMS_AMOUNT:
            state = state.set('itemsInCart', action.itemsInCart);
            break;

        case OrderConstants.ORDER.ITEM.MODIFY:
            const assetId = action.asset.get('id');
            const assetTypeName = AssetTypeConstants.ASSET_TYPE_NAMES[action.assetType];
            const titleIndex = state.getIn(['orderTitles', assetTypeName]).findIndex((t) => t.get('id') === action.titleId);
            const assetIndex = state.getIn(['orderTitles', assetTypeName, titleIndex, 'items']).findIndex(a => a.get('assetId') === assetId);
            state = state.mergeIn(['orderTitles', assetTypeName, titleIndex, 'items', assetIndex, 'asset'], action.asset);
            break;

        case OrderConstants.ORDER.ITEMS.GET.ERROR:
            state = state.set('loadingItems', false);
            break;

        case OrderConstants.ORDER.ITEMS.GET.START:
            state = state.set('loadingItems', true);
            break;

        case OrderConstants.DELIVERY_SPEED_TYPE.GET.SUCCESS:
            state = state.updateIn(['orderTitles', 'video'], titles => {
                let newTitle = [];
                titles.toJS().forEach(title => {
                    const newArray = title.items.map(item => {
                        const matchingDelivery = action.itemsSpeedRes.toJS().find(delivery => delivery.orderItemId === item.id);
                        if (matchingDelivery) {
                            return Immutable.fromJS({...item, deliverySpeedType: matchingDelivery.deliverySpeedType});
                        }
                        return Immutable.fromJS(item);
                    });
                    newTitle.push(Immutable.fromJS({items: newArray, id: title.id, name: title.name}));
                });
                return Immutable.fromJS(newTitle);
            });

            state = state.updateIn(['orderTitles', 'video-user-clip'], titles => {
                let newTitle = [];
                titles.toJS().forEach(title => {
                    const newArray = title.items.map(item => {
                        const matchingDelivery = action.itemsSpeedRes.toJS().find(delivery => delivery.orderItemId === item.id);
                        if (matchingDelivery) {
                            return Immutable.fromJS({...item, deliverySpeedType: matchingDelivery.deliverySpeedType});
                        }
                        return Immutable.fromJS(item);
                    });
                    newTitle.push(Immutable.fromJS({items: newArray, id: title.id, name: title.name}));
                });
                return Immutable.fromJS(newTitle);
            });
            break;

        case OrderConstants.ORDER.ITEMS.GET.SUCCESS:
            state = state.merge({
                loadingItems: false,
                orderTitles: action.titles
            });
            break;

        case OrderConstants.ORDER.METADATA.GET.SUCCESS:
            state = state.set('myOrderMetadata', action.myOrderMetadata);
            break;

        case OrderConstants.ORDER.METADATA_TITLE.GET.START:
            state = state.set('isWorkOrderLoading', true);
            break;

        case OrderConstants.ORDER.METADATA_TITLE.GET.ERROR:
            state = state.set('isWorkOrderLoading', false);
            break;

        case OrderConstants.ORDER.RELOAD_TO_CART.CLEAR:
            state = state.delete('toReloadToCart');
            break;

        case OrderConstants.ORDER.RELOAD_TO_CART.SET:
            state = state.set('toReloadToCart', action.orderId);
            break;

        case OrderConstants.ORDER.STATUS_CHANGE_HIDE:
            state = state.set('statusChange', Immutable.fromJS({
                orderId:undefined,
                show:false
            }));
            break;

        case OrderConstants.ORDER.STATUS_CHANGE_SHOW:
            state = state.set('statusChange', Immutable.fromJS({
                orderId: action.orderId,
                show:true
            }));
            break;

        case OrderConstants.ORDER.TOGGLE_SELECT:
            // Toggle the item value.
            state = state.setIn(['orders', action.index, '__selected'], action.value);
            // Update the selected array.
            state = state.set('selectedClients', state.get('orders').filter(o => !!o.get('__selected')));
            state = state.set('selectAll', state.get('orders').size === state.get('selectedClients').size);
            break;

        case OrderConstants.ORDER.TOGGLE_SELECT_ALL:
            // Toggle all items.
            state = state.merge({
                selectAll: action.value,
                orders: state.get('orders').map(o => o.set('__selected', action.value))
            });

            // Update the selectedClients list according to the current value,
            // no need to filter the array.
            if (action.value) {
                state = state.set('selectedClients', state.get('orders'));
            } else {
                state = state.set('selectedClients', Immutable.List());
            }
            break;

        case OrderConstants.ORDER.UNSUBSCRIBE_CLIENT_HIDE:
            state = state.set('unsubscribeClients', Immutable.fromJS({
                show:false
            }));
            break;

        case OrderConstants.ORDER.UNSUBSCRIBE_CLIENT_SHOW:
            state = state.set('unsubscribeClients', Immutable.fromJS({
                show:true
            }));
            break;

        case OrderConstants.ORDER.UPDATE:
            state = state.setIn(['order', action.attr], action.value);
            break;

        case OrderConstants.REQUESTER.GET.SUCCESS:
            state = state.set('requester', action.user);
            break;

        case OrderConstants.REMOVE:
            state = state.set('toRemove', action.item);
            break;

        case OrderConstants.REMOVE_OK:
            let assetType = AssetTypeConstants.ASSET_TYPE_NAMES[action.assetType];
            let actionItems = action.items;
            if (action.items.get('itemId')) {
                actionItems = [action.items];
            }
            actionItems.forEach(i => {
                state = state.updateIn(['orderTitles', assetType, i.get('titleIndex'), 'items'], items => {
                    return items.filter( item => {
                        return item.get('id') !== i.get('itemId');
                    });
                });
            });
            if (action.isCart) {
                state = state.set('itemsInCart', state.get('itemsInCart') - actionItems.size);
            }
            state = state.delete('toRemove');

            break;

        case OrderConstants.TITLE.GET.SUCCESS:
            state = state.setIn(['orderTitles', action.title.get('id')], action.title);
            break;

        case OrderConstants.TITLE.UNSUBSCRIBE_CLIENT.CLEAR:
            state = state.delete('toUnsubscribeClient');
            break;

        case OrderConstants.TITLE.UNSUBSCRIBE_CLIENT.SET:
            state = state.set('toUnsubscribeClient', {
                subscriptionContentType: action.subscriptionContentType,
                titleId: action.titleId,
                userId: action.userId
            });
            break;

        case OrderConstants.USER.GET.SUCCESS:
            state = state.setIn(['users', action.user.get('id')], action.user);
            break;

        case OrderConstants.USERS_LIST.GET.SUCCESS:
            state = state.set('usersList', action.usersList);
            break;

        case SessionConstants.LOGOUT:
            state = this.getInitialState();
            break;

        case OrderConstants.ORDER.HISTORY.SUCCESS:
            state = state.merge({
                history: action.history
            });
            break;
        }

        return state;
    }

}

export default new OrderStore(Dispatcher);
export {
    OrderValidations
};
