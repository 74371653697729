/* istanbul ignore file */
/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {WBTVDAnalytics, WBTVDAnalyticsConstants} from './wbtvd-analytics';

class Analytics {
    init() {
        this.isInitialized = true;
    }

    assetShareEvent(assetId, assetType, titleId, shareId, allowedViews, daysActive, email) {
        WBTVDAnalytics.event('analytics/asset/share', {
            allowedViews,
            assetId,
            assetType: WBTVDAnalyticsConstants.ASSET.TYPES[assetType],
            daysActive,
            email,
            titleId,
            shareId,
        });
    }

    audioDownloadEvent(audioId, titleId) {
        WBTVDAnalytics.event('analytics/audio/download', {
            audioId,
            titleId
        });
    }

    documentDownloadEvent(documentId, titleId) {
        WBTVDAnalytics.event('analytics/document/download', {
            documentId,
            titleId
        });
    }

    imageDownloadEvent(imageId, rendition, titleId, orderId, orderItemId) {
        WBTVDAnalytics.event('analytics/image/download', {
            imageId,
            orderId,
            orderItemId,
            renditionType: WBTVDAnalyticsConstants.IMAGE.RENDITION_TYPE[rendition],
            titleId,
        });
    }

    imageViewEvent(imageId, titleId) {
        WBTVDAnalytics.event('analytics/image/view', {
            imageId,
            titleId,
        });
    }

    orderAssetAddEvent(orderId, assetId, assetType) {
        WBTVDAnalytics.event('analytics/order/asset/add', {
            assetId,
            assetType,
            orderId,
        });
    }

    orderStatusChangeEvent(orderId, newStatusId, clientUserId, requesterUserId, manualChange) {
        WBTVDAnalytics.event('analytics/order/status', {
            clientUserId,
            manualChange,
            newStatus: WBTVDAnalyticsConstants.ORDER.STATUS_TYPE[newStatusId],
            orderId,
            requesterUserId,
        });
    }

    pageViewEvent() {
        WBTVDAnalytics.event('analytics/page/view', {
            resolution: window.screen.width + 'x' + window.screen.height,
            title: document.title,
            url: window.location.href,
            viewportSize: window.innerWidth + 'x' + window.innerHeight,
        });
    }

    scriptDownloadEvent(scriptId, titleId) {
        WBTVDAnalytics.event('analytics/script/download', {
            scriptId,
            titleId
        });
    }

    userLoginEvent() {
        WBTVDAnalytics.event('analytics/user/login');
    }

    userTitleSubscribeEvent(titleId, subscriptionContentType, suggestedByUserId) {
        WBTVDAnalytics.event('analytics/user/title/subscribe', {
            titleId,
            subscriptionContentType: WBTVDAnalyticsConstants.TITLE.SUBSCRIPTION_CONTENT_TYPE_BY_ID[subscriptionContentType],
            suggestedByUserId,
        });
    }

    userTitleUnsubscribeEvent(titleId, subscriptionContentType) {
        WBTVDAnalytics.event('analytics/user/title/unsubscribe', {
            titleId,
            subscriptionContentType: WBTVDAnalyticsConstants.TITLE.SUBSCRIPTION_CONTENT_TYPE_BY_ID[subscriptionContentType],
        });
    }

    videoPlaybackEvent(videoId, titleId, type, watchtime, url, sourceType, fullscreen, errorCode, errorMessage) {
        WBTVDAnalytics.event('analytics/video/playback', {
            errorCode,
            errorMessage,
            fullscreen,
            playerName: 'AMP',
            sourceType,
            titleId,
            type,
            url,
            videoId,
            watchtime,
        });
    }
}

// Create our singleton.
export default new Analytics();
