/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import {timecodeFactory} from '../common/timecode/time-code';
import AssetTitleStore from '../titles/asset-title/asset-title-store';
import {ClipActions} from '../titles/clip/clip-actions';
import ClipStore from '../titles/clip/clip-store';

export const KEY_CODES = {
    ARROW_DOWN: 40,
    ARROW_LEFT: 37,
    ARROW_RIGHT: 39,
    ARROW_UP: 38,
    CHAR_I: 73,
    CHAR_J: 74,
    CHAR_K: 75,
    CHAR_L: 76,
    CHAR_O: 79,
    END: 35,
    HOME: 36,
    SPACE: 32,
};

const KEY_HANDLES = {
    [KEY_CODES.ARROW_DOWN]: 'jumpToNextMarker',
    [KEY_CODES.ARROW_LEFT]: 'moveOneSecondBack',
    [KEY_CODES.ARROW_RIGHT]: 'moveOneSecondForward',
    [KEY_CODES.ARROW_UP]: 'jumpToPreviousMarker',
    [KEY_CODES.CHAR_I]: 'changeIn',
    [KEY_CODES.CHAR_J]: 'handleBackward',
    [KEY_CODES.CHAR_K]: 'pause',
    [KEY_CODES.CHAR_L]: 'handleForward',
    [KEY_CODES.CHAR_O]: 'changeOut',
    [KEY_CODES.END]: 'jumpToEndVideo',
    [KEY_CODES.HOME]: 'jumpToBeginningVideo',
    [KEY_CODES.SPACE]: 'playOrPause',
};

export default class HotkeysListener extends React.PureComponent {
    static get propTypes() {
        return {
            children: PropTypes.node.isRequired,
            clip: PropTypes.instanceOf(Immutable.Map),
            frameRate: PropTypes.number,
            isClippingModeOn: PropTypes.bool,
            onlyViewingClip: PropTypes.bool,
            playerController: PropTypes.object,
        };
    }

    static get defaultProps() {
        return {
            clip: null,
            frameRate: null,
            isClippingModeOn: false,
            onlyViewingClip: false,
            playerController: null,
        };
    }

    constructor(props) {
        super(props);
        this.handleKeydownEvent = this.handleKeydownEvent.bind(this);
        this.handleKeyupEvent = this.handleKeyupEvent.bind(this);
        this.holding = {};
        this.timecode = timecodeFactory({frameRate: props.frameRate});
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeydownEvent, true);
        document.addEventListener('keyup', this.handleKeyupEvent, true);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeydownEvent, true);
        document.removeEventListener('keyup', this.handleKeyupEvent, true);

        this.props.playerController.dispose();
    }

    hasActiveModal() {
        const showShareModal = AssetTitleStore.getState().get('showShare');
        const showCreateClipModal = ClipStore.getState().get('isModalVisible');
        return showShareModal || showCreateClipModal;
    }

    /**
     * Handle keydown event
     * @param e {KeyboardEvent}
     */
    handleKeydownEvent(e) {
        const code = e.which;
        const {playerController} = this.props;
        if (!playerController || !isSupportedKeyCode(code) || this.hasActiveModal() || playerController.isHandledKeyboardEvent(e)) {
            return;
        }
        this.holding[code] = true;
        if (code === KEY_CODES.CHAR_K) {
            playerController.pause();
        }
        e.stopPropagation();
        e.preventDefault();
    }

    /**
     * Handle keyup event
     * @param e {KeyboardEvent}
     */
    handleKeyupEvent(e) {
        const code = e.which;
        const {playerController} = this.props;
        if (!playerController || !isSupportedKeyCode(code) || this.hasActiveModal() || playerController.isHandledKeyboardEvent(e)) {
            return;
        }
        this[KEY_HANDLES[code]](playerController);
        this.holding[code] = false;
        e.stopPropagation();
        e.preventDefault();
    }

    /**
     * handle forward event
     * @param controller {PlayerController}
     */
    handleForward(controller) {
        if (this.holding[KEY_CODES.CHAR_K]) {
            this.moveOneSecondForward(controller);
        } else if (controller.isPlaying()) {
            const currentPlaybackRate = controller.playbackRate();
            const multiplier = currentPlaybackRate === 1 ? 2 : 4;
            const playbackRate = Math.min(currentPlaybackRate * multiplier, 16);
            controller.playbackRate(playbackRate);
        } else {
            this.play(controller);
        }
    }

    /**
     * handle backward event
     * @param controller {PlayerController}
     */
    handleBackward(controller) {
        if (this.holding[KEY_CODES.CHAR_K]) {
            this.moveOneSecondBack(controller);
        } else {
            const currentTime = controller.currentTime();
            controller.currentTime(Math.max(0, currentTime - 5));
        }
    }

    /**
     * play or pause the video
     * @param controller {PlayerController}
     */
    playOrPause(controller) {
        if (controller.isPlaying()) {
            this.pause(controller);
        } else {
            this.play(controller);
        }
    }

    /**
     * play the video
     * @param controller {PlayerController}
     */
    play(controller) {
        controller.playbackRate(1);
        controller.play();
    }

    /**
     * pause the video
     * @param controller {PlayerController}
     */
    pause(controller) {
        controller.playbackRate(1);
        controller.pause();
    }

    /**
     * play head jumps to previous marker
     * @param controller {PlayerController}
     */
    jumpToPreviousMarker(controller) {
        const currentTime = ClipStore.normalizeTime(controller.currentTime(), controller.timeAccuracyCoefficient);
        const time = this.markers(controller).filter(t => t < currentTime).pop();
        if (time) {
            controller.currentTime(time);
        } else {
            this.jumpToBeginningVideo(controller);
        }
    }

    /**
     * play head jumps to next marker
     * @param controller {PlayerController}
     */
    jumpToNextMarker(controller) {
        const currentTime = ClipStore.normalizeTime(controller.currentTime(), controller.timeAccuracyCoefficient);
        const time = this.markers(controller).filter(t => t > currentTime).shift();
        if (time) {
            controller.currentTime(time);
        } else {
            this.jumpToEndVideo(controller);
        }
    }

    /**
     * get list of markers
     * @param controller {PlayerController}
     * @returns {number[]} list of markers
     */
    markers(controller) {
        const {clip, isClippingModeOn} = this.props;
        const duration = ClipStore.normalizeTime(controller.duration(), controller.timeAccuracyCoefficient);
        if (isClippingModeOn && clip) {
            const clipIn = ClipStore.normalizeTime(clip.get('tcIn'), controller.timeAccuracyCoefficient);
            const clipOut = ClipStore.normalizeTime(clip.get('tcOut'), controller.timeAccuracyCoefficient);
            return [0, clipIn, clipOut, duration];
        }
        return [0, duration];
    }

    /**
     * increment by 1 second
     * @param controller {PlayerController}
     */
    moveOneSecondForward(controller) {
        controller.currentTime(controller.currentTime() + 1);
    }

    /**
     * decrement by 1 seconds
     * @param controller {PlayerController}
     */
    moveOneSecondBack(controller) {
        controller.currentTime(controller.currentTime() - 1);
    }

    /**
     * jumps to beginning of video
     * @param controller {PlayerController}
     */
    jumpToBeginningVideo(controller) {
        controller.pause();
        controller.currentTime(0);
    }

    /**
     * jumps to end of video
     * @param controller {PlayerController}
     */
    jumpToEndVideo(controller) {
        const duration = controller.duration();
        controller.currentTime(duration);
    }

    /**
     * set current time as clip in
     * @param controller {PlayerController}
     */
    changeIn(controller) {
        const {clip, onlyViewingClip} = this.props;
        if (!clip || onlyViewingClip) {
            return;
        }
        const currentTime = controller.currentTime();
        const duration = controller.duration();
        const oldTcOut = clip.get('tcOut');
        if (oldTcOut > currentTime) {
            ClipActions.updateClip(Immutable.fromJS({tcIn: currentTime}));
        } else {
            const tcOut = ClipStore.normalizeTime(Math.min(duration, currentTime + 10), controller.timeAccuracyCoefficient);
            ClipActions.updateClip(Immutable.fromJS({tcIn: currentTime, tcOut}));
        }
    }

    /**
     * set current time as clip out
     * @param controller {PlayerController}
     */
    changeOut(controller) {
        const {clip, onlyViewingClip} = this.props;
        if (!clip || onlyViewingClip) {
            return;
        }
        const currentTime = controller.currentTime();
        const oldTcIn = clip.get('tcIn');
        if (oldTcIn < currentTime) {
            ClipActions.updateClip(Immutable.fromJS({tcOut: currentTime}));
        } else {
            const tcIn = ClipStore.normalizeTime(Math.max(0, currentTime - 10), controller.timeAccuracyCoefficient);
            ClipActions.updateClip(Immutable.fromJS({tcOut: currentTime, tcIn}));
        }
    }

    render() {
        return this.props.children;
    }
}

/**
 * @param code {number}
 * @return {boolean}
 */
function isSupportedKeyCode(code) {
    return !!KEY_HANDLES[code];
}
