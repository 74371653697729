/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {ClipConstants} from './clip-actions';
import {timecodeFactory} from '../../common/timecode/time-code';
import Validations from '../../common/validations/validations';
import {Dispatcher} from '../../flux-helpers';
import {PlayerConstants} from '../../player/player-actions';
import {SessionConstants} from '../../session/session-actions';

const SHOWCODE_LENGTH = 4;
const DEFAULT_CLIP_DURATION = 10;
const ACCURACY_RATE = 6;
const ACCURACY_KOEFF = Math.pow(10, ACCURACY_RATE);

const EMPTY_CLIP = {
    frameRate: null,
    id: 0,
    isTouched: false,
    name: '',
    tcIn: 0,
    tcOut: 0,
    titleId: null,
    videoId: null,
};

const CreateClipValidations = {
    name: {
        label: 'title.modal.moment.validations.name',
        validations: [
            Validations.max(100),
            Validations.regex(/.*[^ ].*$/),
            Validations.required
        ]
    }
};

class ClipStore extends ReduceStore {
    /**
     * Generate default name of clip
     * @param showName {string}
     * @param season {number}
     * @param episode {number}
     * @param description {string}
     * @returns {string}
     */
    generateClipName(showName, season, episode, description) {
        let clipName = description;
        if (showName) {
            clipName = `${this.generateShowCode(showName)}${pad(season || '')}${pad(episode || '')}-${description}`;
        }
        return clipName;
    }

    /**
     * Generate SHOWCODE
     * @param showName {string}
     * @returns {string} SHOWCODE
     */
    generateShowCode(showName) {
        const words = showName.split(' ').filter(w => !!w);
        const lastIndex = words.length - 1;
        const charsFromWord = Math.max(1, Math.floor(SHOWCODE_LENGTH / words.length));
        return words.reduce((showcode, word, idx) => {
            let charsCount = charsFromWord;
            if (lastIndex === idx) {
                charsCount = Math.max(1, SHOWCODE_LENGTH - showcode.length);
            }
            return showcode + word.substr(0, charsCount);
        }, '').toUpperCase().substr(0, SHOWCODE_LENGTH);
    }

    getInitialState() {
        return Immutable.fromJS({
            currentClip: {...EMPTY_CLIP},
            isClippingModeOn: false,
            isModalVisible: false,
            showName: '',
            video: null,
        });
    }

    getValidations() {
        return Validations.validate(this.getState().get('currentClip'), CreateClipValidations);
    }

    /**
     * find out default tcIn/tcOut
     * @param {number} currentTime current player time in sec
     * @param {number} duration current player time in sec
     * @returns {[number, number]} default tcIn/tcOut for clip
     */
    makeDefaultClip(currentTime, duration) {
        const tcIn = currentTime;
        let tcOut = this.normalizeTime(Math.min(currentTime + DEFAULT_CLIP_DURATION, duration));
        return [tcIn, tcOut];
    }

    normalizeTime(time, coefficient = ACCURACY_KOEFF) {
        return Math.ceil(time * coefficient) / coefficient;
    }

    reduce(state, action) {
        switch (action.actionType) {

        case ClipConstants.CREATE_CLIP_USE_MOMENT:
            state = state.merge(Immutable.fromJS({
                currentClip: {
                    ...action.data.get('currentClip').toJS(),
                    id: 0,
                    isTouched: false,
                    name: '',
                },
                isModalVisible: true,
                showName: action.data.get('showName'),
                video: action.data.get('video'),
            }));
            break;

        case ClipConstants.CREATE_NEW_CLIP:
            const [tcIn, tcOut] = this.makeDefaultClip(action.data.get('currentTime'), action.data.get('duration'));
            state = state.merge(Immutable.fromJS({
                currentClip: {
                    frameRate: action.data.get('frameRate'),
                    id: 0,
                    isTouched: false,
                    name: '',
                    tcIn,
                    tcOut,
                    titleId: action.data.get('titleId'),
                    videoId: action.data.get('videoId'),
                },
                isClippingModeOn: !state.get('isClippingModeOn'),
                showName: action.data.get('showName'),
            }));
            break;

        case SessionConstants.LOGOUT:
        case PlayerConstants.HIDE_PLAYER:
            state = state.merge({
                currentClip: Immutable.fromJS(EMPTY_CLIP),
                isClippingModeOn: false,
                video: null,
            });
            break;

        case PlayerConstants.SET_SELECTED:
            state = state.merge({
                currentClip: Immutable.fromJS({...EMPTY_CLIP, frameRate: action.video.get('frameRate')}),
                isClippingModeOn: false,
                video: null,
            });
            break;

        case PlayerConstants.SHOW_PLAYER:
            state = state.setIn(['currentClip', 'frameRate'], action.video.get('frameRate'));
            break;

        case ClipConstants.TOGGLE_CLIPPING:
            const isClippingModeOn = !state.get('isClippingModeOn');
            state = state.merge({
                currentClip: state.get('currentClip').merge(action.delta),
                isClippingModeOn,
            });
            break;

        case ClipConstants.TOGGLE_MODAL_VISIBILITY:
            const isModalVisible = !state.get('isModalVisible');
            const override = Immutable.fromJS({isTouched: false});
            state = state.merge({
                currentClip: state.get('currentClip').merge(override),
                isModalVisible,
            });
            break;

        case ClipConstants.VIEW_CLIP:
            const frameRate = action.frameRate;
            const timecode = timecodeFactory({frameRate});
            state = state
                .set('currentClip', Immutable.fromJS({
                    id: action.clip.get('id'),
                    isTouched: false,
                    name: action.clip.get('assetName'),
                    tcIn: this.normalizeTime(timecode.framesToSeconds(action.clip.get('tcInFrameRounded'))),
                    tcOut: this.normalizeTime(timecode.framesToSeconds(action.clip.get('tcOutFrameRounded'))),
                    titleId: action.titleId,
                    videoId: action.clip.get('videoId'),
                }))
                .set('showName', action.showName)
                .set('isClippingModeOn', true);

            break;

        case ClipConstants.SET_SHOW_NAME:
            state = state.set('showName', action.showName);
            break;

        case ClipConstants.UPDATE_CLIP:
            state = state.mergeIn(['currentClip'], Immutable.fromJS({isTouched: true}).merge(action.delta));
            break;
        }

        return state;
    }
}

export default new ClipStore(Dispatcher);
export {
    CreateClipValidations
};

function pad(num) {
    if (!num) {
        return '';
    }
    let res = `${num}`;
    if (num <= 9) {
        res = `0${num}`;
    }
    return res;
}
