/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import BitmovinPlayer from './BitmovinPlayer';
import Disclaimer from './disclaimer';
import {PlayerActions} from './player-actions';
import Poster from './poster';
import Config from '../config/config';
import {Debounce} from '../utils/utils';

// This class is a wrapper around any player we would like to use or test.
export default class Player extends React.Component {
    static get propTypes() {
        return {
            areHotkeysEnabled: PropTypes.bool,
            canPreviewVideoPlayer: PropTypes.bool,
            clip: PropTypes.instanceOf(Immutable.Map),
            isClippingModeOn: PropTypes.bool,
            isCreateClipModalVisible: PropTypes.bool,
            onDurationChange: PropTypes.func,
            onTimeUpdate: PropTypes.func,
            onlyViewingClip: PropTypes.bool,
            preauthenticated: PropTypes.bool,
            preauthenticatedUser: PropTypes.string,
            searchTerm: PropTypes.string,
            showPoster: PropTypes.bool,
            titleId: PropTypes.number.isRequired,
            toggleCreateModal: PropTypes.func,
            video: PropTypes.instanceOf(Immutable.Map),
            watchlist: PropTypes.instanceOf(Immutable.Map).isRequired,
        };
    }

    static get defaultProps() {
        return {
            areHotkeysEnabled: true,
            canPreviewVideoPlayer: false,
            clip: null,
            isClippingModeOn: false,
            isCreateClipModalVisible: false,
            onDurationChange: () => void 0,
            onTimeUpdate: () => void 0,
            onlyViewingClip: false,
            preauthenticated: false,
            preauthenticatedUser: '',
            searchTerm: '',
            showPoster: false,
            toggleCreateModal: () => void 0,
            video: undefined,
        };
    }

    constructor(props) {
        super(props);
        let disclaimerSeconds = 0;

        if (this.shouldRenderDisclaimer()) {
            disclaimerSeconds = 10;
        }

        this.state = {
            disclaimerSeconds: disclaimerSeconds
        };

        this.disclaimerCountdown = this.disclaimerCountdown.bind(this);
        this.handleTimeUpdateEvent = this.handleTimeUpdateEvent.bind(this);
        this.updateWatchlist = Debounce(this.updateWatchlist.bind(this), 1000);

        this.timerId = null;
    }

    componentDidMount() {
        this.countdownTick();
    }

    componentWillUnmount() {
        clearTimeout(this.timerId);
        this.props.onDurationChange(0);
    }

    countdownTick() {
        if (this.state.disclaimerSeconds <= 0) {
            return;
        }

        this.timerId = setTimeout(() => {
            this.setState(this.disclaimerCountdown);
        }, 1000);
    }

    updateWatchlist(currentTime) {
        const {titleId, video} = this.props;
        const videoId = getVideoId(video);

        PlayerActions.updateWatchlist(videoId, currentTime, titleId);
    }

    // FIXME: find a beeter way to check if the watchlist should be updated.
    // Keep in mind updateWatchlist is a debounced function.
    handleTimeUpdateEvent(time, forceUpdate) {
        if (
            !this.props.video.get('clipId') &&
            (Math.round(time) % Config.PlayerReportPlaybackTime === 0 || forceUpdate)
        ) {
            this.updateWatchlist(time);
        }

        this.props.onTimeUpdate(time);
    }

    detectStartPlayerPosition() {
        const {clip, isClippingModeOn, video, watchlist} = this.props;
        if (isClippingModeOn && clip) {
            return clip.get('tcIn');
        }
        const videoId = getVideoId(video);

        if (!watchlist) {
            return 0;
        }
        return parseFloat(watchlist.getIn([videoId.toString(), 'lastPosition'], '0')) || 0;
    }

    disclaimerCountdown(state) {
        const disclaimerSeconds = state.disclaimerSeconds - 1;
        if (disclaimerSeconds > 0) {
            this.countdownTick();
        }
        return {disclaimerSeconds};
    }

    shouldRenderDisclaimer() {
        const startPlayerPosition = this.detectStartPlayerPosition();
        const disclaimerText = this.props.video.get('disclaimerText');
        return startPlayerPosition === 0 && !!disclaimerText;
    }

    render() {
        if (this.props.showPoster) {
            return <Poster video={this.props.video} />;
        }

        const {disclaimerSeconds} = this.state;
        if (disclaimerSeconds) {
            const disclaimerText = this.props.video.get('disclaimerText');
            return <Disclaimer seconds={disclaimerSeconds} text={disclaimerText} />;
        }

        const startPlayerPosition = this.detectStartPlayerPosition();
        const onTimeUpdate = this.handleTimeUpdateEvent;
        const props = {...this.props, startPlayerPosition, onTimeUpdate};

        return <BitmovinPlayer {...props} />;
    }
}

function getVideoId(video) {
    let id = video.get('id');
    if (id !== undefined) {
        return id;
    }

    return video.get('assetId');
}

