/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */
import React from 'react';

// import language objects
import enUS from './en-US.json';
import deLocale from './locales/de.json';
import esLocale from './locales/es.json';
import es419Locale from './locales/es_419.json';
import frLocale from './locales/fr.json';
import itLocale from './locales/it.json';
import jaLocale from './locales/ja.json';
import ptLocale from './locales/pt.json';
import Config from '../config/config.js';

const MessagesContext = React.createContext();

// creates lowercase English
const enLower = Object.assign({},
    ...Object.keys(enUS).map(
        (id) => ({
            [id]: enUS[id].toLowerCase()
        })
    )
);

/* Note: the locale must match the LanguageCodeType enum code value in the backend. This enables automatic
    language selection via Accept-Language request header to work! As of 2023-05-09 the enum values are:

    AR("Arabic", "Arabic", "ar", "ar", "ar-BH"),
    BG("Bulgarian", "Bulgaria", "bg", "bg", "bg-BG"),
    CMN("Mandarin", "Mandarin", "cmn", "cmn", "zh-CN"),
    CS("Czech", "Czech", "cs", "cs", "cs-CZ"),
    DA("Danish", "Danish", "da", "da", "da-DK"),
    EL("Greek", "Greece", "el", "el", "el-GR"),
    EN("English", "USA", "en", "en", "en-US"),
    ENG("English", "USA", "eng", "en", "en-US"),
    ET("Estonian", "Northern Europe", "et", "et", "et-EE"),
    FA("Persian", "Persian","fa", "fa", "fa-IR"),
    FI("Finnish", "Finland", "fi", "fi", "fi-FI"),
    FR_CA("French (Canadian)", "Canada", "fr-ca", "fr_CA", "fr-CA"),
    FR("French", "France", "fr", "fr", "fr-FR"),
    GER("German", "Germany", "de", "de", "de-DE"),
    HE("Hebrew", "Hebrew", "he", "he", "he-IL"),
    HIF("Hindi", "Hindi", "hif", "hi", "hi-IN"), // transifex's code variant is just 'hi'
    HR("Croatian", "Croatian", "hr", "hr", "hr-HR"),
    HU("Hungarian", "Hungarian", "hu", "hu", "hu-UH"),
    ID("Bahasa Indonesia", "Bahasa Indonesia", "id", "id", "id-ID"),
    IS("Icelandic", "Iceland", "is", "is", "is-IS"),
    ITA("Italian", "Italy", "it", "it", "it-IT"),
    JPN("Japanese", "Japan", "ja", "ja", "ja-JP"),
    KO("Korean", "Korean", "ko", "ko", "ko-KR"),
    LT("Lithuanian", "Lithuania", "lt", "lt", "lt-LT"),
    LV("Latvian", "Latvia", "lv", "lv", "lv-LV"),
    MK("Macedonian", "Macedonia", "mk", "mk", "mk-MK"),
    MS("Bahasa Malay", "Bahasa Malaysia", "ms", "ms", "ms-MY");
    NL("Dutch", "Netherlands", "nl", "nl", "nl-NL"),
    NO("Norwegian", "Norwegian", "no", "no", "nb-NO"),
    PA("Panjabi", "Panjabi", "pa", "pa", "pa"),
    PL("Polish", "Polish", "pl", "pl", "pl-PL"),
    POR_BR("Portuguese (Brazil)", "Brazil", "pt-br", "pt_BR", "pt-BR"),
    POR("Portuguese (Euro)", "Portugal", "pt", "pt", "pt-PT"),
    PRS("Dari", "Dari", "prs", "prs", "prs"), // Dari is not officially available in Transifex (05/26/2021)
    RO("Romanian", "Romanian", "ro", "ro", "ro-RO"),
    RU("Russian", "Russian", "ru", "ru", "ru-RU"),
    SK("Slovak", "Slovakia", "sk", "sk", "sk_SK"),
    SPA_LAT("Spanish Latin America", "Spain", "es-419", "es_419", "es-LA"),
    SPA("Spanish (Castilian)", "Spain", "es", "es", "es-ES"),
    SR("Serbian", "Serbia", "sr", "sr", "sr-SP"),
    SV("Swedish", "Swedish", "sv", "sv", "sv-SE"),
    TA("Tamil", "Tamil", "ta", "ta", "ta-IN"),
    TH("Thai", "Thai", "th", "th", "th-TH"),
    TL("Tagalog", "Tagalog", "tl", "tl", "tl"),
    TR("Turkish", "Turkey", "tr", "tr", "tr-TR"),
    UR("Urdu", "Urdu", "ur", "ur", "ur-PK"),
    VI("Vietnamese", "Vietnamese", "vi", "vi", "vi-VN"),
    ZH_HANS("Chinese (Simplified)", "Chinese", "zh-Hans", "zh-Hans", "zh-Hans"),
    ZH_HANT("Chinese (Traditional)", "Chinese", "zh-Hant", "zh-Hant", "zh-Hant"),
    ZH_HK("Chinese (Hong Kong)", "Hong Kong", "zh_HK", "zh_HK", "zh_HK"),
    ZH_TW("Chinese (Taiwan)", "Taiwan", "zh_Tw", "zh_Tw", "zh_Tw"),
    ZLM("Malay", "Malay", "zlm", "ms", "ms-MY"), // transifex's code variant is completely different: 'ms'
    */
const Languages = {
    // german
    de: {
        displayOrder: 0,
        locale: 'de',
        label: 'Deutsch',
        messages: deLocale
    },
    // english
    en: {
        displayOrder: 1,
        locale: 'en',
        label: 'English',
        messages: enUS
    },
    // spanish
    es: {
        displayOrder: 2,
        locale: 'es',
        label: 'Español',
        messages: esLocale
    },
    // latin american spanish
    'es-419': {
        displayOrder: 3,
        locale: 'es-419',
        label: 'Español (Latinoamérica)',
        messages: es419Locale
    },
    // french
    fr: {
        displayOrder: 4,
        locale: 'fr',
        label: 'Français',
        messages: frLocale
    },
    // italian
    it: {
        displayOrder: 5,
        locale: 'it',
        label: 'Italiano',
        messages: itLocale
    },
    // japanese
    ja: {
        displayOrder: 6,
        locale: 'ja',
        label: '日本語',
        messages: jaLocale
    },
    // Portuguese
    'pt': {
        displayOrder: 7,
        locale: 'pt',
        label: 'Português',
        messages: ptLocale
    }
};
/* istanbul ignore next */
if (Config.Environment !== Config.Environments.PRODUCTION) {
    // DEBUG: uppercase english
    Languages['en-Shaw'] = {
        displayOrder: 8,
        locale: 'en-Shaw',
        label: 'lowercase english',
        messages: enLower // enUPPER
    };
}

export {Languages, MessagesContext};
