/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';
import QueryString from 'query-string';
import url from 'url';

import mfa from './mfa';
import SessionStore from './session-store';
import Analytics from '../analytics';
import {BaftaActions} from '../bafta-video-player/bafta-actions';
import Config from '../config/config';
import {Dispatcher} from '../flux-helpers';
import {HomeActions} from '../home/home-actions';
import {NotificationActions} from '../notification/notification-actions';
import Request from '../request';
import {RouterActions} from '../router/router-actions';
import {Debounce} from '../utils/utils';


const WBTVD = {
    PERMISSION_TYPE: 5,
    SITE_TYPE: 6
};

const CONSTANTS = {
    DOMAIN_ZERO_WITHOUT_ACCOUNT: {
        GET: {
            SUCCESS: 'session_actions.domain_zero_without_accounts.get.success'
        }
    },
    JWT: {
        GET: {
            ERROR: 'session_actions.jwt.get.error',
            START: 'session_actions.jwt.get.start',
            SUCCESS: 'session_actions.jwt.get.success'
        }
    },
    KEEP_ALIVE: 'session.keep.alive',
    EXTERNAL_URLS: {
        HELP: 'https://support.wbtvd.com/home/',
        PRIVACY_POLICY: 'https://policies.warnerbros.com/privacy/b2b/en/html/privacy_en_1.0.0.html',
        TERMS_OF_USE: 'https://policies.warnerbros.com/terms/en-us/',
    },
    LOGIN: {
        ERROR: 'session_constants.login.error',
        START: 'session_constants.login.start',
        SUCCESS: 'session_constants.login.success'
    },
    LOGOUT: 'session_constants.logout',
    TIMEOUT: {
        CLEAR: 'session_constants.timeout.clear',
    },
    TIMEOUT_WARNING: 'session.timeout.warning',
    AUTH_USER: {
        EVENTS: {
            GET: {
                SUCCESS: 'session_constants.auth_user.events.get.success',
            }
        },
        HOMEPAGE: {
            GET: {
                SUCCESS: 'session_constants.auth_user.homepage.get.success'
            }
        },
        GET: {
            SUCCESS: 'session_constants.auth_user.get.success'
        },
        IS_ACS: {
            GET: {
                SUCCESS: 'session_actions.auth_user.is_acs.get.success'
            }
        },
        IS_BRANDS: {
            GET: {
                SUCCESS: 'session_actions.auth_user.is_brands.get.success'
            }
        },
        LAST_LOGIN_TIMESTAMP: {
            GET: {
                SUCCESS: 'session_actions.auth_user.last_login_timestamp.get.success'
            }
        },
        ROLES: {
            GET: {
                SUCCESS: 'session_actions.auth_user.roles.get.success'
            }
        },
        PARENT_USER: {
            GET: {
                SUCCESS: 'session_actions.auth_user.parent_user.get.success'
            }
        },
        PERMISSIONS: {
            GET: {
                SUCCESS: 'session_actions.auth_user.permissions.get.success'
            }
        },
        USER_BRANDS: {
            GET: {
                SUCCESS: 'session_actions.auth_user.user_brands.get.success'
            },
        },
        USER_PARTNERS: {
            GET: {
                SUCCESS: 'session_actions.auth_user.user_partners.get.success'
            }
        },
        UPDATE_SESSION_BRAND: 'session_actions.auth_user.update_session_brand',
        SET_IS_PARTNER_AUTH_LOGIN: 'session_actions.auth_user.set_is_partner_auth_login'
    },
    PASSWORD_RESET: {
        SET_NEW: {
            CLEAR: 'session_actions.password_reset.set_new.clear',
            ERROR: 'session_actions.password_reset.set_new.error',
            START: 'session_actions.password_reset.set_new.start',
            SUCCESS: 'session_actions.password_reset.set_new.success'
        },
        EMAIL_SENT: 'PASSWORD_RESET_SENT'
    },
    RESEND_LOGIN_CODE: {
        CLEAR: 'session_actions.resend_login_code.clear',
        START: 'session_actions.resend_login_code.start',
        SUCCESS: 'session_actions.resend_login_code.success',
    },
    SEND_ONE_TIME_LINK: {
        ERROR: 'session_actions.send_one_time_link.error',
        START: 'session_actions.send_one_time_link.start',
        SUCCESS: 'session_actions.send_one_time_link.success'
    },
    ATTR: {
        UPDATE: 'session_actions.attr.update'
    },
    GET: {
        MFA_DATA: {
            SUCCESS: 'session_actions.get.mfa_data.success'
        },
        MFA_VERIFY_CODE: {
            ERROR: 'session_actions.get.mfa.verify_code.error',
            START: 'session_actions.get.mfa.verify_code.start',
            SUCCESS: 'session_actions.get.mfa.verify_code.success'
        }
    },
    USER_TYPES: {
        ANONYMOUS: {id: 'ANONYMOUS', name: 'ANONYMOUS'},
        PRIMARY: {id: 'PRIMARY', name: 'PRIMARY'},
        SECONDARY: {id: 'SECONDARY', name: 'SECONDARY'}
    },
    WELCOME_TOAST_SHOWN: 'session.welcome.toast.shown'
};

class SessionActions {

    constructor() {
        // We don't want to execute this function all the time.
        this.renewJWT = Debounce(this.renewJWT.bind(this), 1000);

        return;
    }

    checkForward(auth, forward) {
        let forwardPromise = Promise.resolve();
        /* istanbul ignore next */
        if (forward) {
            // true to parse the query string object too.
            let forwardUrlObject = url.parse(forward, true);
            forwardPromise = Request.get('system/valid-forward-domain').query({
                domain: `${forwardUrlObject.protocol}//${forwardUrlObject.host}`
            }).set('Authorization', auth).then(() => {
                // As per WPB-5389, just paste the auth token to the end of the
                // given URL.
                window.location.href = forwardUrlObject.href + auth;
                // return true to skip redirect.
                return true;
            }).catch(err => {
                // This means the server doesn't recognize the forward URL as
                // a valid one. Continue with the standard login.
                console.error(err);
                return;
            });
        }

        return forwardPromise;
    }

    clearNewPasswordFields() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.PASSWORD_RESET.SET_NEW.CLEAR
        });
    }

    clearTimeout() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TIMEOUT.CLEAR
        });
        return;
    }

    keepAlive() {
        Request.post('heartbeat').then(res => res).catch((err) => {
            // Received an error, need to end session
            Dispatcher.dispatch({
                actionType: CONSTANTS.LOGOUT
            });
            // Also clear homepage when logout
            HomeActions.clearHomepage();
            // Session has been re-initialzied, redirect to login
            RouterActions.redirect('/login');
            setTimeout(() => NotificationActions.showAlertDanger('login.logout.invalid', true));
            throw err;
        });
    }

    // next is used for internal redirects.
    // forward is used for external redirects.
    // eslint-disable-next-line default-param-last
    login(email, password, next = '/', forward, partner, clearSessionToken, partnerAuthToken) {
        let auth;
        Dispatcher.dispatch({
            actionType: CONSTANTS.LOGIN.START
        });

        let reqBody = {email, password};
        if (partnerAuthToken) {
            reqBody = {loginToken: partnerAuthToken};
        }

        if (clearSessionToken !== undefined) {
            reqBody.clearSessionToken = clearSessionToken;
        }

        // Authenticate the user.
        Request.post('login').send(
            reqBody
        ).set(
            'WBTV-Partner-Id', partner.id
        ).set(
            'WBTV-Partner-Key', partner.key
        ).then(
            res => res
        ).catch(/* istanbul ignore next */err => {
            // This catch is only for MFA Required error.
            /* istanbul ignore next */
            if (err.response.body.error === 'MFARequired' || err.response.body.error === 'MFATokenRequired') {
                let authorization = err.response.header.authorization;
                let userId = err.response.body.UserId;
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.MFA_DATA.SUCCESS,
                    userId,
                    uuid: authorization
                });
                // Do MFA Stuff.
                return mfa.RequestMFA(userId, true, authorization).catch(mfaErr => {
                    switch (true) {
                    case mfaErr instanceof mfa.MFARejectedError:
                        NotificationActions.showAlertDanger('login.error.mfa.rejected');
                        break;
                    default:
                        NotificationActions.showAlertDanger('login.error.mfa.400');
                        break;
                    }

                    throw mfaErr;
                }).then(() => {
                    // Mock the valid login server response.
                    return {
                        header: {
                            authorization
                        }
                    };
                });
            }

            throw err;
        }).then(res => {
            auth = res.header.authorization;
            return this.getNewSession(auth, !!partnerAuthToken);
        }).spread((permissionsRes, userRes, jwtRes, lastLoginRes, partnerRes, brandRes) => {
            if (jwtRes.body.token) {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.JWT.GET.SUCCESS,
                    jwt: jwtRes.body.token
                });
            }
            Dispatcher.dispatch({
                actionType: CONSTANTS.AUTH_USER.LAST_LOGIN_TIMESTAMP.GET.SUCCESS,
                lastLoginTimestamp: lastLoginRes.body.lastLogin
            });

            Dispatcher.dispatch({
                actionType: CONSTANTS.AUTH_USER.USER_PARTNERS.GET.SUCCESS,
                userPartners: Immutable.fromJS(partnerRes.body)
            });

            Dispatcher.dispatch({
                actionType: CONSTANTS.AUTH_USER.USER_BRANDS.GET.SUCCESS,
                userBrands: Immutable.fromJS(brandRes.body)
            });

            return this.getPermissions(permissionsRes, userRes, email, auth);
        }).then(permissionsRes => {
            this.isACSUser(permissionsRes.groups, partner);
            this.isBrandsUser(permissionsRes.groups);
            return this.getRoles(permissionsRes.roles, auth, forward, partner);
        }).then(skipRedirect => {
            this.setHomepageData();
            return this.skipRedirect(skipRedirect, next);
        }).catch(/* istanbul ignore next */err => {
            this.getErrorLogin(err, email);
        });

        return;
    }

    loginWithOneTimeLink(secureLoginToken, partner) {
        let auth;
        Dispatcher.dispatch({
            actionType: CONSTANTS.LOGIN.START
        });

        // Authenticate the user via anonymousId (always clear old session)
        Request.post('user/login-link/validate').send({
            secureLoginToken: secureLoginToken
        }).set(
            'WBTV-Partner-Id', partner.id
        ).set(
            'WBTV-Partner-Key', partner.key
        ).then(
            res => res
        ).catch(/* istanbul ignore next */err => {
            // This catch is only for MFA Required error.
            /* istanbul ignore next */
            if (err.response.body.error === 'MFARequired' || err.response.body.error === 'MFATokenRequired') {
                let authorization = err.response.header.authorization;
                let userId = err.response.body.UserId;
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.MFA_DATA.SUCCESS,
                    userId,
                    uuid: authorization
                });
                // Do MFA Stuff.
                return mfa.RequestMFA(userId, true, authorization).catch(mfaErr => {
                    switch (true) {
                    case mfaErr instanceof mfa.MFARejectedError:
                        NotificationActions.showAlertDanger('login.error.mfa.rejected');
                        break;
                    default:
                        NotificationActions.showAlertDanger('login.error.mfa.400');
                        break;
                    }

                    throw mfaErr;
                }).then(() => {
                    // Mock the valid login server response.
                    return {
                        header: {
                            authorization
                        }
                    };
                });
            }

            throw err;
        }).then(res => {
            auth = res.header.authorization;
            return this.getNewSession(auth);
        }).spread((permissionsRes, userRes, jwtRes, lastLoginRes, partnerRes, brandRes) => {
            if (jwtRes.body.token) {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.JWT.GET.SUCCESS,
                    jwt: jwtRes.body.token
                });
            }

            Dispatcher.dispatch({
                actionType: CONSTANTS.AUTH_USER.LAST_LOGIN_TIMESTAMP.GET.SUCCESS,
                lastLoginTimestamp: lastLoginRes.body.lastLogin
            });

            Dispatcher.dispatch({
                actionType: CONSTANTS.AUTH_USER.USER_PARTNERS.GET.SUCCESS,
                userPartners: Immutable.fromJS(partnerRes.body)
            });

            Dispatcher.dispatch({
                actionType: CONSTANTS.AUTH_USER.USER_BRANDS.GET.SUCCESS,
                userBrands: Immutable.fromJS(brandRes.body)
            });

            Dispatcher.dispatch({
                actionType: CONSTANTS.AUTH_USER.GET.SUCCESS,
                user: Immutable.fromJS(userRes.body)
            });

            Analytics.userLoginEvent();
            const permissions = Immutable.fromJS(permissionsRes.body.results.filter(/* istanbul ignore next */r => r.url !== null));

            Dispatcher.dispatch({
                actionType: CONSTANTS.AUTH_USER.PERMISSIONS.GET.SUCCESS,
                permissions: permissions
            });

            Dispatcher.dispatch({
                actionType: CONSTANTS.LOGIN.SUCCESS,
                partner: Immutable.fromJS(partner),
                uuid: auth,
            });
            /* istanbul ignore next */
            if (partner.id === Config.Partners.BAFTA_WEB.id) {
                BaftaActions.getUserVideo();
            }

            RouterActions.redirect('/');
        }).catch((err) => {
            console.error(err);
            RouterActions.redirect('/login');
            this.getErrorLogin(err, undefined, true);
        });

        return;
    }

    // LAScreenings/BAFTA/FYC/anonymousId login process
    // eslint-disable-next-line default-param-last
    loginWithCode(anonymousId, partner, next) {
        let auth;
        Dispatcher.dispatch({
            actionType: CONSTANTS.LOGIN.START
        });

        // Authenticate the user via anonymousId (always clear old session)
        Request.post('login?clear-old-session=true').send({
            anonymousId: anonymousId
        }).set(
            'WBTV-Partner-Id', partner.id
        ).set(
            'WBTV-Partner-Key', partner.key
        ).then(
            res => res
        ).catch(/* istanbul ignore next */err => {
            // This catch is only for MFA Required error.
            /* istanbul ignore next */
            if (err.response.body.error === 'MFARequired' || err.response.body.error === 'MFATokenRequired') {
                let authorization = err.response.header.authorization;
                let userId = err.response.body.UserId;
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.MFA_DATA.SUCCESS,
                    userId,
                    uuid: authorization
                });
                // Do MFA Stuff.
                return mfa.RequestMFA(userId, true, authorization).catch(mfaErr => {
                    switch (true) {
                    case mfaErr instanceof mfa.MFARejectedError:
                        NotificationActions.showAlertDanger('login.error.mfa.rejected');
                        break;
                    default:
                        NotificationActions.showAlertDanger('login.error.mfa.400');
                        break;
                    }

                    throw mfaErr;
                }).then(() => {
                    // Mock the valid login server response.
                    return {
                        header: {
                            authorization
                        }
                    };
                });
            }

            throw err;
        }).then(res => {
            auth = res.header.authorization;
            return this.getNewSession(auth);
        }).spread((permissionsRes, userRes, jwtRes, lastLoginRes, partnerRes, brandRes) => {
            if (jwtRes.body.token) {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.JWT.GET.SUCCESS,
                    jwt: jwtRes.body.token
                });
            }

            Dispatcher.dispatch({
                actionType: CONSTANTS.AUTH_USER.LAST_LOGIN_TIMESTAMP.GET.SUCCESS,
                lastLoginTimestamp: lastLoginRes.body.lastLogin
            });

            Dispatcher.dispatch({
                actionType: CONSTANTS.AUTH_USER.USER_PARTNERS.GET.SUCCESS,
                userPartners: Immutable.fromJS(partnerRes.body)
            });

            Dispatcher.dispatch({
                actionType: CONSTANTS.AUTH_USER.USER_BRANDS.GET.SUCCESS,
                userBrands: Immutable.fromJS(brandRes.body)
            });

            Dispatcher.dispatch({
                actionType: CONSTANTS.AUTH_USER.GET.SUCCESS,
                user: Immutable.fromJS(userRes.body)
            });

            Analytics.userLoginEvent();
            const permissions = Immutable.fromJS(permissionsRes.body.results.filter(/* istanbul ignore next */r => r.url !== null));

            Dispatcher.dispatch({
                actionType: CONSTANTS.AUTH_USER.PERMISSIONS.GET.SUCCESS,
                permissions: permissions
            });

            Dispatcher.dispatch({
                actionType: CONSTANTS.LOGIN.SUCCESS,
                partner: Immutable.fromJS(partner),
                uuid: auth,
                anonymousId,
            });

            /* istanbul ignore next */
            if (partner.id === Config.Partners.BAFTA_WEB.id) {
                BaftaActions.getUserVideo();
            }

            if (next) {
                this.skipRedirect(false, next);
            }
        }).catch(/* istanbul ignore next */err => {
            this.getErrorLogin(err);
        });

        return;
    }

    getNewSession(auth, isPartnerAuthLogin) {
        // Get the user profile, permissions and JWT token for services like Analytics.
        return [
            Request.get('user/permission').query({
                'permission-type': WBTVD.PERMISSION_TYPE,
                'site-type': WBTVD.SITE_TYPE
            }).set('Authorization', auth),
            Request.post('system/validate-session').set(
                'Authorization', auth
            ).then(
                validateRes => {
                    if (validateRes.body.eventIds.length > 0) {
                        Dispatcher.dispatch({
                            actionType: CONSTANTS.AUTH_USER.EVENTS.GET.SUCCESS,
                            eventIds: Immutable.fromJS(validateRes.body.eventIds)
                        });
                    }
                    if (validateRes.body.parentUserId) {
                        let parentUser = validateRes.body;
                        parentUser.name = parentUser.firstName;
                        parentUser.email = parentUser.emailAddress;
                        ['firstName', 'emailAddress'].forEach(key => delete parentUser[key]);
                        Dispatcher.dispatch({
                            actionType: CONSTANTS.AUTH_USER.PARENT_USER.GET.SUCCESS,
                            parentUser: Immutable.fromJS(parentUser)
                        });
                    }
                    if (isPartnerAuthLogin) {
                        this.updateAttr('login', 'email', validateRes.body.emailAddress);
                        Dispatcher.dispatch({
                            actionType: CONSTANTS.AUTH_USER.SET_IS_PARTNER_AUTH_LOGIN,
                            isPartnerAuthLogin
                        });
                    }

                    return Request.get(`user/${validateRes.body.userId}`).set('Authorization', auth);
                }
            ),
            Request.post('system/analytics-token').set('Authorization', auth).then(res => res).catch(/* istanbul ignore next */err => {
                /* istanbul ignore next */console.error('Error getting JWT.', err);
                // Don't break the process, just don't do analytics.
                return {body: {token: null}};
            }),
            Request.get('user/last-login').set('Authorization', auth).then(res => res).catch(/* istanbul ignore next */err => {
                /* istanbul ignore next */console.error('Error getting last login timestamp.', err);
                // Don't break the process, just log the error.
                return {body: {lastLogin: '-'}};
            }),
            Request.get('user/session/partner').set('Authorization', auth).then(res => res).catch(/* istanbul ignore next */err => {
                /* istanbul ignore next */console.error('Error getting partners.', err);
                return {body: []};
            }),
            Request.get('user/session/brand').set('Authorization', auth).then(res => res).catch(/* istanbul ignore next */err => {
                /* istanbul ignore next */console.error('Error getting brands.', err);
                return {body: []};
            })
        ];
    }

    getPermissions(permissionsRes, userRes, email, auth) {
        /* istanbul ignore next */
        if (!userRes.body) {
            // No user!
            // Panic!
            throw new Error(`No user for email "${email}" or user has no access to GET /user/{userId} API`);
        }

        const permissions = Immutable.fromJS(permissionsRes.body.results.filter(/* istanbul ignore next */r => r.url !== null));

        Dispatcher.dispatch({
            actionType: CONSTANTS.AUTH_USER.PERMISSIONS.GET.SUCCESS,
            permissions: permissions
        });

        Dispatcher.dispatch({
            actionType: CONSTANTS.AUTH_USER.GET.SUCCESS,
            user: Immutable.fromJS(userRes.body)
        });

        Analytics.userLoginEvent();

        return Promise.props({
            groups: Request.get(`user/${userRes.body.id}/group`).set('Authorization', auth),
            roles: Request.get(`user/${userRes.body.id}/role`).set('Authorization', auth)
        });
    }

    getRoles(rolesRes, auth, forward, partner) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.AUTH_USER.ROLES.GET.SUCCESS,
            roles: Immutable.fromJS(rolesRes.body)
        });

        Dispatcher.dispatch({
            actionType: CONSTANTS.LOGIN.SUCCESS,
            partner: Immutable.fromJS(partner),
            uuid: auth
        });
        return this.checkForward(auth, forward);
    }

    setHomepageContentType(contentType) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.AUTH_USER.HOMEPAGE.GET.SUCCESS,
            contentType,
        });
    }

    isACSUser(groupsRes, partner) {
        const HBO_ACS_GROUP_ID = 31319;
        const WBTVD_ACS_GROUP_ID = 20022;

        // Check if user is Alternative Content Sales for the
        // current partner.
        let isACS = false;
        switch (partner.id) {
        case Config.Partners.HBO.id:
            isACS = groupsRes.body.some(g => HBO_ACS_GROUP_ID === g.id);
            break;
        case Config.Partners.WBTVD.id:
            isACS = groupsRes.body.some(g => WBTVD_ACS_GROUP_ID === g.id);
            break;
        }

        Dispatcher.dispatch({
            actionType: CONSTANTS.AUTH_USER.IS_ACS.GET.SUCCESS,
            isACS
        });

        return;
    }

    isBrandsUser(groupsRes) {
        const userBrands = SessionStore.getUserBrands();

        let isBrands = userBrands.some(brand => groupsRes.body.some(g => brand.id === g.id));

        Dispatcher.dispatch({
            actionType: CONSTANTS.AUTH_USER.IS_BRANDS.GET.SUCCESS,
            isBrandsUser: isBrands
        });

        return;
    }

    navigateToPartner(partner, authorization) {
        Request.post(`user/partner/${partner.id}/login-token`).set('Authorization', authorization)
            .then(res => {
                const auth = res.body.secureLoginToken;
                window.location.assign(`${partner.baseUrl}/login?sso=${auth}&partnerId=${partner.id}&partnerKey=${partner.key}`);
            })
            .catch(err => {
                NotificationActions.showAlertDanger('login.navigate-to-partner.get-token.error', false, partner.hostname);
                throw err;
            });
    }
    /* istanbul ignore next */
    renewJWT(jwt) {
        if (!jwt) {
            console.warn('No JWT token to check.');
            return;
        }

        // Check if token is about to expire and ask for a new one.
        const now = new Date();
        let expireDate;
        try {
            // Get the middle part of the JWT, base64 decode it, parse it, read expiration date
            // and convert it to a date.
            expireDate = new Date(
                JSON.parse(
                    atob(
                        jwt.split('.')[1]
                    )
                ).exp * 1000
            );
        } catch (err) {
            console.error('Error parsing JWT.', err);
        }

        const fiveMins = 5 * 60 * 1000;
        const isAboutToExpire = (expireDate - now) < fiveMins;

        if (!isAboutToExpire) {
            return;
        }

        Dispatcher.dispatch({
            actionType: CONSTANTS.JWT.GET.START
        });

        Request.post('system/analytics-token').then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.JWT.GET.SUCCESS,
                jwt: res.body.token
            });

            return;
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.JWT.GET.ERROR
            });
            throw err;
        });
    }

    skipRedirect(skipRedirect, next) {
        if (skipRedirect) {
            return;
        }

        RouterActions.redirect(decodeURIComponent(next));
        return;
    }

    getErrorLogin(err, email, hideErrorNotification = false) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.LOGIN.ERROR
        });

        if (email && email.match(/@(warnermedia)\.com$/)) {
            NotificationActions.showAlertWarning('login.error.warner-media');
        }

        if (
            // In these cases, the modal will display a notification.
            err instanceof mfa.MFARejectedError ||
            err instanceof mfa.MFARejectedCodeError ||
            // In this case, the login page must not show anything.
            // Usually there's another kind of message to the user.
            err.defaultPrevented
        ) {
            // Don't do anything here.
            throw err;
        }

        if (hideErrorNotification) {
            throw err;
        }

        if (!err.response) {
            NotificationActions.showAlertDanger('login.error');
            throw err;
        }

        switch (err.response.status) {
        case 401:
            if (!err.response.body) {
                NotificationActions.showAlertDanger('login.error.401');
                break;
            }

            switch (err.response.body.error) {
            case 'ACCOUNT_INACTIVE':
                NotificationActions.showAlertDanger('login.error.inactive');
                break;

            case 'CLEAR_SESSION_TOKEN_MISMATCH':
                NotificationActions.showAlertDanger('login.error.clear-session-token-mismatch');
                break;

            case 'EXPIRED_PASSWORD':
                NotificationActions.showAlertDanger('login.error.expired');
                break;

            case 'LOCKED_OUT':
                NotificationActions.showAlertDanger('login.error.locked_out');
                break;

            case 'NO_BRAINIAC_ACCOUNT_FOR_DOMAIN_ZERO':
                NotificationActions.showModal('domainZeroWithoutAccountModal');
                Dispatcher.dispatch({
                    actionType: CONSTANTS.DOMAIN_ZERO_WITHOUT_ACCOUNT.GET.SUCCESS,
                    account: Immutable.fromJS(err.response.body).delete('error')
                });
                break;

            case 'PASSWORD_RESET_REQUIRED':
                RouterActions.redirect(`/first-time?${QueryString.stringify({token: err.response.body.token})}`);
                break;

            case 'FAILED_AUTHENTICATION':
            case 'NO_ACCOUNT':
            default:
                NotificationActions.showAlertDanger('login.error.401');
                break;
            }
            break;

        case 404:
            NotificationActions.showAlertDanger('login.error.401');
            break;

        case 429:
            NotificationActions.showAlertDanger('login.rate-limit-exceed');
            break;

        default:
            NotificationActions.showAlertDanger('login.error');
            break;
        }

        throw err;
    }

    logout(reason, next, loginUrl = '/login') {
        Request.post('logout').then(res => res).catch(err => {
            throw err;
        }).finally(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.LOGOUT
            });

            switch (reason) {
            case 'timeout':
                /* istanbul ignore else */
                if (next) {loginUrl += `?next=${next}`;}
                RouterActions.redirect(loginUrl);
                setTimeout(() => NotificationActions.showAlertSuccess('login.logout.timeout', true));
                break;
            default:
                RouterActions.redirect(loginUrl);
                setTimeout(() => NotificationActions.showAlertSuccess('login.logout.success', true));
                break;
            }

            // Also clear homepage when logout
            HomeActions.clearHomepage();
            return;
        });
        return;
    }

    resendLoginCode(emailAddress) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.RESEND_LOGIN_CODE.START
        });
        Request.post('user/retrieve/token').send({
            emailAddress: emailAddress
        }).then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.RESEND_LOGIN_CODE.SUCCESS
            });
        }).catch(/* istanbul ignore next */err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.RESEND_LOGIN_CODE.SUCCESS
            });
            throw err;
        });
    }

    clearResendingCodeStatus() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.RESEND_LOGIN_CODE.CLEAR
        });
    }

    resetPassword(emailAddress) {
        Request.get(`user/validation/password-reset-allowed/${emailAddress}`).then(() => {
            Request.post('user/request-password-reset').send({
                emailAddress: emailAddress
            }).then(() => {
                RouterActions.redirect('/password-reset-confirm');
            }).catch(err => {
                RouterActions.redirect('/login');
                throw err;
            });
        }).catch((err) => {
            switch (err.status) {
            case 429:
                NotificationActions.showAlertDanger('login.rate-limit-exceed');
                break;
            case 403:
            default:
                RouterActions.redirect('/login');
                NotificationActions.showAlertDanger('forgot-password.error.domain');
                break;
            }
            throw err;
        });
    }

    setNewPassword(resetPasswordToken, newPassword) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.PASSWORD_RESET.SET_NEW.START
        });
        Request.post('user/password-reset')
            .send({
                resetPasswordToken: resetPasswordToken,
                newPassword: newPassword
            })
            .then(() => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.PASSWORD_RESET.SET_NEW.SUCCESS
                });
                RouterActions.redirect('/login');
                NotificationActions.showAlertSuccess('reset-password.set-new.success');
            })
            .catch(err => {
                if (err.response.body && err.response.body.error === CONSTANTS.PASSWORD_RESET.EMAIL_SENT) {
                    NotificationActions.showAlertDanger('reset-password.set-new.expired-link');
                } else {
                    NotificationActions.showAlertDanger('reset-password.set-new.error');
                }
                Dispatcher.dispatch({
                    actionType: CONSTANTS.PASSWORD_RESET.SET_NEW.ERROR
                });
                throw err;
            });
    }

    swapToken(partner, token) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.LOGIN.START
        });

        let auth;

        Request.post(
            'system/exchange-token'
        ).set(
            'Authorization', token
        ).set(
            'WBTV-Partner-Id', partner.id
        ).set(
            'WBTV-Partner-Key', partner.key
        ).then(res => {
            auth = res.header.authorization;
            return this.getNewSession(auth);
        }).spread((permissionsRes, userRes, partnerRes, brandRes) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.AUTH_USER.USER_PARTNERS.GET.SUCCESS,
                userPartners: Immutable.fromJS(partnerRes.body)
            });

            Dispatcher.dispatch({
                actionType: CONSTANTS.AUTH_USER.USER_BRANDS.GET.SUCCESS,
                userBrands: Immutable.fromJS(brandRes.body)
            });
            return this.getPermissions(permissionsRes, userRes, 'unknown user', auth);
        }).then(permissionsRes => {
            this.isACSUser(permissionsRes.groups, partner);
            this.isBrandsUser(permissionsRes.groups);
            return this.getRoles(permissionsRes.roles, auth, false, partner);
        }).then(skipRedirect => {
            this.setHomepageData();
            return this.skipRedirect(skipRedirect, '/');
        }).catch(err => {
            this.getErrorLogin(err);
        });
    }

    timeoutWarning() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TIMEOUT_WARNING
        });
    }

    updateAttr(model, attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ATTR.UPDATE,
            attr,
            model,
            value
        });
        return;
    }

    deleteUserSessionBrand(authorization) {
        Request.del('user/session/brand').set('Authorization', authorization)
            .then(() => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.AUTH_USER.UPDATE_SESSION_BRAND,
                    brand: Immutable.fromJS({})
                });
                RouterActions.redirect('/');
                window.location.reload();
            })
            .catch(err => {
                NotificationActions.showAlertDanger('login.delete-user-session-brand.error');
                throw err;
            });
    }

    sendOneTimeLoginLink(email, partner) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SEND_ONE_TIME_LINK.START
        });
        Request.post('user/login-link').send({
            emailAddress: email
        }).set(
            'WBTV-Partner-Id', partner.id
        ).set(
            'WBTV-Partner-Key', partner.key
        ).then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.SEND_ONE_TIME_LINK.SUCCESS
            });
            NotificationActions.showAlertSuccess('one-time-login.success');
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.SEND_ONE_TIME_LINK.ERROR
            });
            NotificationActions.showAlertDanger('one-time-login.error');
            throw err;
        });
    }

    setHomepageData() {
        return Request.get('web/homepage/current-user').then(homepageRes => {
            let res = homepageRes.body;
            const contentType = res.contentType;
            this.setHomepageContentType(contentType);
        }).catch(/* istanbul ignore next */err => {
            throw err;
        });
    }

    updateUserSessionBrand(brand, authorization) {
        Request.put(`user/session/brand/${brand.id}`).set('Authorization', authorization)
            .then(() => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.AUTH_USER.UPDATE_SESSION_BRAND,
                    brand: Immutable.fromJS(brand)
                });
                RouterActions.redirect('/');
                window.location.reload();
            })
            .catch(err => {
                NotificationActions.showAlertDanger('login.update-user-session-brand.error');
                throw err;
            });
    }

    verifyToken(userId, verificationCode, authorization) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.GET.MFA_VERIFY_CODE.START
        });

        mfa.VerifyToken(userId, verificationCode, authorization).then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.MFA_VERIFY_CODE.SUCCESS
            });
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.MFA_VERIFY_CODE.ERROR
            });
            /* istanbul ignore next */
            if (err instanceof mfa.MFARejectedCodeError) {
                NotificationActions.showAlertDanger('login.error.mfa.code.rejected');
                throw err;
            }

            throw err;
        });
    }

    welcomeToastShown() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.WELCOME_TOAST_SHOWN
        });
    }
}

const actions = new SessionActions();

export {
    actions as SessionActions,
    CONSTANTS as SessionConstants
};
