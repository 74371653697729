/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {AssetTypeConstants} from '../../asset-types/asset-type-constants';

export default class Watermarks {
    constructor() {
        this.add = this.add.bind(this);
        this.hideWatermark = this.hideWatermark.bind(this);
        this.randomizePositions = this.randomizePositions.bind(this);
        this.remove = this.remove.bind(this);
        this.setDisableVisualWatermark = this.setDisableVisualWatermark.bind(this);
        this.setPaused = this.setPaused.bind(this);
        this.setPersistentVisualWatermark = this.setPersistentVisualWatermark.bind(this);
        this.showWatermark = this.showWatermark.bind(this);
        this.update = this.update.bind(this);

        this.counter = 0;
        this.disableVisualWatermark = false;
        this.paused = true;
        this.videoContentType = null;
        this.watermarkToShow = 0;
        this.persistentVisualWatermark = false;
        this.watermarks = [];

        const bitmovinWatermark = document.querySelector('button.bmpui-ui-watermark');
        bitmovinWatermark.style.display = 'none'; // This removes Bitmovin's original watermark

        // If we just use the first parent for some reason the detected height is 0, so we use the parent's parent
        this.container = bitmovinWatermark.parentElement.parentElement;
        this.timer = setInterval(this.update, 1000);
    }

    add(text) {
        let element = document.createElement('div');
        element.style.setProperty('color', '#666666', 'important');
        element.style.setProperty('font-family', 'Helvetica, Arial, sans-serif', 'important');
        element.style.setProperty('font-size', '13px', 'important');
        element.style.setProperty('opacity', '0', 'important');
        element.style.setProperty('pointer-events', 'none', 'important');
        element.style.setProperty('position', 'absolute', 'important');
        element.style.setProperty('transition', 'opacity 0.25s ', 'important');
        element.style.setProperty('-moz-transition', 'opacity 0.25s ', 'important');
        element.style.setProperty('-webkit-transition', 'opacity 0.25s ', 'important');
        element.style.setProperty('white-space', 'nowrap', 'important');
        element.style.setProperty('margin', '0', 'important');
        element.style.setProperty('padding', '0', 'important');
        element.innerHTML = text;

        this.container.appendChild(element);
        this.watermarks.push(element);

        this.randomizePositions();
    }

    fadeInWatermark() {
        this.randomizePositions();
        this.watermarks[this.watermarkToShow].style.setProperty('transition', 'all 3.5s');
        this.watermarks[this.watermarkToShow].style.setProperty('opacity', '1');
    }

    fadeOutWatermark() {
        this.watermarks[this.watermarkToShow].style.setProperty('opacity', '0');
    }

    hideWatermark() {
        this.watermarks.forEach((watermark) => {
            watermark.style.setProperty('opacity', '0');
        });
    }

    randomizePositions() {
        const bounds = this.container.getBoundingClientRect();

        this.watermarks.forEach((watermark) => {
            const watermarkHeight = Math.ceil(watermark.getBoundingClientRect().height) + 10;
            const watermarkWidth = Math.ceil(watermark.getBoundingClientRect().width) + 10;

            const watermarkLeft = Math.abs(Math.floor(Math.random() * (bounds.width - watermarkWidth)));
            const watermarkTop = Math.abs(Math.floor(Math.random() * (bounds.height - watermarkHeight)));

            watermark.style.left = watermarkLeft + 'px';
            watermark.style.top = watermarkTop + 'px';
        });
    }

    remove() {
        this.watermarks.forEach((watermark) => {
            watermark.remove();
        });

        this.watermarks = [];
        clearInterval(this.timer);
    }

    setDisableVisualWatermark(disableVisualWatermark) {
        this.disableVisualWatermark = disableVisualWatermark;
    }

    setPaused(paused) {
        this.paused = paused;
    }

    setPersistentVisualWatermark(persistentVisualWatermark) {
        this.persistentVisualWatermark = persistentVisualWatermark;
    }

    setVideoContentType(videoContentType) {
        this.videoContentType = videoContentType;
    }

    showWatermark() {
        this.randomizePositions();
        this.watermarks.forEach((watermark) => {
            watermark.style.setProperty('opacity', '1');
        });
    }

    update() {
        if (this.paused || this.disableVisualWatermark) {
            return;
        }

        this.counter++;

        /**
         * if this.persistentVisualWatermark === false and video is Episode, Feature o Full Program apply same rules mobile apps works
         * otherwise shows watermarks as before
         */
        if (!this.persistentVisualWatermark &&
            (this.videoContentType === AssetTypeConstants.VIDEO_CONTENT_TYPE[101].description ||
            this.videoContentType === AssetTypeConstants.VIDEO_CONTENT_TYPE[102].description ||
            this.videoContentType === AssetTypeConstants.VIDEO_CONTENT_TYPE[103].description)) {
            /**
             * if persistentVisualWatermark = false and it should show long period as mobile apps does
             * 1.- wait for 20 seconds
             * 2.- fades in for 3.5 seconds
             * 3.- display watermark for 20 seconds
             * 4.- fades out for 3.5 seconds
             * 5.- wait for 5 min
             * 6.- randomly choose if show first watermark or second watermark (with 70% fisrt and second with 30% probability to be shown)
             * 7.- go to step 2.-
             *   */
            const resetCycle = 5 * 60; //5 min  - 300 cycles
            switch (this.counter) {
            case 20: // fist time wait for 20 seconds always
                this.fadeInWatermark();
                break;
            case 40:
                this.fadeOutWatermark();
                break;
            case (resetCycle+40):
                this.counter = 19; // reset just one cycle before fadeIn again
                const randomNumber = Math.floor(Math.random() * 100) + 1;
                if (randomNumber <= 70) {
                    this.watermarkToShow = 0; // show first watermark
                } else {
                    this.watermarkToShow = 1; // show second watermark
                }
                break;
            }
        } else {
            // if persistentVisualWatermark = true show watermark on first cycle during 5 cycles
            // and hide in last cycle, then counter is reset
            switch (this.counter) {
            case 1:
                this.showWatermark();
                break;
            case 6:
                this.hideWatermark();
                this.counter = 0;
                break;
            }
        }
    }
}
