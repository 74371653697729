/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {TitleConstants} from './title-actions';
import {AssetTypeConstants} from '../asset-types/asset-type-constants';
import {Dispatcher} from '../flux-helpers';
import {FindInConstant} from '../utils/utils';

class TitleStore extends ReduceStore {
    getInitialState() {
        return Immutable.Map({
            aspectRatio: Immutable.List(),

            // Count of assets in each season.
            audioSeasonCounts: Immutable.List(),
            clipsSeasonCounts: Immutable.List(),
            documentsSeasonCounts: Immutable.List(),
            merchandiseSeasonCounts: Immutable.List(),
            imagesSeasonCounts: Immutable.List(),
            videosSeasonCounts: Immutable.List(),
            scriptsSeasonCounts: Immutable.List(),

            // The assets property stores only audio, documents and merchandise
            // because there aren't much assets of those types.
            // Update: it also stores the first 8 images and the first 4 videos.
            assets: Immutable.Map({
                audio: Immutable.List(),
                documents: Immutable.List(),
                isClipsAvailable: false,
                images: Immutable.List(),
                merchandise: Immutable.List(),
                videos: Immutable.List(),
                scripts: Immutable.List()
            }),
            assetsLoaded: false,
            audioPreview: Immutable.fromJS({
                show: false,
                audio: Immutable.Map()
            }),
            audios: Immutable.List(), // This is the audio lookup types. TODO: rename this variable to audioTypes.
            episodes: Immutable.List(),
            episodesForSeason: Immutable.List(),
            cover: Immutable.Map(),
            genres: Immutable.List(),
            images: Immutable.List(),
            languageAvailability: Immutable.List(),
            languages: Immutable.List(),
            links: Immutable.List(),
            navigation: Immutable.Map({
                episodes: Immutable.List(),
                seasons: Immutable.List()
            }),
            networks: Immutable.List(),
            originalSources: Immutable.List(),
            productionCompany: Immutable.List(),
            related: Immutable.List(),
            releaseDates: Immutable.List(),
            seasons: Immutable.List(),
            seasonsMap: Immutable.Map(),
            sourceMaster: Immutable.List(),
            standards: Immutable.List(),
            synopses: Immutable.List(),
            themes: Immutable.List(),
            thumbnails: Immutable.Map({
                image: Immutable.Map(),
                video: Immutable.Map()
            }),
            title: Immutable.Map({
                suggestedUsers: Immutable.List(),
                ratingReasons: Immutable.List(),
            }),
            titleTalent: Immutable.List(),
            titles: Immutable.Map(),
            videos: Immutable.List()
        });
    }

    getParentalRatingType(id) {
        return FindInConstant(TitleConstants.PARENTAL_RATING_TYPES, id);
    }

    isEpisode(categoryGroup) {
        return categoryGroup === TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE;
    }

    isMiniSeries(categoryGroup) {
        return categoryGroup === TitleConstants.TITLE_CATEGORY_GROUPS.MINI_SERIES;
    }

    isSeason(categoryGroup) {
        return categoryGroup === TitleConstants.TITLE_CATEGORY_GROUPS.SEASON;
    }

    isSeries(categoryGroup) {
        return categoryGroup === TitleConstants.TITLE_CATEGORY_GROUPS.SERIES;
    }

    reduce(state, action) {
        switch (action.actionType) {
        case TitleConstants.TITLE.ASPECT_RATIO.GET.SUCCESS:
            state = state.set('aspectRatio', action.lookup);
            break;

        case TitleConstants.TITLE.ASSETS.GET.SUCCESS:
            state = state.mergeIn(['assets'], {
                audio: action.audio,
                documents: action.documents,
                isClipsAvailable: action.clipsCount > 0,
                images: action.images,
                merchandise: action.merchandise,
                videos: action.videos,
                scripts: action.scripts
            });
            state = state.set('assetsLoaded', true);
            break;

        case TitleConstants.TITLE.AUDIO.GET.SUCCESS:
            state = state.set('audios', action.lookup);
            break;

        case TitleConstants.TITLE.AUDIO.PREVIEW.SHOW:
            state = state.set('audioPreview', Immutable.fromJS({
                show:true,
                audio: action.audio
            }));
            break;

        case TitleConstants.TITLE.AUDIO.PREVIEW.HIDE:
            state = state.set('audioPreview', Immutable.fromJS({
                show:false,
                audio: {}
            }));
            break;

        case TitleConstants.TITLE.CLEAR:
            state = this.getInitialState();
            break;

        case TitleConstants.TITLE.EPISODES_FOR_SEASON.SUCCESS:
            state = state.setIn(['episodesForSeason', action.seasonNumber], action.episodes);
            break;

        case TitleConstants.TITLE.GET.SUCCESS:
            state = state.set('title', action.title);
            break;

        case TitleConstants.TITLE.GENRES.GET.SUCCESS:
            state = state.set('genres', action.lookup);
            break;

        case TitleConstants.TITLE.LANGUAGE_AVAILABILITY.GET.SUCCESS:
            state = state.set('languageAvailability', action.languageAvailability);
            break;

        case TitleConstants.TITLE.LANGUAGES.GET.SUCCESS:
            state = state.set('languages', action.lookup);
            break;

        case TitleConstants.TITLE.LINKS.GET.SUCCESS:
            state = state.set('links', action.links);
            break;

        case TitleConstants.TITLE.NETWORKS.GET.SUCCESS:
            state = state.set('networks', action.networks);
            break;

        case TitleConstants.TITLE.ORIGINAL_SOURCE.GET.SUCCESS:
            state = state.set('originalSources', action.lookup);
            break;

        case TitleConstants.TITLE.PRODUCTION_COMPANIES.GET.SUCCESS:
            state = state.set('productionCompany', action.lookup);
            break;

        case TitleConstants.TITLE.RATING_REASONS.GET.SUCCESS:
            state = state.setIn(['title', 'ratingReasons'], action.ratingReasonArray);
            break;

        case TitleConstants.TITLE.RELATED.GET.SUCCESS:
            state = state.merge({
                episodes: action.episodes,
                related: action.related,
                seasons: action.seasons
            });
            break;

        case TitleConstants.TITLE.RELEASE_DATES.GET.SUCCESS:
            state = state.set('releaseDates', action.releaseDates);
            break;

        case TitleConstants.TITLE.NAVIGATION.EPISODES.GET.SUCCESS:
            state = state.setIn(['navigation', 'episodes'], action.episodes);
            break;

        case TitleConstants.TITLE.NAVIGATION.SEASONS.GET.SUCCESS:
            state = state.setIn(['navigation', 'seasons'], action.seasons);
            break;

        case TitleConstants.TITLE.SEASON_ASSET_COUNT.SUCCESS:
            state = state.merge({
                audioSeasonCounts: action.audioSeasonCounts,
                documentsSeasonCounts: action.documentsSeasonCounts,
                merchandiseSeasonCounts: action.merchandiseSeasonCounts,
                scriptsSeasonCounts: action.scriptsSeasonCounts,
                seasonsMap: action.seasons
            });
            break;

        case TitleConstants.TITLE.SEASON_SINGLE_ASSET_COUNT.SUCCESS:
            if (action.assetType === AssetTypeConstants.ASSET_TYPE.IMAGE) {
                state = state.set('imagesSeasonCounts', action.assetCount);
            }
            if (action.assetType === AssetTypeConstants.ASSET_TYPE.VIDEO) {
                state = state.set('videosSeasonCounts', action.assetCount);
            }
            if (action.assetType === AssetTypeConstants.ASSET_TYPE.VIDEO_USER_CLIP) {
                state = state.set('clipsSeasonCounts', action.assetCount);
            }
            break;

        case TitleConstants.TITLE.SOURCE_MASTER.GET.SUCCESS:
            state = state.set('sourceMaster', action.lookup);
            break;

        case TitleConstants.TITLE.STANDARD.GET.SUCCESS:
            state = state.set('standards', action.lookup);
            break;

        case TitleConstants.TITLE.SUBSCRIPTION.SUGGESTED_USERS.SUCCESS:
            state = state.setIn(['title', 'suggestedUsers'], action.results);
            break;

        case TitleConstants.TITLE.SYNOPSIS.GET.SUCCESS:
            state = state.set('synopses', action.synopses);
            break;

        case TitleConstants.TITLE.TALENT.GET.SUCCESS:
            state = state.set('titleTalent', action.titleTalent);
            break;

        case TitleConstants.TITLE.THEMES.GET.SUCCESS:
            state = state.set('themes', action.lookup);
            break;

        /* istanbul ignore next */
        case TitleConstants.TITLE.VIDEOS.THUMBNAILS.GET.SUCCESS:
            state = state.updateIn(
                ['assets', 'videos'],
                videos => videos.map(
                    v => v.set(
                        'thumbnails',
                        action.thumbnails.find(
                            t => t.get('videoId') === v.get('assetId')
                        ).get('thumbnailList')
                    )
                )
            );
            break;

        case TitleConstants.TITLES.GET.SUCCESS:
            state = state.setIn(['titles', action.title.get('id').toString()], action.title);
            break;
        }

        return state;
    }
}

export default new TitleStore(Dispatcher);
