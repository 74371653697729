/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ENVIRONMENTS, MergePartners} from './base-config';

export default {
    // Important: if the ApiBaseUrl has an uri, then it must always
    // end with a trailing '/'.
    // For example: "http://localhost:8080/api/"
    ApiBaseUrl: 'https://api.wbtvd.com/',
    BrainiacBaseUrl: 'https://brainiac.wbtvd.com',
    Datadog: {
        // WBD tagging documentation: https://wbddigital.atlassian.net/wiki/spaces/STUDIO/pages/92438568/Datadog
        applicationId: 'ab9649c9-5a9b-44b5-9511-4b36952ef02e',
        clientToken: 'pub1d422c17a4adbe820636dae274e727aa',
        enabledForEnvironment: false,
        env: 'production',
        service: 'sie:wbtvd',
        sessionReplaySampleRate: 5,
        sessionSampleRate: 50,
        traceSampleRate: 50,
    },
    DefaultTimezone: 'America/Los_Angeles',
    Environment: ENVIRONMENTS.PRODUCTION,
    Environments: ENVIRONMENTS,
    HappyFoxWidgetUrl: ' https://requests.wbtvd.com/supportwidgets/code_support_widget/2',
    PlayerReportPlaybackTime: 15,
    Mux: '29f2f9e4284e53afe975cdff9',
    Partners: MergePartners({
        ADULTSWIM_PRESS: {
            baseUrl: 'https://adultswim.wbtvd.com',
            hostname: 'adultswim.wbtvd.com',
            loginBaseUrl: 'https://adultswim.wbtvd.com'
        },
        CARTOON_NETWORK_PRESS: {
            baseUrl: 'https://cartoonnetwork.wbtvd.com',
            hostname: 'cartoonnetwork.wbtvd.com',
            loginBaseUrl: 'https://cartoonnetwork.wbtvd.com'
        },
        CARTOONITO_PRESS: {
            baseUrl: 'https://cartoonito.wbtvd.com',
            hostname: 'cartoonito.wbtvd.com',
            loginBaseUrl: 'https://cartoonito.wbtvd.com'
        },
        FORMATS_PORTAL: {
            baseUrl: 'https://formats.wbtvd.com',
            hostname: 'formats.wbtvd.com',
            loginBaseUrl: 'https://formats.wbtvd.com'
        },
        HBO: {
            baseUrl: 'https://hbo.wbtvd.com',
            hostname: 'hbo.wbtvd.com',
            loginBaseUrl: 'https://hbo.wbtvd.com'
        },
        HBO_MAX_PORTAL: {
            baseUrl: 'https://hbomax.wbtvd.com',
            hostname: 'hbomax.wbtvd.com',
            loginBaseUrl: 'https://hbomax.wbtvd.com'
        },
        LA_SCREENINGS: {
            baseUrl: '',
            hostname: 'www.lascreenings.com'
        },
        PRESS_SITE: {
            baseUrl: 'https://press.wbtvd.com',
            hostname: 'press.wbtvd.com',
            loginBaseUrl: 'https://press.wbtvd.com'
        },
        WBTVD: {
            baseUrl: 'https://www.wbtvd.com',
            loginBaseUrl: 'https://www.wbtvd.com'
        }
    }),
    SessionTimeout: 1500,
    SessionTimeoutWarning: 1200
};
