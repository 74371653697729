/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';

import Clipping from './clipping/clipping';
import HotkeysListener from './hotkeys-listener';
import CreateClipModal from '../titles/clip/create-clip-modal';


/**
 * @callback doActionWithPlayer
 * @param {PlayerController} controller
 */

export default class ClippablePlayer extends React.PureComponent {
    static get propTypes() {
        return {
            areHotkeysEnabled: PropTypes.bool,
            children: PropTypes.node.isRequired,
            clip: PropTypes.instanceOf(Immutable.Map),
            clippingEl: PropTypes.any,
            duration: PropTypes.number.isRequired,
            isClippingModeOn: PropTypes.bool.isRequired,
            isCreateClipModalVisible: PropTypes.bool.isRequired,
            onlyViewingClip: PropTypes.bool.isRequired,
            playerController: PropTypes.object.isRequired,
            searchTerm: PropTypes.string.isRequired,
            toggleCreateModal: PropTypes.func.isRequired,
            video: PropTypes.instanceOf(Immutable.Map).isRequired,
        };
    }

    static get defaultProps() {
        return {
            areHotkeysEnabled: true,
            clip: null,
            clippingEl: null,
        };
    }

    constructor(props) {
        super(props);

        this.setTime = this.setTime.bind(this);
    }

    componentDidUpdate(oldProps) {
        if (isMapValueChanged(oldProps.clip, this.props.clip, 'tcIn')) {
            this.setTime(safeGetter(this.props.clip, 'tcIn'));
        } else if (isMapValueChanged(oldProps.clip, this.props.clip, 'tcOut')) {
            this.setTime(safeGetter(this.props.clip, 'tcOut'));
        }

        if (this.props.isCreateClipModalVisible) {
            this.doWithPlayerController((c) => c.pause());
        }
    }

    setTime(time) {
        this.doWithPlayerController((c) => c.currentTime(time));
    }

    /**
     * @param {doActionWithPlayer} action
     * @return {*}
     */
    doWithPlayerController(action) {
        /** @type {PlayerController} */
        const controller = this.props.playerController;
        if (controller && controller.isInitialized()) {
            return action(controller);
        }

        return;
    }

    renderModal() {
        const {clip, isCreateClipModalVisible, searchTerm} = this.props;
        if (!clip) {
            return null;
        }
        return (
            <CreateClipModal clip={clip} searchTerm={searchTerm} show={isCreateClipModalVisible} />
        );
    }

    renderCreateButton() {
        const {clip, isClippingModeOn, onlyViewingClip} = this.props;
        if (!clip || !isClippingModeOn || onlyViewingClip) {
            return null;
        }
        const isEditClip = !!clip.get('id');
        let tkey = 'title.player.add-new-moment';
        if (isEditClip) {
            tkey = 'title.player.update-moment';
        }

        return (
            <div className="Pos(a) R(0) T(0) panel-body">
                <Button className="btn btn-yellow" onClick={this.props.toggleCreateModal}>
                    <span className={ClassNames('glyphicon', {'glyphicon-pencil': isEditClip, 'glyphicon-shopping-cart': !isEditClip})}/>
                    <FormattedMessage id={tkey} />
                </Button>
            </div>
        );
    }

    renderClipping() {
        const {clip, clippingEl, duration, isClippingModeOn, onlyViewingClip, video} = this.props;
        if (!clip || !isClippingModeOn || !duration) {
            return null;
        }
        return (
            <Clipping
                duration={duration}
                disabled={onlyViewingClip}
                frameRate={video.get('frameRate')}
                mountTo={clippingEl}
                onTimeUpdate={this.setTime}
                tcIn={clip.get('tcIn')}
                tcOut={clip.get('tcOut')}
                thumbnailsVtt={video.getIn(['streams', 0, 'spriteThumbVTTUrl'])}
            />
        );
    }

    render() {
        const {
            areHotkeysEnabled, clip, isClippingModeOn,
            onlyViewingClip, playerController, video
        } = this.props;

        const clipping = this.renderClipping();
        const modal = this.renderModal();
        const createButton = this.renderCreateButton();
        const player = this.props.children;

        let innerComponent = (
            <table width="100%" cellSpacing="5">
                <tbody>
                    <tr>
                        <td className="player-container">
                            {player}
                            {clipping}
                        </td>
                    </tr>
                </tbody>
            </table>
        );

        if (areHotkeysEnabled) {
            innerComponent = (
                <HotkeysListener
                    clip={clip}
                    frameRate={video.get('frameRate')}
                    onlyViewingClip={onlyViewingClip}
                    isClippingModeOn={isClippingModeOn}
                    playerController={playerController}
                >
                    {innerComponent}
                </HotkeysListener>
            );
        }

        return (
            <div className={ClassNames('Pos(rel)', {'player-clipping-mode-on': isClippingModeOn})}>
                {innerComponent}
                {createButton}
                {modal}
            </div>
        );
    }
}

function isMapValueChanged(oldMap, newMap, key) {
    return safeGetter(oldMap, key) !== safeGetter(newMap, key);
}

function safeGetter(map, key) {
    if (map) {
        return map.get(key);
    }

    return null;
}
