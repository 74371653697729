/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {AssetTypeConstants} from '../../asset-types/asset-type-constants';
import {timecodeFactory} from '../../common/timecode/time-code';
import {Dispatcher} from '../../flux-helpers';
import {NotificationActions} from '../../notification/notification-actions';
import {OrderActions} from '../../orders/order-actions';
import {OrderConstants} from '../../orders/order-constants';
import {PlayerActions} from '../../player/player-actions';
import Request from '../../request';
import {TitleConstants} from '../../titles/title-actions';
import TitleStore from '../title-store';

const CONSTANTS = {
    CREATE_CLIP_USE_MOMENT: 'clip.create.moment',
    CREATE_NEW_CLIP: 'clip.create.new',
    SET_SHOW_NAME: 'clip.set-show-name',
    TOGGLE_CLIPPING: 'clip.clipping.toggle',
    TOGGLE_MODAL_VISIBILITY: 'clip.modal.toggle',
    VIEW_CLIP: 'clip.view',
    UPDATE_CLIP: 'clip.update-new',
};

const SHOW_PREDICATES = [TitleStore.isEpisode, TitleStore.isMiniSeries, TitleStore.isSeason, TitleStore.isSeries];
const isHierarchicalCategoryGroup = (categoryGroup) => {
    return SHOW_PREDICATES.some((predicate) => predicate(categoryGroup));
};

class ClipActions {
    closeClipModal() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TOGGLE_MODAL_VISIBILITY,
        });
    }

    createClipFromMoment(clip) {
        const {frameRate, tcInFrameRounded, tcOutFrameRounded, titleId, videoId} = clip.toJS();
        Promise.all([
            Request.get(`asset/video/${videoId}`),
            detectShowName(titleId),
        ]).spread((videoRes, showName) => {
            const converter = timecodeFactory({frameRate});
            const tcIn = converter.framesToSeconds(tcInFrameRounded);
            const tcOut = converter.framesToSeconds(tcOutFrameRounded);
            const currentClip = {tcIn, tcOut, titleId, videoId};
            const data = Immutable.fromJS({currentClip, video: videoRes.body, showName});
            Dispatcher.dispatch({
                actionType: CONSTANTS.CREATE_CLIP_USE_MOMENT,
                data,
            });
        }).catch((e) => {
            console.error(e);
            NotificationActions.showAlertDanger('title.modal.moment.create.error');
            throw e;
        });
    }

    createNewClip(data) {
        const titleId = data.get('titleId');
        const titleIdReq = titleId ? Promise.resolve(titleId) : detectTitleId(data.get('videoId'));

        titleIdReq.then(id => {
            data = data.set('titleId', id);
            return detectShowName(data.get('titleId'));
        }).then(showName => {
            data = data.set('showName', showName);
            Dispatcher.dispatch({
                actionType: CONSTANTS.CREATE_NEW_CLIP,
                data,
            });
        });
    }

    playAutoClip(clip, titleId) {
        NotificationActions.clearQueue();
        this.playClip(clip.delete('id'), titleId);
    }

    playUserClip(clip, titleId) {
        this.playClip(clip, titleId);
    }

    playClip(clip, titleId) {
        const frameRate = clip.get('frameRate');
        if (!frameRate) {
            NotificationActions.showAlertDanger('title.clipping.no-frame-rate');
            return;
        }
        const assetId = clip.get('videoId');
        Dispatcher.dispatch({
            actionType: CONSTANTS.VIEW_CLIP,
            clip,
            frameRate,
            titleId,
        });
        PlayerActions.showPlayer(Immutable.fromJS({assetId}), void 0, titleId, clip);
        detectShowName(titleId).then((showName) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.SET_SHOW_NAME,
                showName,
            });
        });
    }

    saveAndAddToCart(clipAsset, titleId) {
        const assetType = AssetTypeConstants.ASSET_TYPE.VIDEO_USER_CLIP;
        const {assetName, id} = clipAsset;
        const method = id ? 'put' : 'post';
        const req = Request[method]('asset/video-user-clip');
        req.send(clipAsset).then((res) => {
            if (id) {
                NotificationActions.showAlertSuccess('title.modal.moment.edit.success');
                Dispatcher.dispatch({
                    actionType: OrderConstants.ORDER.ITEM.MODIFY,
                    asset: Immutable.fromJS({...clipAsset, name: assetName}),
                    assetType,
                    titleId,
                });
                return;
            }
            Dispatcher.dispatch({
                actionType: CONSTANTS.UPDATE_CLIP,
                delta: Immutable.fromJS({id: 0, isTouched: false, name: ''}),
            });
            OrderActions.add(assetType, titleId, [
                Immutable.fromJS({
                    ...res.body,
                    assetId: res.body.id,
                    assetType,
                }),
            ]);
        }).catch((err) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.UPDATE_CLIP,
                delta: Immutable.fromJS({name: assetName, isTouched: false}),
            });
            const action = id ? 'edit' : 'create';
            let msg = `title.modal.moment.${action}.error`;
            msg = err.status === 404 ? `${msg}.no-permission` : msg;
            NotificationActions.showAlertDanger(msg);
            throw err;
        });
    }

    saveClip(clip) {
        const {frameRate, name: assetName, tcIn, tcOut, titleId, videoId, id} = clip.toJS();
        const timecode = timecodeFactory({frameRate});
        const clipAsset = {
            assetName,
            id,
            tcInFrameRounded: timecode.secondsToFrames(tcIn),
            tcOutFrameRounded: timecode.secondsToFrames(tcOut),
            videoId,
        };
        this.saveAndAddToCart(clipAsset, titleId);
    }

    toggleClipping(delta) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TOGGLE_CLIPPING,
            delta,
        });
    }

    toggleCreateModal() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TOGGLE_MODAL_VISIBILITY,
        });
    }

    updateClip(delta) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.UPDATE_CLIP,
            delta,
        });
    }
}

const actions = new ClipActions();

export {
    actions as ClipActions,
    CONSTANTS as ClipConstants
};

function detectShowName(titleId) {
    return Request.get(`title/${titleId}`).then(titleResp => {
        const {category, displayName} = titleResp.body;
        if (isHierarchicalCategoryGroup(TitleConstants.categoryGroupMap[category])) {
            return Request.get(`title/${titleId}/season-list`).then(res => res.body.titleName);
        }
        return displayName;
    }).catch((err) => {
        console.error(err);
        return '';
    });
}

function detectTitleId(videoId) {
    return Request.get(`asset/${videoId}/title`).then(res => {
        const [first] = res.body;
        return first ? first.titleId : null;
    });
}
