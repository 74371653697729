/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import enUS from '../../messages/en-US';

const SEVERITY = {
    ALERT: 1,
    WARNING: 2
};

const noop = () => void 0;

const SkipIfEmpty = validator => {
    return v => {
        if (v === undefined || v === null || v === '') {
            return true;
        }

        return validator(v);
    };
};

const CommonPasswords = ['123456', 'password', '12345678', 'qwerty', '123456789', '12345', '1234', '111111', '1234567', 'dragon',
    '123123', 'baseball', 'abc123', 'football', 'monkey', 'letmein', '696969', 'shadow', 'master', '666666', 'qwertyuiop', '123321',
    'mustang', '1234567890', 'michael', '654321', 'pussy', 'superman', '1qaz2wsx', '7777777', 'fuckyou', '121212', '000000', 'qazwsx',
    '123qwe', 'killer', 'trustno1', 'jordan', 'jennifer', 'zxcvbnm', 'asdfgh', 'hunter', 'buster', 'soccer', 'harley', 'batman', 'andrew',
    'tigger', 'sunshine', 'iloveyou', 'fuckme', '2000', 'charlie', 'robert', 'thomas', 'hockey', 'ranger', 'daniel', 'starwars', 'klaster',
    '112233', 'george', 'asshole', 'computer', 'michelle', 'jessica', 'pepper', '1111', 'zxcvbn', '555555', '11111111', '131313', 'freedom',
    '777777', 'pass', 'fuck', 'maggie', '159753', 'aaaaaa', 'ginger', 'princess', 'joshua', 'cheese', 'amanda', 'summer', 'love', 'ashley',
    '6969', 'nicole', 'chelsea', 'biteme', 'matthew', 'access', 'yankees', '987654321', 'dallas', 'austin', 'thunder', 'taylor', 'matrix'];

// class ValidationCanceledError extends Error {}

/**
 * Validations
 *
 * validate method MUST return TRUE if value is VALID.
 */
const Validations = {
    capitalization: {
        getMessage: function(label) {
            return `${label} does not start with a capital letter`;
        },
        severity: SEVERITY.ALERT,
        type: 'capitalization',
        validate: SkipIfEmpty(function(value) {
            let items = value.split(' ');
            return items.every(function(item) {
                /* istanbul ignore else */
                if (item.length !== 0) {
                    // check if word is correctly formed Ullllll
                    let testValue = item[0].toUpperCase() + item.substring(1).toLowerCase();
                    return testValue === item;
                }
                return true;
            });
        })
    },

    custom: function(getMessage, validate) {
        return {
            getMessage,
            severity: SEVERITY.ALERT,
            type: 'custom',
            validate
        };
    },

    email: {
        getMessage: function(label) {
            return `${label} is not a valid email`;
        },
        severity: SEVERITY.ALERT,
        type: 'email',
        validate: SkipIfEmpty(function(value) {
            //let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            // this is "official" regex for emails.
            // but according to wbtv's specs x@x.x should be valid
            // (that's why I changed it to support one letter top domains)
            let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;

            return re.test(value);
        })
    },

    max: function(n) {
        return {
            getMessage: function(label) {
                return `${label} length is larger than the allowed value (${n})`;
            },
            severity: SEVERITY.ALERT,
            type: 'max',
            validate: SkipIfEmpty(function(value) {
                return value.toString().length <= n;
            })
        };
    },

    min: function(n) {
        return {
            getMessage: function(label) {
                return `${label} length is smaller than the allowed value (${n})`;
            },
            severity: SEVERITY.ALERT,
            type: 'min',
            validate: SkipIfEmpty(function(value) {
                return value.toString().length >= n;
            })
        };
    },

    noNumbers: {
        getMessage: function(label) {
            return `${label} must not contain numbers or digits`;
        },
        severity: SEVERITY.ALERT,
        type: 'noNumbers',
        validate: SkipIfEmpty(function(value) {
            let reNumbers = /[0-9]/;
            return value.search(reNumbers) === -1;
        })
    },

    noSymbols: {
        getMessage: function(label) {
            return `${label} must not contain symbols`;
        },
        severity: SEVERITY.ALERT,
        type: 'noSymbols',
        validate: SkipIfEmpty(function(value) {
            let reSymbols = /[!|@#$%^&*()+=\\<>/?]/;
            return value.search(reSymbols) === -1;
        })
    },

    number: {
        getMessage: function(label) {
            return `${label} must be a number`;
        },
        severity: SEVERITY.ALERT,
        type: 'number',
        validate: SkipIfEmpty(function(value) {
            return Number.isInteger(value);
        })
    },

    onlySpaces: {
        getMessage: function(label) {
            return `${label} must contain only spaces as whitespace`;
        },
        severity: SEVERITY.ALERT,
        type: 'onlySpaces',
        validate: SkipIfEmpty(function(value) {
            let reBlanks = /[\t|\r|\n]/;
            return value.search(reBlanks) === -1;
        })
    },

    passwordHasUppercase: {
        getMessage: function() {
            return 'Contain at least 1 UPPERCASE letter';
        },
        severity: SEVERITY.ALERT,
        type: 'password',
        validate: function(value) {

            let re = /^(?=.*[A-Z])/;
            return re.test(value);
        }
    },

    passwordHasLowercase: {
        getMessage: function() {
            return 'Contain at least 1 lowercase letter';
        },
        severity: SEVERITY.ALERT,
        type: 'password',
        validate: function(value) {

            let re = /^(?=.*[a-z])/;
            return re.test(value);
        }
    },

    passwordHasNumber: {
        getMessage: function() {
            return 'Contain at least 1 number';
        },
        severity: SEVERITY.ALERT,
        type: 'password',
        validate: function(value) {

            let re = /^(?=.*[\d])/;
            return re.test(value);
        }
    },

    passwordHasSymbolOrPunctuationMark: {
        getMessage: function() {
            return 'Contain at least 1 punctuation mark/symbol (i.e. ! @ # $ % * &)';
        },
        severity: SEVERITY.ALERT,
        type: 'password',
        validate: function(value) {
            const reSymbolValid = /^(?=.*[!#$%@*&])/;
            const reSymbolInvalid = /^(?=.*[-^()_+ |~=`{}[\]:";'<>?,.\s])/;
            //const reNumber = /^(?=.*[\d])/;
            const reAlpha = /^(?=.*[a-zA-Z])/;
            return reSymbolValid.test(value) /*&& reNumber.test(value) */&& reAlpha.test(value) && !reSymbolInvalid.test(value);
        }
    },

    passwordLength: {
        getMessage: () => 'Contain at least 12 characters',
        severity: SEVERITY.ALERT,
        type: 'password',
        validate: function(value) {
            return value.length >= 12;
        }
    },

    /* istanbul ignore next */
    passwordDoesNotContain: (words) => {
        return {
            getMessage: label => `${label} must not contain your first name, last name or username`,
            severity: SEVERITY.ALERT,
            type: 'password',
            validate: SkipIfEmpty(value => {
                if (!words) {return true;}

                return words.map(w => w.trim()).every(w => value.search(new RegExp(w, 'i')) === -1);
            })
        };
    },

    passwordNotCommon: {
        getMessage: () => 'Not contain commonly used passwords',
        severity: SEVERITY.ALERT,
        type: 'password',
        validate: (value) => {
            return CommonPasswords.every(p => {
                return value.search(new RegExp(p, 'i')) === -1;
            });
        }
    },

    regex: function(regex, getMessage = noop) {
        return {
            getMessage,
            severity: SEVERITY.ALERT,
            type: 'regex',
            validate: function(value) {
                return regex.test(value);
            }
        };
    },

    /**
     * Required rule
     */
    required: {
        getMessage: noop,
        severity: SEVERITY.ALERT,
        type: 'required',
        validate: function(value) {
            if (Array.isArray(value)) {
                return !!value.length;
            }

            return ['', null, undefined].indexOf(value) === -1;
        }
    },

    /**
     * RequiredIf rule.
     *
     * The value will be required only if the result of condition is true.
     */
    requiredIf: function(condition) {
        return {
            getMessage: noop,
            severity: SEVERITY.ALERT,
            type: 'required',
            validate: function(value) {
                // If condition is truthy, then validate the value
                // using the standard required validation.
                if (condition()) {
                    return Validations.required.validate(value);
                }

                // If not, return true by default (value is valid).
                return true;
            }
        };
    },

    stringMatch: function(getMessage, stringValue, failOnEmpty = false) {
        return {
            getMessage,
            severity: SEVERITY.ALERT,
            type: 'stringMatch',
            validate: function(value) {
                /* istanbul ignore else */
                if (!value && failOnEmpty) {
                    return false;
                }

                return stringValue().toString() === value;
            }
        };
    },

    /**
     * warnOnTrue.
     *
     * Will rise warningMessage if passed value is true.
     */
    warnOnTrue: {
        getMessage: function(message) {
            return message;
        },
        severity: SEVERITY.WARNING,
        type: 'warnOnTrue',
        validate: SkipIfEmpty(function(value) {
            return !value;
        })
    },
    /**
     * Validate a model against a set of validation rules
     *
     * @return [Errors] array of errors
     */
    validate: function(model, validationRules, instantFeedback = false) {
        let errors = [];

        /**
         * Iterate the validationRules object. This object contains
         * a set of validations for each attribute we want to check.
         * E.g.: {
         *     name: {validations: [required, max(200)]}
         * }
         */
        Object.keys(validationRules).forEach(attrName => {
            // Get the value we want to check.
            let value = model.getIn(attrName.split('.'));
            // Get the array of rules and iterate it.
            let rules = validationRules[attrName].validations;
            rules.forEach(r => {
                const {severity, type} = r;

                // Does value pass? true|false
                const valid = r.validate(value);

                // FIXME: remove this if. Why is this needed?
                let message = enUS[validationRules[attrName].message];
                if (validationRules[attrName].label) {
                    message = enUS[validationRules[attrName].label];
                }

                if (!valid) {
                    // If false, then build the error message.
                    errors.push({
                        message: r.getMessage(message),
                        severity,
                        type
                    });

                    return;
                }

                // Getting here means the rule passed, now check if caller
                // requested instant feedback as you type
                // (like the password reset page)
                if (valid && instantFeedback) {
                    errors.push({
                        message: r.getMessage(message),
                        valid,
                        severity,
                        type
                    });

                    return;
                }
            });
        });

        return errors;
    }
};

const WithValidations = Base => {
    return class WithValidationsImpl extends Base {
        static get displayName() {
            return `WithValidations(${Base.displayName})`;
        }

        /**
         * Get validation state from [model]
         *
         * @param {Immutable Map} model - The model itself
         * @param {array} attr - Input list
         * @param {object} modelValidationRules - Model store Validations array
         *
         * @return {String|undefined} 'error', 'success' or undefined
         */
        getAccordionValidationState(model, attr, modelValidationRules) {
            let isSuccessful = false;
            let hasErrors = attr.some(value => {
                let modelValue = model.get(value);
                /* istanbul ignore if */
                if (modelValue && modelValue.toJS) {
                    modelValue = modelValue.toJS();
                }
                /* istanbul ignore else */
                if (!isSuccessful) {
                    /* istanbul ignore else */
                    if ([undefined, null, ''].indexOf(modelValue) === -1 &&
                        !(Array.isArray(modelValue) && !modelValue.length)) {
                        isSuccessful = true;
                    }

                }

                let rules = modelValidationRules[value];
                /* istanbul ignore else */
                if (rules) {
                    return this.getValidationState(modelValue, true, rules.validations);
                }

                return undefined;
            });

            if (hasErrors) {
                return 'error';
            }

            if (isSuccessful) {
                return 'success';
            }

            return;
        }

        /* istanbul ignore next */
        getValidationState(value, isDirty, validations, validatedValue) {
            if (!isDirty || !validations.length) {return;}

            return validations.reduce((r, v) => {
                if (r === 'error') {
                    return 'error';
                }

                if (!v.validate(value, validatedValue)) {
                    if (v.severity === SEVERITY.ALERT) {
                        return 'error';
                    }
                    return 'warning';
                }
                return r || undefined;
            }, undefined);
        }

        /* istanbul ignore next */
        getValidationError(value, isDirty, validations, validatedValue) {
            /* istanbul ignore else */
            if (!isDirty || !validations.length) {return undefined;}
            let failingValidation = validations.find(validation => !validation.validate(value, validatedValue));

            if (failingValidation) {
                return failingValidation.getMessage(value);
            } else {
                return undefined;
            }
        }
    };
};

export default Validations;
export {
    SEVERITY,
    WithValidations
};
