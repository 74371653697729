/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Checkbox, Col, ControlLabel, Grid, FormControl, FormGroup, HelpBlock, Row, InputGroup} from 'react-bootstrap';

import DatePicker from './date-picker';
import {WithValidations} from '../common/validations/validations';
import {MessagesContext} from '../messages/messages-context';
import {Debounce} from '../utils/utils';

// Require datepicker styles.
require('react-datepicker/dist/react-datepicker.css');

let FormItem = WithValidations(class FormItem extends Component {

    static get propTypes() {
        return {
            addonBefore: PropTypes.node,
            attr: PropTypes.string.isRequired,
            autoFocus: PropTypes.bool,
            children: PropTypes.element,
            datepicker: PropTypes.object,
            disabled: PropTypes.bool,
            icon: PropTypes.string,
            onIconClick: PropTypes.func,
            label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
            legend: PropTypes.string,
            model: PropTypes.object.isRequired,
            onChange: PropTypes.func,
            placeholder: PropTypes.string,
            regex: PropTypes.instanceOf(RegExp),
            setter: PropTypes.func.isRequired,
            showErrorMessage: PropTypes.bool,
            showErrors: PropTypes.bool,
            style: PropTypes.object,
            type: PropTypes.string,
            validations: PropTypes.array,
            weekSelection: PropTypes.bool,
        };
    }

    static get defaultProps() {
        return {
            addonBefore: undefined,
            autoFocus: false,
            children: undefined,
            datepicker: {},
            disabled: false,
            icon: '',
            onIconClick: /* istanbul ignore next */() => /* istanbul ignore next */void 0,
            label: undefined,
            legend: '',
            onChange: /* istanbul ignore next */() => /* istanbul ignore next */void 0,
            placeholder: '',
            regex: undefined,
            showErrorMessage: false,
            showErrors: false,
            style: undefined,
            type: '',
            validations: [],
            weekSelection: false,
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            dirty: false,
            value: this.readValue(this.props)
        };

        this.readValue = this.readValue.bind(this);
        this.getInputType = this.getInputType.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.notifyChange = Debounce(this.notifyChange.bind(this), 200);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let value = this.readValue(nextProps);

        if (this.state.value !== value) {
            this.setState({
                value: value
            });
        }

        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.validations !== nextProps.validations ||
            this.props.disabled !== nextProps.disabled ||
            this.props.type !== nextProps.type) {
            return true;
        }

        if (this.state.dirty !== nextState.dirty ||
            this.state.value !== nextState.value) {
            return true;
        }

        return false;
    }

    static contextType = MessagesContext;

    /**
     * Read the input value from the provided properties.
     */
    readValue(props) {
        let value;
        switch (props.type) {
        case 'date':
            value = props.model.getIn(props.attr.split('.'));
            /* istanbul ignore if */
            if (value === undefined) {
                return undefined;
            }
            value = Moment(value).utc();
            if (!value.isValid()) {
                // Really special case!
                // The React-DatePicker component needs the value to be undefined.
                return undefined;
            }
            break;

        // Default type is also text.
        case 'text':
        default:
            value = props.model.getIn(props.attr.split('.'));

            break;
        }

        // React-Bootstrap warning: (first if condition)
        // Warning: `value` prop on `input` should not be null.
        // Consider using the empty string to clear the component
        // or `undefined` for uncontrolled components.
        //
        // React-Bootstrap warning v2: (second if condition)
        // FormControl is changing a uncontrolled input of type text
        // to be controlled. Input elements should not switch from
        // uncontrolled to controlled (or vice versa).
        // Decide between using a controlled or uncontrolled input
        // element for the lifetime of the component.
        if (value === null || value === undefined) {
            value = '';
        }

        return value;
    }

    getInputType() {
        let type;
        switch (this.props.type) {
        case 'email':
        case 'name':
            type = 'text';
            break;
        default:
            type = this.props.type;
            break;
        }
        return type;
    }

    handleChange(event) {
        let value;

        switch (this.props.type) {
        case 'checkbox':
            // Really hate checkboxes.
            value = event.target.checked;
            break;
        case 'date':
            // Datepicker sends a Moment object.
            value = event;
            break;
        default:
            value = event.target.value;
            break;
        }

        // Update the input.
        this.setState({
            value: value
        });

        // Notify the model. Note that this function is
        // debounced.
        this.notifyChange(value);

        return;
    }

    notifyChange(value) {
        this.props.setter(this.props.attr, value);
        this.props.onChange(value);
        this.setState({
            dirty: true
        });
        return;
    }

    render() {
        let type = this.getInputType();

        let label = this.props.label;
        if (label) {
            if (this.props.validations.some(/* istanbul ignore next */r => /* istanbul ignore next */r.type === 'required')) {
                label = <span>{this.props.label}&nbsp;<span className="text-red">*</span></span>;
            }
            label = <ControlLabel>{label}</ControlLabel>;
        }

        let legend = this.props.legend;
        if (this.props.legend) {
            if (this.props.validations.some(r => r.type === 'required')) {
                legend = <span>{this.props.legend}&nbsp;<span className="text-red">*</span></span>;
            }
            legend = <HelpBlock>{this.props.legend}</HelpBlock>;
        }

        let formControl = <FormControl
            disabled={this.props.disabled}
            onChange={this.handleChange}
            placeholder={this.props.placeholder}
            type={type}
            value={this.state.value}
        />;
        let icon;
        if (this.props.icon) {
            icon = <span
                className={
                    ClassNames('glyphicon', 'glyphicon-' + this.props.icon, 'form-control-feedback', 'login-password')
                }
                onClick={this.props.onIconClick}
                aria-hidden="true">
            </span>;
        }

        if (this.props.addonBefore) {
            formControl = <InputGroup>
                <InputGroup.Addon>{this.props.addonBefore}</InputGroup.Addon>
                {formControl}
            </InputGroup>;
        }

        switch (type) {
        case 'checkbox':
            label = null;
            let checked = '';
            if (this.state.value) {
                checked = 'checked';
            }

            formControl = <Checkbox
                checked={checked}
                disabled={this.props.disabled}
                onChange={this.handleChange}>
                {this.props.label}
            </Checkbox>;
            break;

        case 'date':
            /**
             * TODO: Configure popover placement
             * https://hacker0x01.github.io/react-datepicker/#example-14
             * And re-style the clear button.
             */
            formControl = (
                <DatePicker
                    className="form-control"
                    disabled={this.props.disabled}
                    onChange={this.handleChange}
                    placeholderText={this.context.intl.messages['common.please-select']}
                    selected={this.state.value}
                    {...this.props.datepicker}
                    weekSelection={this.props.weekSelection}
                />
            );
            break;

        case 'select':
            formControl = <FormControl
                componentClass="select"
                disabled={this.props.disabled}
                onChange={this.handleChange}
                placeholder={this.props.placeholder}
                value={this.state.value}>
                {this.props.children}
            </FormControl>;
            break;

        case 'textarea':
            formControl = <FormControl
                style={this.props.style}
                componentClass="textarea"
                disabled={this.props.disabled}
                onChange={this.handleChange}
                placeholder={this.props.placeholder}
                value={this.state.value}/>;
            break;

        default:
            // This is here to avoid overriding of selects (because
            // they have children too and will enter here).
            if (this.props.children) {
                formControl = this.props.children;
            }
            break;
        }

        let errorMessageToShow;
        let errorMessage = this.getValidationError(this.state.value, this.state.dirty, this.props.validations, this.state.validatedValue);
        if (errorMessage && this.props.showErrorMessage) {
            errorMessageToShow = (<p className="text-red">{errorMessage}</p>);
        }

        return (
            <FormGroup validationState={this.getValidationState(this.state.value, this.state.dirty, this.props.validations)}>
                {label}
                {formControl}
                {icon}
                {legend}
                {errorMessageToShow}
            </FormGroup>
        );
    }
});


const GRID_SIZE=12;

class FormRow extends Component {

    static get propTypes() {
        return {
            additionalClass: PropTypes.string,
            children: PropTypes.node
        };
    }

    static get defaultProps() {
        return {
            additionalClass: '',
            children: []
        };
    }

    render() {
        let fixedSizeItems = 0;
        let reserved = 0;
        let currentAutoChild = 0;
        let childCount = React.Children.count(this.props.children);
        React.Children.map(this.props.children, function(child) {
            if (child.props.md) {
                reserved += child.props.md;
                fixedSizeItems += 1;
            }
        });
        let autoSizeItems = childCount - fixedSizeItems;
        let total = reserved;
        return (
            <Row className={ClassNames(this.props.additionalClass)}>
                {React.Children.map(this.props.children, function(child) {
                    let calculatedSize = child.props.md;
                    // if autoSizeItems == 0 all children should have defined
                    // lengths and this should not be executed, but if someone
                    // have messed up with the counts
                    // lets avoid a division by zero error
                    if (!calculatedSize && autoSizeItems > 0) {
                        currentAutoChild += 1;
                        // last autochild, get the reminder
                        if (currentAutoChild === autoSizeItems) {
                            calculatedSize = GRID_SIZE - total;
                        } else {
                            // Integer division :)
                            calculatedSize = ((GRID_SIZE - reserved)/autoSizeItems) >> 0;
                            total += calculatedSize;
                        }
                    }
                    return <Col md={calculatedSize}>{child}</Col>;
                })}
            </Row>
        );
    }
}

class FormSection extends Component {

    static get propTypes() {
        return {
            children: PropTypes.array,
            className: PropTypes.object,
            fluid: PropTypes.bool,
            iconClass: PropTypes.string,
            title: PropTypes.node
        };
    }

    static get defaultProps() {
        return {
            children: [],
            className: undefined,
            fluid: false,
            iconClass: '',
            title: undefined
        };
    }

    render() {
        let header;

        if (this.props.title || this.props.iconClass) {
            header = (
                <h1 className="h3">
                    <i className={this.props.iconClass}/>
                    &nbsp;{this.props.title}
                </h1>
            );
        }

        return (
            <div>
                {header}
                <Grid className={`form-section ${this.props.className}`} fluid={this.props.fluid} componentClass="section">
                    {this.props.children}
                </Grid>
            </div>
        );
    }
}

export {
    FormSection,
    FormRow,
    FormItem
};
