/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {GetSubtitleLanguageByCode} from '@wbdt-sie/brainiac-web-common';

import {ROLE_LABELS} from './label-utils';

// Based on subtitlesutils.ts from github:bitmovin/bitmovin-player-ui

/**
 * Helper class to handle all subtitle related events
 *
 * This class listens to player events as well as the `ListSelector` event if selection changed
 */
export class BitmovinSubtitleSwitchHandler {
    constructor(player, element, uimanager) {
        this.addSubtitle = this.addSubtitle.bind(this);
        this.removeSubtitle = this.removeSubtitle.bind(this);
        this.selectCurrentSubtitle = this.selectCurrentSubtitle.bind(this);
        this.clearSubtitles = this.clearSubtitles.bind(this);
        this.refreshSubtitles = this.refreshSubtitles.bind(this);

        this.player = player;
        this.listElement = element;
        this.uimanager = uimanager;
        this.bindSelectionEvent();
        this.bindPlayerEvents();
        this.refreshSubtitles();
    }
    bindSelectionEvent() {
        this.listElement.onItemSelected.subscribe((_, value) => {
            // Note: the following TODO is from the original Bitmovin source
            // TODO add support for multiple concurrent subtitle selections
            if (value === BitmovinSubtitleSwitchHandler.SUBTITLES_OFF_KEY) {
                const currentSubtitle = this.player.subtitles.list().filter((subtitle) => subtitle.enabled).pop();
                if (currentSubtitle) {
                    this.player.subtitles.disable(currentSubtitle.id);
                }
            } else {
                this.player.subtitles.enable(value, true);
            }
        });
    }
    bindPlayerEvents() {
        this.player.on(this.player.exports.PlayerEvent.SubtitleAdded, this.addSubtitle);
        this.player.on(this.player.exports.PlayerEvent.SubtitleEnabled, this.selectCurrentSubtitle);
        this.player.on(this.player.exports.PlayerEvent.SubtitleDisabled, this.selectCurrentSubtitle);
        this.player.on(this.player.exports.PlayerEvent.SubtitleRemoved, this.removeSubtitle);
        // Update subtitles when source goes away
        this.player.on(this.player.exports.PlayerEvent.SourceUnloaded, this.clearSubtitles);
        // Update subtitles when the period within a source changes
        this.player.on(this.player.exports.PlayerEvent.PeriodSwitched, this.refreshSubtitles);
        this.uimanager.getConfig().events.onUpdated.subscribe(this.refreshSubtitles);
    }
    addSubtitle(event) {
        const subtitle = event.subtitle;
        if (!this.listElement.hasItem(subtitle.id)) {
            this.listElement.addItem(subtitle.id, this.getSubtitleLabel(subtitle));
        }
    }
    clearSubtitles() {
        this.listElement.clearItems();
    }
    getSubtitleLabel(subtitle) {
        if (subtitle.label === subtitle.lang) {
            let subtitleLabel = GetSubtitleLanguageByCode(subtitle.label);

            // TODO: Fully account for all valid role combinations
            // instead of the first role encountered
            if (subtitle?.role?.[0]?.value) {
                let subtitleRoleDisplayName = ROLE_LABELS(subtitle.role[0].value);
                if (!subtitleRoleDisplayName) {
                    return subtitleLabel;
                }
                if (subtitleRoleDisplayName !== 'Main') {
                    if (subtitleRoleDisplayName === 'Caption') {
                        subtitleLabel = `${subtitleLabel} [CC]`;
                    } else {
                        subtitleLabel = `${subtitleLabel} (${subtitleRoleDisplayName})`;
                    }
                }
            }
            return subtitleLabel;
        } else if (subtitle.kind === 'captions') {
            let captionLabel = GetSubtitleLanguageByCode(subtitle.lang);
            if (!captionLabel || captionLabel === 'und' || captionLabel === 'unknown') {
                captionLabel = 'English';
            }
            return `${captionLabel} [CC]`;
        }

        return GetSubtitleLanguageByCode(subtitle.label);
    }
    refreshSubtitles() {
        if (!this.player.subtitles) {
            // Subtitles API not available (yet)
            return;
        }
        const offListItem = {
            key: BitmovinSubtitleSwitchHandler.SUBTITLES_OFF_KEY,
            label: 'off',
        };
        const subtitles = this.player.subtitles.list();
        const subtitleToListItem = (subtitle) => {
            return {
                key: subtitle.id,
                label: this.getSubtitleLabel(subtitle)
            };
        };
        this.listElement.synchronizeItems([
            offListItem, ...subtitles.map(subtitleToListItem),
        ]);
        this.selectCurrentSubtitle();
    }
    removeSubtitle(event) {
        const subtitle = event.subtitle;
        if (this.listElement.hasItem(subtitle.id)) {
            this.listElement.removeItem(subtitle.id);
        }
    }
    selectCurrentSubtitle() {
        if (!this.player.subtitles) {
            // Subtitles API not available (yet)
            return;
        }
        let currentSubtitle = this.player.subtitles.list().filter((subtitle) => subtitle.enabled).pop();
        let item = BitmovinSubtitleSwitchHandler.SUBTITLES_OFF_KEY;
        if (currentSubtitle) {
            item = currentSubtitle.id;
        }
        this.listElement.selectItem(item);
    }
}
BitmovinSubtitleSwitchHandler.SUBTITLES_OFF_KEY = 'null';
