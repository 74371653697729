/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React from 'react';

export default class SliderHandle extends React.Component {
    static get defaultProps() {
        return {
            children: null,
            min: 0,
            max: 100,

            onValueChanged: () => void 0,
        };
    }

    static get propTypes() {
        return {
            children: PropTypes.node,
            getParentRect: PropTypes.func.isRequired,
            max: PropTypes.number,
            min: PropTypes.number,
            onValueChanged: PropTypes.func,
            value: PropTypes.number.isRequired,
        };
    }

    constructor(props) {
        super(props);
        this.getNewValue = this.getNewValue.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
    }

    getNewValue(pos) {
        const {width, left} = this.props.getParentRect();
        const percent = (pos.x - left) / width;
        return percentToValue(percent, this.props.min, this.props.max);
    }

    /**
     * @param {MouseEvent} [event] mouse event
     */
    handleMouseDown(event) {
        event.preventDefault();
        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('mouseup', this.handleMouseUp);
    }

    /**
     * @param {MouseEvent} [event] mouse event
     */
    handleMouseMove(event) {
        const position = getPosition(event);
        const newValue = this.getNewValue(position);
        this.props.onValueChanged(newValue);
    }

    handleMouseUp() {
        document.removeEventListener('mousemove', this.handleMouseMove);
        document.removeEventListener('mouseup', this.handleMouseUp);
    }

    render() {
        const {value, min, max, children} = this.props;
        const percent = value / (max - min) * 100;
        return (
            <div className="slider-handle" style={{left: `${percent}%`}} onMouseDown={this.handleMouseDown}>
                {children}
            </div>
        );
    }
}

/**
 * @param {MouseEvent} [event] mouse event
 */
function getPosition(event) {
    return {
        x: event.clientX,
        y: event.clientY,
    };
}

function percentToValue(percent, min, max) {
    return (max - min) * percent + min;
}

