/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import ResizeObserverPolyfill from 'resize-observer-polyfill';

import ClippingThumbnailMark from './clipping-thumbnail-mark';
import Slider from './slider';
import {timecodeFactory} from '../../common/timecode/time-code';
import {ClipActions} from '../../titles/clip/clip-actions';
import {VideoPlayerThumbnails} from '../video-player-thumbnails';

import './clipping.less';

const DEFAULT_THUMBNAIL_WIDTH = 160;
const RISING_HEIGHT = '40px';
const BORDER_WIDTH = 2;

export default class Clipping extends React.PureComponent {
    static get propTypes() {
        return {
            duration: PropTypes.number.isRequired,
            disabled: PropTypes.bool,
            frameRate: PropTypes.number,
            mountTo: PropTypes.object.isRequired,
            onTimeUpdate: PropTypes.func,
            tcIn: PropTypes.number.isRequired,
            tcOut: PropTypes.number.isRequired,
            thumbnailsVtt: PropTypes.string,
        };
    }

    static get defaultProps() {
        return {
            disabled: false,
            frameRate: null,
            onTimeUpdate: () => void 0,
            thumbnailsVtt: null,
        };
    }

    constructor(props) {
        super(props);
        const {frameRate} = props;
        this.state = {sliderWidth: 0, cues: []};
        this.timeFormatter = timecodeFactory({frameRate});
        this.handleRangeChanged = this.handleRangeChanged.bind(this);
        this.clippingHandle = this.clippingHandle.bind(this);
        this.handleResizeEvent = this.handleResizeEvent.bind(this);
    }

    componentDidMount() {
        this.observer = new ResizeObserverPolyfill(this.handleResizeEvent);
        this.observer.observe(this.props.mountTo);
        this.initCues();
    }

    componentWillUnmount() {
        if (this.observer) {
            this.observer.unobserve(this.props.mountTo);
            this.observer = null;
        }
    }

    initCues() {
        const {thumbnailsVtt} = this.props;
        VideoPlayerThumbnails.GetVTTFile(thumbnailsVtt).then(vttData => {
            return VideoPlayerThumbnails.ParseThumbnailTrack(vttData);
        }).then(cues => {
            this.setState(() => ({cues}));
        });
    }

    handleResizeEvent() {
        const {width} = this.props.mountTo.getBoundingClientRect();
        this.setState(() => ({sliderWidth: width}));
    }

    getPositionFor(value) {
        return this.state.sliderWidth * value / this.props.duration;
    }

    leftHandlePosition() {
        return this.getPositionFor(this.props.tcIn);
    }

    rightHandlePosition() {
        return this.getPositionFor(this.props.tcOut);
    }

    leftHandleStyle(thumbnailWidth) {
        const left = this.leftHandlePosition();
        const right = this.rightHandlePosition();
        const isSticked = left < thumbnailWidth;
        return {
            left: isSticked ? `-${left + BORDER_WIDTH}px` : `-${thumbnailWidth}px`,
            bottom: isSticked && (right < thumbnailWidth) ? RISING_HEIGHT : '25px',
        };
    }

    rightHandleStyle(thumbnailWidth) {
        const {sliderWidth} = this.state;
        const left = this.leftHandlePosition();
        const right = this.rightHandlePosition();
        const offset = sliderWidth - thumbnailWidth - right + BORDER_WIDTH;
        const isSticked = offset < 0;
        return {
            left: isSticked ? `${offset}px` : '',
            bottom: isSticked && (sliderWidth < left + thumbnailWidth) ? RISING_HEIGHT : '25px',
        };
    }

    handleRangeChanged([tcIn, tcOut]) {
        if (this.props.disabled) {
            return;
        }
        if (this.props.tcIn !== tcIn) {
            ClipActions.updateClip(Immutable.fromJS({tcIn}));
        } else if (this.props.tcOut !== tcOut) {
            ClipActions.updateClip(Immutable.fromJS({tcOut}));
        }
    }

    findCuesForTime(time) {
        const [cue] = this.state.cues.filter(({startTime, endTime}) => startTime <= time && time <= endTime);
        if (!cue) {
            return null;
        }
        const data = resolveHref(cue, this.props.thumbnailsVtt);
        if (!data) {
            return;
        }
        const [path, positions] = data.split('=');
        const [href] = path.split('#');
        const xywh = positions.split(',');
        return {
            href,
            x: xywh[0],
            y: xywh[1],
            width: xywh[2],
            height: xywh[3],
        };
    }

    clippingHandle(value) {
        const isLeft = this.props.tcIn === value;
        const thumbnailData = this.findCuesForTime(value);
        const thumbnailWidth = thumbnailData ? +thumbnailData.width : DEFAULT_THUMBNAIL_WIDTH;
        const style = isLeft ? this.leftHandleStyle(thumbnailWidth) : this.rightHandleStyle(thumbnailWidth);
        const cn = isLeft ? 'clipping-handle-left' : 'clipping-handle-right';
        return (
            <ClippingThumbnailMark
                className={cn}
                style={style}
                timecode={this.timeFormatter.secondsToTime(value, false)}
                thumbnailData={thumbnailData}
                onClick={() => this.props.onTimeUpdate(value)}
            />
        );
    }

    render() {
        const {duration, tcIn, tcOut} = this.props;
        const range = Immutable.List([tcIn, tcOut]);
        return ReactDOM.createPortal(
            <Slider min={0} max={duration} value={range} handle={this.clippingHandle}
                onChangeValue={this.handleRangeChanged} />,
            this.props.mountTo,
        );
    }
}

function resolveHref(cue, vttUrl) {
    try {
        const vtt = new URL(vttUrl, window.location.href);
        return new URL(cue.text, vtt.href).href;
    } catch (e) {
        console.error(e);
        return null;
    }
}
