/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {Dispatcher} from '~/src/flux-helpers';
import {NotificationActions} from '~/src/notification/notification-actions';
import Request from '~/src/request/index';

const Constants = {
    HEADER: {
        GET_HOMEPAGE_NAVIGATION_MENU_ITEMS: {
            ERROR: 'header_actions.get_homepage_navigation_menu_items.error',
            START: 'header_actions.get_homepage_navigation_menu_items.start',
            SUCCESS: 'header_actions.get_homepage_navigation_menu_items.success',
        }
    },
};

class HeaderActions {
    getHomepageNavigationMenuItems(currentUserHomepage) {
        if (!currentUserHomepage || !currentUserHomepage.navigationPublishListId) {
            NotificationActions.showAlertDanger('header.navbar.no-items-configured');
            return;
        }

        Dispatcher.dispatch({
            actionType: Constants.HEADER.GET_HOMEPAGE_NAVIGATION_MENU_ITEMS.START,
        });

        return Request.get(`web/publish-list/${currentUserHomepage.navigationPublishListId}/my-items`).then((listItemsRes) => {
            const publishListItems = listItemsRes.body.sort((a, b) => a.displayOrder - b.displayOrder);

            if (!publishListItems.length) {
                NotificationActions.showAlertDanger('header.navbar.no-items-configured');
            }

            Dispatcher.dispatch({
                actionType: Constants.HEADER.GET_HOMEPAGE_NAVIGATION_MENU_ITEMS.SUCCESS,
                items: Immutable.fromJS(publishListItems),
            });
        }).catch((err) => {
            console.error(err);

            NotificationActions.showAlertDanger('header.navbar.error');
            Dispatcher.dispatch({
                actionType: Constants.HEADER.GET_HOMEPAGE_NAVIGATION_MENU_ITEMS.ERROR,
            });
            throw err;
        });
    }
}

const actions = new HeaderActions();
export {
    actions as HeaderActions,
    Constants as HeaderConstants
};
