/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {Dispatcher} from '../../flux-helpers';
import Request from '../../request';
import SessionStore from '../../session/session-store';

const CONSTANTS = {
    LOGIN_CONTENT: {
        GET: {
            SUCCESS: 'landing-actions.login-contents.get.success'
        }
    }
};

class LandingActions {
    getLoginContent() {
        Request.get(
            'web/login-content'
        ).set(
            'WBTV-Partner-Id', SessionStore.getPartner().id
        ).set(
            'WBTV-Partner-Key', SessionStore.getPartner().key
        ).then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.LOGIN_CONTENT.GET.SUCCESS,
                loginBackgrounds: Immutable.fromJS(res.body.partnerLoginContents),
                defaultCopyright: res.body.defaultCopyright
            });

            return;
        }).catch(err => {
            throw err;
        });
    }
}

let actions = new LandingActions();
export {
    actions as LandingActions,
    CONSTANTS as LandingConstants
};
