/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {AssetTitleConstants} from './asset-title-actions';
import {Dispatcher} from '../../flux-helpers';

class AssetTitleStore extends ReduceStore {

    getInitialState() {
        let emptyAssets = Immutable.Map({
            results: Immutable.List(),
            totalCount: 0
        });

        return Immutable.Map({
            activePageLabel: 1,
            assetFacets: Immutable.List(),
            assetFacetCounts: Immutable.Map(),
            assetsLoaded: false,
            imagesSeasonCounts: Immutable.List(),
            currentImage: undefined,
            clips: emptyAssets,
            documentPdfUrl: '',
            documentId: null,
            documents: Immutable.Map({
                seasonEpisodesDocuments: Immutable.List(),
                stationDocuments: Immutable.List()
            }),
            entityId: null,
            entityType: null,
            images: Immutable.Map({
                keyArt: emptyAssets,
                outdoorArt: emptyAssets,
                online: emptyAssets,
                logo: emptyAssets,
                photographyStills: emptyAssets,
                gallery: emptyAssets,
                svod: emptyAssets,
                clientSamples: emptyAssets,
                animationProductionMaterials: emptyAssets,
                bts: emptyAssets,
                socialMedia: emptyAssets,
                events: emptyAssets,
                other: emptyAssets,
                hboMax: emptyAssets
            }),
            offset: 0,
            openSharedImage: undefined,
            pageNumber: 0,
            pageSize: 80,
            selectedVideo: Immutable.Map(),
            single: false,
            showShare: false,
            totalCount: 0,
            unstacked: Immutable.List(),
            videos: Immutable.Map({
                fullEpisodeOrFeature: emptyAssets,
                promo: emptyAssets,
                element: emptyAssets,
                behindTheScenes: emptyAssets,
                sales: emptyAssets,
                sample: emptyAssets
            }),
            videosIndex: Immutable.Map()
        });
    }

    reduce(state, action) {

        let findInVideos = (videoId, videosMap) => {
            let index;
            let videoSuperType = videosMap.findKey(videos => {
                index = videos.get('results').findIndex(v => v.get('assetId') === videoId);
                return index > -1;
            });

            // This means the videoId has not been found in videosMap.
            // If we allow the method to return videoSuperType = undefined
            // and index = -1, then that breaks the state.videos object.
            /* istanbul ignore next */
            if (videoSuperType === undefined) {
                const err = `Video with id ${videoId} has not been found in videoMap. This is probably because the video does not belong to this title.`;
                return {err};
            }

            return {
                videoSuperType,
                index
            };
        };

        switch (action.actionType) {
        case AssetTitleConstants.ASSET.ERROR:
            state = state.set('assetsLoaded', true);
            break;

        case AssetTitleConstants.ASSET.DOCUMENT.CLOSE:
            state = state.set('documentPdfUrl', '');
            state = state.set('documentId', null);
            break;

        case AssetTitleConstants.ASSET.DOCUMENT.VIEW:
            state = state.set('documentPdfUrl', action.url);
            state = state.set('documentId', action.id);
            break;

        case AssetTitleConstants.ASSET.LOADING:
            state = state.set('assetsLoaded', false);
            break;

        case AssetTitleConstants.ASSET.THUMBNAILS.GET.SUCCESS:
            // Let's find the video and add the thumbnails.
            action.thumbnails.forEach(t => {
                // Disable no-shadowing warnings.
                // eslint-disable-next-line
                let {videoSuperType, index, err} = findInVideos(t.get('videoId'), state.get('videos'));
                if (err) {
                    return;
                }
                // With the videoSuperType and the index in the results array
                // we can now set the thumbnails.
                state = state.setIn(['videos', videoSuperType, 'results', index, 'thumbnails'], t.get('thumbnailList'));
            });
            break;

        case AssetTitleConstants.CLIPS.GET.SUCCESS:
            state = state.merge({
                assetsLoaded: true,
                entityId: action.id,
                clips: action.clips,
            });
            break;

        case AssetTitleConstants.CLEAR:
            const assetFacetCounts = state.get('assetFacetCounts');
            state = this.getInitialState().set('assetFacetCounts', assetFacetCounts);
            break;

        case AssetTitleConstants.VIDEO.HIDE_SHARE:
            state = state.merge({
                showShare: false
            });
            break;

        case AssetTitleConstants.FACET_COUNTS.GET.SUCCESS:
            const facetCounts = Immutable.Map({
                keyArt: Immutable.Map(),
                outdoorArt: Immutable.Map(),
                online: Immutable.Map(),
                logo: Immutable.Map(),
                photographyStills: Immutable.Map(),
                gallery: Immutable.Map(),
                svod: Immutable.Map(),
                clientSamples: Immutable.Map(),
                animationProductionMaterials: Immutable.Map(),
                bts: Immutable.Map(),
                other: Immutable.Map(),
                fullEpisodeOrFeature: Immutable.Map(),
                promo: Immutable.Map(),
                element: Immutable.Map(),
                behindTheScenes: Immutable.Map(),
                sales: Immutable.Map(),
                sample: Immutable.Map(),
                socialMedia: Immutable.Map(),
                events: Immutable.Map(),
                hboMax: Immutable.Map()
            }).merge(action.facetCounts);
            state = state.setIn(['assetFacetCounts', action.titleId], facetCounts);
            state = state.set('assetFacets', action.assetFacets);
            break;

        case AssetTitleConstants.DOCUMENT.GET.START:
            if (
                action.id !== state.get('entityId') ||
                action.entityType !== state.get('entityType')
            ) {
                state = state.merge({
                    entityId: action.id,
                    entityType: action.entityType,
                    documents: this.getInitialState().get('documents'),
                    assetsLoaded: false
                });
            }
            break;

        case AssetTitleConstants.DOCUMENT.GET.SUCCESS:
            state = state.merge({
                assetsLoaded: true,
                entityId: action.id,
                entityType: action.entityType,
                documents: action.documents,
                single: action.single,
            });
            break;

        case AssetTitleConstants.DOCUMENT.GET_ALL_SEASON_EPISODE_DOCUMENTS:
            state = state.setIn(['documents', 'seasonEpisodeDocuments'], action.seasonEpisodesDocuments);
            break;

        case AssetTitleConstants.IMAGE.ADD_URL:
            state = state.update('images', images => {
                images = images.map(image => {
                    let newResults = image.get('results').map(results => {
                        if (results.get('assetId') === action.image.get('id')) {
                            return results.merge(action.image);
                        }
                        return results;
                    });
                    return image.set('results', newResults);
                });
                return images;
            });
            break;

        case AssetTitleConstants.IMAGE.GALLERY.CLOSE:
            state = state.remove('currentImage');
            break;

        case AssetTitleConstants.IMAGE.GALLERY.OPEN:
            state = state.merge({
                currentImage: action.image
            });
            break;

        case AssetTitleConstants.IMAGE.GET.START:
            if (
                action.id !== state.get('entityId') ||
                action.entityType !== state.get('entityType')
            ) {
                state = state.merge({
                    entityId: action.id,
                    entityType: action.entityType,
                    images: this.getInitialState().get('images'),
                    assetsLoaded: false
                });
            }
            break;

        case AssetTitleConstants.IMAGE.GET.SUCCESS:
            state = state.merge({
                assetsLoaded: true,
                entityId: action.id,
                entityType: action.entityType,
                images: action.images,
                openSharedImage: action.openSharedImage,
                single: action.single,
            });
            break;

        case AssetTitleConstants.IMAGE.LOAD_SINGLE:
            state = state.merge({
                openSharedImage: action.openSharedImage
            });
            break;

        case AssetTitleConstants.ASSET.UPDATE_PAGE.OFFSET:
            state = state.set('offset', action.offset);
            break;

        case AssetTitleConstants.ASSET.UPDATE_PAGE.SIZE:
            state = state.set('pageSize', action.pageSize).set('offset', 0);
            break;

        case AssetTitleConstants.VIDEO.SHOW_SHARE:
            state = state.merge({
                showShare: true,
                selectedVideo: action.video
            });
            break;

        case AssetTitleConstants.VIDEO.ADD_EXTRA_DATA:
            let {videoSuperType, index, err} = findInVideos(action.video.get('id'), state.get('videos'));
            if (err) {
                break;
            }
            state = state.mergeIn(
                ['videos', videoSuperType, 'results', index],
                {
                    assetComment: action.video.get('assetComment'),
                    deliveryType: action.video.get('deliveryType'),
                    disableVisualWatermark: action.video.get('disableVisualWatermark'),
                    disclaimerText: action.video.get('disclaimerText'),
                    drmAmsCencLicenseUrl: action.video.get('drmAmsCencLicenseUrl'),
                    drmAmsHlsFairplayLicenseUrl: action.video.get('drmAmsHlsFairplayLicenseUrl'),
                    drmHlsUrl: action.video.get('drmHlsUrl'),
                    drmMpegDashUrl: action.video.get('drmMpegDashUrl'),
                    forensicUrlRequired: action.video.get('forensicUrlRequired'),
                    frameRate: action.video.get('frameRate'),
                    hasDub: action.video.get('hasDub'),
                    hasSub: action.video.get('hasSub'),
                    isClippable: action.video.get('isClippable'),
                    language: action.video.get('language'),
                    mfaRequired: action.video.get('mfaRequired'),
                    mediaKey: action.video.get('mediaKey'),
                    persistentVisualWatermark: action.video.get('persistentVisualWatermark'),
                    rightsNotes: action.video.get('rightsNotes'),
                    texted: action.video.get('texted'),
                    textless: action.video.get('textless'),
                    runtime: action.video.get('runtime'),
                    videoRightsMediaType: action.video.get('videoRightsMediaType'),
                    videoRightsTermType: action.video.get('videoRightsTermType'),
                    videoRightsTerritoryType: action.video.get('videoRightsTerritoryType'),
                    videoRightsType: action.video.get('videoRightsType'),
                    updatedDate: action.video.get('updatedDate'),
                    watermarkText: action.video.get('watermarkText')
                }
            );
            break;

        case AssetTitleConstants.VIDEO.ADD_VIDEO_AUDIO_CONFIG_TYPES:
            let videoData = findInVideos(action.videoId, state.get('videos'));
            /* istanbul ignore next */
            if (videoData.err) {
                return;
            }

            const audioConfigTypesData = action.videoAudioConfigTypes.map(audioConfigId => {
                const audioConfigObject = Object.keys(AssetTitleConstants.VIDEO.AUDIO_CONFIG_TYPES).find(key => AssetTitleConstants.VIDEO.AUDIO_CONFIG_TYPES[key].id === audioConfigId);
                /* istanbul ignore else */
                if (audioConfigObject) {
                    return AssetTitleConstants.VIDEO.AUDIO_CONFIG_TYPES[audioConfigObject].name;
                }
            });
            // With the videoSuperType and the index in the results array
            // we can now set the audioConfigTyes.
            state = state.setIn(['videos', videoData.videoSuperType, 'results', videoData.index, 'audioConfigTypes'], audioConfigTypesData);
            break;

        case AssetTitleConstants.VIDEO.GET.SUCCESS:
            state = state.merge({
                single: action.single,
                videos: action.videos,
                assetsLoaded: true
            });

            break;
        }

        return state;
    }
}

export default new AssetTitleStore(Dispatcher);
