/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {LanguageCodeType, isStation} from '@wbdt-sie/brainiac-web-common';
import Promise from 'bluebird';
import Immutable from 'immutable';
import {Subject} from 'rxjs';

import {AssetTypeConstants} from '../../asset-types/asset-type-constants';
import {convertVideoFragmentToClip} from '../../common/clips/converters';
import {Dispatcher} from '../../flux-helpers';
import {NotificationActions} from '../../notification/notification-actions';
import Request from '../../request';
import keyBy from '../../utils/keyBy';
import pick from '../../utils/pick';
import {StartDownload} from '../../utils/utils';
import {TitleConstants} from '../title-actions';

const CONSTANTS = {
    ASSET: {
        ERROR: 'titles.asset.error',
        DOCUMENT: {
            CLOSE: 'titles.asset.document.close',
            VIEW: 'titles.asset.document.view'
        },
        LOADING: 'titles.asset.loading',
        THUMBNAILS: {
            GET: {
                SUCCESS: 'titles.videos.thumbnails.get.success'
            }
        },
        UPDATE_PAGE: {
            OFFSET: 'titles.asset.update_page.offset',
            SIZE: 'titles.asset.update_page.size'
        },
    },
    ASSET_CATEGORY: {
        IMAGE: 'IMAGE',
        VIDEO: 'VIDEO',
        STATIONS: 'STATIONS',
    },
    CLEAR: 'titles.videos.video.clear',
    CLIPS: {
        GET: {
            START: 'titles.moments.get.start',
            SUCCESS: 'titles.moments.get.success',
        },
    },
    CONTENT_TYPES: {
        DOCUMENT: {
            DOCUMENT_TYPE: {
                id: 1,
                key: 'documentType',
                TYPES: {//TODO refactor this to use @wbdt-sie/brainiac-web-common (\src\asset\document\content-types-constants.js)
                    AIR_SCHEDULES: '34',
                    FORMAT_SHEETS_PRIMARY_RUN: '42',
                    FORMAT_SHEETS_SECONDARY_RUN: '43',
                    FORMAT_SHEETS_TERTIARY_RUN: '44',
                    FORMAT_SHEETS_WEEKEND_PRIMARY_RUN: '46',
                    FORMAT_SHEETS_WEEKEND_SECONDARY_RUN: '47',
                    SEASON_PACKETS: '33',
                    REFEEDS: '36',
                    CASH_FORMATS: '38',
                    CONTACT_SHEETS: '35',
                    FORMAT_SHEETS_DAY_DELAY: '45',
                    PRODUCTION_TIMING_SHEETS: '41',
                    AIR_DATE_SCHEDULES: '32',
                    SHOW_INFO: '40',
                    TITLE_LISTS: '39',
                }
            },
        },
        IMAGE: {
            KEY_ART: {
                id: 1,
                key: 'keyArt',
                TYPES: {
                    ART_PROMO: '1',
                    BOX_ART: '2',
                    KEY_ART: '3',
                    KEY_ART_INTERNAITONAL_VERSION: '4',
                    KEY_ART_TELEVISION_SERIES: '5',
                    KET_ART_TEXTED: '6',
                    KEY_ART_TEXTLESS: '7',
                    KEY_ART_TITLED: '8',
                    KEY_ART_WITH_TITLE: '9',
                    VIDEO_BOX_ART: '10'
                }
            },
            OUTDOOR_ART: {
                id: 2,
                key: 'outdoorArt',
                TYPES: {
                    KISOK_ART: '11',
                    BILLBOARD: '12'
                }
            },
            ONLINE: {
                id: 3,
                key: 'online',
                TYPES: {
                    BANNER: '13',
                    WEB_SITE_OTHER: '15',
                    WEB_SITE_WBITV: '16',
                    WEB_SITE_WBITV_B2B: '17'
                }
            },
            LOGO: {
                id: 4,
                key: 'logo',
                TYPES: {
                    LOGO_ANIMATION: '18',
                    LOGO_MOVIDE_OF_WEEK: '19',
                    LOGO_NETWORK_STATION: '20',
                    LOGO_PRODUCTION_COMPANY: '21',
                    LOGO_SERIES: '22',
                    LOGO_THEATRICAL: '23',
                    LOGO_WB_DIVISION: '24',
                    TITLE_TREATMENT_ON_AIR: '25',
                    TITLE_TREATMENT_PRINT: '26'
                }
            },
            PHOTOGRAPHY_STILLS: {
                id: 5,
                key: 'photographyStills',
                TYPES: {
                    PHOTOGRAPHY: '27',
                    UNIT: '28',
                    EPISODIC: '29',
                    ANIMATED_STILLS: '30'
                }
            },
            GALLERY: {
                id: 6,
                key: 'gallery',
                TYPES: {
                    GALLERY: '31',
                    GALLERY_CAST: '32',
                    GALLERY_MUTIPLE: '33',
                    GALLERY_SINGLE: '34',
                    HEADSHOTS: '35',
                    SET: '36'
                }
            },
            SVOD: {
                id: 7,
                key: 'svod',
                TYPES: {
                    SVOD_VERTICAL_DISPLAY_ART: '37',
                    SVOD_HORIZONTAL_DISPLAY_ART: '38',
                    STORY_ART: '39'
                }
            },
            CLIENT_SAMPLES: {
                id: 8,
                key: 'clientSamples',
                TYPES: {
                    CLIENT_CREATED: '40'
                }
            },
            ANIMATION_PRODUCTON_MATERIALS: {
                id: 9,
                key: 'animationProductionMaterials',
                TYPES: {
                    ANIMATION_COMPOSITE: '41',
                    BACKGROUNDS: '42',
                    CHARACTER_STOCK: '43',
                    CHARACTER_ART: '44',
                    CHARACTER_ART_ANIMATION: '45',
                    CHARACTER_ART_GENERAL: '46'
                }
            },
            BTS: {
                id: 10,
                key: 'bts',
                TYPES: {
                    BEHIND_THE_SCENES: '47',
                }
            },
            EVENTS: {
                id: 13,
                key: 'events',
                TYPES: {
                    EVENTS: '48',
                    EVENTS_IJP: '49',
                    EVENTS_LA_SCREENINGS: '50',
                    EVENTS_MISC: '51'
                }
            },
            OTHER: {
                id: 11,
                key: 'other',
                TYPES: {
                    OTHER: '52',
                    AD_CONTEST: '53',
                    AD_COVER: '54',
                    AD_TRADE: '55',
                    ADVERTISING: '56',
                    ART_MERCHANDISE: '57',
                    MERCHANDISE_ART: '58',
                    MERCHANDISE: '59',
                    BAGS: '60',
                    CONTENT_ASSET_LIBRARY: '61',
                    CUSTOM_DVD_PACKAGING: '62',
                    ILLUSTRATOR_TEMPLATE: '63',
                    INSERT_DVD: '64',
                    MARKETING_SALES_KIT: '65',
                    PHOTOSHOP_ACTION: '66',
                    PRODUCT: '67',
                    PRMOTIONAL_PACKAGING: '68',
                    STOCK: '69',
                    STOCK_WB_COPYRIGHT: '70',
                    TEASER_CAMPAIGN: '71'
                }
            },
            SOCIAL_MEDIA: {
                id: 12,
                key: 'socialMedia',
                TYPES: {
                    GIF: '72',
                    COUNTDOWN_CARD: '73',
                    SOCIAL_MEDIA_CLIP: '74',
                    CHARACTER_CARD: '75'
                }
            },
            HBO_MAX: {
                id: 14,
                key: 'hboMax',
                TYPES: {
                    TILE: '76',
                    TILE_BURNED_IN: '77',
                    BACKGROUND: '78',
                    LOGO_HBO_MAX: '79',
                    UNIVERSAL_SEARCH: '80',
                    EPISODIC_TILE: '81'
                }
            }
        },
        VIDEO: {
            FULL_EPISODE_OR_FEATURE: {
                id: 3,
                key: 'fullEpisodeOrFeature',
                TYPES: {
                    FULL_EPISODE: '101', // Important! Content types are treated as Strings because they come from the URL.
                    FULL_PROGRAM: '102', // if we ever need to send this values back to the server, then we shoul convert to Integer.
                    FEATURE: '103'
                }
            },
            PROMO: {
                id: 4,
                key: 'promo',
                TYPES: {
                    SERIES_LAUNCH_PROMO: '121',
                    SEASON_LAUNCH_PROMO: '122',
                    MID_SEASON_PROMO: '123',
                    EPISODIC_PROMO: '124',
                    GENERIC_PROMO: '125',
                    TEASER: '126',
                    TRAILER: '127',
                    NEXT_ON: '128',
                    RECAP: '129',
                    TV_SPOT: '130',
                    SNEAK_PEEK: '131'
                }
            },
            ELEMENT: {
                id: 2,
                key: 'element',
                TYPES: {
                    GREEN_SCREEN: '141',
                    CLIP: '142',
                    TAGS: '143',
                    B_ROLL: '144',
                    GRAPHIC: '145',
                    ELEMENT: '146',
                    SOURCE_MATERIALS: '147',
                    INTERSTITIAL: '148',
                    CLIP_REEL: '149',
                    VAM: '150',
                    TEXTLESS_MATERIALS: '151',
                    MUSIC: '152'
                }
            },
            BEHIND_THE_SCENES: {
                id: 1,
                key: 'behindTheScenes',
                TYPES: {
                    EPK: '161',
                    FEATURETTE: '162',
                    INTERVIEW: '163',
                    PROMOTIONAL_SPECIAL: '164',
                    BTS_FINISHED: '165',
                    PANEL: '167',
                    EVENT_FOOTAGE: '168',
                    OPEN_ENDS: '169',
                    WEBISODE: '170'
                }
            },
            SALES: {
                id: 5,
                key: 'sales',
                TYPES: {
                    SALES_PRESENTATION: '181',
                    SIZZLE_REEL: '182',
                    BRAND_EXTENSION: '183',
                    INTEGREATION_CLIP: '184',
                    SALES_KIT: '185',
                    MARKETING_KIT: '186'
                }
            },
            SAMPLE: {
                id: 6,
                key: 'sample',
                TYPES: {
                    CLIENT_PRODUCED_SAMPLES: '201',
                    US_NETWORK_PROMOS: '202'
                }
            }
        }
    },
    ENTITY_TYPE: {
        TALENT: 'talent',
        TITLE: 'title'
    },
    FACET_COUNTS: {
        GET: {
            SUCCESS: 'titles.facet_counts.get.success'
        }
    },
    DOCUMENT: {
        GET: {
            START: 'titles.document.get.start',
            SUCCESS: 'titles.document.get.success',
        },
        GET_ALL_SEASON_EPISODE_DOCUMENTS: 'titles.document.get_all_season_episode_documents'
    },
    IMAGE: {
        ADD_URL: 'titles.images.image.add_url',
        GET_ALL: {
            ERROR: 'titles.images.image.get_all.error',
            START: 'titles.images.image.get_all.start',
            SUCCESS: 'titles.images.image.get_all.success'
        },
        GALLERY: {
            CLOSE: 'titles.images.gallery.close',
            OPEN: 'titles.images.gallery.open'
        },
        GET: {
            START: 'titles.images.get.start',
            SUCCESS: 'titles.images.get.success',
        },
        GROUP_KEY: {
            TITLE_IMAGE: 'titleImage',
            RELATED_IMAGE: 'relatedImage'
        },
        LOAD_SINGLE: 'titles.images.image.load.single'
    },
    VIDEO: {
        AUDIO_CONFIG_TYPES: {
            STEREO_MIX: {id: 1, name: 'Stereo Mix'},
            FIVE_POINT_ONE_MIX: {id: 2, name: '5.1 Mix'},
            MONO: {id: 3, name: 'MONO'},
            MOS: {id: 4, name: 'Mos'},
            SOT: {id: 5, name: 'Sot'},
            SPLIT: {id: 6, name: 'Split'}
        },
        ADD_EXTRA_DATA: 'titles.videos.add_extra_data',
        ADD_VIDEO_AUDIO_CONFIG_TYPES: 'titles.videos.add_video_audio_configuration_Types',
        GET: {
            START: 'titles.videos.get.start',
            SUCCESS: 'titles.videos.get.success'
        },
        LANGUAGES: LanguageCodeType.toArray(),
        HIDE_SHARE: 'titles.videos.share.hide',
        SHOW_SHARE: 'titles.videos.share.show'
    }
};

// This code uses side-effects.
// The functional alternative, while elegant, is way larger
// and doesn't make any sense.
// This code adds an ALL property to each content type listing
// all the sub-types.

// Documents
Object.values(CONSTANTS.CONTENT_TYPES.DOCUMENT).forEach(ct => {
    ct.ALL = Object.values(ct.TYPES);
});

CONSTANTS.DOCUMENT.MAP_CONTENT_TYPE_TO_SUPER_TYPE_KEY = Object.values(CONSTANTS.CONTENT_TYPES.DOCUMENT).reduce((r, contentType) => {
    contentType.ALL.forEach(ct => {
        r[ct] = contentType.key;
    });

    return r;
}, {});

CONSTANTS.DOCUMENT.DEFAULT_CONTENT_TYPE_FILTERS = [].concat(
    CONSTANTS.CONTENT_TYPES.DOCUMENT.DOCUMENT_TYPE.ALL,
);

// Images
Object.values(CONSTANTS.CONTENT_TYPES.IMAGE).forEach(ct => {
    ct.ALL = Object.values(ct.TYPES);
});

CONSTANTS.IMAGE.MAP_CONTENT_TYPE_TO_SUPER_TYPE_KEY = Object.values(CONSTANTS.CONTENT_TYPES.IMAGE).reduce((r, contentType) => {
    contentType.ALL.forEach(ct => {
        r[ct] = contentType.key;
    });

    return r;
}, {});

CONSTANTS.IMAGE.DEFAULT_CONTENT_TYPE_FILTERS = [].concat(
    CONSTANTS.CONTENT_TYPES.IMAGE.LOGO.ALL,
    CONSTANTS.CONTENT_TYPES.IMAGE.KEY_ART.ALL,
    CONSTANTS.CONTENT_TYPES.IMAGE.OUTDOOR_ART.ALL,
    CONSTANTS.CONTENT_TYPES.IMAGE.ONLINE.ALL,
    CONSTANTS.CONTENT_TYPES.IMAGE.SVOD.ALL,
    CONSTANTS.CONTENT_TYPES.IMAGE.GALLERY.ALL,
    CONSTANTS.CONTENT_TYPES.IMAGE.CLIENT_SAMPLES.ALL,
    CONSTANTS.CONTENT_TYPES.IMAGE.ANIMATION_PRODUCTON_MATERIALS.ALL,
    CONSTANTS.CONTENT_TYPES.IMAGE.BTS.ALL,
    CONSTANTS.CONTENT_TYPES.IMAGE.EVENTS.ALL,
    CONSTANTS.CONTENT_TYPES.IMAGE.PHOTOGRAPHY_STILLS.ALL,
    CONSTANTS.CONTENT_TYPES.IMAGE.OTHER.ALL,
    CONSTANTS.CONTENT_TYPES.IMAGE.SOCIAL_MEDIA.ALL,
    CONSTANTS.CONTENT_TYPES.IMAGE.HBO_MAX.ALL
);

// Videos
Object.values(CONSTANTS.CONTENT_TYPES.VIDEO).forEach(ct => {
    ct.ALL = Object.values(ct.TYPES);
});

CONSTANTS.VIDEO.MAP_CONTENT_TYPE_TO_SUPER_TYPE_KEY = Object.values(CONSTANTS.CONTENT_TYPES.VIDEO).reduce((r, contentType) => {
    contentType.ALL.forEach(ct => {
        r[ct] = contentType.key;
    });

    return r;
}, {});

CONSTANTS.VIDEO.DEFAULT_CONTENT_TYPE_FILTERS = [].concat(
    CONSTANTS.CONTENT_TYPES.VIDEO.FULL_EPISODE_OR_FEATURE.ALL,
    CONSTANTS.CONTENT_TYPES.VIDEO.PROMO.ALL,
    CONSTANTS.CONTENT_TYPES.VIDEO.ELEMENT.ALL,
    CONSTANTS.CONTENT_TYPES.VIDEO.BEHIND_THE_SCENES.ALL,
    CONSTANTS.CONTENT_TYPES.VIDEO.SALES.ALL,
    CONSTANTS.CONTENT_TYPES.VIDEO.SAMPLE.ALL
);

class AssetTitleActions {

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
        return;
    }

    closeGallery() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.IMAGE.GALLERY.CLOSE
        });
        return;
    }

    closePdfPreview() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ASSET.DOCUMENT.CLOSE
        });
    }

    downloadAsset(assetId) {
        return Request.get(`asset/${assetId}/download-url`).then((res) => {
            // Start the download by simulating a click in the browser.
            StartDownload(res.body.downloadUrl);
        }).catch(err => {
            NotificationActions.showAlertDanger('asset.download.error');
            throw err;
        });
    }

    downloadImageRendition(imageId, renditionType, viewFile) {
        return Request.get('asset/download').query({
            'asset-id': imageId,
            'rendition-type': renditionType,
        }).then((res) => {
            // Start the download by simulating a click in the browser.
            if (viewFile && res.body.downloadUrl.match(/.+?(?=\?)/)[0].toLowerCase().endsWith('.pdf')) {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.ASSET.DOCUMENT.VIEW,
                    url: res.body.downloadUrl,
                    id: imageId
                });
            } else {
                StartDownload(res.body.downloadUrl);
            }
        }).catch(err => {
            NotificationActions.showAlertDanger('asset.download.error');
            throw err;
        });
    }

    filterByGroupKey(assets, groupKey) {
        return assets.filter( asset => {
            return asset.get('groupKey') === groupKey;
        });
    }

    // this is used for talent images
    get(entityType, id, openSharedImageId, userId, offset = 0, size = 80) {
        let rxImage = this.getTitleAssetsImages(entityType, id, openSharedImageId, userId, null, offset, size);
        rxImage.subscribe(
            (event) => {
                Dispatcher.dispatch(event);
            }
        );
    }

    getAllSeasonEpisodesDocuments(titleIds, offset = 0, size = 80) {
        let docRequests = titleIds.map(id => {
            return Request.get(`title/${id}/web/asset?asset-type=21&offset=${offset}&size=${size}`);
        });
        Promise.all(docRequests).then(documentRes => {
            let seasonEpisodesDocuments = [];
            documentRes.forEach(res => {
                if (res.body.results.length) {
                    res.body.results.forEach(doc => {
                        if (!isStation(doc)) {
                            seasonEpisodesDocuments.push(doc);
                        }
                    });
                }
            });
            Dispatcher.dispatch({
                actionType: CONSTANTS.DOCUMENT.GET_ALL_SEASON_EPISODE_DOCUMENTS,
                seasonEpisodesDocuments: Immutable.fromJS(seasonEpisodesDocuments)
            });
        }).catch(err => {
            console.log(err);
            return undefined;
        });
    }

    getTitleAssetsClips(titleId, {offset = 0, searchTerm = '', size = 80, sortFieldName = '', sortDirection = '', ...facets}, needToLoadSeasonsCounts = false) {
        if (!searchTerm.trim()) {
            Dispatcher.dispatch({
                actionType: CONSTANTS.CLIPS.GET.SUCCESS,
                clips: Immutable.fromJS({results: [], totalCount : 0}),
                entityType: CONSTANTS.ENTITY_TYPE.TITLE,
                id: titleId,
            });
            return;
        }

        const query = {
            'is-clippable': true,
            offset,
            size,
            'search-term': searchTerm,
            'sort-field-name': sortFieldName,
            'sort-direction': sortDirection,
            'title-id': titleId,
            ...facets,
        };

        let seasonCountsReq = Promise.resolve({body: {}});
        const seasonsCountQuery = pick(query, ['is-clippable', 'search-term', 'title-id']);
        if (needToLoadSeasonsCounts) {
            seasonCountsReq = Request.get('asset/video-search/series-season-count').query(seasonsCountQuery);
        }

        Promise.all([
            Request.get('asset/video-search').query(query),
            seasonCountsReq,
        ]).spread((videoSearchResp, seasonCountsResp) => {
            let thumbnailsReq = {body: []};
            /* istanbul ignore else */
            if (videoSearchResp.body.results.length) {
                const map = keyBy('videoId', videoSearchResp.body.results);
                thumbnailsReq = Request.get('asset/video/thumbnailURL').query({'video-id': Object.keys(map)});
            }
            if (needToLoadSeasonsCounts) {
                Dispatcher.dispatch({
                    actionType: TitleConstants.TITLE.SEASON_SINGLE_ASSET_COUNT.SUCCESS,
                    assetType: AssetTypeConstants.ASSET_TYPE.VIDEO_USER_CLIP,
                    assetCount: Immutable.fromJS(seasonCountsResp.body),
                });
            }
            return Promise.all([videoSearchResp.body, thumbnailsReq]);
        }).spread((clipsReq, thumbnailsReq) => {
            const thumbnails = keyBy('videoId', thumbnailsReq.body);

            const results = clipsReq.results.map((clip) => convertVideoFragmentToClip(clip, thumbnails));

            Dispatcher.dispatch({
                actionType: CONSTANTS.CLIPS.GET.SUCCESS,
                clips: Immutable.fromJS({results, totalCount: clipsReq.totalCount}),
                entityType: CONSTANTS.ENTITY_TYPE.TITLE,
                id: titleId,
            });
        }).catch(/* istanbul ignore next */(err) => {
            throw err;
        });
    }

    /* istanbul ignore next */
    getTitleAssetsDocuments(entityType,
        id, userId,
        // eslint-disable-next-line default-param-last
        contentTypeFilters = CONSTANTS.DOCUMENT.DEFAULT_CONTENT_TYPE_FILTERS,
        // eslint-disable-next-line default-param-last
        offset = 0, size = 9999, canceler, sortFieldName, sortDirection, formatRunWeek) {

        let rxDocument = new Subject();
        setTimeout(() => {
            // to avoid double dispatch :(
            rxDocument.next({
                actionType: CONSTANTS.DOCUMENT.GET.START,
                entityType: entityType,
                id: id,
            });
        }, 0);

        if (!Array.isArray(contentTypeFilters)) {
            contentTypeFilters = [contentTypeFilters];
        }

        const getSummaryAsset = (filters, o, s) => {
            if (filters.size === 0) {
                return Promise.resolve({body: {results: [], totalCount: 0}});
            }

            let query = {
                'asset-type': AssetTypeConstants.ASSET_TYPE.DOCUMENT,
                'content-type': filters.toArray(),
                jp: userId,
                offset: o,
                size: s,
                'sort-field-name': sortFieldName,
                'sort-direction': sortDirection,
                'format-run-week': formatRunWeek
            };

            if (
                sortFieldName === 'relevance' ||
                !sortFieldName
            ) {
                delete query['sort-field-name'];
                delete query['sort-direction'];
            }

            return Request.get(`${entityType}/${id}/web/asset`).query(query).canceler(canceler);
        };


        let contentTypeFiltersSet = Immutable.Set(contentTypeFilters);

        const filterF = (all) => {
            return Immutable.Set(all.map(v => v.toString())).intersect(contentTypeFiltersSet);
        };

        let stationDocuments = filterF(CONSTANTS.CONTENT_TYPES.DOCUMENT.DOCUMENT_TYPE.ALL);

        let single = [stationDocuments].reduce ( (r, e) => {
            let elementSize = 0;
            if (e.size) {
                elementSize = 1;
            }
            return r + (elementSize);
        }, 0) === 1;

        // single category show full page
        // more than one category or zero only first 4 per category
        if (!single) {
            offset = 0;
            size = 6;
        }

        const filterDocuments = (documents) => {
            documents.results = documents.results.filter( i => i.isActive === 1);
            return documents;
        };

        Promise.props({
            stationDocuments: getSummaryAsset(stationDocuments, offset, size),
        }).then(documentsRes => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.DOCUMENT.GET.SUCCESS,
                documents: Immutable.fromJS({
                    stationDocuments: filterDocuments(documentsRes.stationDocuments.body).results,
                }),
                single,
                entityType: entityType,
                id: id
            });
            rxDocument.complete();
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.ASSET.ERROR
            });
            throw err;
        });

        return rxDocument;
    }

    getTitleAssetsImages(entityType,
        id, openSharedImageId, userId,
        // eslint-disable-next-line default-param-last
        contentTypeFilters = CONSTANTS.IMAGE.DEFAULT_CONTENT_TYPE_FILTERS,
        // eslint-disable-next-line default-param-last
        offset = 0, size = 80, canceler, sortFieldName, sortDirection) {
        let rxImage = new Subject();
        setTimeout(() => {
            // to avoid double dispatch :(
            rxImage.next({
                actionType: CONSTANTS.IMAGE.GET.START,
                entityType: entityType,
                id: id,
            });
        }, 0);

        if (!Array.isArray(contentTypeFilters)) {
            contentTypeFilters = [contentTypeFilters];
        }

        let getSummaryAsset = (filters, o, s) => {

            if (filters.size === 0) {
                return Promise.resolve({body: {results: [], totalCount: 0}});
            }

            let query = {
                'asset-type': AssetTypeConstants.ASSET_TYPE.IMAGE,
                'content-type': filters.toArray(),
                jp: userId,
                offset: o,
                size: s,
                'sort-field-name': sortFieldName,
                'sort-direction': sortDirection
            };

            if (
                sortFieldName === 'relevance' ||
                !sortFieldName
            ) {
                delete query['sort-field-name'];
                delete query['sort-direction'];
            }

            return Request.get(`${entityType}/${id}/web/asset`).query(query).canceler(canceler);
        };


        let contentTypeFiltersSet = Immutable.Set(contentTypeFilters);

        let filterF = (all) => {
            return Immutable.Set(all.map(v => v.toString())).intersect(contentTypeFiltersSet);
        };

        let logo = filterF(CONSTANTS.CONTENT_TYPES.IMAGE.LOGO.ALL);
        let keyArt = filterF(CONSTANTS.CONTENT_TYPES.IMAGE.KEY_ART.ALL);
        let outdoorArt = filterF(CONSTANTS.CONTENT_TYPES.IMAGE.OUTDOOR_ART.ALL);
        let online = filterF(CONSTANTS.CONTENT_TYPES.IMAGE.ONLINE.ALL);
        let svod = filterF(CONSTANTS.CONTENT_TYPES.IMAGE.SVOD.ALL);
        let gallery = filterF(CONSTANTS.CONTENT_TYPES.IMAGE.GALLERY.ALL);
        let clientSamples = filterF(CONSTANTS.CONTENT_TYPES.IMAGE.CLIENT_SAMPLES.ALL);
        let animationProductionMaterials = filterF(CONSTANTS.CONTENT_TYPES.IMAGE.ANIMATION_PRODUCTON_MATERIALS.ALL);
        let bts = filterF(CONSTANTS.CONTENT_TYPES.IMAGE.BTS.ALL);
        let photographyStills = filterF(CONSTANTS.CONTENT_TYPES.IMAGE.PHOTOGRAPHY_STILLS.ALL);
        let other = filterF(CONSTANTS.CONTENT_TYPES.IMAGE.OTHER.ALL);
        let socialMedia = filterF(CONSTANTS.CONTENT_TYPES.IMAGE.SOCIAL_MEDIA.ALL);
        let events = filterF(CONSTANTS.CONTENT_TYPES.IMAGE.EVENTS.ALL);
        let hboMax = filterF(CONSTANTS.CONTENT_TYPES.IMAGE.HBO_MAX.ALL);

        let single = [logo, keyArt, outdoorArt, online, svod, gallery, clientSamples, animationProductionMaterials, bts, photographyStills, other, socialMedia, events, hboMax].reduce ( (r, e) => {
            let elementSize = 0;
            if (e.size) {
                elementSize = 1;
            }
            return r + (elementSize);
        }, 0) === 1;

        // single category show full page
        // more than one category or zero only first 4 per category
        if (!single) {
            offset = 0;
            size = 6;
        }

        const filterImg = (imgs) => {
            imgs.results = imgs.results.filter( i => i.isActive === 1).map(img => {
                if (entityType === CONSTANTS.ENTITY_TYPE.TITLE) {
                    let imageGroupKeyConstant = CONSTANTS.IMAGE.GROUP_KEY.RELATED_IMAGE;
                    if (img.titleId === id) {
                        imageGroupKeyConstant = CONSTANTS.IMAGE.GROUP_KEY.TITLE_IMAGE;
                    }
                    img.groupKey = imageGroupKeyConstant;
                }
                return img;
            });
            return imgs;
        };

        Promise.props({
            logo: getSummaryAsset(logo, offset, size),
            keyArt: getSummaryAsset(keyArt, offset, size),
            outdoorArt: getSummaryAsset(outdoorArt, offset, size),
            online: getSummaryAsset(online, offset, size),
            svod: getSummaryAsset(svod, offset, size),
            gallery: getSummaryAsset(gallery, offset, size),
            clientSamples: getSummaryAsset(clientSamples, offset, size),
            animationProductionMaterials: getSummaryAsset(animationProductionMaterials, offset, size),
            bts: getSummaryAsset(bts, offset, size),
            photographyStills: getSummaryAsset(photographyStills, offset, size),
            other: getSummaryAsset(other, offset, size),
            socialMedia: getSummaryAsset(socialMedia, offset, size),
            events: getSummaryAsset(events, offset, size),
            hboMax: getSummaryAsset(hboMax, offset, size)
        }).then(imagesRes => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.IMAGE.GET.SUCCESS,
                images: Immutable.fromJS({
                    logo: filterImg(imagesRes.logo.body),
                    keyArt: filterImg(imagesRes.keyArt.body),
                    outdoorArt: filterImg(imagesRes.outdoorArt.body),
                    online: filterImg(imagesRes.online.body),
                    svod: filterImg(imagesRes.svod.body),
                    gallery: filterImg(imagesRes.gallery.body),
                    clientSamples: filterImg(imagesRes.clientSamples.body),
                    animationProductionMaterials: filterImg(imagesRes.animationProductionMaterials.body),
                    bts: filterImg(imagesRes.bts.body),
                    photographyStills: filterImg(imagesRes.photographyStills.body),
                    other: filterImg(imagesRes.other.body),
                    socialMedia: filterImg(imagesRes.socialMedia.body),
                    events: filterImg(imagesRes.events.body),
                    hboMax: filterImg(imagesRes.hboMax.body)
                }),
                single,
                entityType: entityType,
                id: id
            });
            rxImage.complete();
            if (openSharedImageId) {
                this.loadSharedImage(entityType, id, openSharedImageId);
            }
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.ASSET.ERROR
            });
            throw err;
        });

        return rxImage;
    }

    /* istanbul ignore next */
    getTitleAssetsVideos(entityType,
        id, userId,
        // eslint-disable-next-line default-param-last
        contentTypeFilters = CONSTANTS.VIDEO.DEFAULT_CONTENT_TYPE_FILTERS,
        // eslint-disable-next-line default-param-last
        offset = 0, size = 80,
        sortFieldName, sortDirection
    ) {

        if (!Array.isArray(contentTypeFilters)) {
            contentTypeFilters = [contentTypeFilters];
        }

        let getSummaryAsset = (filters, o, s) => {
            if (filters.size === 0) {
                return Promise.resolve({body: {results: [], totalCount: 0}});
            }

            //check if full episode / features has empty sortFieldName, then set sort by air date and desc order
            const fullEpisodeOrFeaturesTypes = Object.values(CONSTANTS.CONTENT_TYPES.VIDEO.FULL_EPISODE_OR_FEATURE.TYPES);
            if (filters.some(f => fullEpisodeOrFeaturesTypes.includes(f)) && !sortFieldName) {
                sortFieldName = 'titlereleasedate';
                sortDirection = 'desc';
            }

            let query = {
                'asset-type': AssetTypeConstants.ASSET_TYPE.VIDEO,
                'content-type': filters.toArray(),
                jp: userId,
                offset: o,
                size: s,
                'sort-field-name': sortFieldName,
                'sort-direction': sortDirection
            };

            if (
                sortFieldName === 'relevance' ||
                !sortFieldName
            ) {
                delete query['sort-field-name'];
                delete query['sort-direction'];
            }

            return Request.get(`${entityType}/${id}/web/asset`).query(query);
        };

        let contentTypeFiltersSet = Immutable.Set(contentTypeFilters);

        let filterF = (all) => {
            return Immutable.Set(all.map(v => v.toString())).intersect(contentTypeFiltersSet);
        };

        let fullEpisodeOrFeature = filterF(CONSTANTS.CONTENT_TYPES.VIDEO.FULL_EPISODE_OR_FEATURE.ALL);
        let promo = filterF(CONSTANTS.CONTENT_TYPES.VIDEO.PROMO.ALL);
        let element = filterF(CONSTANTS.CONTENT_TYPES.VIDEO.ELEMENT.ALL);
        let behindTheScenes = filterF(CONSTANTS.CONTENT_TYPES.VIDEO.BEHIND_THE_SCENES.ALL);
        let sales = filterF(CONSTANTS.CONTENT_TYPES.VIDEO.SALES.ALL);
        let sample = filterF(CONSTANTS.CONTENT_TYPES.VIDEO.SAMPLE.ALL);

        let single = [fullEpisodeOrFeature, promo, element, behindTheScenes, sales, sample].reduce ( (r, e) => {
            let elementSize = 0;
            if (e.size) {
                elementSize = 1;
            }
            return r + (elementSize);
        }, 0) === 1;

        // single category show full page
        // more than one category or zero only first 4 per category
        if (!single) {
            offset = 0;
            size = 4;
        }

        Promise.props({
            fullEpisodeOrFeature: getSummaryAsset(fullEpisodeOrFeature, offset, size),
            promo: getSummaryAsset(promo, offset, size),
            element: getSummaryAsset(element, offset, size),
            behindTheScenes: getSummaryAsset(behindTheScenes, offset, size),
            sales: getSummaryAsset(sales, offset, size),
            sample: getSummaryAsset(sample, offset, size)
        }).then(videosRes => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.VIDEO.GET.SUCCESS,
                videos: Immutable.fromJS({
                    fullEpisodeOrFeature: videosRes.fullEpisodeOrFeature.body,
                    promo: videosRes.promo.body,
                    element: videosRes.element.body,
                    behindTheScenes: videosRes.behindTheScenes.body,
                    sales: videosRes.sales.body,
                    sample: videosRes.sample.body
                }),
                single
            });

            // Get the ids in order to request the thumbnails.
            let videoIds = [].concat(...Object.keys(videosRes).map(
                videoSuperType => videosRes[videoSuperType].body.results.map(
                    v => v.assetId
                )
            ));

            let breakInGroups = (arr, n) => {
                let groups = [];

                for (let i = 0; i < arr.length; i += n) {
                    groups.push(arr.slice(i, i + n));
                }

                return groups;
            };

            // Request thumbnails in groups of 10 videos.
            let promises = breakInGroups(videoIds, 10).map(
                ids => Request.get('asset/video/thumbnailURL').query({'video-id': ids}).then(urls => {
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.ASSET.THUMBNAILS.GET.SUCCESS,
                        thumbnails: Immutable.fromJS(urls.body),
                    });
                    return;
                })
            );

            // Also get all videos in the page (worst case, there will be 80)
            promises = promises.concat(videoIds.map(
                videoId => Request.get(`asset/video/${videoId}`).then(videoRes => {
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.VIDEO.ADD_EXTRA_DATA,
                        video: Immutable.fromJS(videoRes.body),
                    });
                })
            ));

            // Also get videoAudioConfiguration types for each video
            promises = promises.concat(videoIds.map(
                videoId => Request.get(`asset/video/${videoId}/audio-configuration`).then(videoAudioConfigTypesRes => {
                    if (videoAudioConfigTypesRes.body.length) {
                        Dispatcher.dispatch({
                            actionType: CONSTANTS.VIDEO.ADD_VIDEO_AUDIO_CONFIG_TYPES,
                            videoId,
                            videoAudioConfigTypes: videoAudioConfigTypesRes.body,
                        });
                    }
                }).catch(err => {
                    console.error(err);
                    NotificationActions.showAlertWarning('orders.get.audio-config-types.error');
                })
            ));

            return promises;
        });

        return;
    }

    /* istanbul ignore next */
    getTitleAssetFacetCounts(id, assetType, runWeek) {
        Request.get(`title/${id}/web/series-season-count`).query({
            'asset-type': AssetTypeConstants.ASSET_TYPE[assetType]
        }).then(assetCountRes => {
            const parentTitle = assetCountRes.body.find(title => title.orderWithinParent === -1);

            let facetCounts = {
                // image filters
                keyArt: {},
                outdoorArt: {},
                online: {},
                logo: {},
                photographyStills: {},
                gallery: {},
                svod: {},
                clientSamples: {},
                animationProductionMaterials: {},
                bts: {},
                other: {},
                socialMedia: {},
                events: {},
                hboMax: {},
                // video filters
                fullEpisodeOrFeature: {},
                promo: {},
                element: {},
                behindTheScenes: {},
                sales: {},
                sample: {},
            };

            parentTitle.assetFacetsCounts.forEach(facetCount => {
                const facetName = CONSTANTS[assetType].MAP_CONTENT_TYPE_TO_SUPER_TYPE_KEY[facetCount.contentType];
                if (!runWeek) {

                    if (!facetCounts[facetName]) {
                        facetCounts[facetName] = {};
                    }
                    if (!facetCounts[facetName][facetCount.contentType]) {
                        facetCounts[facetName][facetCount.contentType] = 0;
                    }
                    facetCounts[facetName][facetCount.contentType] += facetCount.count;
                } else if (facetCount.secondaryGroup === runWeek) {//doing a manual filtering
                    if (!facetCounts[facetName]) {
                        facetCounts[facetName] = {};
                    }
                    if (!facetCounts[facetName][facetCount.contentType]) {
                        facetCounts[facetName][facetCount.contentType] = 0;
                    }
                    facetCounts[facetName][facetCount.contentType] += facetCount.count;
                }
                return;
            });

            Dispatcher.dispatch({
                actionType: CONSTANTS.FACET_COUNTS.GET.SUCCESS,
                asset: assetType,
                titleId: id,
                assetFacets: Immutable.fromJS(parentTitle.assetFacetsCounts),
                facetCounts: Immutable.fromJS(facetCounts)
            });
        });
        return;
    }

    hideShare() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO.HIDE_SHARE
        });
    }

    loading() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ASSET.LOADING
        });
    }

    loadSharedImage(entityType, id, openSharedImageId) {
        return Request.get(`asset/image/${openSharedImageId}`).then((openSharedImage) => {
            openSharedImage = openSharedImage.body;
            // FIXME use only image object
            // to make it look like it came from web/asset endpoint
            let asset = Object.assign({}, openSharedImage);
            asset.imageId = openSharedImage.id;

            openSharedImage.assetId = openSharedImage.id;
            openSharedImage[`${entityType}Id`] = id;
            openSharedImage.asset = asset;

            Dispatcher.dispatch({
                actionType: CONSTANTS.IMAGE.LOAD_SINGLE,
                openSharedImage: Immutable.fromJS(openSharedImage),
            });
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.ASSET.ERROR
            });
            throw err;
        });
    }

    openGallery(image) {
        setTimeout(() => {
            // to avoid double dispatch :(
            Dispatcher.dispatch({
                actionType: CONSTANTS.IMAGE.GALLERY.OPEN,
                image
            });
        }, 0);
        return;
    }

    requestUrl(image) {
        Request.get(`asset/image/${(image.get('assetId'))}`).then(assetRes => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.IMAGE.ADD_URL,
                image: Immutable.fromJS(assetRes.body)
            });

            return;
        });

        return;
    }

    requestUrlsAndOpenGallery(images, currentImage) {
        let promises = [];
        images.forEach(image => {
            if (image.get('previewUrl') !== undefined) {
                return;
            }
            promises.push(this.requestUrl(image));
        });

        return Promise.all(promises).then(() => {
            this.openGallery(currentImage);
            return;
        });
    }

    showShare(video) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.VIDEO.SHOW_SHARE,
            video: video
        });
    }

    updatePageSize(size) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ASSET.UPDATE_PAGE.SIZE,
            pageSize: size
        });
        return;
    }
}

let actions = new AssetTitleActions();

export {
    actions as AssetTitleActions,
    CONSTANTS as AssetTitleConstants
};
