/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Bitmovin, {Player, PlayerEvent, ViewMode} from 'bitmovin-player';
import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import ClippablePlayer from './clippable-player';
import {createHotkeyControllerFromBitmovin} from './player-controller-factory';
import {PlayerUIFactory} from './player-ui-factory';
import Watermarks from './watermark/BitmovinPlayer';
import initBitmovinMux from '../../third-party-modules/bitmovin-mux';
import Analytics from '../analytics';
import {WBTVDAnalyticsConstants} from '../analytics/wbtvd-analytics';
import {AssetTypeConstants} from '../asset-types/asset-type-constants';
import Config from '../config/config';
import {MessagesContext} from '../messages/messages-context';
import {resolveURL} from '../request';
import SessionStore from '../session/session-store';
import {ImageLoaderPlaceholders} from '../titles/asset-title/images/image-loader';
import {GetThumbnail} from '../utils/utils';

// Bitmovin player styles.
import 'bitmovin-player/bitmovinplayer-ui.css';
import './bitmovin-player-ui.less';

// Bitmovin - Mux hack.
// Mux does not provide an NPM package for its Bitmovin integration.
// The next line exposes Bitmovin as if it was loaded via a <script> tag.
window.bitmovin = {
    player: Bitmovin
};
// Important! This import must always be AFTER Bitmovin player JS
// has been loaded.

export default class BitmovinPlayer extends React.Component {
    static get propTypes() {
        return {
            areHotkeysEnabled: PropTypes.bool,
            baftaPlayer: PropTypes.bool,
            clip: PropTypes.instanceOf(Immutable.Map),
            isClippingModeOn: PropTypes.bool.isRequired,
            onComplete: PropTypes.func.isRequired,
            onDurationChange: PropTypes.func.isRequired,
            onTimeUpdate: PropTypes.func.isRequired,
            preauthenticated: PropTypes.bool.isRequired,
            preauthenticatedUser: PropTypes.string.isRequired,
            startPlayerPosition: PropTypes.number.isRequired,
            titleId: PropTypes.number.isRequired,
            video: PropTypes.instanceOf(Immutable.Map).isRequired,
        };
    }

    static get defaultProps() {
        return {
            areHotkeysEnabled: true,
            baftaPlayer: false,
            clip: null,
        };
    }

    constructor(props) {
        super(props);

        this.createPlayer = this.createPlayer.bind(this);
        this.focusSettings = this.focusSettings.bind(this);
        this.getLargeThumbnailUrl = this.getLargeThumbnailUrl.bind(this);
        this.getUserWatermarkString = this.getUserWatermarkString.bind(this);
        this.getVideoContentType = this.getVideoContentType.bind(this);
        this.getVideoSources = this.getVideoSources.bind(this);
        this.initMux = this.initMux.bind(this);
        this.play = this.play.bind(this);
        this.registerAnalyticsEvents = this.registerAnalyticsEvents.bind(this);
        this.registerPlayerEvents = this.registerPlayerEvents.bind(this);
        this.updateMux = this.updateMux.bind(this);

        this.hotkeyController = createHotkeyControllerFromBitmovin();
        this.isViewingClip = false;
        this.playerUIFactory = PlayerUIFactory();
        this.playerUIManager;
    }

    componentDidUpdate(oldProps) {
        // If the video changes after the player was initialized, eg when watching a playlist, we call play again with the new video
        if (this.player && this.props.video !== oldProps.video) {
            this.player.unload();
            // Give Bitmovin some time to unload the old video, glitchy playback ensues otherwise
            setTimeout(() => {
                this.play();
                this.updateMux(this.player, this.getVideoId(this.props.video), this.props.video);
                this.focusSettings();
            }, 200);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.catchClose);
    }

    static contextType = MessagesContext;

    createPlayer(videoPlayer) {
        if (!videoPlayer) {
            try {
                this.watermarks.remove();
                this.playerUIManager.release();
                this.player.destroy();
                delete this.watermarks;
                delete this.player;
            } catch (e) {
                console.error(e);
            }
            return;
        }

        const options = {
            cast: {
                enable: false
            },
            key: '97514b02-131a-440c-92b0-ae583ba449cd',
            playback: {
                autoplay: true,
                muted: false
            },
            ui: false
        };

        const container = document.getElementById('video-player');

        this.player = new Player(container, options);
        this.playerUIManager = this.playerUIFactory.buildDefaultUI(this.player);

        this.watermarks = new Watermarks();
        this.watermarks.add(this.getUserWatermarkString(this.props.video.get('watermarkText')));
        this.watermarks.add('Warner Bros. Discovery, Inc.');
        this.watermarks.setDisableVisualWatermark(this.props.video.get('disableVisualWatermark'));
        this.watermarks.setPersistentVisualWatermark(this.props.video.get('persistentVisualWatermark'));
        this.watermarks.setVideoContentType(this.getVideoContentType(this.props.video));

        this.registerPlayerEvents(this.player);
        this.play();
        this.initMux(this.player, this.getVideoId(this.props.video), this.props.video);
        this.focusSettings();
        this.hotkeyController.init(this.player);
    }

    focusSettings() {
        const captionsButton = document.querySelector('.bmpui-ui-settingstogglebutton');
        if (captionsButton) {
            captionsButton.focus();
        }
    }

    /**
     * Returns the poster image
     */
    getLargeThumbnailUrl(video) {
        let url = ImageLoaderPlaceholders.HORIZONTAL;
        let thumbnail = GetThumbnail(video.get('thumbnails'), 1920);
        if (thumbnail) {
            url = thumbnail.get('thumbnailUrl');
        }

        return url;
    }

    getUserWatermarkString(watermarkText) {
        let watermark = watermarkText || this.context.intl.messages['titles.videos.player.watermark'];
        // Init the userEmail, it was showing as undefined in the watermark
        // when using a pre-auth link.
        let userEmail = '';
        let userName = 'WBTVD User';

        if (SessionStore.isSessionValid()) {
            let watermarkUser = SessionStore.getState().get('authUser');
            if (SessionStore.getParentUser()) {
                watermarkUser = SessionStore.getParentUser();
            }

            userName = watermarkUser.get('fullName', '');
            userEmail = `(${watermarkUser.get('email')})`;
        }

        if (this.props.preauthenticated) {
            userName = this.props.preauthenticatedUser;
        }

        return `${watermark} ${userName} ${userEmail}`;
    }

    getVideoId(video) {
        const id = video.get('id');
        if (id !== undefined) {
            return id;
        }

        return video.get('assetId');
    }

    // Iterate all sources and only keep the ones that are defined.
    // This method contains DRM logic.
    getVideoSources(video) {
        const poster = this.getLargeThumbnailUrl(video);
        const sources = video.get('streams', []).toJS().map((stream) => {
            const drm = {};
            const progressive = [];

            // PlayReady, Widevine and FairPlay are available
            if (stream.drmAmsCencLicenseUrl || stream.drmAmsHlsFairplayLicenseUrl) {
                drm.playready = {
                    headers: {
                        authorization: video.getIn(['drmToken', 'token'])
                    }
                };
                drm.widevine = {
                    headers: {
                        authorization: video.getIn(['drmToken', 'token'])
                    }
                };
                drm.fairplay = {
                    certificateURL: resolveURL('asset/video/fairplay.der'),
                    getLicenseServerUrl: function(skdURL) {
                        const regex = /^skd:/;

                        if (regex.test(skdURL)) {
                            return skdURL.replace(regex, '');
                        }

                        console.error(`Incorrect URL: ${skdURL}`);
                        return skdURL;
                    },
                    headers: {
                        authorization: video.getIn(['drmToken', 'fairplayToken'])
                    }
                };
            }

            // MP4 source available (No DRM required)
            if (stream.videoStreamUrl) {
                progressive.push({
                    src: stream.videoStreamUrl,
                    type: 'video/mp4',
                });
            }

            return {
                dash: stream.drmMpegDashUrl,
                drm,
                hls: stream.drmHlsUrl,
                poster,
                progressive,
                smooth: stream.src,
                thumbnailTrack: {
                    url: stream.spriteThumbVTTUrl,
                }
            };
        });

        return sources;
    }

    getVideoContentType(video) {
        // All this is because I'm not sure if we always have
        // a complete video object with content type.
        if (video.get('contentType') && AssetTypeConstants.VIDEO_CONTENT_TYPE[video.get('contentType')]) {
            return AssetTypeConstants.VIDEO_CONTENT_TYPE[video.get('contentType')].description;
        }

        return 'N/A';
    }

    initMux(player, videoId, video) {
        let playerName = SessionStore.getPartner().id;
        if (this.props.baftaPlayer) {
            playerName = `BAFTA ${playerName}`;
        }

        initBitmovinMux(player, {
            debug: true,
            data: {
                property_key: Config.Mux,
                page_type: '', // (see docs) 'watchpage', 'iframe', or leave empty
                viewer_user_id: SessionStore.getState().getIn(['authUser', 'id'], 'preauthenticated user'), // TODO: set user id even if pre-authenticated
                experiment_name: 'WBTVD Bitmovin', // ex: 'player_test_A'
                sub_property_id: '', // ex: 'cus-1'

                // Player Metadata
                player_name: playerName, // ex: 'My Main Player'
                player_version: '2.1.5', // ex: '1.0.0'
                player_init_time: Date.now(), // ex: 1451606400000

                // Video Metadata (cleared with 'videochange' event)
                video_id: videoId,
                video_title: `${video.get('name')}. Video ID: ${videoId}. Title ID: ${this.props.titleId || 'N/A'}.`,
                video_series: '', // ex: 'Weekly Great Videos'
                video_producer: '', // ex: 'Bob the Producer'
                video_content_type: this.getVideoContentType(video),
                video_language_code: '', // ex: 'en'
                video_variant_name: '', // ex: 'Spanish Hard Subs'
                video_variant_id: '', // ex: 'abcd1234'
                video_duration: '', // in milliseconds, ex: 120000
                video_stream_type: '', // 'live' or 'on-demand'
                video_encoding_variant: '', // ex: 'Variant 1'
                video_cdn: '' // ex: 'Fastly', 'Akamai'
            }
        });
    }

    updateMux(player, videoId, video) {
        player.mux.emit('videochange', {
            // Video Metadata (cleared with 'videochange' event)
            video_id: videoId,
            video_title: `${video.get('name')}. Video ID: ${videoId}. Title ID: ${this.props.titleId || 'N/A'}.`,
            video_series: '', // ex: 'Weekly Great Videos'
            video_producer: '', // ex: 'Bob the Producer'
            video_content_type: this.getVideoContentType(video),
            video_language_code: '', // ex: 'en'
            video_variant_name: '', // ex: 'Spanish Hard Subs'
            video_variant_id: '', // ex: 'abcd1234'
            video_duration: '', // in milliseconds, ex: 120000
            video_stream_type: '', // 'live' or 'on-demand'
            video_encoding_variant: '', // ex: 'Variant 1'
            video_cdn: '' // ex: 'Fastly', 'Akamai'
        });
    }

    play() {
        const videoSources = this.getVideoSources(this.props.video);
        this.player.load(videoSources[0]);
    }

    registerAnalyticsEvents(player) {
        let startWatchTime;
        player.on(PlayerEvent.Ready, () => {
            startWatchTime = Moment();
        });
        player.on(PlayerEvent.Play, () => {
            startWatchTime = Moment();
        });

        let fullscreen;
        let sourceType;
        let url;
        const analyticsEvent = (playbackType, isDestroy = false) => {
            // The destroy event is fired when the player is being removed from the page.
            if (!isDestroy) {
                fullscreen = player.getViewMode() === ViewMode.Fullscreen;
                // Fake a Mime Type
                sourceType = `application/${player.getStreamType()}`; //https://cdn.bitmovin.com/player/web/8.22.0/docs/enums/core.streamtype.html
                url = player.getSource()[sourceType];
            }

            const titleId = this.props.titleId;
            const videoId = this.getVideoId(this.props.video);
            const watchTime = Math.floor(Moment.duration(Moment().diff(startWatchTime)).asSeconds());

            Analytics.videoPlaybackEvent(videoId, titleId, playbackType, watchTime, url, sourceType, fullscreen);
        };

        // Use this boolean because player.isPlaying() is not available when Destroy is triggered.
        let isPlaying = false;
        player.on(PlayerEvent.Paused, () => {
            isPlaying = false;
            analyticsEvent(WBTVDAnalyticsConstants.VIDEO.PLAYBACK_TYPE.PAUSE);
        });
        // Use this boolean because Playing is triggered for the initial play and the subsequent resumes.
        let first = true;
        player.on(PlayerEvent.Playing, () => {
            isPlaying = true;
            if (first) {
                analyticsEvent(WBTVDAnalyticsConstants.VIDEO.PLAYBACK_TYPE.START);
                first = false;
                return;
            }
            analyticsEvent(WBTVDAnalyticsConstants.VIDEO.PLAYBACK_TYPE.RESUME);
        });
        // Reset the first flag when the player finishes or a new
        // source is loaded.
        player.on(PlayerEvent.PlaybackFinished, () => {
            analyticsEvent(WBTVDAnalyticsConstants.VIDEO.PLAYBACK_TYPE.COMPLETE);
            first = true;
            isPlaying = false;
        });
        player.on(PlayerEvent.SourceLoaded, () => {
            first = true;
        });

        this.catchClose = (e) => {
            if (isPlaying) {
                analyticsEvent(WBTVDAnalyticsConstants.VIDEO.PLAYBACK_TYPE.PAUSE, e.type === PlayerEvent.Destroy);
            }
        };
        player.on(PlayerEvent.Destroy, this.catchClose);
        window.addEventListener('beforeunload', this.catchClose);
    }

    registerPlayerEvents(player) {
        // Watermark events
        player.on(PlayerEvent.Paused, () => {
            this.watermarks.setPaused(true);
        });
        player.on(PlayerEvent.PlaybackFinished, () => {
            this.watermarks.setPaused(true);
        });
        player.on(PlayerEvent.Playing, () => {
            this.watermarks.setPaused(false);
        });

        // Analytics and Watchlist events, if not preauthenticated
        if (this.props.preauthenticated) {
            return;
        }

        // Send WBTVD analytics events
        this.registerAnalyticsEvents(player);

        player.on(PlayerEvent.Play, (event) => {
            // If this is the first start event, seek to the last position
            if (event.issuer === 'api') {
                this.player.seek(this.props.startPlayerPosition);
            }
        });

        player.on(PlayerEvent.Seeked, () => {
            const currentTime = this.player.getCurrentTime();
            this.props.onTimeUpdate(currentTime, false);
        });

        player.on(PlayerEvent.TimeChanged, () => {
            const currentTime = this.player.getCurrentTime();

            if (this.isViewingClip && (currentTime >= this.props.clip.get('tcOut'))) {
                this.isViewingClip = false;
                this.player.pause();
            }

            this.props.onTimeUpdate(currentTime, false);
        });

        player.on(PlayerEvent.Paused, () => {
            this.isViewingClip = false;
            this.props.onTimeUpdate(this.player.getCurrentTime(), true);
        });

        player.on(PlayerEvent.PlaybackFinished, () => {
            // Set the watchlist position to 0 so we get the disclaimer again next time we play the video
            this.props.onTimeUpdate(0, true);
            this.props.onComplete();
        });

        player.on(PlayerEvent.Ready, () => {
            this.isViewingClip = this.props.isClippingModeOn;
            this.props.onDurationChange(player.getDuration());
        });
    }

    playerClippingEl() {
        if (this.player) {
            return document.querySelector('.bmpui-ui-seekbar');
        }
        return null;
    }

    render() {
        return (
            <ClippablePlayer {...this.props} clippingEl={this.playerClippingEl()} playerController={this.hotkeyController}>
                <div
                    id="video-player"
                    ref={this.createPlayer}
                    tabIndex="0"
                />
            </ClippablePlayer>
        );
    }
}
