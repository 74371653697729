{
  "name": "wbtvd",
  "version": "15.1.0",
  "description": "Brainiac WBTVD site",
  "license": "UNLICENSED",
  "author": "WBTVD",
  "homepage": "https://github.com/wbdt-sie/wbtvd-web",
  "repository": {
    "type": "git",
    "url": "https://github.com/wbdt-sie/wbtvd-web.git"
  },
  "bugs": {
    "url": "https://wbddigital.atlassian.net/jira/software/c/projects/STUDIO/boards/6/backlog"
  },
  "scripts": {
    "clearcache": "rimraf ~/.local/share/pnpm/store/v3 .parcel-cache/ node_modules/ dist/",
    "dist": "./dist.sh",
    "start": "./scripts/start.sh",
    "start:windows": "node node_modules/parcel/lib/bin.js src/index.html src/lascreenings/index.html src/bafta-video-player/index.html -p 9001 --https --log-level verbose --no-hmr",
    "test": "jest --maxWorkers=50%",
    "test:ci": "./tests/test.sh",
    "test:ci_file": "./tests/test_ci_file.sh",
    "test:fast": "./tests/test_no_coverage.sh",
    "test:ts": "jest -c ./jest.ts.config.js src/ts",
    "test:watch": "jest --watch"
  },
  "dependencies": {
    "@babel/runtime": "7.23.2",
    "@datadog/browser-rum": "4.42.2",
    "@reduxjs/toolkit": "1.7.1",
    "@wbdt-sie/brainiac-web-common": "1.3.2",
    "bitmovin-player": "8.144.0",
    "bitmovin-player-ui": "3.52.2",
    "bluebird": "3.7.2",
    "bootstrap": "3.4.1",
    "bootstrap4": "npm:bootstrap@4.6.0",
    "btoa": "1.2.1",
    "classnames": "2.3.2",
    "datatables.net": "1.13.8",
    "datatables.net-bs": "1.13.8",
    "datatables.net-responsive": "2.5.0",
    "datatables.net-responsive-bs": "2.5.0",
    "datatables.net-rowreorder": "1.4.1",
    "datatables.net-rowreorder-bs": "1.4.1",
    "detect-browser": "5.3.0",
    "flux": "4.0.4",
    "immutable": "3.8.2",
    "jquery": "3.7.1",
    "jquery-touchswipe": "1.6.19",
    "moment": "2.29.4",
    "moment-timezone": "0.5.43",
    "photoswipe": "4.1.3",
    "prop-types": "15.8.1",
    "query-string": "7.0.1",
    "querystring-es3": "0.2.1",
    "react": "17.0.2",
    "react-autocomplete": "1.8.1",
    "react-bootstrap": "0.33.1",
    "react-datepicker": "4.24.0",
    "react-dom": "17.0.2",
    "react-intl": "5.18.3",
    "react-overlays": "1.2.0",
    "react-pdf": "6.2.2",
    "react-redux": "7.2.6",
    "react-router": "5.2.0",
    "react-router-6": "npm:react-router@6.2.1",
    "react-router-dom": "5.2.0",
    "react-router-dom-6": "npm:react-router-dom@6.2.1",
    "react-scroll": "1.9.0",
    "react-select": "4.3.1",
    "react-transition-group": "4.4.5",
    "reactstrap": "8.10.1",
    "rxjs": "7.8.1",
    "superagent": "8.1.2",
    "swagger-ui": "4.1.3",
    "ua-parser-js": "1.0.39",
    "url": "0.11.3",
    "uuid": "9.0.1"
  },
  "devDependencies": {
    "@axe-core/react": "4.3.2",
    "@babel/core": "7.23.2",
    "@babel/plugin-transform-class-properties": "7.23.3",
    "@babel/plugin-transform-modules-commonjs": "7.23.3",
    "@babel/plugin-transform-object-assign": "7.23.3",
    "@babel/plugin-transform-object-rest-spread": "7.23.3",
    "@babel/plugin-transform-runtime": "7.23.3",
    "@babel/preset-env": "7.13.9",
    "@babel/preset-react": "7.12.13",
    "@datadog/datadog-ci": "2.42.0",
    "@parcel/babel-preset-env": "2.10.2",
    "@parcel/core": "2.10.2",
    "@parcel/optimizer-terser": "2.10.2",
    "@parcel/transformer-inline-string": "2.10.2",
    "@parcel/transformer-less": "2.10.2",
    "@testing-library/react-hooks": "7.0.2",
    "@types/enzyme": "3.10.10",
    "@types/jest": "29.5.3",
    "@welldone-software/why-did-you-render": "4.3.2",
    "@wojtekmaj/enzyme-adapter-react-17": "0.6.2",
    "babel-eslint": "10.1.0",
    "babel-jest": "29.6.1",
    "babel-loader": "8.2.2",
    "babel-plugin-istanbul": "6.1.1",
    "babel-plugin-syntax-dynamic-import": "6.18.0",
    "enzyme": "3.11.0",
    "enzyme-to-json": "3.6.1",
    "jest": "29.6.1",
    "jest-axe": "5.0.1",
    "jest-environment-jsdom": "29.6.1",
    "jest-environment-node": "29.6.1",
    "jest-junit": "10.0.0",
    "less": "3.12.2",
    "mockdate": "2.0.5",
    "parcel": "2.10.2",
    "parcel-reporter-static-files-copy": "1.4.0",
    "process": "0.11.10",
    "punycode": "1.4.1",
    "resize-observer-polyfill": "1.5.1",
    "shallow-with-context": "0.4.1",
    "tailwindcss": "3.0.16",
    "ts-jest": "29.0.3",
    "ts-loader": "9.2.6",
    "typescript": "4.5.4"
  },
  "keywords": [],
  "@parcel/transformer-css": {
    "errorRecovery": true
  },
  "packageManager": "pnpm@9.15.4",
  "targets": {
    "qa": {
      "context": "browser",
      "sourceMap": {
        "inlineSources": false,
        "inline": false
      }
    },
    "uat": {
      "context": "browser",
      "sourceMap": {
        "inlineSources": false,
        "inline": false
      }
    },
    "prod": {
      "context": "browser",
      "sourceMap": {
        "inlineSources": false,
        "inline": false
      }
    }
  }
}
