/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {SerieNavigationConstants} from './serie-navigation-actions';
import {Dispatcher} from '../../flux-helpers';

class SerieNavigationStore extends ReduceStore {
    detectSeasonAndEpisodeNumbers(seasons, titleId) {
        const result = {
            seasonNumber: undefined,
            episodeNumber: undefined
        };
        if (seasons && seasons.size > 0) {
            seasons.some(season => {
                if (season.get('titleId') === titleId) {
                    result.seasonNumber = season.get('seasonNumber');
                    return true;
                }
                const episode = season.get('episodes').find((e) => e.get('titleId') === titleId);
                if (episode) {
                    result.seasonNumber = season.get('seasonNumber');
                    result.episodeNumber = episode.get('episodeNumber');
                    return true;
                }

                return false;
            });
        }

        return result;
    }

    getInitialState() {
        let state = Immutable.fromJS({
            serieNavigation: {
                seasons: [],
            }
        });
        return state;
    }

    reduce(state, action) {
        switch (action.actionType) {

        case SerieNavigationConstants.CLEAR:
        case SerieNavigationConstants.GET.START:
            state = this.getInitialState();
            break;

        case SerieNavigationConstants.GET.SUCCESS:
            state = state.set('serieNavigation', action.serieNavigation);
            break;
        }
        return state;
    }
}

let store = new SerieNavigationStore(Dispatcher);

export default store;
