/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {Dispatcher} from '../flux-helpers';
import {SingleTitleActions} from './single-title/single-title-actions';
import {HeaderActions} from '../layout/header/header-actions';
import {NotificationActions} from '../notification/notification-actions';
import {PlayerActions} from '../player/player-actions';
import Request from '../request';
import {RouterActions} from '../router/router-actions';
import mfa from '../session/mfa';
import {SessionActions} from '../session/session-actions';
import SessionStore from '../session/session-store';

const CONSTANTS = {
    CLEAR_HOMEPAGE: 'homepage_actions.homepage.clear',
    GET: {
        SUCCESS: 'homepage_actions.get.success',
    },
    IMAGE: {
        GET: {
            SUCCESS: 'homepage_actions.image.get.success'
        }
    },
    NEW_THIS_WEEK: {
        GET: {
            SUCCESS: 'homepage_actions.newthisweek.get.success',
        },
    },
    TARGET_TYPE: {
        TITLE: {id: 1, name: 'Title'},
        ASSET: {id: 2, name: 'Asset'},
        INTERNAL_PAGE: {id: 3, name: 'Internal Page'},
        APP_SECTION: {id: 4, name: 'App Section'},
        EXTERNAL_WEB_PAGE: {id: 5, name: 'External Web Page'}
    },
    TITLE: {
        GET: {
            SUCCESS: 'homepage_actions.title.get.success'
        }
    },
    VIDEO: {
        GET: {
            SUCCESS: 'homepage_actions.video.get.success'
        },
        STREAMS: {
            GET: {
                SUCCESS: 'homepage_actions.video.streams.get.success'
            }
        },
        TITLES: {
            GET: {
                SUCCESS: 'homepage_actions.video.titles.get.success'
            }
        }
    }
};

class HomeActions {
    assetDisplayNameToUrl(titleId, assetType, assetId) {
        switch (assetType) {
        case 'Image':
            return `/titles/${titleId}/images/${assetId}`;
        case 'Audio':
            return `/titles/${titleId}/audio`;
        case 'Merchandise':
            return `/titles/${titleId}/merchandise`;
        }
        return `/titles/${titleId}/documents`;
    }

    clearHomepage() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR_HOMEPAGE
        });
    }

    getHomepage() {
        let homepage;
        // Get the homepages.
        Request.get('web/homepage/current-user').then(homepageRes => {
            let res = homepageRes.body;
            const contentType = res.contentType;
            SessionActions.setHomepageContentType(contentType);

            switch (contentType) {
            case 'HOME_PAGE':
                if (!res.homePages.length) {
                    NotificationActions.showAlertDanger('homepage.error.page-not-found');
                    throw new Error('No Homepages Defined');
                }

                homepage = res.homePages.reduce((prev, next) => {
                    if (prev.updatedDate > next.updatedDate && prev.active === 1) {
                        return prev;
                    }
                    return next;
                }, {updatedDate: ''});

                // User does not have a homepage. Show an error!
                if (homepage.homePageId === undefined) {
                    NotificationActions.showAlertDanger('homepage.error.page-not-available');
                    throw new Error('No Assigned Homepage');
                }

                HeaderActions.getHomepageNavigationMenuItems(homepage);

                return this.getHomepageDetails(homepage);
            case 'MULTIPLE_TITLES':
                return;
            case 'SINGLE_TITLE':
                return SingleTitleActions.setTitle(res.homeTitles[0].id);
            case 'NO_CONTENT_NO_DEFAULT':
            default:
            }
        }).catch(err => {
            throw err;
        });

        return;
    }

    getHomepageDetails(homepage) {
        // Get the details for each homepage section.
        return Request.get(`web/homepage/${homepage.homePageId}/section`).then(sectionsRes => {
            homepage.sections = sectionsRes.body;
            return Promise.all(
                sectionsRes.body.map(
                    s => Request.get(`web/publish-list/${s.publishListId}`)
                )
            );
        }).then(publishingLists => {
            homepage.sections = homepage.sections.map((section, i) => {
                section.publishingList = publishingLists[i].body;
                return section;
            });

            // Get al items for each publishing list.
            return Promise.all(
                publishingLists.map(
                    r => Request.get(`web/publish-list/${r.body.id}/my-items`).query({'load-urls': true})
                )
            );
        }).then(publishingListsItems => {
            homepage.sections = homepage.sections.map((section, i) => {
                section.publishingList.items = publishingListsItems[i].body.sort((a, b) => a.displayOrder - b.displayOrder);
                return section;
            });

            // Get image, title and video by ids.
            homepage.sections.forEach(s => {
                s.publishingList.items.forEach(i => {
                    switch (i.targetType) {
                    case CONSTANTS.TARGET_TYPE.ASSET.id:
                        switch (i.displayAssetTypeName) {
                        case 'Image':
                            this.getImage(i.assetId);
                            break;
                        case 'Video':
                            this.getVideo(i.assetId);
                            break;
                        }
                        break;

                    case CONSTANTS.TARGET_TYPE.TITLE.id:
                        this.getTitle(i.assetId);
                        break;
                    }
                });
            });

            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.SUCCESS,
                homepage: Immutable.fromJS(homepage)
            });

            return;
        }).catch(err => {
            throw err;
        });
    }

    getImage(id) {
        Promise.all([
            Request.get(`asset/image/${id}`),
            Request.get(`asset/${id}/title`)
        ]).spread((imageRes, titlesRes) => {
            const image = imageRes.body;
            image.titles = titlesRes.body;
            Dispatcher.dispatch({
                actionType: CONSTANTS.IMAGE.GET.SUCCESS,
                id,
                image: Immutable.fromJS(image)
            });
        }).catch(err => {
            throw err;
        });
    }

    getNewThisWeek() {
        Request.get('asset/new-this-week').then(newThisWeekRes => {
            // Update the HomeStore
            Dispatcher.dispatch({
                actionType: CONSTANTS.NEW_THIS_WEEK.GET.SUCCESS,
                newThisWeek: Immutable.fromJS(newThisWeekRes.body)
            });
            return;
        }).catch(err => {
            throw err;
        });

        return;
    }

    getTitle(id) {
        Request.get(`title/${id}`).then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TITLE.GET.SUCCESS,
                id,
                title: Immutable.fromJS(res.body)
            });
        }).catch(err => {
            throw err;
        });
    }

    getVideo(id) {
        Promise.all([
            Request.get(`asset/video/${id}`),
            Request.get('asset/video/thumbnailURL').query({'video-id': id}),
            Request.get(`asset/${id}/title`)
        ]).spread((videoRes, thumbnailRes, titlesRes) => {
            let video = videoRes.body;
            if (thumbnailRes.body && thumbnailRes.body[0]) {
                video.thumbnails = thumbnailRes.body[0].thumbnailList;
            }
            video.titles = titlesRes.body;

            Dispatcher.dispatch({
                actionType: CONSTANTS.VIDEO.GET.SUCCESS,
                id,
                video: Immutable.fromJS(video)
            });

            const titlesDataRequests = video.titles.map(title => Request.get(`title?title-id=${title.titleId}`));
            return Promise.all([
                video,
                ...titlesDataRequests,
            ]);
        }).spread((video, titlesDataResponse) => {
            const titlesResults = titlesDataResponse?.body?.results || [];
            const titles = video.titles.map(title => {
                title.parentTitleDisplayName = titlesResults.find(titleResult => titleResult.id === title.titleId)?.parentTitleDisplayName;
                return title;
            });

            Dispatcher.dispatch({
                actionType: CONSTANTS.VIDEO.TITLES.GET.SUCCESS,
                id: video.id,
                titles: Immutable.fromJS(titles)
            });

            return;
        }).catch(err => {
            throw err;
        });
    }

    gotoItem(item) {
        switch (item.get('targetType')) {
        case CONSTANTS.TARGET_TYPE.ASSET.id:
            return Request.get(`asset/${item.get('assetId')}/title`).then(titles => {
                if (titles.body.length > 0) {
                    RouterActions.redirect(`${this.assetDisplayNameToUrl(titles.body[0].titleId, item.get('displayAssetTypeName'), item.get('assetId'))}`);
                } else {
                    throw new Error('No titles asociated what should i do?');
                }
            });
        case CONSTANTS.TARGET_TYPE.TITLE.id:
            // yes assetId is title's id in this case ...
            // :gun: :nuclear_bomb: :face_palm:
            RouterActions.redirect(`/titles/${item.get('assetId')}`);
            break;
        }
    }

    // Get video streams and, if needed, the DRM token.
    /* istanbul ignore next */
    prepareVideo(video, videoIndex) {
        const videoId = video.get('id');
        const forensicUrlRequired = video.get('forensicUrlRequired');

        let mfaPromise = Promise.resolve();
        if (video.get('mfaRequired') || video.get('children', Immutable.List()).some(vc => vc.get('mfaRequired'))) {
            mfaPromise = mfa.RequestMFA(
                SessionStore.getState().getIn(['authUser', 'id']),
                false
            ).catch(err => {
                switch (true) {
                case err instanceof mfa.MFARejectedError:
                    NotificationActions.showAlertDanger('player.errors.mfa.rejected');
                    break;
                case err instanceof mfa.MFARequestError:
                    NotificationActions.showAlertDanger('player.errors.mfa.status');
                    break;
                case err instanceof mfa.MFAUnknownStatusError:
                case err instanceof mfa.MFAUnregisteredError:
                    break;
                default:
                    NotificationActions.showAlertDanger('player.errors.mfa.400');
                    break;
                }

                // Set the error as default prevented so that the next handler
                // doesn't add alerts on top of existing alerts.
                err.defaultPrevented = true;
                throw err;
            });
        }

        mfaPromise.then(() => {
            let streamRequest = Request.get('asset/video/streamURL').query({'video-id': videoId});
            if (forensicUrlRequired) {
                streamRequest = Request.get(`asset/video/${videoId}/forensic-url`);
            }

            return streamRequest;
        }).then(streamRes => {
            let streams = Immutable.fromJS(streamRes.body[0]);
            if (forensicUrlRequired) {
                streams = Immutable.fromJS(streamRes.body);
            }

            if (!streams) {
                // No streams for this video.
                NotificationActions.showAlertDanger('player.errors.no-sources');
                let err = new Error(`Video ${videoId} does not have any playable sources.`);
                err.defaultPrevented = true;
                throw err;
            }

            let {hasDRM, err} = PlayerActions._hasDRM(streams, true);
            if (err) {
                // Throw error (instead of return) to reject the promise.
                throw err;
            }

            let tokenResponse;
            if (hasDRM) {
                streams = streams.set('assetId', videoId);
                tokenResponse = PlayerActions._requestToken(streams);
            }

            return Promise.props({
                streams,
                tokenResponse,
                // The following action gets the watchlist (creating it if needed)
                // and dispatches new actions to the PlayerStore. It resolves to an
                // empty promise if everything was ok.
                watchlist: PlayerActions.getWatchlistDetail(videoId)
            });
        }).then(data => {
            let token;
            if (data.tokenResponse) {
                token = Immutable.fromJS(data.tokenResponse.body);
            }

            Dispatcher.dispatch({
                actionType: CONSTANTS.VIDEO.STREAMS.GET.SUCCESS,
                streams: data.streams,
                token,
                videoId,
                videoIndex
            });

            return;
        }).catch(err => {
            if (!err.defaultPrevented) {
                NotificationActions.showAlertDanger('player.errors.streams.400');
            }
            throw err;
        });
    }
}

let actions = new HomeActions();

export {
    actions as HomeActions,
    CONSTANTS as HomeConstants,
};
