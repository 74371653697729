/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

export const ROLE_LABELS = (role) => {
    if (!role) {
        return null;
    }
    switch (role.toLowerCase()) {
    // Valid roles as defined in ISO/IEC 23009-1 (5.8.5.5 DASH role scheme)
    case 'enhanced-audio-intelligibility':
        return 'Enhanced Dialogue';
    case 'caption':
    case 'subtitle':
    case 'main':
    case 'alternate':
    case 'supplementary':
    case 'commentary':
    case 'dub':
    case 'description':
    case 'sign':
    case 'metadata':
    case 'emergency':
        return role[0].toUpperCase() + role.substring(1).toLowerCase(); // titlecase
    default:
        return null;
    }
};
