/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';

import {MessagesContext} from '../messages/messages-context';
import {ImageLoaderPlaceholders} from '../titles/asset-title/images/image-loader';
import {GetThumbnail} from '../utils/utils';

export default class Poster extends PureComponent {
    static get propTypes() {
        return {
            video: PropTypes.instanceOf(Immutable.Map).isRequired,
        };
    }

    static contextType = MessagesContext;

    render() {
        const src = getLargeThumbnailUrl(this.props.video);
        const alt = this.context.intl.messages['player.container'];

        return (
            <div className="text-center">
                <img id="video-player" alt={alt} src={src} tabIndex={0} />
            </div>
        );
    }
}

function getLargeThumbnailUrl(video) {
    let thumbnail = GetThumbnail(video.get('thumbnails'), 1920);
    return thumbnail ? thumbnail.get('thumbnailUrl') : ImageLoaderPlaceholders.HORIZONTAL;
}
