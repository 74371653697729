/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {OrderConstants} from './order-constants';
import OrderStore from './order-store';
import {DeliveryConstants} from '../account/tabs/delivery/delivery-actions';
import Analytics from '../analytics';
import {AssetTypeConstants} from '../asset-types/asset-type-constants';
import {DownloadActions, DownloadConstants} from '../common/download/download-actions';
import {Actions, Dispatcher} from '../flux-helpers';
import {NotificationActions} from '../notification/notification-actions';
import Request from '../request';
import {RouterActions} from '../router/router-actions';
import SessionStore from '../session/session-store';
import {AssetTitleConstants} from '../titles/asset-title/asset-title-actions';
import {DownloadAsTextFile, StartDownload} from '../utils/utils';

const CART_ADD_SUCCESS_MSGS = {
    [AssetTypeConstants.ASSET_TYPE.IMAGE]: 'cart.add.image.ok',
    [AssetTypeConstants.ASSET_TYPE.VIDEO]: 'cart.add.video.ok',
    [AssetTypeConstants.ASSET_TYPE.VIDEO_USER_CLIP]: 'cart.add.video-user-moment.ok',
};

class OrderActions extends Actions {

    constructor(CONST) {
        super(CONST);
    }

    // FIXME: this function needs some refactor
    /* istanbul ignore next */
    _addToCart(assetType, titleId, assets, addChildren = false) {
        let assetsPromise = Promise.resolve(assets);
        if (addChildren) {
            // FIXME: this is a potential bug, it is mixing Immutable with
            // mutable objects and assuming the user only wants to add the children
            // from the first item in the array.
            assetsPromise = Request.get(
                `title/${titleId}/web/asset/${assets[0].get('assetId')}/stack-asset`
            ).query({
                offset: 0,
                size: 1000 // this is set for adding all stacked videos
            }).then(stackedAssetsRes => {
                return [...assets, ...stackedAssetsRes.body.map(a => Immutable.fromJS(a))];
            });
        }
        return assetsPromise.then(allAssets => {
            return Promise.all(
                allAssets.map(
                    asset => Request.post('order/cart/item').send({
                        assetId: asset.get('assetId'),
                        assetType: assetType,
                        titleId: titleId
                    })
                )
            ).then(res => {
                const items = res.map((r, i) => {
                    const asset = allAssets[i];
                    Analytics.orderAssetAddEvent(r.body.orderId, asset.get('assetId'), asset.get('assetType'));
                    let item = Immutable.fromJS(r.body);
                    item = item.set('asset', asset);
                    return item;
                });
                Dispatcher.dispatch({
                    actionType: OrderConstants.CART.ADD_OK,
                    items: items
                });
                const successMessage = CART_ADD_SUCCESS_MSGS[assetType] || CART_ADD_SUCCESS_MSGS[AssetTypeConstants.ASSET_TYPE.VIDEO];
                NotificationActions.showAlertSuccess(successMessage);
            }).catch(/* istanbul ignore next */err => {
                switch (err.status) {
                case 409:
                    NotificationActions.showAlertDanger('cart.add.error.already-in-cart');
                    break;
                default:
                    NotificationActions.showAlertDanger('cart.add.error');
                    break;
                }
                throw err;
            });
        });
    }

    _getVideoRequestWithExtraDetails(item) {
        return Request.get(`asset/video/${item.assetId}`).query({'order-number': item.orderId}).then(assetRes => {
            return Request.get(`asset/video/${item.assetId}/audio-configuration`).then(videoAudioTypesRes => {
                const audioConfigTypesData = videoAudioTypesRes.body.map(audioConfigId => {
                    const audioConfigObject = Object.keys(AssetTitleConstants.VIDEO.AUDIO_CONFIG_TYPES).find(key => AssetTitleConstants.VIDEO.AUDIO_CONFIG_TYPES[key].id === audioConfigId);
                    /* istanbul ignore else */
                    if (audioConfigObject) {
                        return AssetTitleConstants.VIDEO.AUDIO_CONFIG_TYPES[audioConfigObject].name;
                    }
                });

                assetRes.body.audioConfigTypes = audioConfigTypesData;
                return assetRes;
            }).catch(err => {
                NotificationActions.showAlertWarning('orders.get.audio-config-types.error');
                console.error(err);
                return assetRes;
            });
        });
    }

    /*
    * Add to cart
    * */
    add(assetType, titleId, assets, addChildren = false) {
        /* istanbul ignore else */
        if (titleId) {
            return this._addToCart(assetType, titleId, assets, addChildren);
        }
        // this case should only happen when adding an asset from talent views
        // so asset quantity will be 1 (I'm assuming that)
        let id = assets[0].get('assetId');
        let tid;
        /* istanbul ignore next */
        Request.get(`asset/${id}/title`).then( res => {
            if (res.body && res.body.length > 0) {
                tid = res.body[0].titleId;
            }
            return this._addToCart(assetType, tid, assets);
        }).catch(err => {
            throw err;
        });
    }

    addAllToCart(titleId, assetSuperType) {
        Request.post(`order/cart/title/${titleId}/image-super-type/${assetSuperType}`).then(() => {
            this.getCartItemsAmount();
            NotificationActions.showAlertSuccess('cart.add.all-images.ok');
        }).catch(err => {
            NotificationActions.showAlertDanger('cart.add.error');
            throw err;
        });
    }

    addAllToCartByContentType(titleId, assetContentTypes) {
        Promise.any(assetContentTypes.map(contentType => {
            return Request.post(`order/cart/title/${titleId}/image-content-type/${contentType}`);
        })).then(() => {
            this.getCartItemsAmount();
            NotificationActions.showAlertSuccess('cart.add.all-images.ok');
        }).catch((err) => {
            NotificationActions.showAlertDanger('cart.add.all-images.error.already-in-cart');
            throw err;
        });
    }

    approveOrder(order) {
        this.saveOrder(order).then(() => {
            return Request.put(`order/${order.get('id')}/approve`).send(order.toJS()).then(() => {
                Analytics.orderStatusChangeEvent(order.get('id'), OrderConstants.ORDER.STATUS_BY_NAME.APPROVED, order.get('clientUserId'), order.get('requesterUserId'), false);
                RouterActions.redirect('/orders');
                NotificationActions.showAlertSuccess('orders.sent-to-me.approve.success');
            });
        }).catch(() => {
            NotificationActions.showAlertDanger('orders.sent-to-me.approve.error');
        });

    }

    buildTree(items, titles) {
        // create map for title faster access
        let titleMap = {};
        titles.forEach( title => {
            titleMap[title.id]=title;
        });
        let tree = {
            image:[],
            video:[],
            'video-user-clip': [],
        };
        items.forEach( item => {
            let section = AssetTypeConstants.ASSET_TYPE_NAMES[item.assetType];

            // Poor's man find
            let title = tree[section].filter( /* istanbul ignore next */t => {
                return t.id === item.titleId;
            });
            /* istanbul ignore next */
            if (title.length > 0) {
                title = title[0];
            } else {
                title = undefined;
            }
            // End poors Man find
            /* istanbul ignore else */
            if (!title) {
                title = Object.assign({}, titleMap[item.titleId]);
                title.items=[];
                tree[section].push(title);
            }
            title.items.push(item);
        });
        return tree;
    }

    cancelReloadToCart() {
        Dispatcher.dispatch({
            actionType: OrderConstants.ORDER.RELOAD_TO_CART.CLEAR,
        });
    }

    cancelRemove() {
        Dispatcher.dispatch({
            actionType: OrderConstants.CANCEL_REMOVE
        });
    }

    cancelUnsubscribeClient() {
        Dispatcher.dispatch({
            actionType: OrderConstants.TITLE.UNSUBSCRIBE_CLIENT.CLEAR,
        });
    }

    clear() {
        setTimeout(() => {
            Dispatcher.dispatch({
                actionType: OrderConstants.ORDER.CLEAR
            });
        }, 0);
    }

    clearCart() {
        Request.del('order/cart/empty').then(() => {
            this.getById();
            NotificationActions.showAlertSuccess('cart.clear.ok');
        }).catch(err => {
            NotificationActions.showAlertDanger('cart.clear.error');
            throw err;
        });
    }

    clearDownloads() {
        Dispatcher.dispatch({
            actionType: OrderConstants.CLEAR_DOWNLOADS
        });
    }

    clearSelectedClient() {
        Dispatcher.dispatch ({
            actionType: OrderConstants.CLIENT.CLEAR
        });
    }

    //TODO: this seems to have no usages, verify and remove
    /* istanbul ignore next */
    createOrdersWithFulfillmentSet(orderRes) {
        return orderRes.filter(order => order.status === OrderConstants.ORDER.STATUS_BY_NAME.FULFILMENT);
    }

    /**
     * Creates an immutable set of Users from an Order
     * @param  {object} orderRes Request body.results object
     * @return {Immutable.Set} Set of usersId
     */
    createUsersSet(orderRes, isUserSubscription) {
        let users = [];
        /* istanbul ignore next */
        orderRes.forEach(order => {
            if (order.deliverToUserId && order.deliverToUserId !== null && order.deliverToUserId !== order.clientUserId) {
                users.push(order.deliverToUserId);
            }
            if (order.requesterUserId && order.requesterUserId !== null && order.requesterUserId !== order.clientUserId) {
                users.push(order.requesterUserId);
            }
            /* For getting Field Office value later */
            if (isUserSubscription && order.userId && order.userId !== null) {
                users.push(order.userId);
            }
        });
        return Immutable.Set(users);
    }

    denyOrder(order) {
        this.saveOrder(order).then(() => {
            return Request.put(`order/${order.get('id')}/deny`).send(order.toJS()).then(() => {
                Analytics.orderStatusChangeEvent(order.get('id'), OrderConstants.ORDER.STATUS_BY_NAME.DENIED, order.get('clientUserId'), order.get('requesterUserId'), false);
                RouterActions.redirect('/orders');
                NotificationActions.showAlertSuccess('orders.sent-to-me.deny.success');
            });
        }).catch(() => {
            NotificationActions.showAlertDanger('orders.sent-to-me.deny.error');
        });
    }

    doReloadToCart(orderId) {
        Request.put(`order/${orderId}/reload`).then(() => {
            this.getById();
            NotificationActions.showAlertSuccess('orders.tab.my-saved-carts.reload-to-cart.success');
        }).catch((err) => {
            NotificationActions.showAlertDanger('orders.tab.my-saved-carts.reload-to-cart.error');
            throw err;
        }).finally(() => {
            Dispatcher.dispatch({
                actionType: OrderConstants.ORDER.RELOAD_TO_CART.CLEAR
            });
            return;
        });
        return;
    }

    /*
    * Remove from cart
    * */
    doRemove(isCart, assetType, cartId, items) {
        // do the actual remove
        Request.del(`order/${cartId}/item`).query({
            'item-id': items.map(i => i.get('itemId')).toJS()
        }).then(() => {
            Dispatcher.dispatch({
                actionType: OrderConstants.REMOVE_OK,
                assetType: assetType,
                items,
                isCart
            });
            NotificationActions.showAlertSuccess('cart.image.delete.success');
        }).catch(err => {
            NotificationActions.showAlertDanger('cart.image.delete.error');
            throw err;
        });
    }

    doUnsubscribeClient(toUnsubscribeClient) {
        Request.del(`title/${toUnsubscribeClient.titleId}/user-subscription/${toUnsubscribeClient.userId}/subscription-type/${toUnsubscribeClient.subscriptionContentType}`).then(() => {
            Analytics.userTitleUnsubscribeEvent(toUnsubscribeClient.titleId, toUnsubscribeClient.subscriptionContentType);
            NotificationActions.showAlertSuccess('orders.tab.subscriptions.unsubscribe-client.success');
            return this.get(OrderConstants.ORDER.TAB.SUBSCRIPTIONS.url, {
                offset: 0,
                size: 40,
                'sort-field': 'user_id'
            });
        }).catch((err) => {
            NotificationActions.showAlertDanger('orders.tab.subscriptions.unsubscribe-client.error');
            throw err;
        }).finally(() => {
            Dispatcher.dispatch({
                actionType: OrderConstants.TITLE.UNSUBSCRIBE_CLIENT.CLEAR
            });
            return;
        });
        return;
    }

    download(orderItemId, renditionType) {
        return Request.get('asset/download').query({
            'order-item-id': orderItemId,
            'rendition-type': renditionType,
        }).then((res) => {
            // Start the download by simulating a click in the browser.
            StartDownload(res.body.downloadUrl);
        }).catch(err => {
            NotificationActions.showAlertDanger('asset.download.error');
            throw err;
        });
    }

    downloadALE(orderId) {
        const req = Request.get(`order/${orderId}/ale`);
        req.set('Content-Type', 'text/plain');
        req.set('Accept', 'text/plain');
        req.then((res) => {
            DownloadAsTextFile('cart.ale', res.text);
        }).catch(err => {
            NotificationActions.showAlert('cart.view.load-ale.error');
            throw err;
        });
    }

    downloadAll(renditionType) {
        Dispatcher.dispatch({
            actionType: OrderConstants.DOWNLOAD_COMPLETE_ALL,
            renditionType
        });
    }

    downloadVideo(videoId) {
        Request.get(`order/video/${videoId}/http-download`).then((response) => {
            window.open(response?.body?.downloadUrl, '_self');
        }).catch(err => {
            NotificationActions.showAlertDanger('orders.download-video.error', false, videoId);
            throw err;
        });
    }

    downloadVideoOrderItem(orderId, orderItemId, videoId) {
        Request.get(`order/${orderId}/item/${orderItemId}/http-download`).then((response) => {
            window.open(response?.body?.downloadUrl, '_self');
        }).catch(err => {
            NotificationActions.showAlertDanger('orders.download-video.error', false, videoId);
            throw err;
        });
    }

    fulfillOrder(order) {
        this.saveOrder(order).then(() => {
            return Request.put(`order/${order.get('id')}/fulfill`).send(order.toJS()).then(() => {
                Analytics.orderStatusChangeEvent(order.get('id'), OrderConstants.ORDER.STATUS_BY_NAME.FULFILLED, order.get('clientUserId'), order.get('requesterUserId'), false);
                RouterActions.redirect('/orders');
                NotificationActions.showAlertSuccess('orders.sent-to-me.fulfill.success');
            });
        }).catch(() => {
            NotificationActions.showAlertDanger('orders.sent-to-me.fulfill.error');
        });
    }

    /**
     * Get orders from DB
     * @param {string} [type] value for the url to be used in the request
     * @param {object} [criteria] values set from the filters navbar
     */
    get(type, criteria, sortFieldName, sortDirection) {
        let url = 'order';
        /* istanbul ignore else */
        if (type) {
            url = `order/${type}`;
        }

        // Dispatch Loading message
        Dispatcher.dispatch({
            actionType: OrderConstants.ORDERS.GET.START
        });

        // Duplicate the object so that it can be modified without side-effects.
        let criteriaCopy = Object.assign({}, criteria);

        // Status filters, need to convert to its corresponding value for the API query
        let statusQuery = [];
        if (criteria) {
            statusQuery.status = [];
            // After adding each of the statuses, delete the boolean
            // from the criteriaCopy object so that it doesn't send extra
            // parameters to the server.
            if (criteria.approved) {
                statusQuery.status.push(3);
                delete criteriaCopy.approved;
            }
            if (criteria.denied) {
                statusQuery.status.push(6);
                delete criteriaCopy.denied;
            }
            if (criteria.fulfilled) {
                statusQuery.status.push(5);
                delete criteriaCopy.fulfilled;
            }
            if (criteria.fulfillment) {
                statusQuery.status.push(51);
                delete criteriaCopy.fulfillment;
            }
            if (criteria.requested) {
                statusQuery.status.push(60);
                delete criteriaCopy.requested;
            }
            if (criteria['in-process']) {
                statusQuery.status.push(1);
                delete criteriaCopy['in-process'];
            }
        }
        let sortQuery;
        if (sortFieldName) {
            sortQuery = {
                'sort-field': sortFieldName,
                'sort-order': sortDirection
            };

            if (type === OrderConstants.ORDER.TAB.SUBSCRIPTIONS.url) {
                // FIXME: use a different enum :)
                sortQuery['sort-field'] = 'user_id';
            }
        }

        Request.get(url).query(Object.assign(
            {
                offset: 0,
                size: 40
            },
            criteriaCopy,
            statusQuery,
            sortQuery
        )).then(orderRes => {
            Dispatcher.dispatch({
                actionType: OrderConstants.ORDERS.GET.SUCCESS,
                orders: Immutable.fromJS(orderRes.body.results),
                totalCount: Immutable.fromJS(orderRes.body.totalCount),
            });
            let users = this.createUsersSet(orderRes.body.results, type === 'user-subscription');
            return this.getUsers(users);
        }).catch(/* istanbul ignore next */err => {
            Dispatcher.dispatch({
                actionType: OrderConstants.ORDERS.GET.ERROR
            });
            throw err;
        });
        return;
    }

    /**
     * Get order/cart data
     * @param {number} [id] if it has id it is in order item, if not it is a cart and none id is needed
     */
    getById(id) {
        // request order & order items for id
        let cartPromise;
        if (!id) {
            // get current cart
            cartPromise = Request.get('order/cart').then(res => {
                return res;
            }, (/*error*/) => {
                // no current cart create a new one
                return Request.post('order/cart');
            });
        } else {
            // get order by id
            cartPromise = Request.get(`order/${id}`);
        }
        cartPromise.then(order => {
            return Promise.all([order, Request.get(`order/${order.body.id}/item`)]);
        }).spread((order, items) => {
            this.getItemsInfo(items.body);
            this.getOrderHistory(id);
            if (id) {
                this.getUser(order.body.requesterUserId, OrderConstants.REQUESTER.GET.SUCCESS);
                //By default if object has clientUserId it would be selected
                /* istanbul ignore next */
                if (order.body.clientUserId) {
                    this.getUser(order.body.clientUserId, OrderConstants.CLIENT.SELECT);
                }
                Dispatcher.dispatch({
                    actionType: OrderConstants.ORDER.GET.SUCCESS,
                    order: Immutable.fromJS(order.body)
                });
            } else {
                Dispatcher.dispatch({
                    actionType: OrderConstants.ORDER.GET.SUCCESS,
                    order: Immutable.fromJS(order.body),
                    itemsInCart: items.body.length
                });
                /* istanbul ignore else */
                if (!order.deliveryFormat) {
                    this.setDefaultCartDeliveryFormat(order.body);
                }
            }

            return;
        }).catch(err => {
            // If no cart, then throw the error but don't show any message.
            /* istanbul ignore next */
            if (err && err.response && err.status === 404) {
                throw err;
            }

            NotificationActions.showAlertDanger('cart.view.load.error');
            throw err;
        });

        return;
    }

    /**
     * Get amount of cartItems to show in navbar
     */
    getCartItemsAmount() {
        // get current cart
        Request.get('order/cart').then(cart => {
            return Request.get(`order/${cart.body.id}/item`);
        }).then((items) => {
            Dispatcher.dispatch({
                actionType: OrderConstants.CART.GET_ITEMS_AMOUNT,
                itemsInCart: items.body.length,
            });

            return;
        }).catch(err => {
            // If no cart, then throw the error but don't show any message.
            if (err && err.response && err.status === 404) {
                throw err;
            }

            NotificationActions.showAlertDanger('cart.view.load.error');
            throw err;
        });
        return;
    }

    getDeliveryInfo(clientId) {
        return Promise.all([
            Request.get(`user/${clientId}/delivery-profile`),
            Request.get(`user/${clientId}/delivery-profile/notification`),
        ]).spread((deliveryFormat, notificationList) => {
            Dispatcher.dispatch({
                actionType: OrderConstants.DELIVERY_INFO.GET.SUCCESS,
                deliveryFormat: Immutable.fromJS(deliveryFormat.body),
                notificationList: Immutable.fromJS(notificationList.body),
            });
        });
    }

    getDeliverySpeed(orderId, deliveryFormat) {
        Request.get(`order/${orderId}/delivery-speed?delivery-format=${deliveryFormat}`).then(res => {
            Dispatcher.dispatch({
                actionType: OrderConstants.DELIVERY_SPEED_TYPE.GET.SUCCESS,
                itemsSpeedRes: Immutable.fromJS(res.body.results)
            });
            return;
        });
    }

    getItemsInfo(cartItems, constants = OrderConstants.ORDER.ITEMS.GET) {
        Dispatcher.dispatch({
            actionType: constants.START
        });

        // request assets by id and type
        let assets = cartItems.map(item => {
            let assetType = AssetTypeConstants.ASSET_TYPE_NAMES[item.assetType];
            if (AssetTypeConstants.ASSET_TYPE_NAMES[item.assetType] === AssetTypeConstants.ASSET_TYPE_NAMES[2]) {
                return this._getVideoRequestWithExtraDetails(item);
            }
            return Request.get(`asset/${assetType}/${item.assetId}`).query({'order-number': item.orderId});

        });
        // convert titles to set to remove duplicates
        let titleSet = new Immutable.Set(cartItems.map(item => {
            return {id: item.titleId, name: item.displayTitleName};
        }));
        let titles = new Array(...titleSet.toJS());
        //Don't throw error when some request fails
        assets = assets.map(p => p.then(res => res).catch(/* istanbul ignore next */(err) => {
            console.error(err);
            return undefined;
        }));
        Promise.all(assets).then(assetsRes => {
            cartItems.map((item, i) => {
                /* istanbul ignore else */
                if (assetsRes[i] !== undefined) {
                    let asset = assetsRes[i].body;
                    // Add the assetId so that features like the photoswipe or the
                    // video player are able to work in the cart view.
                    asset.assetId = asset.id;
                    asset.name = asset.assetName;
                    item.asset = asset;
                }
                return item;
            });
            titles = this.buildTree(cartItems, titles);
            Dispatcher.dispatch({
                actionType: constants.SUCCESS,
                titles: Immutable.fromJS(titles)
            });
        });
    }

    getMetadataOrder(status, sortFieldName = 'dueDate', sortDirection = 'asc') {
        Request.get('work-order/web/my-work-order').query({
            'work-order-status': status,
            'sort-field-name': sortFieldName,
            'sort-direction': sortDirection
        }).then((myOrderMetadata) => {
            Dispatcher.dispatch({
                actionType: OrderConstants.ORDER.METADATA.GET.SUCCESS,
                myOrderMetadata: Immutable.fromJS(myOrderMetadata.body)
            });
            return;
        }).catch(err => {
            NotificationActions.showAlertDanger('orders.metadata.get.error');
            throw err;
        });
        return;
    }

    getOrderHistory(id) {
        let query = {
            'action-object': 'Order',
            offset: 0,
            size: 1
        };
        if (id) {
            query = {...query, 'object-id': id};
        }
        return Request.get('system/action-history').query(query).then(response => {
            Dispatcher.dispatch({
                actionType: OrderConstants.ORDER.HISTORY.SUCCESS,
                history: Immutable.fromJS(response.body.results)
            });
            return;
        }).catch(/* istanbul ignore next */err => {
            throw err;
        });
    }

    getUser(userId, actionType) {
        return Request.get(`user/${userId}`).then(user => {
            Dispatcher.dispatch({
                actionType: actionType,
                user: Immutable.fromJS(user.body)
            });

            if (actionType === OrderConstants.CLIENT.SELECT) {
                this.getDeliveryInfo(user.body.id);
            }
            return;
        }).catch(/* istanbul ignore next */err => {
            throw err;
        });
    }

    getUserFilters(usersType, term, offset, size) {
        offset = offset || 0;
        size = size || 20;
        Request.get('user').query({
            email: term,
            name: term,
            operator: 'OR',
            offset,
            size
        }).then(res => {
            Dispatcher.dispatch({
                actionType: usersType.SUCCESS,
                users: Immutable.fromJS(res.body.results)
            });
            return;
        });
        return;
    }

    getUserFiltersById(usersType, ids) {
        ids.forEach(id => {
            Request.get(`user/${id}`).then(res => {
                Dispatcher.dispatch({
                    actionType: usersType.APPEND,
                    user: Immutable.fromJS(res.body)
                });
                return;
            }).catch(/* istanbul ignore next */e => {
                throw e;
            });

            return;
        });
        return;
    }

    getUsers(usersId) {
        return Promise.all(usersId.map(userId => {
            return Request.get(`user/${userId}`).then(user => {
                Dispatcher.dispatch({
                    actionType: OrderConstants.USER.GET.SUCCESS,
                    user: Immutable.fromJS(user.body)
                });
                return;
            });
        }));
    }

    getUsersList(term) {
        let params = {
            email: term,
            name: term,
            operator: 'OR',
            offset: 0,
            size: 10
        };

        return Request.get('user').query(params).then(usersList => {
            Dispatcher.dispatch({
                actionType: OrderConstants.USERS_LIST.GET.SUCCESS,
                usersList: Immutable.fromJS(usersList.body.results)
            });
            return;
        }).catch(/* istanbul ignore next */err => {
            throw err;
        });
    }

    instantOrderVideo(titleId, videos, addChildren = false) {
        // Start with all assets and add the children only if needed.
        let videosPromise = Promise.resolve(videos);
        if (addChildren) {
            videosPromise = Promise.all(
                // Request the children of each video asset.
                videos.map(
                    v => Request.get(`title/${titleId}/web/asset/${v.get('assetId')}/stack-asset`).query({offset: 0, size: 1000}).then(r => r).catch(err => {
                        NotificationActions.showAlertDanger('cart.instant.order.video.error.failed-to-get-children', false, v.get('assetComment'));
                        console.error(err);
                        return {body: []};
                    })
                )
            ).then(childrenResponses => {
                // Create a single array containing all the assets in videos and all
                // the children of each video.
                return videos.concat(
                    childrenResponses.map(cr => cr.body).reduce(
                        (r, childrenArray) => r.concat(childrenArray.map(c => Immutable.fromJS(c))),
                        Immutable.List()
                    )
                );
            });
        }

        videosPromise.then(allVideos => {
            const filteredVideos = allVideos.filter(video => {
                // Remove videos that are not pre-approved.
                if (video.get('deliveryType') === AssetTypeConstants.DELIVERY_TYPES.NON_SERVICEABLE) {
                    NotificationActions.showAlertDanger('cart.instant.order.video.error.children-is-non-serviceable', false, video.get('assetComment'));
                    return false;
                }
                return true;
            });

            return Promise.all(filteredVideos.map(
                video => Request.post(`order/video-on-demand/${video.get('assetId')}`).then(
                    () => NotificationActions.showAlertSuccess('cart.instant.order.video.single.success', false, video.get('assetComment'))
                ).catch(err => {
                    NotificationActions.showAlertDanger('cart.instant.order.video.single.error', false, video.get('assetComment'));
                    throw err;
                })
            ));
        }).catch(err => {
            throw err;
        });
    }

    photoSwipeRemove(assetType, order, titles, assetId) {
        let itemId;
        const titleIndex = titles.findIndex((title)=>{
            const item = title.get('items').find(i => i.get('assetId') === assetId);
            /* istanbul ignore else */
            if (item) {
                itemId = item.get('id');
                return true;
            }
        });

        this.remove(assetType, order.get('id'), titleIndex, itemId);
    }

    placeOrder(cart, orderTitles) {
        let cartjs = cart.toJS();
        cartjs.orderDate = new Date();

        if (cartjs.deliveryFormat === OrderConstants.VIDEO_FILE_FORMAT_OPTIONS.PRO_RES.id) {
            cartjs.deliveryFormat = OrderConstants.VIDEO_FILE_FORMAT_OPTIONS.PRO_RES.idForAPI;
        }

        Request.put(`order/${cartjs.id}`)
            .send(cartjs)
            .then(() => this.updateVideoFileFormatItems(cartjs.id, cartjs.deliveryFormat, orderTitles))
            .then(() => Request.put(`order/${cartjs.id}/submit-cart`).send({}))
            .then(() => this.saveDeliveryFormatDefaults(cart))
            .then(() => {
                Analytics.orderStatusChangeEvent(cartjs.id, OrderConstants.ORDER.STATUS_BY_NAME.REQUESTED, cartjs.clientUserId, cartjs.requesterUserId, false);

                RouterActions.redirect('/account/my-orders');
                NotificationActions.showAlertSuccess('cart.submit.ok');
                this.getById(); // get new cart
            }).catch(err => {
                NotificationActions.showAlertDanger('cart.submit.error');
                throw err;
            });
    }

    reloadToCart(orderId) {
        Dispatcher.dispatch({
            actionType: OrderConstants.ORDER.RELOAD_TO_CART.SET,
            orderId: orderId
        });
    }

    remove(assetType, cartId, titleIndex, itemId) {
        Dispatcher.dispatch({
            actionType: OrderConstants.REMOVE,
            item: Immutable.fromJS({
                assetType: assetType,
                cartId: cartId,
                titleIndex: titleIndex,
                itemId: itemId
            })
        });
    }

    reprocessOrder(orderId) {
        Request.put(`order/${orderId}/reprocess`).then(() => {
            NotificationActions.showAlertSuccess('orders.reprocess-order.success');
        }).catch((err) => {
            NotificationActions.showAlertDanger('orders.reprocess-order.error');
            throw err;
        });
    }

    saveDeliveryFormatDefaults(cart) {
        const cartjs = cart.toJS();

        if (cartjs.saveAsDefault) {
            let updateProfile = {
                downloadRenditionType: cartjs.downloadRenditionType,
                fileFormat: cartjs.deliveryFormat
            };

            // Note: this will return 404 if the user has no onboarding profile
            return Request.get(`user/${cartjs.deliverToUserId}/delivery-profile`).then((profileRes) => {
                let profile = profileRes.body;
                profile = Object.assign(profile, updateProfile);

                return Request.put(`user/${cartjs.deliverToUserId}/delivery-profile`).send(profile);
            }).catch(/* istanbul ignore next */(err) => {
                /* istanbul ignore next */
                if (err.status === 404) {
                    // Create a new onboarding profile with just the two delivery format fields
                    return Request.put(`user/${cartjs.deliverToUserId}/delivery-profile`)
                        .send(updateProfile)
                        .catch((errPut) => {
                            throw errPut;
                        });
                }

                throw err;
            });
        }
    }

    saveForLater(cart, orderTitles) {
        let cartjs = cart.toJS();
        let deliveryFormat = cartjs.deliveryFormat;

        if (deliveryFormat === OrderConstants.VIDEO_FILE_FORMAT_OPTIONS.PRO_RES.id) {
            cartjs.deliveryFormat = OrderConstants.VIDEO_FILE_FORMAT_OPTIONS.PRO_RES.idForAPI;
        }

        Request.put(`order/${cartjs.id}`).send(cartjs).then(
            () => Request.post('order/cart').send({})
        ).then(resp => {
            this.updateVideoFileFormatItems(cartjs.id, cartjs.deliveryFormat, orderTitles);
            this.saveDeliveryFormatDefaults(cart);
            RouterActions.redirect('/account/my-saved-carts');
            NotificationActions.showAlertSuccess('cart.view.save.ok');
            let newCart = resp.body;
            newCart.titles=this.buildTree([], []);
            Dispatcher.dispatch({
                actionType: OrderConstants.CART.SAVE_OK,
                cart: Immutable.fromJS(newCart)
            });
        }).catch(err => {
            NotificationActions.showAlertDanger('cart.view.save.error');
            throw err;
        });
    }

    saveOrder(order) {
        return Request.put(`order/${order.get('id')}`).send(order.toJS());
    }

    setSort(sortFieldName, sortDirection) {
        Dispatcher.dispatch({
            actionType: OrderConstants.ORDER.SORT.SET,
            sortFieldName: sortFieldName,
            sortDirection: sortDirection
        });
    }

    setDefaultCartDeliveryFormat(cart) {
        const userId = SessionStore.getState().get('authUser').get('id');
        Request.get(`user/${userId}/delivery-profile`)
            .then(res => {
                const profile = res.body;
                let selectedVideoDeliveryFormat;
                if (
                    cart.status !== OrderConstants.ORDER.STATUS_BY_NAME.SAVED
                ) {
                    selectedVideoDeliveryFormat = OrderStore.getVideoDeliveryFormatType(profile.fileFormat).toJS();
                }
                //BRAIN-3194 if user doesn't have a prefered video delivery format, default to XDCAM
                if ((profile.fileFormat === null)) {
                    selectedVideoDeliveryFormat = OrderStore.getVideoDeliveryFormatType(OrderConstants.VIDEO_FILE_FORMAT_OPTIONS.XDCAM.id).toJS();
                }

                // BRAIN-3145 if user doesn't have a prefered image delivery format, default to HiRes
                let selectedDownloadRenditionTypeId = profile.downloadRenditionType;
                if (selectedDownloadRenditionTypeId === null) {
                    selectedDownloadRenditionTypeId = DeliveryConstants.IMAGE_DELIVERY_TYPES.HIGH_RESOLUTION.id;
                }

                this.updateAttr('deliveryFormat', selectedVideoDeliveryFormat.id);
                this.updateAttr('downloadRenditionType', selectedDownloadRenditionTypeId);

                return;
            })
            .catch((err) => {
                throw err;
            });
        return;
    }

    // itemIds where needed before, but now the cartId is enough to download
    // all items in the cart. However, I'm leaving it here, just in case the
    // API changes again. Eventually, we can remove it from the params list.
    startDownload(isCart, cartId, rendition, toRemove, nextPage) {
        let removeOrderedItems = false;
        if (Immutable.List.isList(toRemove) && toRemove.size > 0) {
            removeOrderedItems = true;
        }

        Request.get('asset/download').query({
            'order-id': cartId,
            'rendition-type': rendition,
            'remove-order-items': removeOrderedItems,
        }).then(res => {
            Dispatcher.dispatch({
                actionType: DownloadConstants.EXECUTION_ID.GET.SUCCESS,
                downloadExecutionId: res.body.downloadExecutionId
            });
            RouterActions.redirect(nextPage);

            DownloadActions.watchDownloadExecution(res.body.downloadExecutionId)
                .subscribe(
                    action => Dispatcher.dispatch(action),
                    null,
                    () => {
                        if (removeOrderedItems) {
                            Dispatcher.dispatch({
                                actionType: OrderConstants.REMOVE_OK,
                                assetType: AssetTypeConstants.ASSET_TYPE.IMAGE,
                                items: toRemove,
                                isCart
                            });
                            NotificationActions.showAlertSuccess('cart.image.delete.success');
                        }
                    }
                );

            return;
        }).catch(err => {
            NotificationActions.showAlertDanger('cart.download.all.error');
            throw err;
        });
    }

    statusChange(orderId, newStatus, closeFunction) {
        Request.put(`order/${orderId}/force-update`)
            .send({status: newStatus})
            .then(() => {
                const order = OrderStore.getState().get('order');
                Analytics.orderStatusChangeEvent(order.get('id'), newStatus, order.get('clientUserId'), order.get('requesterUserId'), true);
                NotificationActions.showAlertSuccess('orders.status.change.modal.success');
                closeFunction(true);
            })
            .catch(() => {
                NotificationActions.showAlertDanger('orders.status.change.modal.error');
                closeFunction();
            });
    }

    statusChangeHide() {
        Dispatcher.dispatch({
            actionType: OrderConstants.ORDER.STATUS_CHANGE_HIDE
        });
    }

    statusChangeShow(orderId) {
        Dispatcher.dispatch({
            actionType: OrderConstants.ORDER.STATUS_CHANGE_SHOW,
            orderId
        });
    }

    toggleSelect(index, value) {
        Dispatcher.dispatch({
            actionType: OrderConstants.ORDER.TOGGLE_SELECT,
            index: index,
            value: value
        });

        return;
    }

    toggleSelectAll(value) {
        Dispatcher.dispatch({
            actionType: OrderConstants.ORDER.TOGGLE_SELECT_ALL,
            value: value
        });

        return;
    }

    unsubscribeClient(subscriptionContentType, titleId, userId) {
        Dispatcher.dispatch({
            actionType: OrderConstants.TITLE.UNSUBSCRIBE_CLIENT.SET,
            subscriptionContentType: subscriptionContentType,
            titleId: titleId,
            userId: userId
        });
    }

    unsubscribeClientsHide() {
        Dispatcher.dispatch({
            actionType: OrderConstants.ORDER.UNSUBSCRIBE_CLIENT_HIDE
        });
    }

    unsubscribeClientsShow() {
        Dispatcher.dispatch({
            actionType: OrderConstants.ORDER.UNSUBSCRIBE_CLIENT_SHOW
        });
    }

    unsubscribeSelectedClients(selectedClients) {
        Promise.all(
            selectedClients.map( c => {
                return Request.del(`title/${c.get('titleId')}/user-subscription/${c.get('userId')}/subscription-type/${c.get('subscriptionContentType')}`).then(() => {
                    Analytics.userTitleUnsubscribeEvent(c.get('titleId'), c.get('subscriptionContentType'));
                }).catch(err => {
                    let errInfo = `${c.get('clientFullName')} from ${c.get('titleName')} - ${OrderConstants.SUBSCRIPTION_CONTENT_TYPES[c.get('subscriptionContentType')]}`;
                    NotificationActions.showAlertDanger('orders.tab.subscriptions.unsubscribe-clients.client.error', false, errInfo);
                    err.defaultPrevented = true;
                    throw err;
                });
            })
        ).then(() => {
            NotificationActions.showAlertSuccess('orders.tab.subscriptions.unsubscribe-clients.success');
            return this.get(OrderConstants.ORDER.TAB.SUBSCRIPTIONS.url, {
                offset: 0,
                size: 40
            }, 'user_id', 'desc');
        }).catch(err => {
            if (!err.defaultPrevented) {
                NotificationActions.showAlertDanger('orders.tab.subscriptions.unsubscribe-clients.error');
            }
            throw err;
        });
    }

    updateAttr(attr, value) {
        Dispatcher.dispatch({
            actionType: OrderConstants.ORDER.UPDATE,
            attr: attr,
            value: value
        });
    }

    updateVideoFileFormatItems(cartId, deliveryFormatId, orderTitles) {
        orderTitles = orderTitles.toJS();
        let videos = orderTitles.video.concat(orderTitles['video-user-clip']).reduce((allVideos, videoItem) => {
            videoItem.items = videoItem.items.map(item => {
                item.deliveryFormatType = deliveryFormatId;
                return item;
            });
            return [...allVideos, ...videoItem.items];
        }, []);

        // assets from reach will cause the api to return a 404, preventing the order from being submitted.
        videos = videos.filter(v => v.sourceSystemType !== 'reach');
        if (!videos.length) {
            return;
        }

        videos = videos.map(v => {
            // Only send the two fields the API is using for this request.
            // This prevents a 403 error from cloudflare related to html in the asset fields! See BRAIN-3645
            return {
                id: v.id,
                deliveryFormatType: v.deliveryFormatType
            };
        });

        return Request.put(`order/${cartId}/item/delivery-format`).send(videos)
            .catch((err) => {
                NotificationActions.showAlertDanger('cart.update-file-format-items.error');
                throw err;
            });
    }
}

let actions = new OrderActions(OrderConstants);

export {
    actions as OrderActions,
};
