/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {Alert} from 'react-bootstrap';
import {Transition, TransitionGroup} from 'react-transition-group';

import {SEVERITY} from '../../common/validations/validations';
import {MessagesContext} from '../../messages/messages-context';
require('./alert-warning.less');

const TEXT_COLORS = {
    danger: 'text-red',
    info: 'text-blue',
    warning: 'text-yellow'
};

class Validation extends React.Component {
    static get propTypes() {
        return {
            colorIndicators: PropTypes.bool,
            icon: PropTypes.string,
            validation: PropTypes.object.isRequired,
            type: PropTypes.oneOf(['danger', 'info', 'warning'])
        };
    }

    static get defaultProps() {
        return {
            colorIndicators: false,
            icon: 'fa-ban',
            type: undefined
        };
    }

    render() {
        let classNames = ['far'];
        let textColor = 'text-yellow';
        if (this.props.validation.severity === SEVERITY.ALERT) {
            textColor = 'text-red';
        }

        if (this.props.type) {
            textColor = TEXT_COLORS[this.props.type];
        }

        let icon = this.props.icon;
        if (this.props.validation.valid) {
            icon = 'fa-check';
        }
        classNames.push(icon);

        if (this.props.colorIndicators) {
            if (this.props.validation.valid) {
                classNames.push('text-success');
            } else {
                classNames.push('text-danger');
            }
        }

        return (
            <li>
                <span className={textColor}>
                    <i className={ClassNames(classNames)}/>
                    &nbsp;{this.props.validation.message}
                </span>
            </li>
        );
    }
}

class AlertsWarnings extends React.Component {
    static get propTypes() {
        return {
            children: PropTypes.node,
            colorIndicators: PropTypes.bool,
            title: PropTypes.string.isRequired,
            type: PropTypes.oneOf(['danger', 'info', 'password-reset']),
            validations: PropTypes.array.isRequired
        };
    }

    static get defaultProps() {
        return {
            children: undefined,
            colorIndicators: false,
            type: 'danger'
        };
    }

    static contextType = MessagesContext;

    render() {
        let headerClassName;
        let title = this.props.title;
        let titleIcon;
        if (!this.props.title) {
            title = this.context.intl.messages['common.alerts-warnings.title'];
        }

        if (this.props.validations.length === 0) {
            return null;
        }

        let alertProps = {};
        if (this.props.type === 'password-reset') {
            alertProps.bsClass = 'alert alert-password-reset';
            headerClassName = 'padding-x-20 padding-y-10';
        } else {
            alertProps.bsStyle = this.props.type;
            titleIcon = <i className="far fa-flag"/>;
        }

        return (
            <Alert {...alertProps} title={title}>
                <h4 className={headerClassName}>{titleIcon} {title}</h4>
                <ul className="error-list">
                    <TransitionGroup
                        transitionName="alert"
                        transitionEnterTimeout={300}
                        transitionLeaveTimeout={300}>
                        {this.props.validations.reduce((r, v) => {
                            /* istanbul ignore next */
                            if ((v.type !== 'required') && v.message) {
                                r.push(v);
                                return r;
                            }
                            // If v is of type required, then check if any required validation is already added
                            // to r.
                            // If any, return r.
                            /* istanbul ignore next */
                            if (v.type === 'required' && r.some(vv => vv.type === 'required')) {
                                return r;
                            }
                            // If not, add the required validation.
                            // r.push({
                            //     message: <span><b>One or more required fields missing</b> - Please complete all fields marked with *</span>,
                            //     severity: SEVERITY.ALERT,
                            //     type: 'required'
                            // });

                            return r;
                        }, []).map((v, i) =>
                            <Transition>
                                <Validation colorIndicators={this.props.colorIndicators} key={i} type={this.props.type} validation={v}/>
                            </Transition>
                        )}
                    </TransitionGroup>
                </ul>
                {this.props.children}
            </Alert>
        );
    }
}

export {
    AlertsWarnings,
    Validation
};
