/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */
import {PlayerEvent as BitmovinEvent} from 'bitmovin-player';

/**
 * @name PlayerController
 * @class
 */


/**
 * init
 * @method
 * @name PlayerController#init
 * @param {object} [p]
 */
/**
 * timeAccuracyCoefficient
 * @property {number} PlayerController#timeAccuracyCoefficient
 */
/**
 * is controller initialized
 * @method
 * @name PlayerController#isInitialized
 * @param {boolean}
 */
/**
 * dispose
 * @method
 * @name PlayerController#dispose
 */
/**
 * Get/set current time
 * @method
 * @name PlayerController#currentTime
 * @param {number} [time]
 * @returns {number} current time
 */
/**
 * Get video duration
 * @method
 * @name PlayerController#duration
 * @returns {number} duration of the video
 */
/**
 * Check if keyboard's event will handle by player
 * @method
 * @name PlayerController#isHandledKeyboardEvent
 * @param ev {KeyboardEvent}
 * @returns {boolean}
 */
/**
 * Is video playing
 * @method
 * @name PlayerController#isPlaying
 * @returns {boolean}
 */
/**
 * pause the video
 * @method
 * @name PlayerController#pause
 * @returns {void}
 */
/**
 * play the video
 * @method
 * @name PlayerController#play
 * @returns {void}
 */
/**
 * Get/set playback rate the video
 * @method
 * @name PlayerController#playbackRate
 * @param {number} [playbackRate]
 * @returns {number} playback rate
 */
/**
 * reset playback rate to default
 * @method
 * @name PlayerController#resetPlaybackRate
 */

export const KEY_CODES = {
    ENTER: 13,
    SPACE: 32,
};

/**
 * create controller for Bitmovin
 * @return {PlayerController}
 */
export function createHotkeyControllerFromBitmovin() {
    let player = null;
    return Object.freeze({
        currentTime() {
            if (arguments.length) {
                const time = Math.max(0, Math.min(arguments[0], this.duration()));
                player.seek(time);
            }
            return player.getCurrentTime();
        },
        dispose() {
            player.off(BitmovinEvent.PlaybackFinished, this.resetPlaybackRate);
            player.off(BitmovinEvent.Paused, this.resetPlaybackRate);
            player.off(BitmovinEvent.Seek, this.resetPlaybackRate);
        },
        duration() {
            return player.getDuration();
        },
        init(p) {
            player = p;
            player.on(BitmovinEvent.PlaybackFinished, this.resetPlaybackRate);
            player.on(BitmovinEvent.Paused, this.resetPlaybackRate);
            player.on(BitmovinEvent.Seek, this.resetPlaybackRate);
        },
        isHandledKeyboardEvent(ev) {
            const el = player.getContainer();
            if (!(el instanceof Node)) {
                return false;
            }

            let res = false;
            // when player in focus
            if (el.contains(document.activeElement)) {
                // skip `enter` and `space` is amp in focus
                res = res || [KEY_CODES.ENTER, KEY_CODES.SPACE].some(k => ev.which === k);
            }

            // skip everything if menu is open
            res = res || !!el.querySelectorAll('.bmpui-ui-settings-panel:not(.bmpui-hidden)').length;

            return res;
        },
        isInitialized() {
            return !!player;
        },
        isPlaying() {
            return player.isPlaying();
        },
        pause() {
            return player.pause();
        },
        play() {
            return player.play();
        },
        playbackRate() {
            if (arguments.length) {
                player.setPlaybackSpeed(arguments[0]);
            }
            return player.getPlaybackSpeed();
        },
        resetPlaybackRate() {
            player.setPlaybackSpeed(1);
        },
        timeAccuracyCoefficient: Math.pow(10, 2),
    });
}
