/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

/**
 * @typedef {Object} TimeData
 * @property {number} hours - Hours
 * @property {number} minutes - Minutes
 * @property {number} seconds - Seconds
 * @property {number} ms - MilliSeconds
 */

/**
 * @typedef {Object} FrameData
 * @property {number} hours - Hours
 * @property {number} minutes - Minutes
 * @property {number} seconds - Seconds
 * @property {number} frames - MilliSeconds
 */

/**
 * Timecode factory
 * @param {String} [offset='00:58:00:00'] offset of the video
 * @param {number} [frameRate=23.976] Frame rate
 */
export function timecodeFactory({offset, frameRate} = {}) {
    const offsetInSeconds = timeToSeconds(typeof offset === 'string' ? offset : '00:00:00:000');
    frameRate = typeof frameRate === 'number' ? frameRate : 23.976;

    /**
     * convert seconds to TimeCode
     * @param {number} [value] seconds
     * @returns {String} TimeCode
     */
    function secondsToTimecode(value) {
        return frameDataToTimecode(secondsToFrameData(value, frameRate));
    }

    /**
     * convert seconds to TimeCode
     * @param {FrameData} [frameData] seconds
     * @returns {String} TimeCode
     */
    function frameDataToTimecode(frameData) {
        const seconds = offsetInSeconds + timeDataToSeconds({
            hours: frameData.hours,
            minutes: frameData.minutes,
            seconds: frameData.seconds,
            ms: 0,
        });

        const timeData = secondsToTimeData(seconds);
        return convertToString([timeData.hours, timeData.minutes, timeData.seconds, frameData.frames]);
    }

    /**
     * convert time to TimeCode
     * @param {String} [value] time
     * @returns {String} TimeCode
     */
    function timeToTimecode(value) {
        return secondsToTimecode(timeToSeconds(value));
    }

    /**
     * convert TimeCode to time
     * @param {String} [value] TimeCode
     * @returns {String} time
     */
    function timecodeToTime(value) {
        return secondsToTime(timecodeToSeconds(value));
    }

    /**
     * convert TimeCode to seconds
     * @param {String} [value] TimeCode
     * @returns {number} seconds
     */
    function timecodeToSeconds(value) {
        const frameData = timecodeToFrameData(value);
        const seconds = timeDataToSeconds({
            hours: frameData.hours,
            minutes: frameData.minutes,
            seconds: frameData.seconds,
            ms: 0,
        }) - offsetInSeconds;

        const timeData = secondsToTimeData(seconds);

        const framesCount = frameDataToFrames({
            hours: timeData.hours,
            minutes: timeData.minutes,
            seconds: timeData.seconds,
            frames: frameData.frames,
        }, frameRate);

        return framesCount / frameRate;
    }

    function secondsToFrames(seconds) {
        return framesInSeconds(seconds, frameRate);
    }

    /**
     * Convert frames to seconds
     * @param {number} [framesCount] count of frame
     * @return {number} seconds
     */
    function framesToSeconds(framesCount) {
        return framesCount / frameRate;
    }

    /**
     * Convert frames to time
     * @param {number} [framesCount] count of frame
     * @param {boolean} [showMs] show ms or not
     * @return {string} time
     */
    function framesToTime(framesCount, showMs = true) {
        return secondsToTime(framesCount / frameRate, showMs);
    }

    /**
     * Convert frames to timecode
     * @param {number} [framesCount] count of frame
     * @return {string} timecode
     */
    function framesToTimecode(framesCount) {
        return frameDataToTimecode(framesToFrameData(framesCount, frameRate));
    }

    return {
        framesToSeconds, framesToTime, framesToTimecode,
        timecodeToSeconds, timecodeToTime, timeToTimecode,
        secondsToFrames, secondsToTime, secondsToTimecode,
    };
}

/**
 * Convert Time to FrameData
 * @param {String} [timecode] Time
 * @returns {FrameData} FrameData
 */
function timecodeToFrameData(timecode) {
    return parse(timecode, '^([012]\\d):(\\d\\d):(\\d\\d):(\\d\\d)$', 'frames');
}

/**
 * Detect count of frames
 * @param {FrameData} [frameData] FrameData
 * @param {number} [frameRate] Frame rate
 * @returns {number} count of frames
 */
function frameDataToFrames(frameData, frameRate) {
    return (frameData.hours * 3600 + frameData.minutes * 60 + frameData.seconds) * Math.round(frameRate) + frameData.frames;
}

/**
 * Convert seconds to FrameData
 * @param {number} [value] Seconds
 * @param {number} [frameRate] frame rate
 * @returns {FrameData} FrameData
 */
function secondsToFrameData(value, frameRate) {
    return framesToFrameData(framesInSeconds(value, frameRate), frameRate);
}

/**
 * Convert frames to FrameData
 * @param {number} [totalFramesCount] frames
 * @param {number} [frameRate] frame rate
 * @returns {FrameData} FrameData
 */
function framesToFrameData(totalFramesCount, frameRate) {
    const roundFrameRate = Math.round(frameRate);

    const hours = Math.floor(totalFramesCount / (roundFrameRate * 3600)) % 24;
    const minutes = Math.floor(totalFramesCount / (roundFrameRate * 60)) % 60;
    const seconds = Math.floor(totalFramesCount / roundFrameRate) % 60;
    const framesCount = totalFramesCount % roundFrameRate;

    return {hours, minutes, seconds, frames: framesCount};
}

/**
 * convert seconds to seconds
 * @param {number} [value] seconds
 * @param {number} [frameRate] frame rates
 * @return {number} ount of frames
 */
function framesInSeconds(value, frameRate) {
    return Math.round(value * frameRate);
}

/**
 * Convert time string to seconds
 * @param {String} [time] Time string
 * @param {string} [pattern] pattern for parsing
 * @returns {number} seconds
 */
export function timeToSeconds(time, pattern = '^([012]\\d):(\\d\\d):(\\d\\d):(\\d\\d\\d)$') {
    const timeData = parse(time, pattern, 'ms');
    return timeDataToSeconds(timeData);
}

/**
 * Convert seconds to time string
 * @param {number} [seconds] seconds
 * @param {boolean} [showMs] show ms or not
 * @returns {String} Time string
 */
function secondsToTime(seconds, showMs = true) {
    const data = secondsToTimeData(seconds);
    const arr = [data.hours, data.minutes, data.seconds];
    if (showMs) {
        arr.push(pad3(data.ms));
    }
    return convertToString(arr);
}

/**
 * Convert seconds to TimeData format
 * @param {number} [value] seconds
 * @returns {TimeData} TimeData
 */
export function secondsToTimeData(value) {
    const hours = Math.floor(value / 3600) % 24;
    const minutes = Math.floor(value / 60) % 60;
    const seconds = Math.floor(value) % 60;
    const ms = Math.round(value % 1 * 1000);
    return {hours, minutes, seconds, ms};
}

/**
 * Convert TimeData to seconds
 * @param {TimeData|FrameData} [value] TimeData
 * @returns {number} seconds
 */
function timeDataToSeconds(value) {
    return (value.hours * 3600) + (value.minutes * 60) + value.seconds + (value.ms / 1000);
}

/**
 * Convert Array of number to string with delim `:`
 * @param {number[]} [arr] array of numbers
 * @returns {String} Time string
 */
function convertToString(arr) {
    return arr.map(pad).join(':');
}

/**
 * Parse time string to TimeData or FrameData
 * @param {String} [str] time
 * @param {String} [pattern] pattern for parsing
 * @param {'ms'|'frames'} [factorialName] name of factorial part
 * @returns {TimeData|FrameData} TimeData or FrameData
 */
function parse(str, pattern, factorialName) {
    const parts = str.match(pattern);
    if (!parts) {
        throw new Error('Time string is incorrect');
    }
    const hours = parseInt(parts[1]);
    const minutes = parseInt(parts[2]);
    const seconds = parseInt(parts[3]);
    const factorial = parseInt(parts[4]);
    return {hours, minutes, seconds, [factorialName]: factorial};
}

/**
 * pad
 * @param {number} [number] value
 * @returns {String} res
 */
function pad(number) {
    if (typeof number !== 'number') {
        return number;
    }
    return number >= 10 ? '' + number : '0' + number;
}

/**
 * pad
 * @param {number} [number] value
 * @returns {String} res
 */
function pad3(number) {
    return ('0' + pad(number)).substr(-3);
}
