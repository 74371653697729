/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import {v4 as UUID} from 'uuid';

import {timeToSeconds} from '../common/timecode/time-code';

const VTT_TIME_DELIM = '-->';
const TIME_PATTERN = '^([012]\\d):(\\d\\d):(\\d\\d).(\\d\\d\\d)$';

/**
 * common cue data
 * @typedef {Object} VTTCue
 * @property {number} startTime - start time in seconds
 * @property {number} endTime - end tim in seconds
 * @property {string} text - text content
 * @property {string} id - id
 */


export class VideoPlayerThumbnails {
    static GetVTTFile(vttFileURL) {
        return new Promise((onSuccess, onError) => {
            let request = new XMLHttpRequest();
            request.onload = () => {
                onSuccess(request.responseText);
            };
            request.onerror = () => {
                onError();
            };
            request.open('GET', vttFileURL, true);
            request.send();
        });
    }

    /**
     * parse vtt file
     * @param {string} data vtt data
     * @returns {Array<VTTCue>}
     */
    static ParseThumbnailTrack(data) {
        let array = data.split(/\n\s*\n+/gm);

        const format = array.shift().trim();
        if (format !== 'WEBVTT') {
            return [];
        }

        return array.filter((_) => _).map((str) => {
            const lines = str.split(/\n/g);

            const period = lines.shift().split(VTT_TIME_DELIM);
            const startTime = timeToSeconds(head(period).trim(), TIME_PATTERN);
            const endTime = timeToSeconds(last(period).trim(), TIME_PATTERN);

            const text = head(lines);

            return {startTime, endTime, text, id: UUID()};
        });
    }
}

function head(arr) {
    return arr[0];
}

function last(arr) {
    return arr[arr.length - 1];
}
