/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export default function ClippingThumbnailMark({className, onClick, thumbnailData, timecode, style}) {
    const thumbnailStyle = stylesForThumbnail(thumbnailData);
    const contentStyle = {...style, width: thumbnailStyle.width, height: thumbnailStyle.height};
    const lineStyle = {left: style.left.substr(1)};
    return (
        <div className={ClassNames('clipping-handle', className)} style={contentStyle} onClick={onClick}>
            <div className="clipping-handle-line" style={lineStyle} />
            <div className="clipping-handle-content">
                <div className="clipping-handle-content-thumbnail" style={thumbnailStyle}/>
                <div className="clipping-handle-content-tc">{timecode}</div>
            </div>
        </div>
    );
}

ClippingThumbnailMark.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    style: PropTypes.object,
    thumbnailData: PropTypes.object,
    timecode: PropTypes.string.isRequired,
};

ClippingThumbnailMark.defaultProps = {
    className: '',
    style: {},
    thumbnailData: null,
};

function stylesForThumbnail(data) {
    if (!data) {
        return {
            visibility: 'hidden',
            display: 'none',
        };
    }
    return {
        background: `url(${data.href}) ${-data.x}px ${-data.y}px`,
        width: `${data.width}px`,
        height: `${data.height}px`,
        top: `${-data.height - 40}px`,
    };
}
