/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Dispatcher} from '../flux-helpers';
import {NotificationActions} from '../notification/notification-actions';
import Request from '../request';

const CONSTANTS = {
    ACCOUNT: {
        CHANGE_LANGUAGE: {
            SUCCESS: 'account.change_language.success'
        },
        CLEAR: 'account.clear',
        CLEAR_UPDATE_MODAL: 'account.clear.update.modal',
        CLONE: 'account.clone',
        SET_TAB: {
            SUCCESS: 'account.set_tab.success'
        },
        SHOW_UPDATE_MODAL: 'account.show.update.modal',
        TAB: {
            INFO: 1,
            SUBSCRIPTION: 2,
            SUBSCRIBED_CONTENT: 3,
            MY_ORDERS: 5,
            MY_SAVED_CARTS: 6,
            MY_SAVED_SEARCHES: 7,
            DOWNLOAD_HISTORY: 8,
            METADATA:9
        },
        TOGGLE_REQUEST_MODAL: 'account.toggle.request.modal',
        UPDATE_ATTR: 'account.update.attr',
        UPDATE: 'account.update',

        toArray: function(constant) {
            return Object.keys(this[constant])
                .map(k => this[constant][k]);
        }
    }
};

class AccountActions {

    changeLanguage(accountInfo) {
        Request.put(`user/${accountInfo.get('id')}/default-language-code`).send({
            defaultLanguageCode: accountInfo.get('defaultLanguageCode')
        }).then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.ACCOUNT.CHANGE_LANGUAGE.SUCCESS,
                languageCode: accountInfo.get('defaultLanguageCode')
            });
            NotificationActions.showAlertSuccess('account.tab.info.language.update.success');
            return;
        }).catch(err => {
            NotificationActions.showAlertDanger('account.tab.info.language.update.error');
            throw err;
        });
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ACCOUNT.CLEAR
        });
    }

    clearUpdateModal() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ACCOUNT.CLEAR_UPDATE_MODAL
        });
    }

    cloneUser() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ACCOUNT.CLONE
        });
    }

    requestUpdate(accountInfo) {
        Request.post(`user/${accountInfo.get('id')}/request-update`).send({
            companyName: accountInfo.get('companyName'),
            country: accountInfo.get('country'),
            email: accountInfo.get('email'),
            name: accountInfo.get('name'),
            lastName: accountInfo.get('lastName')
        }).then(() => {
            NotificationActions.showAlertSuccess('account.tab.info.request.update.success');
            return;
        }).catch(err => {
            NotificationActions.showAlertDanger('account.tab.info.request.update.error');
            throw err;
        });
    }

    setActiveTab(tabNumber) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ACCOUNT.SET_TAB.SUCCESS,
            activeTab: tabNumber
        });
    }

    showUpdateModal(flag) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ACCOUNT.SHOW_UPDATE_MODAL,
            flag: flag
        });
    }

    updateAttr(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ACCOUNT.UPDATE_ATTR,
            attr: attr,
            value: value
        });
    }

    update(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ACCOUNT.UPDATE,
            attr,
            value
        });

        return;
    }

}

let actions = new AccountActions();

export {
    actions as AccountActions,
    CONSTANTS as AccountConstants,
};
