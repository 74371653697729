/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

export default function Disclaimer({seconds, text: disclaimerText}) {
    const text = disclaimerText.replace(/\n/g, '<br/>');
    return (
        <div className="player-container">
            <div className="disclaimer-overlay">
                <h3 dangerouslySetInnerHTML={{__html: text}} />
                <div className="disclaimer-timer">
                    <i className="glyphicon glyphicon-time"/>
                    <FormattedMessage id="titles.videos.video-countdown" values={{seconds}} />
                </div>
            </div>
        </div>
    );
}

Disclaimer.propTypes = {
    seconds: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
};
