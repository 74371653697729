/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {from, of, timer} from 'rxjs';
import {concatMap, filter, finalize, flatMap, map, takeWhile} from 'rxjs/operators';

import {Dispatcher} from '../../flux-helpers';
import {NotificationActions} from '../../notification/notification-actions';
import Request from '../../request';
import {RouterActions} from '../../router/router-actions';
import {StartDownload} from '../../utils/utils';

// FIXME: rename to download_actions
const CONSTANTS = {
    CLEAR: 'download_actions.clear',
    EXECUTION_ID: {
        GET: {
            SUCCESS: 'execution_id.get.success'
        }
    },
    EXECUTION_STATUS: {
        START: 1,
        RUNNING: 2,
        COMPLETED: 3,
        FAILED: 4,
        GET: {
            END: 'execution_status.get.end',
            START: 'execution_status.get.start',
            SUCCESS: 'execution_status.get.success'
        }
    }
};

class DownloadActions {
    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    downloadExecutionZip(executionId) {
        return Request.get(`asset/download-execution/${executionId}/file`).then((res) => {
            // Start the download by simulating a click in the browser.
            StartDownload(res.body.downloadUrl);
        }).catch(err => {
            NotificationActions.showAlertDanger('asset.download.error');
            throw err;
        });
    }

    startDownloadExecution(endpointUrl, query = {}, acceptHeader = '') {
        let req = Request.get(endpointUrl).query(query);
        if (acceptHeader !== '') {
            req = req.set('Accept', acceptHeader);
        }
        req.then(res => {
            const downloadExecutionId = res.body.downloadExecutionId;
            Dispatcher.dispatch({
                actionType: CONSTANTS.EXECUTION_ID.GET.SUCCESS,
                downloadExecutionId
            });
            RouterActions.redirect('/download');
            /* istanbul ignore next */
            this.watchDownloadExecution(downloadExecutionId)
                .subscribe(
                    action => Dispatcher.dispatch(action),
                    null,
                    () => void 0
                );
        });
    }

    /* istanbul ignore next */
    watchDownloadExecution(downloadExecutionId) {
        // Start watching the download.
        let requestInProgress = false;
        return timer(
            0, // Start now.
            500
        ).pipe(
            filter(() => !requestInProgress),
            flatMap(() => {
                requestInProgress = true;
                return from(Request.get(`asset/download-execution/${downloadExecutionId}`));
            }),
            map(downloadStatusRes => {
                requestInProgress = false;
                return {
                    actionType: CONSTANTS.EXECUTION_STATUS.GET.SUCCESS,
                    fileName: downloadStatusRes.body.fileName,
                    processed: downloadStatusRes.body.currentFileCount,
                    progressMessage: downloadStatusRes.body.progressMessage,
                    status: downloadStatusRes.body.status,
                    total: downloadStatusRes.body.totalFileCount
                };
            }),
            concatMap(action => {
                // Ugly hack because takeWhile doesn't include last predicate.
                if (
                    action.status === CONSTANTS.EXECUTION_STATUS.COMPLETED ||
                    action.status === CONSTANTS.EXECUTION_STATUS.FAILED
                ) {
                    return of(action, null);
                }

                return of(action);
            }),
            takeWhile(action => !!action),
            finalize(() => Dispatcher.dispatch({
                actionType: CONSTANTS.EXECUTION_STATUS.GET.END
            }))
        );
    }
}

const actions = new DownloadActions();

export {
    actions as DownloadActions,
    CONSTANTS as DownloadConstants,
};
