/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {AlertTypes} from './alert';
import {Dispatcher} from '../flux-helpers';

const CONSTANTS = {
    ALERT: {
        SHOW: 'alert.show'
    },
    CLEAR: 'notifications.clear',
    MODAL: {
        HIDE: 'notifications.modal.hide',
        SHOW: 'notifications.modal.show',
        SHOW_ID: 'notifications.modal.show_id',
        SHOW_ON_TOP: 'notifications.modal.show.on.top'
    },
    HIDE: 'notifications.hide'
};

class NotificationActions {
    clearQueue() {
        setTimeout(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.CLEAR
            });
        }, 0);

        return;
    }

    // Hides alerts or modals, hence the
    // generic name.
    hide(index = 0) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.HIDE,
            index
        });
        return;
    }

    // Specifically hide a modal by id.
    hideModal(modalId) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MODAL.HIDE,
            modalId
        });
        return;
    }

    showAlert(type, message, noDuplicates = false, ...args) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ALERT.SHOW,
            arguments: Immutable.fromJS(args),
            message,
            noDuplicates,
            type: Immutable.fromJS(type)
        });

        return;
    }

    showAlertInfo(message, noDuplicates = false, ...args) {
        this.showAlert(AlertTypes.ALERT_INFO, message, noDuplicates, ...args);
        return;
    }

    showAlertSuccess(message, noDuplicates = false, ...args) {
        this.showAlert(AlertTypes.ALERT_SUCCESS, message, noDuplicates, ...args);
        return;
    }

    showAlertDanger(message, noDuplicates = false, ...args) {
        this.showAlert(AlertTypes.ALERT_DANGER, message, noDuplicates, ...args);
        return;
    }

    showAlertWarning(message, noDuplicates = false, ...args) {
        this.showAlert(AlertTypes.ALERT_WARNING, message, noDuplicates, ...args);
        return;
    }

    show(type, title, message, confirmText, onConfirm, onCancel) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MODAL.SHOW,
            type: type,
            title: title,
            message: message,
            confirmText: confirmText,
            onConfirm: onConfirm,
            onCancel: onCancel
        });
        return;
    }

    showModal(modalId) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MODAL.SHOW_ID,
            modalId
        });

        return;
    }

    showOnTop(type, title, message, confirmText, onConfirm, onCancel) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MODAL.SHOW_ON_TOP,
            type: type,
            title: title,
            message: message,
            confirmText: confirmText,
            onConfirm: onConfirm,
            onCancel: onCancel
        });
        return;
    }
}

const actions = new NotificationActions();

export {
    actions as NotificationActions,
    CONSTANTS as NotificationConstants
};
