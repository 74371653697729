/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';
import url from 'url';

import {SessionActions, SessionConstants} from './session-actions';
import {AccountConstants} from '../account/account-actions';
import Config from '../config/config';
import {Dispatcher} from '../flux-helpers';
import {LandingConstants} from '../layout/landing/landing-actions';


let __timeoutInterval;

const POLLING_INTERVAL = 5000; // 5 seconds TODO: set a higher value

/**
 * Map of all permissions.
 */
const PERMISSIONS = {
    ASSETS: {
        DOCUMENTS: {
            STATION_DOCUMENTS: 'WBTVD://assets.documents.station-documents',
        }
    },
    CART: {
        IMAGES: {
            ADD: 'WBTVD://cart.images.add',
            ADD_ALL_TO_CART: 'WBTVD://cart.images.add-all-to-cart',
        },
        VIDEOS: {
            ADD: 'WBTVD://cart.videos.add',
            INSTANT_ORDER: 'WBTVD://cart.videos.instant-order'
        }
    },
    DOWNLOAD: {
        CLIPS: {
            ALE: 'WBTVD://orders.download-ale'
        },
        IMAGES: {
            FULL: 'WBTVD://download.images.full',
            LO_RES: 'WBTVD://download.images.lores',
            NEEDS_APPROVAL: 'WBTVD://download.images.approval',
            SOURCE: 'WBTVD://download.images.source',
            NON_SERVICEABLE: 'WBTVD://download.images.non.serviceable'
        },
        VIDEOS: 'WBTVD://orders.download.videos',
    },
    EDIT_IN_BRAINIAC: 'WBTVD://edit.in.brainiac',
    HAPPYFOX: {
        REQUESTS: {
            SUBMIT: 'WBTVD://happyfox.requests.submit'
        }
    },
    HEADER: {
        BRANDS_MENU: 'WBTVD://header.brands-menu'
    },
    MY_ACCOUNT: {
        TABS: {
            INFO: {
                ACCOUNT_INFO: 'WBTVD://my-account.tabs.info.account-info',
                REQUEST_ADDITIONAL_ACCESS: 'WBTVD://my-account.tabs.info.request-additional-access',
                SUBSCRIPTIONS: 'WBTVD://my-account.tabs.info.subscriptions',
            },
            MY_ORDERS: 'WBTVD://my-account.tabs.my-orders',
            MY_SAVED_CARTS: 'WBTVD://my-account.tabs.my-saved-carts',
            TITLE_PACKAGES: 'WBTVD://my-account.tabs.title-packages'
        }
    },
    ORDERS: {
        APPROVE: 'WBTVD://orders.approve',
        BROWSE: 'WBTVD://orders.browse',
        COMMENT_REQUIRED: 'WBTVD://orders.comment.required',
        DENY: 'WBTVD://orders.deny',
        EDIT_CLIENT_USER: 'WBTVD://orders.edit-client-user',
        FULFILL: 'WBTVD://orders.fulfill',
        REMOVE_INDIVIDUAL_ITEMS: 'WBTVD://orders.remove-individual-items',
        REPROCESS: 'WBTVD://orders.reprocess',
        SIDECAR_FILE: 'WBTVD://orders.include-sidecar-file',
        STATUS_CHANGE: 'WBTVD://orders.status.change',
    },
    PLAYER: {
        VIDEO_PLAYER_PREVIEW: 'WBTVD://player.video-player-preview'
    },
    RESTRICTED: {
        HOMEPAGEONLY: 'WBTVD://homepageonly.restricted'
    },
    SEARCH: {
        SAVE: 'WBTVD://search.save',
        SHARE: 'WBTVD://search.share',
        INCLUDE_API_URL: 'WBTVD://search.include-api-url',
        AUTO_SUGGEST: 'WBTVD://search.auto-suggest-for-wbtvd'
    },
    SHARE: {
        TITLES: 'WBTVD://share.titles',
        PRE_AUTH: 'WBTVD://share.preauth',
        SUBSCRIBE_CLIENT: 'WBTVD://share.subscribe-client'
    },
    TITLE: {
        CLIPS: {
            CREATE: 'WBTVD://title.clips.create',
            VIEW: 'WBTVD://title.clips.view',
        },
        DOWNLOAD_AS_SPREADSHEET: 'WBTVD://title.download-as-spreadsheet',
        FACT_SHEETS: {
            DOWNLOAD: 'WBTVD://title.fact-sheets.download',
            DOWNLOAD_ALL: 'WBTVD://title.fact-sheets.download-all'
        },
        NOTIFICATIONS: {
            DOCUMENTS: 'WBTVD://title.notifications.documents'
        },
        METADATA: {
            EXPORT_MULTIPLE_RELEASES: 'WBTVD://title.export.multiple-releases',
        },
        VIEW_ADDITIONAL_INFO: 'WBTVD://title.view-additional-info',
        VIEW_CLIP_LIBRARY: 'WBTVD://title.view-clip-library',
        VIEW_EXTERNAL_LINK: 'WBTVD://title.view-external-link',
        VIEW_FRONTEND_COMMENT: 'WBTVD://title.view-frontend-comment',
        VIEW_INTERNAL_LINK: 'WBTVD://title.view-internal-link',
        VIEW_LANGUAGE_AVAILABILITY: 'WBTVD://title.view-language-availability',
        VIEW_LEGAL_LINES: 'WBTVD://title.view-legal-lines',
        VIEW_MASTERING_INFO: 'WBTVD://view.mastering-info',
        VIEW_RELEASE_DATE: 'WBTVD://title.view-release-date',
        VIEW_MULTIPLE_RELEASE_DATES: 'WBTVD://title.view.multiple-releases',
        WORK_ORDERS: {
            ADD: 'WBTVD://title.work-orders.add',
            CREATE: 'WBTVD://title.work-orders.create',
            DOWNLOAD: 'WBTVD://title.work-orders.download',
            EDIT: 'WBTVD://title.work-orders.edit',
            EXPORT: 'WBTVD://title.work-orders.export',
            VIEW_DSD_LINK: 'WBTVD://title.work-orders.view.dsd.link'
        },
    },
    VIEW: {
        CART: 'WBTVD://cart.view',
        FULL_EPISODES: 'WBTVD://view.full-episodes',
    }
};

class SessionStore extends ReduceStore {

    constructor(dispatcher, permissions) {
        super(dispatcher);
        this.PERMISSIONS = permissions;
        // So you can call instance methods inside a constructor...
        // who would have guessed?
        /* istanbul ignore if */
        if (this.isSessionValid()) {
            this.__setInterval(POLLING_INTERVAL);
        }
    }

    __setInterval(poolingInterval) {
        if (__timeoutInterval) {return;}

        __timeoutInterval = setInterval(() => {
            this.__ticker(new Date(localStorage.__lastRequest));
        }, poolingInterval);
    }

    /* istanbul ignore next */
    __clearInterval() {
        if (!__timeoutInterval) {return;}

        clearInterval(__timeoutInterval);
        __timeoutInterval = null;
    }

    /* istanbul ignore next */
    __ticker(lastRequest) {
        let delta = new Date() - lastRequest;
        if (delta > (Config.SessionTimeout*1000)) {
            let page = window.location.pathname + window.location.search;
            SessionActions.logout('timeout', page);
        } else if (delta > (Config.SessionTimeoutWarning*1000)) {
            SessionActions.timeoutWarning();
        }
    }

    canUser(key) {
        return this.getState().get('permissions').some(/* istanbul ignore next */p => p.get('url') === key);
    }

    getAuthorization(type) {
        switch (type) {
        case 'jwt':
            const jwt = this.getState().getIn(['__jwt', 'token']);

            // If it's not already requesting a token.
            if (!this.getState().getIn(['__jwt', 'inProgress'])) {
                // Then renew it.
                SessionActions.renewJWT(jwt);
            }

            return jwt;
        default:
            return this.getState().get('__token');
        }
    }

    getInitialState() {
        let currentBrand = {};
        if (localStorage.getItem('__currentBrand')) {
            currentBrand = JSON.parse(localStorage.getItem('__currentBrand'));
        }

        return Immutable.fromJS({
            authUser: JSON.parse(localStorage.getItem('__user')) || {roles: []},
            defaultCopyright: localStorage.getItem('__defaultCopyright') || '© Warner Bros. Discovery, Inc.',
            __jwt: {
                inProgress: false,
                token: localStorage.getItem('__jwt')
            },
            eventIds: localStorage.getItem('__eventIds') || undefined,
            homepage: {
                contentType: localStorage.getItem('__homepageContentType'),
            },
            lastLoginTimestamp: localStorage.getItem('__lastLoginTimestamp'),
            login: {
                email: '',
                hasError: false,
                inProgress: false,
                password: '',
                sendOneTimeLinkInProgress: false
            },
            parentUser: JSON.parse(localStorage.getItem('__parentUser')) || undefined,
            partner: JSON.parse(localStorage.getItem('__partner')) || {},
            passwordReset: {
                confirmPassword: '',
                password: ''
            },
            permissions: JSON.parse(localStorage.getItem('__permissions')) || {},
            resendLoginCode: {
                complete: false,
            },
            showOneTimeWelcomeToast: JSON.parse(localStorage.getItem('__showOneTimeWelcomeToast')),
            timeout: undefined,
            __token: localStorage.getItem('__token'),
            __lastRequest: localStorage.getItem('__lastRequest'),
            currentBrand: currentBrand,
            userBrands: JSON.parse(localStorage.getItem('__userBrands')),
            userPartners: JSON.parse(localStorage.getItem('__userPartners')),
            mfaData: {
                userId: undefined,
                authorization: undefined,
                verifyCodeInProgress: false
            },
            isPartnerAuthLogin: false
        });
    }

    getParentUser() {
        return this.getState().get('parentUser');
    }

    getPartner() {
        const value = url.parse(window.location.href).hostname;
        let partner = Config.Partners.WBTVD;

        let results = Object.values(Config.Partners).filter(p => p.hostname === value);
        /* istanbul ignore if */
        if (results.length) {
            partner = results[0];
        }

        let currentBrand = this.getCurrentBrand();
        if (currentBrand && !currentBrand.isEmpty()) {
            currentBrand = currentBrand.toJS();
            partner = {
                ...partner,
                isBrandActive: true,
                brandId: currentBrand.id,
                brandLogo: currentBrand.logo
            };
        }

        return partner;
    }

    getTimeToLogout() {
        const delta = new Date() - new Date(localStorage.__lastRequest);
        const timeToLogout = (Config.SessionTimeout * 1000) - delta;

        /* istanbul ignore if */
        if (timeToLogout > 0) {
            return timeToLogout;
        }

        return 0;
    }

    getCurrentBrand() {
        try {
            // STUDIO-7762 prevent TypeError exception
            return this.getState().get('currentBrand');
        } catch {
            return Immutable.Map();
        }
    }

    getUserBrands() {
        return this.getState().get('userBrands');
    }

    getUserPartners() {
        return this.getState().get('userPartners');
    }

    hasRole(roleName) {
        return this.getState().getIn(['authUser', 'roles']).some(r => r.get('name') === roleName);
    }

    isACSUser() {
        return this.getState().getIn(['authUser', 'isACS'], false);
    }

    isBrandsUser() {
        return this.getState().getIn(['authUser', 'isBrandsUser'], false);
    }

    isBaftaUser() {
        return localStorage.getItem('__isBaftaUser');
    }

    isContentNotAvailable() {
        return this.getState().getIn(['homepage', 'contentType'], null) === 'NO_CONTENT_NO_DEFAULT';
    }

    isMultipleTitles() {
        return this.getState().getIn(['homepage', 'contentType'], null) === 'MULTIPLE_TITLES';
    }

    isSingleTitle() {
        return this.getState().getIn(['homepage', 'contentType'], null) === 'SINGLE_TITLE';
    }

    getAnonymousId() {
        return localStorage.getItem('__anonymousId');
    }

    isAdmin() {
        return this.hasRole('CMS Admin');
    }

    isSessionValid() {
        if (!this.getState().get('__token') || !this.getState().get('__lastRequest')) {
            return false;
        }
        let delta = new Date() - new Date(this.getState().get('__lastRequest'));
        return ( delta < (Config.SessionTimeout*1000));
    }

    logout(state) {
        localStorage.removeItem('__eventIds');
        localStorage.removeItem('__jwt');
        localStorage.removeItem('__partner');
        localStorage.removeItem('__parentUser');
        localStorage.removeItem('__permissions');
        localStorage.removeItem('__lastLoginTimestamp');
        localStorage.removeItem('__lastRequest');
        localStorage.removeItem('__token');
        localStorage.removeItem('__user');
        localStorage.removeItem('__currentBrand');
        localStorage.removeItem('__userBrands');
        localStorage.removeItem('__isBaftaUser');
        localStorage.removeItem('__anonymousId');
        localStorage.removeItem('__homepageContentType');
        localStorage.removeItem('__showOneTimeWelcomeToast');
        this.__clearInterval();
        return state.merge({
            __jwt: {
                inProgress: false,
                token: null
            },
            __token: null,
            authUser: Immutable.fromJS({
                roles: []
            }),
            homepage: Immutable.Map({}),
        });
    }

    reduce(state, action) {
        switch (action.actionType) {

        case AccountConstants.ACCOUNT.CHANGE_LANGUAGE.SUCCESS:
            state = state.setIn(['authUser', 'defaultLanguageCode'], action.languageCode);
            // Save the user to the local storage.
            localStorage.setItem('__user', JSON.stringify(state.get('authUser').toJS()));
            break;

        case LandingConstants.LOGIN_CONTENT.GET.SUCCESS:
            // if it's not null save the copyright to the local storage.
            if (action.defaultCopyright) {
                state = state.set('defaultCopyright', action.defaultCopyright);
                localStorage.setItem('__defaultCopyright', action.defaultCopyright);
            }

            break;

        case SessionConstants.ATTR.UPDATE:
            state = state.setIn([action.model, ...action.attr.split('.')], action.value);
            break;

        case SessionConstants.AUTH_USER.HOMEPAGE.GET.SUCCESS:
            state = state.setIn(['homepage', 'contentType'], action.contentType);
            localStorage.setItem('__homepageContentType', action.contentType);
            break;
        case SessionConstants.AUTH_USER.GET.SUCCESS:
            state = state.set('authUser', action.user);

            // Save the user to the local storage.
            localStorage.setItem('__user', JSON.stringify(action.user.toJS()));
            break;

        case SessionConstants.AUTH_USER.EVENTS.GET.SUCCESS:
            state = state.set('eventIds', action.eventIds);
            localStorage.setItem('__eventIds', state.get('eventIds').toJS());
            break;

        case SessionConstants.AUTH_USER.IS_ACS.GET.SUCCESS:
            state = state.setIn(['authUser', 'isACS'], action.isACS);
            // Update the user data in local storage.
            localStorage.setItem('__user', JSON.stringify(state.get('authUser').toJS()));
            break;

        case SessionConstants.AUTH_USER.IS_BRANDS.GET.SUCCESS:
            state = state.setIn(['authUser', 'isBrandsUser'], action.isBrandsUser);
            // Update the user data in local storage.
            localStorage.setItem('__user', JSON.stringify(state.get('authUser').toJS()));
            break;

        case SessionConstants.AUTH_USER.LAST_LOGIN_TIMESTAMP.GET.SUCCESS:
            let isFirstTimeOnSite = false;
            if (!action.lastLoginTimestamp) {
                isFirstTimeOnSite = true;
            }
            state = state.set('showOneTimeWelcomeToast', isFirstTimeOnSite);
            localStorage.setItem('__showOneTimeWelcomeToast', isFirstTimeOnSite);
            state = state.set('lastLoginTimestamp', action.lastLoginTimestamp);
            localStorage.setItem('__lastLoginTimestamp', action.lastLoginTimestamp);

            break;

        case SessionConstants.AUTH_USER.ROLES.GET.SUCCESS:
            state = state.setIn(['authUser', 'roles'], action.roles);
            // Update the user data in local storage.
            localStorage.setItem('__user', JSON.stringify(state.get('authUser').toJS()));
            break;

        case SessionConstants.AUTH_USER.USER_BRANDS.GET.SUCCESS:
            state = state.set('userBrands', action.userBrands);
            localStorage.setItem('__userBrands', JSON.stringify(action.userBrands.toJS()));
            break;

        case SessionConstants.AUTH_USER.USER_PARTNERS.GET.SUCCESS:
            state = state.set('userPartners', action.userPartners);
            localStorage.setItem('__userPartners', JSON.stringify(action.userPartners.toJS()));
            break;

        case SessionConstants.AUTH_USER.PARENT_USER.GET.SUCCESS:
            state = state.set('parentUser', action.parentUser);
            localStorage.setItem('__parentUser', JSON.stringify(action.parentUser.toJS()));
            break;

        case SessionConstants.AUTH_USER.PERMISSIONS.GET.SUCCESS:
            // Update the state with the authenticated user permissions.
            state = state.set('permissions', action.permissions);
            // Save them in the local storage to allow reloading
            localStorage.setItem('__permissions', JSON.stringify(action.permissions.toJS()));
            break;

        case SessionConstants.JWT.GET.ERROR:
            state = state.mergeIn(['__jwt'], {
                inProgress: false,
                token: null
            });
            // Remove old value from localStorage.
            localStorage.removeItem('__jwt');
            break;

        case SessionConstants.JWT.GET.START:
            state = state.mergeIn(['__jwt'], {
                inProgress: true,
                token: null
            });
            // Remove old value from localStorage.
            localStorage.removeItem('__jwt');
            break;

        case SessionConstants.JWT.GET.SUCCESS:
            state = state.mergeIn(['__jwt'], {
                inProgress: false,
                token: action.jwt
            });
            // Save in the local storage to allow reloading.
            localStorage.setItem('__jwt', action.jwt);
            break;

        case SessionConstants.LOGIN.ERROR:
            state = state.mergeIn(['login'], {
                inProgress: false,
                hasError: true,
                password: ''
            });
            break;

        case SessionConstants.LOGIN.START:
            state = this.logout(state);
            state = state.setIn(['login', 'inProgress'], true);
            state = state.setIn(['login', 'hasError'], false);
            break;

        case SessionConstants.LOGIN.SUCCESS:
            state = state.merge({
                '__token': action.uuid,
                timeout: false,
                login: Immutable.Map({
                    inProgress: false,
                    hasError: false,
                    email: '',
                    password: ''
                }),
                partner: action.partner,
                '__lastRequest': Date()
            });

            localStorage.setItem('__token', action.uuid);
            localStorage.setItem('__lastRequest', state.get('__lastRequest'));
            localStorage.setItem('__partner', JSON.stringify(action.partner.toJS()));

            if (action.isBaftaUser) {
                localStorage.setItem('__isBaftaUser', true);
                localStorage.setItem('__anonymousId', action.anonymousId);
            }

            this.__setInterval(POLLING_INTERVAL);
            break;

        case SessionConstants.LOGOUT:
            state = this.logout(state);
            break;

        case SessionConstants.PASSWORD_RESET.SET_NEW.CLEAR:
        case SessionConstants.PASSWORD_RESET.SET_NEW.ERROR:
            state = state.set('passwordReset', Immutable.Map({
                confirmPassword: '',
                password: '',
                inProgress: false
            }));
            break;
        case SessionConstants.PASSWORD_RESET.SET_NEW.START:
            state = state.setIn(['passwordReset', 'inProgress'], true);
            break;
        case SessionConstants.RESEND_LOGIN_CODE.START:
        case SessionConstants.RESEND_LOGIN_CODE.CLEAR:
            state = state.set('resendLoginCode', Immutable.Map({
                complete: false,
            }));
            break;
        case SessionConstants.RESEND_LOGIN_CODE.SUCCESS:
            state = state.set('resendLoginCode', Immutable.Map({
                complete: true,
            }));
            break;
        case SessionConstants.TIMEOUT.CLEAR:
            state = state.merge({
                timeout: false,
                __lastRequest: new Date()
            });

            localStorage.setItem('__lastRequest', state.get('__lastRequest'));
            break;

        case SessionConstants.TIMEOUT_WARNING:
            state = state.set('timeout', true);
            break;

        case SessionConstants.GET.MFA_DATA.SUCCESS:
            state = state.merge({
                mfaData: Immutable.Map({
                    userId: action.userId,
                    authorization: action.uuid
                })
            });
            break;

        case SessionConstants.GET.MFA_VERIFY_CODE.ERROR:
            state = state.setIn(['mfaData', 'verifyCodeInProgress'], false);
            break;

        case SessionConstants.GET.MFA_VERIFY_CODE.START:
            state = state.setIn(['mfaData', 'verifyCodeInProgress'], true);
            break;

        case SessionConstants.AUTH_USER.UPDATE_SESSION_BRAND:
            state = state.set('currentBrand', action.brand);
            localStorage.setItem('__currentBrand', JSON.stringify(action.brand.toJS()));
            break;

        case SessionConstants.GET.MFA_VERIFY_CODE.SUCCESS:
            state = state.setIn(['mfaData', 'verifyCodeInProgress'], false);
            break;

        case SessionConstants.SEND_ONE_TIME_LINK.ERROR:
        case SessionConstants.SEND_ONE_TIME_LINK.SUCCESS:
            state = state.setIn(['login', 'sendOneTimeLinkInProgress'], false);
            break;
        case SessionConstants.SEND_ONE_TIME_LINK.START:
            state = state.setIn(['login', 'sendOneTimeLinkInProgress'], true);
            break;

        case SessionConstants.AUTH_USER.SET_IS_PARTNER_AUTH_LOGIN:
            state = state.set('isPartnerAuthLogin', action.isPartnerAuthLogin);
            break;

        case SessionConstants.WELCOME_TOAST_SHOWN:
            state = state.set('showOneTimeWelcomeToast', false);
            localStorage.setItem('__showOneTimeWelcomeToast', false);
            break;
        }

        return state;
    }
}

let store = new SessionStore(Dispatcher, PERMISSIONS);
export default store;
