/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {Dispatcher} from '../../../flux-helpers';
import {NotificationActions} from '../../../notification/notification-actions';
import Request from '../../../request';

const CONSTANTS = {
    CLEAR: 'delivery_actions.clear',
    EXPORT_LANGUAGES: [
        {id: 'EL', name: 'Greek'},
        {id: 'EN', name: 'English'},
        {id: 'FR', name: 'French'},
        {id: 'FR_CA', name: 'French (Canadian)'},
        {id: 'GER', name: 'German'},
        {id: 'ITA', name: 'Italian'},
        {id: 'JPN', name: 'Japanese'},
        {id: 'KO', name: 'Korean'},
        {id: 'POR', name: 'Portuguese (Euro)'},
        {id: 'POR_BR', name: 'Portuguese (Brazil)'},
        {id: 'SPA', name: 'Spanish (Castilian)'},
        {id: 'SPA_LAT', name: 'Spanish Latin America'},
        {id: 'TR', name: 'Turkish'},
        {id: 'ZH_HANS', name: 'Chinese'},
    ],
    EXPORT_FALLBACK_ENGLISH: [
        {id: 0, name: 'work-orders.fallback-english.off'},
        {id: 1, name: 'work-orders.fallback-english.on'}
    ],
    EXPORT_SPECIFICATIONS: [
        {id: 1, name: 'Generic'},
        {id: 2, name: 'Netflix'},
        {id: 3, name: 'Mass Update Template'},
        {id: 4, name: 'Amazon'},
        {id: 5, name: 'Hulu'},
        {id: 6, name: 'Tubi'}
    ],
    FILE_FORMAT_TYPES: {
        XDCAM: {id: 'XDCAM', name: 'XDCAM'},
        PRO_RES: {id: 'ProRes', name: 'ProRes'},
        H264: {id: 'H.264', name: 'H.264/MP4'}
    },
    IMAGE_DELIVERY_TYPES: {
        HIGH_RESOLUTION: {id: 'HiRes', name: 'High Res (JPG, PNG, TIF)'},
        LOW_RESOLUTION: {id: 'LoRes', name: 'Low Res (JPG & PNG)'},
        SOURCE: {id: 'Source', name: 'Source (PSD)'}
    },
    NOTIFICATION: {
        ADD: 'delivery_actions.notification.add',
        CLONE: 'delivery_actions.notification.clone',
        GET: 'delivery_actions.notification.get',
        REMOVE: 'delivery_actions.notification.remove',
        UPDATE: 'delivery_actions.notification.update'
    },
    PROFILE: {
        CLEAR: 'delivery_actions.profile.clear',
        GET: 'delivery_actions.profile.get',
        SAVE: 'delivery_actions.profile.save',
        UPDATE: 'delivery_actions.profile.update'
    },
    SET: {
        VALIDATIONS: 'delivery_actions.validations'
    },

    toArray: function(constant) {
        return Object.keys(this[constant])
            .map(k => this[constant][k])
            .sort((a, b) => a.name.localeCompare(b.name));
    }
};

class DeliveryActions {
    addNotification() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.NOTIFICATION.ADD
        });
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    getNotificationsById(userId) {
        Request.get(`user/${userId}/delivery-profile/notification`)
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.NOTIFICATION.GET,
                    notifications: Immutable.fromJS(res.body)
                });

                return;
            });
        return;
    }

    getProfileById(userId) {
        Request.get(`user/${userId}/delivery-profile`)
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.PROFILE.GET,
                    profile: Immutable.fromJS(res.body)
                });
                return;
            })
            .catch((err) => {
                switch (err.status) {
                case 404:
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.PROFILE.CLEAR
                    });
                    break;
                }
                throw err;
            });
        return;
    }

    removeNotification(index, notification) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.NOTIFICATION.REMOVE,
            index: index,
            notification: notification
        });
    }

    saveProfile(userId, profile, notifications) {
        Request.put(`user/${userId}/delivery-profile`).send(profile.toJS()).then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.PROFILE.SAVE,
                profile: Immutable.fromJS(res.body)
            });

            NotificationActions.showAlertSuccess('account.tab.delivery.save.success');

            return this.saveDeliveryNotification(userId, notifications);
        }).catch(err => {
            NotificationActions.showAlertDanger('account.tab.delivery.save.error');

            throw err;
        });
    }

    saveDeliveryNotification(userId, notifications) {
        let notificationsData = notifications.toJS();
        notificationsData.forEach((n, i) => {
            n.notificationNumber = i;
            delete n.orderIn;
        });
        let op = Request.put(`user/${userId}/delivery-profile/notification`)
            .send(notificationsData)
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.NOTIFICATION.CLONE,
                    notifications: Immutable.fromJS(res.body)
                });
            })
            .catch(err => {
                throw err;
            });
        return op;
    }

    setValidations(validations) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SET.VALIDATIONS,
            validations: validations
        });
        return;
    }

    updateNotifications(orderIn, attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.NOTIFICATION.UPDATE,
            attr: attr,
            orderIn: orderIn,
            value: value
        });
        return;
    }

    updateProfile(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.PROFILE.UPDATE,
            attr: attr,
            value: value
        });
        return;
    }
}

let actions = new DeliveryActions();

export {
    actions as DeliveryActions,
    CONSTANTS as DeliveryConstants
};
