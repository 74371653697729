/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

const ENVIRONMENTS = {
    DEV: 'dev',
    PRODUCTION: 'production',
    QA: 'qa',
    UAT: 'uat'
};

/**
 * Notes:
 * showInAdditionalPartners controls the partner being shown in the Additional Partner Sites dropdown in Request Account Access modal
 * showTwoStepSignup causes the signup flow to add a second step (choose contact, etc) for non-wb email addresses
 * preventSignup (see STUDIO-1090) removes signup links on login form, redirects signup page to login, removes from additional access dropdown
 */

const PARTNERS = {
    ADULTSWIM_PRESS: {
        id: 'ADULTSWIM_PRESS',
        key: 'gxqHLy1lHcrfBOJrdJ',
        logo: 'adult-swim-logo.png',
        name: 'Adult Swim Press',
        preventSignup: true,
        showInAdditionalPartners: false,
        showTwoStepSignup: true,
    },
    BAFTA: {
        id: 'BAFTA',
        showInAdditionalPartners: false,
    },
    BAFTA_WEB: {
        id: 'BAFTA_WEB',
        key: 'zr06v58PemxnF9E1MO',
        showInAdditionalPartners: false,
        showTwoStepSignup: false,
    },
    CARTOON_NETWORK_PRESS: {
        id: 'CARTOON_NETWORK_PRESS',
        key: 'FexqDGGMJtaOiGyrDk',
        logo: 'cartoon-network-logo.svg',
        name: 'Cartoon Network Press',
        showInAdditionalPartners: false,
        showTwoStepSignup: true,
        preventSignup: true,
    },
    CARTOONITO_PRESS: {
        id: 'CARTOONITO_PRESS',
        key: 'qwLl2VmO5CqEHAEQ8O',
        logo: 'cartoonito-logo.png',
        name: 'Cartoonito Press',
        showInAdditionalPartners: false,
        showTwoStepSignup: true,
        preventSignup: true,
    },
    FORMATS_PORTAL: {
        id: 'FORMATS_PORTAL',
        key: 'GIrLnhQh19oWJkorTF',
        logo: 'formats-logo.svg',
        name: 'Formats Portal',
        showInAdditionalPartners: true,
        showTwoStepSignup: true,
    },
    HBO: {
        acsLogo: 'hbo-acs-logo.png',
        airlinesLogo: 'hbo-airlines-logo.svg',
        id: 'HBO',
        key: 'oZyS8KL52RvRgPJuH4',
        logo: 'hbo-logo.svg',
        name: 'HBO',
        showInAdditionalPartners: true,
        showTwoStepSignup: true,
    },
    HBO_MAX_PORTAL: {
        airlinesLogo: 'hbo-max-airlines-logo.svg',
        id: 'HBO_MAX_PORTAL',
        key: 'qoBU6K7f8HSm0OlmbL',
        logo: 'hbo-max-logo.svg',
        name: 'HBO Max',
        showInAdditionalPartners: true,
        showTwoStepSignup: true,
    },
    LA_SCREENINGS: {
        id: 'LA_SCREENINGS_WEB',
        key: '4P8BzqzNmCKo6S16TK',
        showInAdditionalPartners: false,
    },
    PRESS_SITE: {
        id: 'WBTVD_PRESS',
        key: 'pUa1PbP0iDAPhmAeIu',
        logo: 'wb-press-logo.png',
        name: 'WBTVD Press Site',
        showInAdditionalPartners: true,
    },
    WBFYC_EMMY: {
        id: 'WBFYC_EMMY',
        key: 'SaDDrdElFPQibQ8s6y',
        showInAdditionalPartners: false,
    },
    WBTVD: {
        acsLogo: 'wbtvd-acs-logo.svg',
        id: 'WBTVD',
        key: '01lpZaMPqimth9sovN',
        logo: 'wbtvd-logo.png',
        name: 'WBTVD',
        showInAdditionalPartners: true,
        showTwoStepSignup: true,
    },
};

// Extend an array of partners by merging it with the base configuration
// in the PARTNERS constant.
const MergePartners = p => {
    Object.keys(PARTNERS).forEach(
        k => p[k] = Object.assign({}, PARTNERS[k], p[k])
    );

    return p;
};

export {
    ENVIRONMENTS,
    MergePartners
};
