/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {STATION_TITLE_ACTIONS_MAP} from '@wbdt-sie/brainiac-web-common';
import Promise from 'bluebird';
import Immutable from 'immutable';
import {Subject} from 'rxjs';
import {filter, merge, takeUntil} from 'rxjs/operators';

import Analytics from '../analytics';
import {AssetTypeConstants} from '../asset-types/asset-type-constants';
import {DownloadActions, DownloadConstants} from '../common/download/download-actions';
import {Dispatcher} from '../flux-helpers';
import {NotificationActions} from '../notification/notification-actions';
import Request from '../request';
import {ObservableRoute, RouterActions} from '../router/router-actions';


const TITLE_CATEGORY_GROUPS = {
    SERIES: 1,
    SEASON: 2,
    EPISODE: 3,
    SINGLE_RELEASE: 4,
    FORMAT_RIGHTS: 5,
    MINI_SERIES: 6
};

const CONSTANTS = {
    BEETLEJUICE: 46103,
    ELF: 171386,
    TITLE: {
        ASPECT_RATIO: {
            GET: {
                SUCCESS: 'title_constants.title.aspect-ratio.get.success'
            }
        },
        ASSETS: {
            GET: {
                SUCCESS: 'title_constants.title.assets.get.success'
            }
        },
        AUDIO: {
            GET: {
                SUCCESS: 'title_constants.title.audio.get.success'
            },
            PREVIEW: {
                HIDE: 'title_constants.title.audio.preview.hide',
                SHOW: 'title_constants.title.audio.preview.show'
            }
        },
        CLEAR: 'title_constants.title.clear',
        SEASON_ASSET_COUNT: {
            SUCCESS: 'title_constants.title.season.asset.count.success'
        },
        SEASON_SINGLE_ASSET_COUNT: {
            SUCCESS: 'title_constants.title.season.single.asset.count.success'
        },
        EPISODES_FOR_SEASON: {
            SUCCESS: 'title_constants.title.episodes.for.season.success'
        },
        GET: {
            SUCCESS: 'title_constants.title.get.success'
        },
        GENRES: {
            GET: {
                SUCCESS: 'title_constants.genre.get.success'
            }
        },
        LANGUAGE_AVAILABILITY: {
            ELEMENT_TYPE: {
                AUDIO: 0,
                SUBTITLE: 1
            },
            GET: {
                SUCCESS: 'title_constants.title.language_availability.get.success'
            }
        },
        LANGUAGES: {
            GET: {
                SUCCESS: 'title_constants.languages.get.success'
            }
        },
        LINKS: {
            GET: {
                SUCCESS: 'title_constants.links.get.success'
            }
        },
        NAVIGATION: {
            EPISODES: {
                GET: {
                    SUCCESS: 'title_constants.title.navigation.episodes.get.success'
                }
            },
            SEASONS: {
                GET: {
                    SUCCESS: 'title_constants.title.navigation.seasons.get.success'
                }
            }
        },
        NETWORKS: {
            GET: {
                ERROR: 'title_constants.networks.get.error',
                SUCCESS: 'title_constants.networks.get.success'
            }
        },
        ORIGINAL_SOURCE: {
            GET: {
                SUCCESS: 'title_constants.original_source.get.success'
            }
        },
        PRODUCTION_COMPANIES: {
            GET: {
                SUCCESS: 'title_constants.company.get.success'
            },
        },
        RATING_REASONS: {
            GET: {
                SUCCESS: 'title_constants.rating_reasons.get.success'
            }
        },
        RELATED: {
            GET: {
                SUCCESS: 'title_constants.title.related.get.success'
            }
        },
        RELEASE_DATES: {
            GET: {
                SUCCESS: 'title_constants.title.release_dates.get.success'
            }
        },
        SOURCE_MASTER: {
            GET: {
                SUCCESS: 'title_constants.title.source_master.get.success'
            }
        },
        STANDARD: {
            GET: {
                SUCCESS: 'title_constants.title.standard.get.success'
            }
        },
        SUBSCRIPTION: {
            ADD: 'title_constants.title.subscription.add',
            SUGGESTED_USERS: {
                SUCCESS: 'title_constants.title.subscription.suggested_users.success'
            }
        },
        SYNOPSIS: {
            GET: {
                SUCCESS: 'title_constants.synopsis.get.success'
            }
        },
        TALENT: {
            GET: {
                SUCCESS: 'title_constants.title.talent.get.success'
            }
        },
        THEMES: {
            GET: {
                SUCCESS: 'title_constants.themes.get.success'
            }
        },
        VIDEOS: {
            THUMBNAILS: {
                GET: {
                    SUCCESS: 'title_constants.title.video.thumbnails.get.success'
                }
            }
        }
    },
    TITLES: {
        GET: {
            SUCCESS: 'title_constants.titles.get.success'
        }
    },
    ACTION_TYPES: {
        1: 'Live Action',
        2: 'Animation',
        3: 'Live Action & Animation'
    },
    LINK_TYPES: {
        CLIP_LIBRARY: 6,
        EXTERNAL_LINK: 12,
        INTERNAL_LINK: 11
    },
    MADE_FOR_TYPES: {
        1: 'TV',
        2: 'Film',
        3: 'Home Video',
        4: 'Web'
    },
    MOVIE_COLOR_TYPES: {
        0: 'Color Unknown',
        1: 'Color',
        2: 'B&W',
        3: 'Colorized',
        4: 'B&W Color',
        5: 'Color Colorized'
    },
    PARENTAL_RATING_TYPES: {
        NO_PARENTAL_RATING: {id: 0, name: 'No Parental Rating'},
        TV_Y: {id: 1, name: 'TV-Y'},
        TV_Y7: {id: 2, name: 'TV-Y7'},
        TV_G: {id: 3, name: 'TV-G'},
        TV_PG: {id: 4, name: 'TV-PG'},
        TV_14: {id: 5, name: 'TV-14'},
        TV_MA: {id: 6, name: 'TV-MA'}
    },
    RELEASE_DATE_TYPES: {
        FULL: {id: 0, name: 'Full'},
        MONTH_YEAR: {id: 1, name: 'Month Year'},
        SEASON_YEAR: {id: 2, name: 'Season Year'},
        YEAR: {id: 3, name: 'Year'},
        TBA: {id: 4, name: 'TBA'},
        DISPLAY_NAME: {id: 5, name: 'Display Name'},
    },
    RELEASE_CONTENT_TYPES: {
        VOD: {id: 'VOD', name: 'VOD'},
        SVOD: {id: 'SVOD', name: 'SVOD'},
        AVOD: {id: 'AVOD', name: 'AVOD'},
        TVOD: {id: 'TVOD', name: 'TVOD'},
        PVOD: {id: 'PVOD', name: 'PVOD'},
        EST: {id: 'EST', name: 'EST'},
        DVD_OR_BLU_RAY: {id: 'DVD_OR_BLU_RAY', name: 'DVD/Blu-ray'},
        THEATRICAL: {id: 'THEATRICAL', name: 'Theatrical'},
        BROADCAST: {id: 'BROADCAST', name: 'Broadcast'},
    },
    SUBSCRIPTION_TYPE: {
        'NORMAL': 'title_constants.subscription_type.Normal',
        'CLIENT': 'title_constants.subscription_type.Client'
    },
    SUBSCRIPTION_CONTENT_TYPES: STATION_TITLE_ACTIONS_MAP,
    SYNOPSIS_TYPES: {
        DEFAULT: {id: 'DEFAULT', name: 'Default Synopsis'},
        TEMPORARY: {id: 'TEMPORARY', name: 'Temporary Synopsis'},
        AIRLINE: {id: 'AIRLINE', name: 'Airline Synopsis'},
        SCREENER: {id: 'SCREENER', name: 'Screener Synopsis'},
        AWARDS: {id: 'AWARDS', name: 'Awards Synopsis'},
    },
    TITLE_CATEGORY_GROUPS: TITLE_CATEGORY_GROUPS,
    TITLE_TYPES: {
        THEATRICAL_FEATURES:{id:1, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE},
        ANIMATED_FEATURES:{id:2, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE},
        MADE_FOR_VIDEO_FEATURES:{id:3, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE},
        SEASON_HALF_HOUR:{id:4, categoryGroup: TITLE_CATEGORY_GROUPS.SEASON},
        SEASON_ONE_HOUR:{id:5, categoryGroup: TITLE_CATEGORY_GROUPS.SEASON},
        TALK_SHOW_SEASON:{id:6, categoryGroup: TITLE_CATEGORY_GROUPS.SEASON},
        GAME_SHOW_SEASON:{id:7, categoryGroup: TITLE_CATEGORY_GROUPS.SEASON},
        ANIMATED_SERIES_SEASON:{id:8, categoryGroup: TITLE_CATEGORY_GROUPS.SEASON},
        CARTOONS:{id:9, categoryGroup: TITLE_CATEGORY_GROUPS.SERIES},
        MINI_SERIES:{id:10, categoryGroup: TITLE_CATEGORY_GROUPS.MINI_SERIES},
        NETWORK:{id:11, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE},
        CABLE:{id:12, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE},
        PAY_TV:{id:13, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE},
        SPECIALS:{id:14, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE},
        ANIMATED_SPECIALS:{id:15, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE},
        COMEDY_SPECIALS:{id:16, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE},
        MUSIC_SPECIALS:{id:17, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE},
        SPORTS:{id:18, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE},
        DOCUMENTARIES:{id:19, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE},
        SHORT_PROGRAMS:{id:20, categoryGroup: TITLE_CATEGORY_GROUPS.SERIES},
        MAKING_OF:{id:21, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE},
        EPISODE:{id:23, categoryGroup: TITLE_CATEGORY_GROUPS.EPISODE},
        SERIES_HALF_HOUR:{id:24, categoryGroup: TITLE_CATEGORY_GROUPS.SERIES},
        SERIES_ONE_HOUR:{id:25, categoryGroup: TITLE_CATEGORY_GROUPS.SERIES},
        TALK_SHOW:{id:26, categoryGroup: TITLE_CATEGORY_GROUPS.SERIES},
        GAME_SHOW:{id:27, categoryGroup: TITLE_CATEGORY_GROUPS.SERIES},
        ANIMATED_SERIES:{id:28, categoryGroup: TITLE_CATEGORY_GROUPS.SERIES},
        FORMAT_RIGHTS:{id:29, categoryGroup: TITLE_CATEGORY_GROUPS.FORMAT_RIGHTS},
        REALITY:{id:30, categoryGroup: TITLE_CATEGORY_GROUPS.SERIES},
        REALITY_SEASON:{id:31, categoryGroup: TITLE_CATEGORY_GROUPS.SEASON},
        DIRECT_TO_VIDEO:{id:32, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE},
        ANIMATION:{id:33, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE},
        CARTOONS_SEASON:{id:34, categoryGroup: TITLE_CATEGORY_GROUPS.SEASON},
        SHORT_PROGRAMS_SEASON:{id:35, categoryGroup: TITLE_CATEGORY_GROUPS.SEASON},
        EVENT:{id:36, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE},
        OTHER_PRODUCTS:{id:37, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE},
        SEGMENT: {id: 38, categoryGroup: TITLE_CATEGORY_GROUPS.EPISODE},
        PROGRAMMING_PACKAGE: {id: 39, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE},
        HBO_FILMS: {id: 40, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE},
        DOCUMENTARY_FILMS: {id: 41, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE},
        LIMITED_SERIES: {id: 42, categoryGroup: TITLE_CATEGORY_GROUPS.SERIES},
        DOCUSERIES: {id: 43, categoryGroup: TITLE_CATEGORY_GROUPS.SERIES},
        LIMITED_SERIES_SEASON: {id: 44, categoryGroup: TITLE_CATEGORY_GROUPS.SEASON},
        DOCUSERIES_SEASON: {id: 45, categoryGroup: TITLE_CATEGORY_GROUPS.SEASON},
        WB_HBO_MAX_RELEASE: {id: 46, categoryGroup: TITLE_CATEGORY_GROUPS.SINGLE_RELEASE},
    },
    TITLE_RELATIONSHIP_TYPE: {
        EPISODE: 120,
        SEASON: 121,
        HAS_EPISODE: 220,
        HAS_SEASON: 221
    }
};

CONSTANTS.categoryGroupMap = Object.keys(CONSTANTS.TITLE_TYPES).reduce( (c, k) => {
    c[CONSTANTS.TITLE_TYPES[k].id] = CONSTANTS.TITLE_TYPES[k].categoryGroup;
    return c;
}, {});

class TitleActions {
    saveSubscriptions(id, subscriptions, put) {
        // Handles saving from the notifications modal
        let payload = [];
        Object.keys(subscriptions.toJS()).forEach(ct => {
            const sub = subscriptions.get(ct);

            if (sub.get('elements')?.size > 0) {
                payload.push(sub.getIn(['elements', 0]).toJS());
            }
        });

        let req = Request.post;
        if (put) {
            req = Request.put;
        }

        return req(`title/${id}/user-subscription`)
            .send(payload)
            .then(() => {
                NotificationActions.showAlertSuccess('title.modal.subscribe.success');

                payload.forEach((s) => {
                    Analytics.userTitleSubscribeEvent(id, s.subscriptionContentType, s.suggestedByUserId);
                });

                return;
            })
            .catch(/* istanbul ignore next */err => {
                NotificationActions.showAlertDanger('title.modal.subscribe.error');
                throw err;
            });
    }

    addSubscription(id, subscriptions, userId, suggestedByUserId) {
        // Deprecated. Used by the subscriptions tab
        let subscriptionsFormats = subscriptions.map(format => {
            let subscriptionContentType = CONSTANTS.SUBSCRIPTION_CONTENT_TYPES[format];
            return {
                suggestedByUserId,
                subscriptionContentType,
                userId
            };
        });

        return Request.put(`title/${id}/user-subscription`)
            .send(subscriptionsFormats)
            .then(() => {
                NotificationActions.showAlertSuccess('title.modal.subscribe.success');

                subscriptions.forEach((subscriptionFormat) => {
                    Analytics.userTitleSubscribeEvent(id, subscriptionFormat, suggestedByUserId);
                });

                return;
            })
            .catch(/* istanbul ignore next */err => {
                if (!err || !err.response || !err.response.body) {
                    NotificationActions.showAlertDanger('title.modal.subscribe.error');
                    throw err;
                }

                switch (err.response.body.message) {
                case 'DUPLICATED_SUBSCRIPTION':
                    if (userId === suggestedByUserId) {
                        NotificationActions.showAlertDanger('title.modal.subscribe.error.duplicated.own');
                    } else {
                        NotificationActions.showAlertDanger('title.modal.subscribe.error.duplicated.other');
                    }
                    break;
                default:
                    NotificationActions.showAlertDanger('title.modal.subscribe.error');
                    break;
                }

                throw err;
            });
    }

    cancelDownload() {
        if (this.downloadExecutionObs) {
            this.downloadExecutionObs.unsubscribe();
        }

        delete this.downloadExecutionObs;
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE.CLEAR
        });
    }

    downloadAllFactSheet(titleId) {
        Request.get(`title/${titleId}/fact-sheet-all`).query({
            'threaded': true,
            'single-pdf-per-title': true
        }).then(res => {
            this.resolveDownloadExecutionFor(res, `titles/${titleId}/download-all-fact-sheet`);
        }).catch(/* istanbul ignore next */err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.DOWNLOAD.ERROR
            });
            NotificationActions.showAlertDanger('work-orders.download.error');
            /* istanbul ignore next */
            throw err;
        });
    }

    downloadAllAssets(titleId, assetType, contentTypeArray, renditionTypeArray) {
        const query = {
            'asset-type': assetType // currently hardcoded as IMAGES on the API side, but useful if we are later going to support multiple asset types
        };
        if (contentTypeArray) {
            // API will search for all available content-types for the asset-type if unspecified
            query['content-type'] = contentTypeArray;
        }
        if (renditionTypeArray) {
            // API defaults to SOURCE if unspecified
            query['rendition-type'] = renditionTypeArray;
        }
        Request.get(`title/${titleId}/web/asset/download`).query(query).then(res => {
            this.resolveDownloadExecutionFor(res, `titles/${titleId}/download-asset-package`);
        }).catch(/* istanbul ignore next */err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.DOWNLOAD.ERROR
            });
            NotificationActions.showAlertDanger('work-orders.download.error');
            /* istanbul ignore next */
            throw err;
        });
    }

    /**
     * Process a generic threaded download execution, redirecting the user to the target page while querying for process
     * completion in the background.
     *
     * @param {*} res The original triggering request. Its body must have a defined 'downloadExecutionId' parameter.
     * @param {*} redirectTo The page where the user shall be redirected to and wait for his download to complete.
     * @returns
     */
    resolveDownloadExecutionFor(res, redirectTo) {
        Dispatcher.dispatch({
            actionType: DownloadConstants.EXECUTION_ID.GET.SUCCESS,
            downloadExecutionId: res.body.downloadExecutionId
        });
        RouterActions.redirect(redirectTo);

        this.downloadExecutionObs = DownloadActions.watchDownloadExecution(res.body.downloadExecutionId)
            .subscribe(
                action => Dispatcher.dispatch(action),
                null,
                () => void 0
            );
    }

    _get(id, userId, getTitleLanguageAvailability, canceler) {
        let rxTitle = new Subject();
        Request.get(`title/${id}`).canceler(canceler).then(titleRes => {
            let title = titleRes.body;

            /* istanbul ignore if */
            if (!title.active) {
                // Redirect to not found when an inactive title is loaded
                RouterActions.notFound();
                throw new Error('Inactive title');
            }

            title.categoryGroup = Object.keys(CONSTANTS.TITLE_TYPES).reduce( (c, t) => {
                if (CONSTANTS.TITLE_TYPES[t].id === title.category) {
                    return CONSTANTS.TITLE_TYPES[t].categoryGroup;
                } else {
                    return c;
                }
            }, undefined);

            rxTitle.next({
                actionType: CONSTANTS.TITLE.GET.SUCCESS,
                title: Immutable.fromJS(title)
            });

            // Get Audio, Document and Merchandise. These are all small arrays, so it is safe to get them on
            // title load.
            // Seed the seasonCounts so that it resolves to undefined if title is not of type series.
            // The navigation bar uses the assets returned in these promises to know which tabs to
            // display or hide.
            let promises = {
                audio: Request.get(`title/${id}/web/asset`).query({
                    'asset-type': AssetTypeConstants.ASSET_TYPE.AUDIO,
                    jp: userId,
                    offset: 0,
                    size: 9999
                }).canceler(canceler),
                audioSeasonCounts: {body: []},
                clips: Request.get('asset/video-search').query({
                    offset: 0,
                    size: 0,
                    'is-clippable': true,
                    'search-term': '',
                    'title-id': id,
                }).canceler(canceler).then(r => r).catch(() => {
                    // skip API permission checker
                    return {
                        body: {
                            totalCount: 0,
                        }
                    };
                }),
                documents: Request.get(`title/${id}/web/asset`).query({
                    'asset-type': AssetTypeConstants.ASSET_TYPE.DOCUMENT,
                    jp: userId,
                    offset: 0,
                    size: 9999
                }).canceler(canceler),
                documentsSeasonCounts: {body: []},
                // Get the first eight images to display. Also, get the images by id
                // so that they display with the thumbnails and they deep link correctly.
                images: Request.get(`title/${id}/web/asset`).query({
                    'asset-type': AssetTypeConstants.ASSET_TYPE.IMAGE,
                    jp: userId,
                    offset: 0,
                    size: 8,
                    'sort-field-name': 'assetorder',
                    'sort-direction': 'asc'
                }).canceler(canceler).then(res => {
                    return Promise.all(
                        res.body.results.map(
                            i => Request.get(`asset/image/${i.assetId}`)
                        )
                    );
                }).then(imagesRes => {
                    return {
                        body: {
                            results: imagesRes.map(iRes => {
                                let image = iRes.body;
                                image.assetId = image.id;
                                return image;
                            })
                        }
                    };
                }),
                merchandise: Request.get(`title/${id}/web/asset`).query({
                    'asset-type': AssetTypeConstants.ASSET_TYPE.MERCHANDISE,
                    jp: userId,
                    offset: 0,
                    size: 9999
                }).canceler(canceler),
                merchandiseSeasonCounts: {body: []},
                scripts: Request.get(`title/${id}/web/asset`).query({
                    'asset-type': AssetTypeConstants.ASSET_TYPE.SCRIPT,
                    jp: userId,
                    offset: 0,
                    size: 9999
                }).canceler(canceler),
                scriptsSeasonCounts: {body: []},
                // Get the first four videos to display.
                videos: Request.get(`title/${id}/web/asset`).query({
                    'asset-type': AssetTypeConstants.ASSET_TYPE.VIDEO,
                    jp: userId,
                    offset: 0,
                    size: 4,
                    'sort-field-name': 'assetorder',
                    'sort-direction': 'asc'
                }).canceler(canceler).then(res => {
                    return Promise.props({
                        assetTitles: res,
                        videosRes: Promise.all(res.body.results.map(
                            v => Request.get(`asset/video/${v.assetId}`)
                        ))
                    });
                }).then(videosAndDetails => {
                    return {
                        body: {
                            results: videosAndDetails.videosRes.map((vRes, i) => {
                                let video = vRes.body;
                                let titleAsset = videosAndDetails.assetTitles.body.results[i];

                                return Object.assign({}, titleAsset, video);
                            })
                        }
                    };
                }),
            };

            // If series, also get the assets counts
            if (title.categoryGroup === CONSTANTS.TITLE_CATEGORY_GROUPS.SERIES) {
                promises.audioSeasonCounts = Request.get(`title/${id}/web/series-season-count`).query({
                    'asset-type': AssetTypeConstants.ASSET_TYPE.AUDIO,
                    jp: userId
                }).canceler(canceler);
                promises.documentsSeasonCounts = Request.get(`title/${id}/web/series-season-count`).query({
                    'asset-type': AssetTypeConstants.ASSET_TYPE.DOCUMENT,
                    jp: userId
                }).canceler(canceler);
                promises.merchandiseSeasonCounts = Request.get(`title/${id}/web/series-season-count`).query({
                    'asset-type': AssetTypeConstants.ASSET_TYPE.MERCHANDISE,
                    jp: userId
                }).canceler(canceler);
                promises.scriptsSeasonCounts = Request.get(`title/${id}/web/series-season-count`).query({
                    'asset-type': AssetTypeConstants.ASSET_TYPE.SCRIPT,
                    jp: userId
                }).canceler(canceler);
            }

            return Promise.props(promises);
        }).then(assetsRes => {
            rxTitle.next({
                actionType: CONSTANTS.TITLE.ASSETS.GET.SUCCESS,
                audio: Immutable.fromJS(assetsRes.audio.body.results),
                clipsCount: Immutable.fromJS(assetsRes.clips.body.totalCount),
                documents: Immutable.fromJS(assetsRes.documents.body.results),
                images: Immutable.fromJS(assetsRes.images.body.results),
                merchandise: Immutable.fromJS(assetsRes.merchandise.body.results),
                videos: Immutable.fromJS(assetsRes.videos.body.results),
                scripts: Immutable.fromJS(assetsRes.scripts.body.results),
            });

            let videoThumbnails = {body: []};
            /* istanbul ignore if */
            if (assetsRes.videos.body.results.length) {
                videoThumbnails = Request.get('asset/video/thumbnailURL').query({
                    'video-id': assetsRes.videos.body.results.map(v => v.assetId)
                }).canceler(canceler);
            }

            // series-season-count v2 now returns the total number of assets for the series
            // including the seasons. Since the UI doesn't show this value, we remove it
            // here so that all components keep working the same.
            let removeSeries = s => s.titleId.toString() !== id;
            let promises = {
                audioSeasonCounts: Immutable.fromJS(assetsRes.audioSeasonCounts.body.filter(removeSeries)),
                documentsSeasonCounts: Immutable.fromJS(assetsRes.documentsSeasonCounts.body.filter(removeSeries)),
                merchandiseSeasonCounts: Immutable.fromJS(assetsRes.merchandiseSeasonCounts.body.filter(removeSeries)),
                scriptsSeasonCounts: Immutable.fromJS(assetsRes.scriptsSeasonCounts.body.filter(removeSeries)),
                seasons: [],
                videoThumbnails
            };
            // This if can be done against any of audioSeasonCounts, documentsSeasonCounts
            // or merchandiseSeasonCounts. In all cases the API returns the list of seasons
            // even if it has 0 assets.
            /* istanbul ignore else */
            if (assetsRes.audioSeasonCounts.body) {
                promises.seasons = Promise.all(assetsRes.audioSeasonCounts.body.filter(removeSeries).map( ac => {
                    return Request.get(`title/${ac.titleId}`).canceler(canceler);
                }));
            }

            return Promise.props(promises);
        }).then(seasonsResults => {
            rxTitle.next({
                actionType: CONSTANTS.TITLE.SEASON_ASSET_COUNT.SUCCESS,
                audioSeasonCounts: seasonsResults.audioSeasonCounts,
                documentsSeasonCounts: seasonsResults.documentsSeasonCounts,
                merchandiseSeasonCounts: seasonsResults.merchandiseSeasonCounts,
                scriptsSeasonCounts: seasonsResults.scriptsSeasonCounts,
                seasons: Immutable.fromJS(seasonsResults.seasons.reduce((r, s) => {
                    let title = s.body;
                    r[title.id.toString()] = title;
                    return r;
                }, {}))
            });

            rxTitle.next({
                actionType: CONSTANTS.TITLE.VIDEOS.THUMBNAILS.GET.SUCCESS,
                thumbnails: Immutable.fromJS(seasonsResults.videoThumbnails.body)
            });

            return;
        }).catch(/* istanbul ignore next */err => {
            rxTitle.error(err);
            switch (err.status) {
            case 404:
                const BATMAN_DAY = '1856215';
                if (id === BATMAN_DAY) {
                    RouterActions.redirect('/coming-soon');
                    break;
                }
                RouterActions.notFound();
                break;
            }
            throw err;
        });

        if (getTitleLanguageAvailability) {
            Request.get(`title/${id}/language-availability`).canceler(canceler).then(languageAvailabilityRes => {
                rxTitle.next({
                    actionType: CONSTANTS.TITLE.LANGUAGE_AVAILABILITY.GET.SUCCESS,
                    languageAvailability: Immutable.fromJS(languageAvailabilityRes.body)
                });

                return;
            }).catch(/* istanbul ignore next */err => {
                if (err.status !== 404) {
                    NotificationActions.showAlertDanger('title.language-availability.get.error');
                }

                throw err;
            });
        }
        return rxTitle;
    }

    // TODO: remove imageId from AssetTitleActions and then remove dependency here
    get(id, userId, getTitleLanguageAvailability) {
        let canceler = ObservableRoute.pipe(filter(x => {
            let cancel = x.pathname.indexOf(`titles/${id}`) === -1;
            return cancel;
        }));
        let rxTitle = this._get(
            id,
            userId,
            getTitleLanguageAvailability,
            canceler
        );

        let rxGenres = this.getGenres(id, canceler);
        let rxThemes = this.getThemes(id, canceler);
        let rxRelated = this.getRelated(id, canceler);
        let rxNetworks = this.getNetworks(id, canceler);
        let rxSynopsis = this.getSynopsis(id, canceler);
        let titleId = parseInt(id, 10);
        let rxSACImages = this.getSeasonAssetCount(titleId, AssetTypeConstants.ASSET_TYPE.IMAGE, userId, canceler);
        let rxSACVideos = this.getSeasonAssetCount(titleId, AssetTypeConstants.ASSET_TYPE.VIDEO, userId, canceler);

        rxTitle.pipe(
            merge(rxGenres, rxThemes, rxRelated, rxNetworks, rxSACImages, rxSACVideos, rxSynopsis),
            takeUntil(canceler)).subscribe(
            (event) => {
                Dispatcher.dispatch(event);
            },
            (/*error*/) => {
                //todo handle error
            }
        );
    }

    _getCanceler(id) {
        return ObservableRoute.pipe(filter(x => {
            return x.pathname.indexOf(`titles/${id}`) === -1;
        }));
    }

    getSummaryData(id) {
        const canceler = this._getCanceler(id);
        let rxLanguages = this.getLanguages(id, canceler);
        let rxLinks = this.getLinks(id, canceler);
        let rxPC = this.getProductionCompanies(id, canceler);
        let rxratingReasons = this.getRatingReasons(id, canceler);
        let rxSynopsis = this.getSynopsis(id, canceler);
        this.getMasteringInfo(); // no need to block is not related to title
        let rxTalent = this.getTalent(id, canceler);

        rxLanguages.pipe(
            merge(rxLinks, rxPC, rxTalent, rxratingReasons, rxSynopsis),
            takeUntil(canceler)).subscribe(
            (event) => {
                Dispatcher.dispatch(event);
            },
            (/*error*/) => {
                //todo handle error
            }
        );
    }

    getAspectRatios() {
        this.getLookups('aspect-ratio', CONSTANTS.TITLE.ASPECT_RATIO.GET.SUCCESS);
    }

    getAudios() {
        this.getLookups('audio', CONSTANTS.TITLE.AUDIO.GET.SUCCESS);
    }

    getSeasonAssetCount(id, assetType, userId, canceler) {
        let rxSeasonAssetCount = new Subject();
        Request.get(`title/${id}/web/series-season-count`).query({
            'asset-type': assetType,
            jp: userId
        }).canceler(canceler).then(assetCountRes => {
            rxSeasonAssetCount.next({
                actionType: CONSTANTS.TITLE.SEASON_SINGLE_ASSET_COUNT.SUCCESS,
                assetType,
                assetCount: Immutable.fromJS(assetCountRes.body.filter(value => {
                    if (value.titleId !== parseInt(id)) {
                        return value;
                    }
                }))
            });
        }).catch(/* istanbul ignore next */err => {
            throw err;
        });
        return rxSeasonAssetCount;
    }

    getEpisodesFromSeason(seasonNumber, id) {
        Request.get(`title/${id}/related-title`).query({active: true}).then(relatedRes => {
            let episodes = [];
            relatedRes.body.forEach(relatedTitle => {
                switch (relatedTitle.relationshipType) {
                case CONSTANTS.TITLE_RELATIONSHIP_TYPE.EPISODE:
                    episodes.push(relatedTitle);
                    break;
                }
            });
            episodes = Immutable.fromJS(episodes);
            Dispatcher.dispatch({
                actionType: CONSTANTS.TITLE.EPISODES_FOR_SEASON.SUCCESS,
                seasonNumber: seasonNumber,
                episodes: episodes.sortBy(r => r.get('orderWithinParent')),
            });
            return this.getTitles(episodes.map( e => {return e.get('childTitleId');}));
        }).catch(/* istanbul ignore next */err => {
            throw err;
        });
        return;
    }

    getGenres(id, canceler) {
        return this.getLookupsByTitleId('genre', id, CONSTANTS.TITLE.GENRES.GET.SUCCESS, canceler);
    }

    getLanguages(id, canceler) {
        return this.getLookupsByTitleId('language', id, CONSTANTS.TITLE.LANGUAGES.GET.SUCCESS, canceler);
    }

    getExternalLinks(id) {
        const canceler = this._getCanceler(id);
        this.getLinks(id, canceler).pipe(takeUntil(canceler)).subscribe(Dispatcher.dispatch);
    }

    getLinks(id, canceler) {
        let rxLinks = new Subject();
        Request.get(`title/${id}/link/web`).canceler(canceler).then(titleLinkRes => {
            rxLinks.next({
                actionType: CONSTANTS.TITLE.LINKS.GET.SUCCESS,
                links: Immutable.fromJS(titleLinkRes.body)
            });
            rxLinks.complete();
        });
        return rxLinks;
    }

    getLookups(lookup, constant) {
        Request.get(`title/lookup/${lookup}`).then(lookupRes => {
            Dispatcher.dispatch({
                actionType: constant,
                lookup: Immutable.fromJS(lookupRes.body)
            });
            return;
        });
        return;
    }

    getLookupsByTitleId(lookup, id, constant, canceler) {
        let rxLookup = new Subject();
        Request.get(`title/${id}/${lookup}`).canceler(canceler).then(lookupTitleRes => {
            return Promise.all(
                lookupTitleRes.body.map(lookupRes => Request.get(`lookup/${lookup}/${lookupRes[`${lookup}Id`]}`).canceler(canceler))
            );
        }).then(lookupRes => {
            rxLookup.next({
                actionType: constant,
                lookup: Immutable.fromJS(lookupRes.map(l => l.body))
            });
            rxLookup.complete();
        });
        return rxLookup;
    }

    getNetworks(id, canceler) {
        let rxNetworks = new Subject();
        Request.get(`title/${id}/release`).canceler(canceler).then(releaseRes => {
            /** Get Releases */
            /** Get Releasing Company / Networks */
            let ids = releaseRes.body.map(
                related => related.domesticReleaseCompanyId
            ).filter(
                i => i !== undefined && i !== null
            );
            Dispatcher.dispatch({
                actionType: CONSTANTS.TITLE.RELEASE_DATES.GET.SUCCESS,
                releaseDates: Immutable.fromJS(releaseRes.body)
            });
            return Promise.all(
                ids.map(relatedId => Request.get(`lookup/company/${relatedId}`).canceler(canceler))
            );
        }).then(networkRes => {
            rxNetworks.next({
                actionType: CONSTANTS.TITLE.NETWORKS.GET.SUCCESS,
                networks: Immutable.fromJS(networkRes.map(n => n.body))
            });
        }).catch(/* istanbul ignore next */err => {
            throw err;
        });
        return rxNetworks;
    }

    getMasteringInfo() {
        this.getAspectRatios();
        this.getAudios();
        this.getOriginalSources();
        this.getStandards();
        this.getSourceMaster();
    }

    getOriginalSources() {
        this.getLookups('original-source', CONSTANTS.TITLE.ORIGINAL_SOURCE.GET.SUCCESS);
    }

    getProductionCompanies(id, canceler) {
        let rxPC = new Subject();
        Request.get(`title/${id}/production-companies`).canceler(canceler).then(companiesTitleRes => {
            return Promise.all(
                companiesTitleRes.body.map(company => Request.get(`lookup/company/${company.productionCompanyId}`).canceler(canceler))
            );
        }).then(companyRes => {
            rxPC.next({
                actionType: CONSTANTS.TITLE.PRODUCTION_COMPANIES.GET.SUCCESS,
                lookup: Immutable.fromJS(companyRes.map(c => c.body))
            });
            rxPC.complete();
        }).catch(/* istanbul ignore next */err => {
            throw err;
        });
        return rxPC;
    }

    getRatingReasons(id, canceler) {
        let rxRatingReasons = new Subject();
        Request.get(`title/${id}/rating-reason`).canceler(canceler).then((result) => {
            let ratingReasonArray = result.body.map((rr)=>{
                return rr.ratingReason;
            });
            rxRatingReasons.next({
                actionType: CONSTANTS.TITLE.RATING_REASONS.GET.SUCCESS,
                ratingReasonArray
            });

            rxRatingReasons.complete();
        }).catch(/* istanbul ignore next */err => {
            throw err;
        });

        return rxRatingReasons;
    }

    getRelated(id, canceler) {
        let rxRelated = new Subject();
        Request.get(`title/${id}/related-title`).query({active: true}).canceler(canceler).then(relatedRes => {
            let episodes = [];
            let related = [];
            let seasons = [];
            relatedRes.body.forEach(relatedTitle => {
                switch (relatedTitle.relationshipType) {
                case CONSTANTS.TITLE_RELATIONSHIP_TYPE.EPISODE:
                case CONSTANTS.TITLE_RELATIONSHIP_TYPE.HAS_EPISODE:
                    episodes.push(relatedTitle);
                    break;
                case CONSTANTS.TITLE_RELATIONSHIP_TYPE.SEASON:
                case CONSTANTS.TITLE_RELATIONSHIP_TYPE.HAS_SEASON:
                    seasons.push(relatedTitle);
                    break;
                default:
                    related.push(relatedTitle);
                    break;
                }
            });
            rxRelated.next({
                actionType: CONSTANTS.TITLE.RELATED.GET.SUCCESS,
                episodes: Immutable.fromJS(episodes).sortBy(r => r.get('orderWithinParent')),
                related: Immutable.fromJS(related).sortBy(r => r.get('orderWithinParent')),
                seasons: Immutable.fromJS(seasons).sortBy(r => r.get('orderWithinParent'))
            });
        }).catch(/* istanbul ignore next */err => {
            throw err;
        });
        return rxRelated;
    }

    getSourceMaster() {
        this.getLookups('source-master', CONSTANTS.TITLE.SOURCE_MASTER.GET.SUCCESS);
    }

    getStandards() {
        this.getLookups('standard', CONSTANTS.TITLE.STANDARD.GET.SUCCESS);
    }

    getSuggestedUsers(term, offset, size) {
        offset = offset || 0;
        size = size || 20;
        Request.get('user').query({'email': term, offset, size}).then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TITLE.SUBSCRIPTION.SUGGESTED_USERS.SUCCESS,
                results: Immutable.fromJS(res.body.results)
            });
            return;
        });
        return;
    }

    getSynopsis(id, canceler) {
        let rxSynopsis = new Subject();
        Request.get(`title/${id}/synopsis`).canceler(canceler).then((result) => {
            rxSynopsis.next({
                actionType: CONSTANTS.TITLE.SYNOPSIS.GET.SUCCESS,
                synopses: Immutable.fromJS(result.body)
            });

            rxSynopsis.complete();
        }).catch(/* istanbul ignore next */err => {
            throw err;
        });

        return rxSynopsis;
    }

    getTalent(id, canceler) {
        let rxTalent = new Subject();
        Request.get(`title/${id}/talent`).canceler(canceler).then(titleTalentRes => {
            rxTalent.next({
                actionType: CONSTANTS.TITLE.TALENT.GET.SUCCESS,
                titleTalent: Immutable.fromJS(titleTalentRes.body)
            });
            rxTalent.complete();
        }).catch(/* istanbul ignore next */err => {
            throw err;
        });
        return rxTalent;
    }

    getThemes(id, canceler) {
        return this.getLookupsByTitleId('theme', id, CONSTANTS.TITLE.THEMES.GET.SUCCESS, canceler);
    }

    // Get a list of titles from the API.
    // Dispatch them one by one to the store so that we don't have
    // to wait for all the response to come.
    getTitles(ids) {
        return Promise.all(ids.toJS().map(id => {
            return Request.get(`title/${id}`).then((titleRes) => {
                let title = titleRes.body;

                Dispatcher.dispatch({
                    actionType: CONSTANTS.TITLES.GET.SUCCESS,
                    title: Immutable.fromJS(title)
                });
            });
        }));
    }

    hideAudioPreview() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE.AUDIO.PREVIEW.HIDE
        });
    }

    showAudioPreview(audio) {
        Request.get(`asset/audio/${audio.get('assetId')}`).then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TITLE.AUDIO.PREVIEW.SHOW,
                audio: audio.set('audioURL', res.body.audioURL)
            });

            return;
        });

        return;
    }

    shareTitle(id, email) {
        Request.post(`web/share-title/${id}`).send({emailAddress: email}).then(() => {
            NotificationActions.showAlertSuccess('title.modal.share.success');

            return;
        }).catch(/* istanbul ignore next */err => {
            NotificationActions.showAlertDanger('title.modal.share.error');
            throw err;
        });
        return;
    }
}

let actions = new TitleActions();

export {
    actions as TitleActions,
    CONSTANTS as TitleConstants
};
