/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';
import {UAParser} from 'ua-parser-js';

import Analytics from '../analytics';
import {WBTVDAnalyticsConstants, WBTVDAnalyticsExtraInfo} from '../analytics/wbtvd-analytics';
import Config from '../config/config';
import {Dispatcher} from '../flux-helpers';
import {NotificationActions} from '../notification/notification-actions';
import Request from '../request';
import mfa from '../session/mfa';
import SessionStore from '../session/session-store';


const CONSTANTS = {
    HIDE_PLAYER: 'player.hide',
    OS_NAMES: {
        WINDOWS: 'Windows'
    },
    SET_SELECTED: 'player.set_selected',
    SHOW_PLAYER_LOADING: 'player.show.loading',
    SHOW_PLAYER: 'player.show',
    WATCHLIST_DETAIL: {
        CREATE: {
            SUCCESS: 'watchlist_detail.create.success'
        },
        GET: {
            SUCCESS: 'watchlist_detail.get.success'
        },
        UPDATE: {
            SUCCESS: 'watchlist_detail.update.success'
        }
    }
};

class BrowserNotSupportedError extends Error {
    constructor() {
        super();
        this.i18nKey = 'player.errors.browser.not-supported';
        return;
    }
}

class DRMNotSupportedError extends Error {
    constructor() {
        super();
        // This key is used to call the nofications actions.
        this.i18nKey = 'player.errors.browser.not-supported';
        return;
    }
}

class PlayerActions {
    analyticsVideoPlaybackError(video, errorCode, errorMessage) {
        let id = video.get('id');
        if (id === undefined) {
            id = video.get('assetId');
        }

        Analytics.videoPlaybackEvent(id, video.get('titleId'), WBTVDAnalyticsConstants.VIDEO.PLAYBACK_TYPE.ERROR, 0, null, null, false, errorCode, errorMessage);
    }

    async _isOSVersionSupported() {
        if ('userAgentData' in navigator) {
            try {
                const {platform, platformVersion} = await navigator.userAgentData.getHighEntropyValues(['platformVersion']);
                if (platform === CONSTANTS.OS_NAMES.WINDOWS) {
                    const majorPlatformVersion = parseInt(platformVersion.split('.')[0]);
                    return majorPlatformVersion > 0;
                }
                return true;
            } catch (e) {
                return true;
            }
        }

        const os = new UAParser(navigator.userAgent).getOS();
        if (os.name === CONSTANTS.OS_NAMES.WINDOWS) {
            const unsupportedVersions = ['XP', 'Vista', '7', '8', '8.1'];
            return !unsupportedVersions.includes(os.version);
        }
        return true;
    }

    async checkOSVersionSupport() {
        const isOSVersionSupported = await this._isOSVersionSupported();
        if (!isOSVersionSupported) {
            NotificationActions.showAlertDanger('errors.playback-not-supported.content-security', true);
        }
    }

    _areStreamsValid(streams) {
        return !['drmMpegDashUrl', 'drmHlsUrl', 'src', 'videoStreamUrl'].every(stream => !streams[stream]);
    }

    createWatchlistDetail(videoId, titleId) {
        let titleIdRequest = Promise.resolve({body: [{titleId}]});

        if (!titleId) {
            titleIdRequest = Request.get(`asset/${videoId}/title`);
        }

        return titleIdRequest.then(titleRes => {
            return Request.post(`user/watch-list/${titleRes.body[0].titleId}/${videoId}`).then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.WATCHLIST_DETAIL.CREATE.SUCCESS,
                    videoId,
                    watchlistDetail: Immutable.fromJS(res.body)
                });

                return;
            });
        });
    }

    getWatchlistDetail(videoId, titleId) {
        return Request.get(`user/watch-list/${videoId}`).then(res => {
            let watchlistDetail = res.body;
            if (!watchlistDetail) {
                return this.createWatchlistDetail(videoId, titleId);
            }

            Dispatcher.dispatch({
                actionType: CONSTANTS.WATCHLIST_DETAIL.GET.SUCCESS,
                videoId,
                watchlistDetail: Immutable.fromJS(watchlistDetail)
            });

            return;
        }).catch(err => {
            switch (err.status) {
            case 404:
                return this.createWatchlistDetail(videoId, titleId);
            default:
                console.error(`Unable to get or create watchlist for video ${videoId}. Playing anyways.`);
                console.error(err);
                return;
            }
        });
    }

    _hasDRM(video, fromHomepage = false) {
        let stream = video;
        if (!fromHomepage) {
            stream = video.getIn(['streams', 0]);
        }

        let hasDashLicense = !!stream.get('drmAmsCencLicenseUrl');
        let hasDashSource = !!stream.get('drmMpegDashUrl');

        let hasHlsLicense = !!stream.get('drmAmsHlsFairplayLicenseUrl');
        let hasHlsSource = !!stream.get('drmHlsUrl');

        // This is an error because it has a license but not a source.
        if ((hasDashLicense && !hasDashSource) ||
            (hasHlsLicense && !hasHlsSource)) {
            NotificationActions.showAlertDanger('player.errors.drm.has-license-but-no-source');

            this.hidePlayer();

            let err = new Error(`Video ${video.get('assetId')} (${video.get('mediaKey')}) has license URL but no stream value.`);
            err.defaultPrevented = true;
            return {err};
        }

        // This is an error, because it has Dash DRM info but doesn't have HLS DRM.
        if (hasDashLicense !== hasHlsLicense) {
            NotificationActions.showAlertDanger('player.errors.drm.incomplete-license');

            this.hidePlayer();

            let err = new Error(`Video ${video.get('assetId')} (${video.get('mediaKey')}) has incomplete DRM info (a license is missing).`);
            err.defaultPrevented = true;
            return {err};
        }

        if (hasDashLicense && hasHlsLicense) {
            // This means the video has valid DRM. Before returning true, let's check
            // if the browser can actually play DRM content.
            if (!this._isDRMCapable()) {
                let err = new DRMNotSupportedError();
                return {err};
            }

            return {hasDRM: true};
        }

        return {hasDRM: false};
    }

    _isBrowserSupported() {
        //If browser is mobile (iOS or Android, we kick them to get the screeners app)
        return ![WBTVDAnalyticsConstants.PLATFORMS.IOS, WBTVDAnalyticsConstants.PLATFORMS.ANDROID].includes(WBTVDAnalyticsExtraInfo.getPlatform(navigator));
    }

    _isDRMCapable() {
        return !!(
            window.MediaKeys || window.WebKitMediaKeys || window.MSMediaKeys
        );
    }

    _requestToken(video) {
        let query = {
            minutes: 1,
            persistent: false,
        };

        return Request.get(`asset/video/${video.get('assetId')}/drm-token`)
            .query(query)
            .then(res => res)
            .catch(err => {
                NotificationActions.showAlertDanger('player.errors.drm.token.404');
                err.defaultPrevented = true;
                this.analyticsVideoPlaybackError(video, WBTVDAnalyticsConstants.VIDEO.PLAYBACK_ERROR_TYPE.DRM_REQUEST_FAILED, err.message);
                throw err;
            });
    }

    updateWatchlist(videoId, position, titleId) {
        let titleIdRequest = Promise.resolve({body: [{titleId}]});

        if (!titleId) {
            titleIdRequest = Request.get(`asset/${videoId}/title`);
        }

        titleIdRequest.then(titleRes => {
            Request.put(`user/watch-list/${titleRes.body[0].titleId}/${videoId}`).send({lastPosition: position.toString()}).then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.WATCHLIST_DETAIL.UPDATE.SUCCESS,
                    videoId,
                    watchlistDetail: Immutable.fromJS(res.body)
                });

                return;
            }).catch(err => {
                throw err;
            });
        });
    }

    getAndPlay(videoId, childVideoId, titleId) {
        this.showPlayer(
            Immutable.fromJS({
                assetId:videoId,
                childStackAssetCount: 1 //cheat to force player to lookup children (if they exist)
            }),
            childVideoId,
            titleId
        );
    }

    hidePlayer() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.HIDE_PLAYER
        });
    }

    // So far, this method is only used for playlists.
    // It assumes that a video already has a streams object. It only
    // lacks of the tokens in case of DRM.
    playVideo(video, titleId) {
        let p = Promise.resolve();

        let {hasDRM, err} = this._hasDRM(video);
        if (err) {
            // Show error in console.
            throw err;
        }

        if (hasDRM && !video.get('drmToken')) {
            // Request the token.
            p = this._requestToken(video);
        }

        if (hasDRM && !this._isBrowserSupported()) {
            const notSupportedError = new BrowserNotSupportedError();
            NotificationActions.showAlertWarning(
                // Use error's specific message.
                notSupportedError.i18nKey
            );
            throw notSupportedError;
        }

        Promise.all([
            p,
            this.getWatchlistDetail(
                video.get('assetId'),
                titleId
            )
        ]).spread(tokenRes => {
            if (tokenRes) {
                video = video.set('drmToken', Immutable.fromJS(tokenRes.body));
            }

            Dispatcher.dispatch({
                actionType: CONSTANTS.SET_SELECTED,
                video
            });

            return;
        }).catch(error => {
            throw error;
        });
    }

    /**
     * Show video player, after loading the streamURLs for the main video
     * and any children it has.
     */
    showPlayer(video, childVideoId, titleId) {
        let childVideo;

        Dispatcher.dispatch({
            actionType: CONSTANTS.SHOW_PLAYER_LOADING,
        });

        let assetIds;

        // Only request video if incomplete. Right now, one of the few fields
        // we can use to know if we have an "Asset Search Result" or a full
        // fledged "Video" is the forensicUrlRequired field.
        let videoReq = null;
        if (video.get('forensicUrlRequired') === undefined) {
            videoReq = Request.get(`asset/video/${video.get('assetId')}`);
        } else if (video.get('assetId') === undefined) {
            // Real video objects (instances of Video.java) only have id.
            // Make sure the object has both assetId and id so that the
            // following code works.
            video = video.set('assetId', video.get('id'));
        }

        // Only request the thumbnails if the video doesn't have them.
        let thumbnailsReq = null;
        if (!video.get('thumbnails')) {
            thumbnailsReq = Request.get('asset/video/thumbnailURL').query({'video-id': video.get('assetId')});
        }

        let userId = SessionStore.getState().getIn(['authUser', 'id']);

        let childrenPromise = Promise.resolve([]);
        /* istanbul ignore next */
        if (video.get('childStackAssetCount') > 0) {
            // Get all children asset to title.
            childrenPromise = Request.get(
                `title/${video.get('titleId', titleId)}/web/asset/${video.get('assetId')}/stack-asset`
            ).query({
                offset: 0,
                size: 1000 // this is set for adding all stacked videos
            }).then(stackedAssetsRes => {
                // Get all individual assets.
                return Promise.all(stackedAssetsRes.body.map(
                    a => Request.get(
                        `asset/video/${a.assetId}`
                    ).then(
                        assetRes => Object.assign({}, a, assetRes.body)
                    ).catch(err => {
                        NotificationActions.showAlertDanger('player.errors.children');
                        err.defaultPrevented = true;
                        throw err;
                    })
                ));
            }).catch(err => {
                NotificationActions.showAlertDanger('player.errors.children');
                console.error('Could not get all children videos. This is the error, continuing anyways.', err);
                // Fix the response.
                return [];
            });
        }

        Promise.props({
            children: childrenPromise,
            thumbnails: thumbnailsReq,
            video: videoReq,
        }).then(res => {
            // Add all needed properties to the video object.
            if (res.video) {
                video = video.merge(res.video.body);
                // And set the name following our custom rules.
                video = video.set('name', res.video.body.displayTitle || res.video.body.assetName);
            }

            if (res.thumbnails) {
                video = video.set('thumbnails', Immutable.fromJS(res.thumbnails.body[0].thumbnailList).sortBy(t => t.get('width')));
            }

            // Set the children if any.
            video = video.set('children', Immutable.fromJS(res.children));
            // And get the assetIds to request the streams.
            assetIds = res.children.map(a => a.assetId);

            let mfaPromise = Promise.resolve();

            // BRAIN-3350: LAS anonymous users should be able to play videos
            // no matter the MFA state.
            if (Config.Partners.LA_SCREENINGS.id === SessionStore.getPartner().id) {
                return mfaPromise;
            }

            if (video.get('mfaRequired') || video.get('children', Immutable.List()).some(vc => vc.get('mfaRequired'))) {
                mfaPromise = mfa.RequestMFA(userId, false).then(mfaRes => mfaRes).catch(err => {
                    switch (true) {
                    case err instanceof mfa.MFARejectedError:
                        NotificationActions.showAlertDanger('player.errors.mfa.rejected');
                        break;
                    case err instanceof mfa.MFARequestError:
                        NotificationActions.showAlertDanger('player.errors.mfa.status');
                        break;
                    case err instanceof mfa.MFAUnknownStatusError:
                    case err instanceof mfa.MFAUnregisteredError:
                        break;
                    default:
                        NotificationActions.showAlertDanger('player.errors.mfa.400');
                        break;
                    }

                    // Set the error as default prevented so that the next handler
                    // doesn't add alerts on top of existing alerts.
                    err.defaultPrevented = true;
                    throw err;
                });
            }

            return mfaPromise;
        }).then(() => {
            if (video.get('forensicUrlRequired')) {
                if (!this._isBrowserSupported()) {
                    throw new BrowserNotSupportedError();
                }
                return Request.get(`asset/video/${video.get('assetId')}/forensic-url`).then(res => res).catch(err => {
                    NotificationActions.showAlertDanger('player.errors.streams.400');
                    err.defaultPrevented = true;
                    throw err;
                });
            }
            return Request.get('asset/video/streamURL').query({
                'video-id': [video.get('assetId'), ...assetIds]
            }).then(res => res).catch(err => {
                NotificationActions.showAlertDanger('player.errors.streams.400');
                err.defaultPrevented = true;
                throw err;
            });
        }).then(streamsRes => {
            let arr = streamsRes.body;
            if (arr.id) {
                arr = [streamsRes.body];
            }
            arr.forEach(streams => {
                if (!this._areStreamsValid(streams)) {
                    this.hidePlayer();
                    NotificationActions.showAlertDanger('player.errors.no-sources');
                    let err = new Error(`No playable sources found for video ${video.get('assetId')} (${video.get('mediaKey')}).`);
                    err.defaultPrevented = true;
                    throw err;
                }

                if (streams.videoId === video.get('assetId')) {
                    // Attach Stream URLs to parent video.
                    video = video.set('streams', Immutable.fromJS([streams]));
                } else {
                    // Attach Stream URLs to child video.
                    let childIndex = video.get('children').findIndex(t => t.get('assetId') === streams.videoId);
                    video = video.setIn(['children', childIndex, 'streams'], Immutable.fromJS([streams]));
                    if (streams.videoId === childVideoId) {
                        childVideo = video.getIn(['children', childIndex]);
                    }
                }
            });
            let v = childVideo || video;

            let tokenRes;
            let {hasDRM, err} = this._hasDRM(v);
            if (err) {
                if (err instanceof DRMNotSupportedError) {
                    this.analyticsVideoPlaybackError(v, WBTVDAnalyticsConstants.VIDEO.PLAYBACK_ERROR_TYPE.DRM_NOT_SUPPORTED);
                } else {
                    this.analyticsVideoPlaybackError(v, WBTVDAnalyticsConstants.VIDEO.PLAYBACK_ERROR_TYPE.UNKNOWN_ERROR, err.message);
                }
                // Throw error (instead of return) to reject the promise and prevent
                // further promises from running.
                throw err;
            }

            if (hasDRM) {
                if (!this._isBrowserSupported()) {
                    this.analyticsVideoPlaybackError(v, WBTVDAnalyticsConstants.VIDEO.PLAYBACK_ERROR_TYPE.BROWSER_NOT_SUPPORTED);
                    throw new BrowserNotSupportedError();
                }
                tokenRes = this._requestToken(v);
            }

            return Promise.props({
                tokenRes,
                watchlistRes: this.getWatchlistDetail(v.get('assetId'), titleId)
            });
        }).then(res => {
            if (res.tokenRes) {
                if (childVideo) {
                    childVideo = childVideo.set('drmToken', Immutable.fromJS(res.tokenRes.body));
                } else {
                    video = video.set('drmToken', Immutable.fromJS(res.tokenRes.body));
                }
            }

            Dispatcher.dispatch({
                actionType: CONSTANTS.SHOW_PLAYER,
                video,
                selectedVideo: childVideo || Immutable.Map()
            });

            return;
        }).catch(err => {
            this.hidePlayer();

            if (!err) {
                return;
            }

            if (!err.defaultPrevented) {
                // Use error's specific message or the default.
                const message = err.i18nKey || 'titles.videos.error';
                let alertMethod = 'showAlertDanger';
                if (err instanceof BrowserNotSupportedError) {
                    alertMethod = 'showAlertWarning';
                }
                NotificationActions[alertMethod](message);
            }

            throw err;
        });
    }
}

let actions = new PlayerActions();

export {
    actions as PlayerActions,
    CONSTANTS as PlayerConstants,
    BrowserNotSupportedError as BrowserNotSupportedError,
    DRMNotSupportedError as DRMNotSupportedError
};
