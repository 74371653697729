/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ResizeObserverPolyfill from 'resize-observer-polyfill';

import SliderFiller from './slider-filler';
import SliderHandler from './slider-handle';

import './slider.less';

export default class Slider extends React.PureComponent {
    static get propTypes() {
        return {
            handle: PropTypes.func,
            max: PropTypes.number.isRequired,
            min: PropTypes.number,
            onChangeValue: PropTypes.func,
            value: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.instanceOf(Immutable.List),
            ]).isRequired,
        };
    }

    static get defaultProps() {
        return {
            onChangeValue: () => void 0,
            handle: () => void 0,
            min: 0,
        };
    }

    constructor(props) {
        super(props);
        this.getSliderRect = this.getSliderRect.bind(this);
        this.handleRangeChanged = this.handleRangeChanged.bind(this);
        this.handleResizeEvent = this.handleResizeEvent.bind(this);
        this.sliderRef = React.createRef();
        this.sliderClientRect = null;
    }

    componentDidMount() {
        this.observer = new ResizeObserverPolyfill(this.handleResizeEvent);
        this.observer.observe(this.sliderRef.current);
    }

    componentWillUnmount() {
        this.observer.unobserve(this.sliderRef.current);
        this.observer = null;
    }

    isRange() {
        return Immutable.List.isList(this.props.value);
    }

    handleRangeChanged(value, idx) {
        const {min, max} = this.props;
        const isRange = this.isRange();
        if (isRange) {
            const currentValueAsArray = this.props.value.toArray();
            const previous = currentValueAsArray.slice(0, idx);
            const next = currentValueAsArray.slice(idx + 1);
            if (previous.length && Math.max.apply(null, previous) >= value) {
                return;
            } if (next.length && Math.min.apply(null, next) <= value) {
                return;
            }
            const range = this.props.value.set(idx, clamp(value, min, max));
            this.props.onChangeValue(Immutable.List(range));
        } else {
            this.props.onChangeValue(clamp(value, min, max));
        }
    }

    handleResizeEvent() {
        this.sliderClientRect = null;
    }

    getSliderRect() {
        if (!this.sliderClientRect) {
            this.sliderClientRect = this.sliderRef.current ? this.sliderRef.current.getBoundingClientRect() : null;
        }
        return this.sliderClientRect;
    }

    render() {
        const isRange = this.isRange();
        const {min, max, value, handle} = this.props;
        const range = isRange ? value : [value];
        const handlers = range.map((val, idx) => (
            <SliderHandler value={val} min={min} max={max} key={idx} getParentRect={this.getSliderRect}
                onValueChanged={(num) => this.handleRangeChanged(num, idx)}>
                {handle(val)}
            </SliderHandler>
        ));
        return (
            <div className="slider" ref={this.sliderRef}>
                {handlers}
                {isRange && <SliderFiller max={max} min={min} range={range} />}
            </div>
        );
    }
}

function clamp(value, min, max) {
    return Math.min(Math.max(min, value), max);
}
