/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {PlayerConstants} from './player-actions';
import {Dispatcher} from '../flux-helpers';
import {SessionConstants} from '../session/session-actions';


class PlayerStore extends ReduceStore {

    getInitialState() {

        return Immutable.Map({
            selectedVideo: Immutable.Map(),
            showOverlay: false,
            video: Immutable.Map(),
            watchlist: Immutable.Map()
        });
    }

    reduce(state, action) {

        switch (action.actionType) {

        case PlayerConstants.SHOW_PLAYER_LOADING:
            state = state.set('showOverlay', true).set('video', Immutable.Map());
            break;

        case PlayerConstants.SET_SELECTED:
            state = state.set('selectedVideo', action.video);
            let drmToken = action.video.get('drmToken');
            // Find the video and add the DRM token to it.
            if (drmToken) {
                // Some objects have assetId and some others have id, let's use
                // mediaKey which is available for all of them.
                let mediaKey = action.video.get('mediaKey');
                if (state.getIn(['video', 'mediaKey']) === mediaKey) {
                    state = state.setIn(['video', 'drmToken'], drmToken);
                } else {
                    state = state.updateIn(['video', 'children'], children => {
                        let i = children.findIndex(c => c.get('mediaKey') === mediaKey);
                        return children.setIn([i, 'drmToken'], drmToken);
                    });
                }
            }
            break;

        case SessionConstants.LOGOUT:
        case PlayerConstants.HIDE_PLAYER:
            state = state.set('showOverlay', false)
                .set('video', Immutable.Map())
                .set('selectedVideo', Immutable.Map());
            break;

        case PlayerConstants.SHOW_PLAYER:
            state = state.set('showOverlay', true)
                .set('video', action.video)
                .set('selectedVideo', action.selectedVideo);
            break;

        case PlayerConstants.WATCHLIST_DETAIL.CREATE.SUCCESS:
        case PlayerConstants.WATCHLIST_DETAIL.GET.SUCCESS:
        case PlayerConstants.WATCHLIST_DETAIL.UPDATE.SUCCESS:
            state = state.setIn(['watchlist', action.videoId.toString()], action.watchlistDetail);
            break;
        }
        return state;
    }
}

export default new PlayerStore(Dispatcher);
